import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { FormGroup } from '@angular/forms';
import { IPosLineItem } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { PointOfSaleHelperService } from '../../services/point-of-sale-helper.service';
import { EMPTY, Subject, Subscription, of } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'pos-order-table-qty-input',
  templateUrl: './pos-order-table-qty-input.component.html',
  styleUrls: ['./pos-order-table-qty-input.component.scss']
})
export class PosOrderTableQtyInputComponent implements OnInit, OnDestroy {

  @Input() item: IPosLineItem;
  @Input() form: FormGroup;
  @Input() shiftId: number;
  @Input() customer: ICustomer;

  @Output() itemUpdated: EventEmitter<IPosLineItem> = new EventEmitter();


  private subscription = new Subscription();
  private destroy$ = new Subject();

  public min = 0;
  public max = 0;

  constructor(
    private readonly helperService: PointOfSaleHelperService
  ) { }

  ngOnInit() {
    this.min = this.helperService.QTY_MIN;
    this.max = this.helperService.QTY_MAX;

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1000),
        distinctUntilChanged((prev, curr) => prev ? (prev.qty === curr.qty) : false),
        switchMap(value => {
          if (value) {
            return this.helperService.changeItemQuantityTrail(this.shiftId, this.item, this.customer, value.qty).pipe(catchError(e => of(this.item)));
          }
          else {
            return EMPTY;
          }
        })
      )
      .subscribe(updateItem => {
        if (updateItem) {
          this.item = updateItem;
          // this.form.controls.qty.patchValue(updateItem.quantity, false);
          this.itemUpdated.emit(updateItem);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.subscription.unsubscribe();
  }

}
