import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import * as moment from 'moment';
import { ICustomerItemSaleSummary } from '@otrack-lib/models/reports/customerItemSummary.model';

@Component({
  selector: 'app-printtransactionreport',
  templateUrl: './print-customer-item-report.component.html',
  styleUrls: ['./print-customer-item-report.component.scss']
})
export class PrintCustomerItemReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  items: ICustomerItemSaleSummary[];
  totalQuantity = 0;
  totalSubTotal = 0;
  totalCogs = 0;
  user: IAuthUser;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchCustomerItemSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchCustomerItemSummaryReport() {
    this.reportService.getCustomerItemSaleSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.items = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.totalSubTotal = this.items.map(t => t.subTotal).reduce((acc, value) => acc + value, 0);
    this.totalQuantity = this.items.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
    this.totalCogs = this.items.map(t => t.cogs).reduce((acc, value) => acc + value, 0);

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
