import { IPosOrderItem, IPosOrderPayment, IPosTrailItem } from './../../models/point-of-sale/point-of-sale.models';
import { Injectable, Injector } from '@angular/core';
import { BaseApiService } from '@otrack-lib/core/services/baseapi.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { HttpUtilsService } from '@otrack-lib/core/_base/crud';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IPosOrderList,
  IPosShift,
  IPosRegister,
  IPosShiftSummary
} from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { PointOfSaleTrailService } from './point-of-sale-trail.service';

@Injectable({
  providedIn: 'root'
})
export class PointOfSaleService extends BaseApiService {

  readonly API_URLS = {
    registers: `${this.baseApiUrl}/v1/pos/registers`,
    shifts: `${this.baseApiUrl}/v1/pos/shifts`,
    activeShifts: `${this.baseApiUrl}/v1/pos/shifts/active`,
    startShift: `${this.baseApiUrl}/v1/pos/shifts/start`,
    orders: `${this.baseApiUrl}/v1/pos/orders`,
    orderHold: `${this.baseApiUrl}/v1/pos/orders/hold`,
  };

  static getPosShiftModel(res: any): IPosShift {
    return {
      shiftId: res.shiftId,
      sequenceNumber: res.sequenceNumber,
      startedByUserId: res.startedByUserId,
      closedByUserId: res.closedByUserId,
      startingCash: res.startingCash,
      closingCash: res.closingCash
    };
  }

  static getPosRegisterModel(res: any): IPosRegister {
    return {
      id: res.id,
      name: res.name,
      description: res.description
    };
  }

  static getPosShiftSummaryModel(res: any): IPosShiftSummary {
    return {
      shiftId: res.shiftId,
      sequenceNumber: res.sequenceNumber,
      startedByUserId: res.startedByUserId,
      startTime: res.startTime,
      closeTime: res.closeTime,
      taxCollected: res.taxCollected,
      startingCash: res.startingCash,
      closingCash: res.closingCash ? res.closingCash : 0,
      cashSales: res.cashSales,
      checkSales: res.checkSales,
      creditCardSales: res.creditCardSales,
      onAccountSales: res.onAccountSales,
      overPayment: res.overPayment
    };
  }

  static getPosOrderListModel(res: any): IPosOrderList {
    return {
      customerId: res.customerId,
      customerName: res.customerName,
      companyName: res.companyName,
      customerAddress: res.customerAddress,
      customerCity: res.customerCity,
      customerState: res.customerState,
      customerPostalCode: res.customerPostalCode,
      customerEmail: res.customerEmail,
      customerOpenBalance: res.customerOpenBalance,
      orderId: res.orderId,
      salesOrderId: res.salesOrderId,
      isQBCustomer: res.isQBCustomer,
      openBalance: res.openBalance,
      itemsCount: res.itemsCount,
      totalAmount: res.totalAmount,
      totalSuggestedRetailPrice: res.totalSuggestedRetailPrice,
      taxPercent: res.taxPercent,
      totalTax: res.totalTax,
      totalCredit: res.totalCredit,
      deliveryFee: res.deliveryFee,
      amountDue: res.amountDue,
      invoiceNumber: res.invoiceNumber,
      isPaid: res.isPaid,
      taxId: res.taxId,
      memo: res.memo,
      invoiceDate: res.invoiceDate,
      orderDate: res.orderDate,
      isTaxable: res.isTaxable,
      isTaxableInvoice: res.isTaxableInvoice,
      invoiceBy: res.invoiceBy,
      isSync: res.isSync,
      deliveryType: res.deliveryType,
      orderType: res.orderType,
      discountType: res.discountType,
      discountValue: res.discountValue,
      convertInvoiceId: res.convertInvoiceId,
      convertDate: res.convertDate,
      status: res.status,
      salesOrderType: res.salesOrderType,
      chargeStatus: res.chargeStatus,
      isDiscounted: res.isDiscounted,
      items: res.items ? this.getPosOrderItemModel(res.items) : []
    };
  }

  static getPosOrderItemModel(items: any): IPosOrderItem[] {
    const output: IPosOrderItem[] = [];
    items.forEach(res => {
      output.push({
        id: res.id,
        productId: res.productId,
        quantity: res.quantity,
        originalPrice: res.originalPrice,
        price: res.price,
        cost: res.cost,
        sortOrder: res.sortOrder,
        isTaxable: res.isTaxable ? res.isTaxable : (res.taxable ? res.taxable : false),
        substitutable: res.substitutable,
        minAge: res.minAge,
        isAgeRestricted: res.isAgeRestricted,
        isWishListItem: res.isWishListItem,
        options: res.options ? res.options : []
      });
    });
    return output;
  }

  constructor(private _injector: Injector, private httpUtils: HttpUtilsService, private userService: UserService) {
    super(_injector);
  }

  startShift(startingCash: number, registerId?: number): Observable<IPosShift> {
    const data = {
      startingCash,
      // registerId
    };

    return this.httpAuthPost(this.API_URLS.startShift, data).pipe(
      map(res => PointOfSaleService.getPosShiftModel(res))
    );
  }

  endShift(closingCash: number, shiftId: number, registerId?: number): Observable<IPosShift> {
    const data = {
      closingCash
      // registerId
    };

    return this.httpAuthPost(`${this.API_URLS.shifts}/${shiftId}/end`, data).pipe(
      map(res => PointOfSaleService.getPosShiftModel(res))
    );
  }

  getActiveShifts(): Observable<IPosShift[]> {
    return this.httpAuthGet(this.API_URLS.activeShifts).pipe(
      map(res => {
        const shifts: IPosShift[] = [];
        if (res && res.data) {
          res.data.forEach(element => {
            shifts.push(PointOfSaleService.getPosShiftModel(element));
          });
        }
        return shifts;
      })
    );
  }

  getShiftSummaryReport(shiftId: number): Observable<IPosShiftSummary> {
    return this.httpAuthGet(`${this.API_URLS.shifts}/${shiftId}/summary`).pipe(
      map(res => res && res.data ? PointOfSaleService.getPosShiftSummaryModel(res.data) : res)
    );
  }

  addRegister(name?: string, description?: string): Observable<IPosRegister> {
    // TODO: remove once add register supported from UI
    name = 'Pos terminal 1';
    description = 'Pos terminal 1';
    const data = {
      name,
      description
    };

    return this.httpAuthPost(this.API_URLS.registers, data).pipe(
      map(res => PointOfSaleService.getPosRegisterModel(res))
    );
  }

  getRegisters(): Observable<IPosRegister[]> {
    return this.httpAuthGet(this.API_URLS.registers).pipe(
      map(res => {
        const registers: IPosRegister[] = [];
        if (res && res.data) {
          res.data.forEach(element => {
            registers.push(PointOfSaleService.getPosRegisterModel(element));
          });
        }
        return registers;
      })
    );
  }

  submitOrderWithPayment(dataToSend: IPosOrderPayment): Observable<any> {
    return this.httpAuthPost(this.API_URLS.orders, dataToSend).pipe(map(res => res.data));
  }

  holdOrder(dataToSend: IPosOrderPayment): Observable<any> {
    return this.httpAuthPost(this.API_URLS.orderHold, dataToSend).pipe(map(res => res.data));
  }

  restoreOrder(orderId: number, customerId?: number, isMerge: boolean = false): Observable<IPosTrailItem[]> {
    const data = {
      customerId,
      isMerge
    };

    if (customerId) {
      data['customerId'] = customerId;
    }

    return this.httpAuthPost(`${this.API_URLS.orders}/${orderId}/restore`, data)
      .pipe(map(res => {
        const items = [];
        if (res && res.data) {
          res.data.forEach(item => {
            items.push(PointOfSaleTrailService.getPosTrailItemModel(item));
          });
        }
        return items;
      }));
  }

  removeOrder(orderId: number): Observable<boolean> {
    return this.httpAuthDelete(`${this.API_URLS.orders}/${orderId}/hold`, {})
      .pipe(map(res => (res && res.data) ? true : false));
  }

  getHoldOrdersByShiftId(shiftId: number): Observable<QueryResultsModel> {
    return this.httpAuthGet(`${this.API_URLS.shifts}/${shiftId}/holdOrders`).pipe(
      map(res => {
        const output: IPosOrderList[] = [];
        res.data.forEach(item => {
          output.push(PointOfSaleService.getPosOrderListModel(item));
        });
        return new QueryResultsModel(output, res.data.length);
      })
    );
  }

  getOrderByShiftId(shiftId: number): Observable<QueryResultsModel> {
    return this.httpAuthGet(`${this.API_URLS.shifts}/${shiftId}/orders`).pipe(
      map(res => {
        const output: IPosOrderList[] = [];
        res.data.forEach(item => {
          output.push(PointOfSaleService.getPosOrderListModel(item));
        });
        return new QueryResultsModel(output, res.data.length);
      })
    );
  }
}
