import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BarcodeGeneratorComponent } from './barcode-generator.component';

@NgModule({
  imports: [CommonModule, NgxBarcodeModule],
  declarations: [
    BarcodeGeneratorComponent
  ],
  exports: [
    BarcodeGeneratorComponent
  ]
})
export class BarcodeGeneratorModule { }
