export enum PosTransactionTypes  {
  Invoice = 'invoice',
  SalesOrder = 'sales_order',
  Payment = 'payment',
  ShiftOpen = 'shift_open',
  ShiftClose = 'shift_close',
  VoidSale = 'void_sale',
}


export enum PosActionTypes  {
  Added = 'added',
  Deleted = 'deleted',
  ChangePrice = 'change_price',
  ChangeQuantity = 'change_quantity',
  PaymentAdded = 'payment_added',
  OnHold = 'hold_order',
  OnHoldDelete = 'hold_delete',
  ShiftOpen = 'shift_open',
  ShiftClose = 'shift_close',
  VoidSale = 'void_sale',
}
