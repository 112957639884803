// Angular
import { Component, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';

import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import { Router } from '@angular/router';
import { AuthService } from '@otrack-lib/core/services/auth.service';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {
  // Public properties
  // user$: Observable<User>;
  user: IAuthUser;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private userService: UserService, private authService: AuthService, private router: Router) { }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // this.user$ = this.store.pipe(select(currentUser));
    this.userService.getUser().subscribe(user => {
      this.user = user;
    });
  }

  /**
   * Log out
   */
  async logout() {
    // this.store.dispatch(new Logout());
    await this.authService.logout();
  }

  gotoProfileSetting() {
    this.router.navigate(['/profile']);
  }
}
