import { ActivatedRoute, Router } from '@angular/router';
import { CompanySettingsService } from '@otrack-lib/core/services/company-settings.service';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// in future create separate callback for different payemnt gateways
@Component({
  selector: 'app-stripe-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private companySettingService: CompanySettingsService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(paramMap => {
      const code = paramMap['code'];
      const state = paramMap['state'];
      this.companySettingService.updateStripeAuthenticationCode(code, state).subscribe((res) => {
        if (res) {
          this.router.navigate(['/profile/payment']);
        }
      }, (error) => {
        this.router.navigate(['/profile/payment']);
      });
    });
  }

}
