<div class="pos-container">

  <div class="header">
    <div class="header-first-line">
      <div class="company-name"> {{ companyName }} </div>
      <div class="date-time">
        <span> {{ (dateTime$ | async) | date: 'MMM dd, yyyy' }} </span>
        <span> {{ (dateTime$ | async) | date: 'EEEE hh:mm a' }} </span>
        <span> {{ username }} </span>
        <span> <a mat-button (click)="onLogoutClicked()">Logout</a> </span>
      </div>
    </div>

    <div class="sub-header"> Start New Shift </div>
  </div>

  <div class="body">
    <div class="cash-in-hand" [formGroup]="form">
      <div class="title"> Add Cash in Hand </div>
      <input class="form-control form-item rounded-input" type="number" placeholder="0.00" formControlName="startingCash" name="closingCash" />
      <button type="button" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || loading">Enter</button>

      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  </div>

</div>
