import { takeUntil, tap } from 'rxjs/operators';
import { InvoiceTemplateType } from './../../../../models/company/company.model';
import { UserService } from './../../../../core/services/user.service';
import { SafeHtml } from '@angular/platform-browser';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { OrderTypes } from '@otrack-lib/enums';
import { OrderTemplateService } from '@otrack-lib/core/services/order-template.service';
import { Observable, Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'otrack-print-template',
  templateUrl: './print-template.component.html',
  styleUrls: ['./print-template.component.scss']
})
export class PrintTemplateComponent implements OnInit, OnDestroy, AfterViewInit {

  public isBarCodeShow = false;
  public htmlTemplate$: Observable<SafeHtml>;
  public companyTemplate: InvoiceTemplateType;
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    protected orderTemplateService: OrderTemplateService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private printService: PrintService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.companyTemplate = this.userService.getUserCompany().invoiceTemplate;

    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      const orderPullTemplate = urlSegments.find(seg => seg.path === InvoiceTemplateType.OrderPullTemplate) ? InvoiceTemplateType.OrderPullTemplate : null;
      this.loadTemplate(this.route.snapshot.params['data'].split(','), orderPullTemplate);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  loadTemplate(data: string[], template: InvoiceTemplateType = null) {
    this.isBarCodeShow = data[2] ? JSON.parse(data[2]) : false;
    const orderId: number = +data[1];
    const orderType = this.mapOrderType(data[0]);

    if (!data[0]) {
      return;
    }

    this.htmlTemplate$ = this.orderTemplateService.getTemplate(orderId, orderType, template ? template : this.companyTemplate, this.isBarCodeShow)
      .pipe(tap(() => this.onInvoiceReady()));
  }

  onInvoiceReady() {
    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
    }, 100);
  }

  private mapOrderType(orderTypeId: string): OrderTypes {
    switch (orderTypeId) {
      case '1':
        return OrderTypes.Invoice;
      case '2':
        return OrderTypes.CreditMemo;
      case '4':
        return OrderTypes.SalesOrder;
      case '5':
        return OrderTypes.PurchaseBill;
      case '6':
        return OrderTypes.PurchaseOrder;
    }
  }
}
