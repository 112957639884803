import { BarcodeGeneratorModule } from './../components/barcode-generator/barcode-generator.module';
import { CommonComponentModule } from './../components/common-component.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { MatTableModule } from '@angular/material';
import { PaymentTemplateComponent } from './payment-template/payment-template.component';
import { BillPaymentTemplateComponent } from './bill-payment-template/bill-payment-template.component';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { AdjustmentTemplateComponent } from './adjustment-template/adjustment-template.component';
import { ShiftCloseTemplateComponent } from './shift-close-template/shift-close-template.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    CommonComponentModule,
    BarcodeGeneratorModule,
    MaterialModule,
  ],
  declarations: [
    InvoiceTemplateComponent,
    PaymentTemplateComponent,
    BillPaymentTemplateComponent,
    AdjustmentTemplateComponent,
    ShiftCloseTemplateComponent
  ],
  exports: [
    InvoiceTemplateComponent,
    PaymentTemplateComponent,
    BillPaymentTemplateComponent,
    AdjustmentTemplateComponent,
    ShiftCloseTemplateComponent
  ]
})
export class TemplateModule { }
