import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { IDriver } from '@otrack-lib/models/driver/driver.model';

@Injectable({
  providedIn: 'root'
})
export class DriverService extends BaseApiService {

  readonly API_URLS = {
    drivers: `${this.baseApiUrl}/v1/delivery/drivers`,
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  static getDriverModel(res: any): IDriver {
    return {
      driverId: +res['id'],
      firstName: res['firstName'],
      lastName: res['lastName'],
      drivingLicense: res['driversLicense'],
      phoneNo: res['phoneNo'],
      userName: res['userName'],
      isActive: res['isActive'],
      email: res['email'],
      createdDate: res['createdDate'],
      };
  }



  getDriversList(): Observable<IDriver[]> {
     //return this.httpAuthGet('./assets/data/drivers-list.json').pipe(
      return this.httpAuthGet(`${this.API_URLS.drivers}`).pipe(
        map(res => {
          if (res && res.data) {
            const output: IDriver[] = [];
            res.data.forEach(dta => {
                  output.push(DriverService.getDriverModel(dta));
            });
            return output;
          }
          throw new ErrorModel(null, 'Drivers not found');
        })
      );
  }

  addDriver(driverInfo: IDriver): Observable<IDriver> {
    const dataToSend = {
      firstName: driverInfo.firstName,
      lastName: driverInfo.lastName,
      userName: driverInfo.userName,
      email: driverInfo.email,
      phoneNo: driverInfo.phoneNo,
      drivingLicence: driverInfo.drivingLicense,
      isActive: driverInfo.isActive,
      password: driverInfo.password,
    };
    return this.httpAuthPost(`${this.API_URLS.drivers}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Add driver failed');
      })
    );
  }


  updateDriver(driverInfo: IDriver): Observable<IDriver> {
    const dataToSend = {
      id: driverInfo.driverId,
      firstName: driverInfo.firstName,
      lastName: driverInfo.lastName,
      email: driverInfo.email,
      phoneNo: driverInfo.phoneNo,
      driversLicense: driverInfo.drivingLicense,
      isActive: driverInfo.isActive,
    };
    return this.httpAuthPut(`${this.API_URLS.drivers}/${driverInfo.driverId}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Edit driver failed');
      })
    );
  }

  setPassword(driverId: number, newPassword: string): Observable<IDriver> {
    const dataToSend = {
      password: newPassword,
    };
    return this.httpAuthPut(`${this.API_URLS.drivers}/${driverId}/password`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Password Change failed');
      })
    );
  }

}
