import { SortDirection } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { AccessControlService } from '@otrack-lib/core/services/access-control.service';
import { OrderTypes } from './../enums/ordertype.enum';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AppplicationPermissions } from './const.application.permission';
import { Subject } from 'rxjs';
import { IColumnFilter } from '@otrack-lib/models/column-filter.model';
import { AppInjector } from '@admin-app/common/app.injector';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { PaginationOptions } from '@admin-app/common/models/pagination';


export interface IKey {
  key: string;
}

@Injectable()
export abstract class AbstractBaseComponent implements OnInit, OnDestroy {

  public orderTypeEnum = OrderTypes;  // this property used in HTML file for checking orderTypes
  public permissions = AppplicationPermissions;
  public destroy$ = new Subject();
  private accessControlService: AccessControlService;
  _router!: Router;
  activatedRoute: ActivatedRoute;
  pagination: PaginationOptions = { pageNumber: 0, pageSize: 10};

  constructor() {
    const injector = AppInjector.getInjector();
    this.accessControlService = injector.get(AccessControlService);
    this._router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
  }

  ngOnInit(): void {
   this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$))
    .subscribe( queryparams => {
       this.parsePaginationParams(queryparams);
    });
  }

  getColumns(columnsList: IColumnFilter[], authorizedColumns: string[], permissionType: string): string[] {
    return this.filterList(columnsList.filter(c => c.show === true), authorizedColumns, permissionType).map(c => c.key);
  }

  filterList(inList: IKey[], filterKeys: string[], inPermission: string): any[] {
    if (this.accessControlService.permissions.includes(inPermission) || !inPermission) {
      return inList;
    }
    return inList.filter(c => !filterKeys.includes(c.key));
  }

  public isPermissions(permission: AppplicationPermissions): boolean {
    return this.accessControlService.permissions.includes(permission);
  }

  updatePaginationQueryParameters()
  updatePaginationQueryParameters(pagination?: PaginationOptions): void {
    let paginationOptions  = pagination ? pagination : this.pagination;
    this._router.navigate([], {
      queryParams: {
        ...paginationOptions
      },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
  }

  parsePaginationParams(params: Params){
    this.pagination.pageNumber = params.pageNumber ? parseFloat(params.pageNumber) : 0;
    this.pagination.pageSize = params.pageSize ? parseFloat(params.pageSize) : 10;
    if (params.sortColumn) {
      this.pagination.sortColumn = params.sortColumn;
    }

    if (params.sortDirection) {
      this.pagination.sortDirection = params.sortDirection;
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
