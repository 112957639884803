import { ConfigurationService } from './../helper-services/configuration.service';
import { Injector } from '@angular/core';
import { throwError as observableThrowError,  Observable,  of as observableOf} from 'rxjs';
import {  HttpClient,  HttpHeaders,  HttpResponse,  HttpErrorResponse,  HttpRequest} from '@angular/common/http';
// import { JwtHelperService } from '@auth0/angular-jwt';
import {  map,  retryWhen,  catchError,  timeout,  delay,  scan} from 'rxjs/operators';
import { Router } from '@angular/router';

// Models
import { ErrorModel } from '@otrack-lib/models/error.model';

// Environnment
// import { environment } from '@client-env/environment'; /TIDO: read frin configuration service

import { UtilsService } from '../helper-services/utils.service';


export abstract class BaseApiService {
  private readonly numberOfTries: number = 4; // Number of tries
  private readonly delayInRetry: number = 2000; // Number of miliseconds between each retry
  private getTimeout = 45000; // Total number of miliseconds to throw timeout error (for GET requests)
  private postTimeout = 45000; // Total number of miliseconds to throw timeout error (for POST requests)
  private readonly skipRetryOnStatuses: Array<number> = [500, 402, 401, 400, 404];

  protected baseApiUrl: string;
  protected http: HttpClient;
  // protected jwtHelper: JwtHelperService;
  protected _router: Router;
  protected _utilService: UtilsService;
  protected configurationService: ConfigurationService = null;

  constructor(protected injector: Injector) {
    // Load dependencies using Injector so that we can decouple BaseApiService extending services
    this.http = this.injector.get(HttpClient);
    //   this.jwtHelper = this.injector.get(JwtHelperService);
    this._router = this.injector.get(Router);
    this._utilService = this.injector.get(UtilsService);
    this.configurationService = this.injector.get(ConfigurationService);
    this.baseApiUrl = `${this.configurationService.config.apiURL}`;
    // Total number of miliseconds to throw timeout error (for GET requests)
    this.getTimeout = this.configurationService.config['getRequestTimeout']
      ? this.configurationService.config['getRequestTimeout']
      : 45000;
    this.postTimeout = this.configurationService.config['postRequestTimeout']
      ? this.configurationService.config['postRequestTimeout']
      : 45000;
  }

  // Get data from server
  httpGet(
    URL: string,
    requestOptions: any = this.getRequestOptions()
  ): Observable<any> {
    try {
      return this.http.get(URL, requestOptions).pipe(
        retryWhen(this.retryStrategy()),
        timeout(this.getTimeout),
        catchError(this.handleError.bind(this))
      );
    } catch (e) {
      return this.handleError(e);
    }
  }

  // Send data to server
  httpPost(
    URL: string,
    data: any,
    requestOptions: any = this.getRequestOptions(),
    errorHandling: boolean = true
  ): Observable<any> {
    try {
      if (errorHandling) {
        return this.http
          .post(URL, data, requestOptions)
          .pipe(catchError(this.handleError.bind(this)));
      } else {
        return this.http.post(URL, data, requestOptions);
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  // Get data from server using auth token
  httpAuthGet(
    URL: string,
    requestOptions: any = this.getRequestOptions()
  ): Observable<any> {
    try {
      // Check if user is logged in
      if (this.isTokenExpired()) {
        return observableThrowError('You are not logged in!');
      }

      return this.http.get(URL, requestOptions).pipe(
        retryWhen(this.retryStrategy()),
        timeout(this.getTimeout),
        catchError(this.handleError.bind(this))
      );
    } catch (e) {
      return this.handleError(e);
    }
  }

  httpAuthGetHtml(
    URL: string,
    requestOptions: any = this.getRequestHtmlResponseOption()
  ): Observable<any> {
    try {
      // Check if user is logged in
      if (this.isTokenExpired()) {
        return observableThrowError('You are not logged in!');
      }

      return this.http.get(URL, requestOptions).pipe(
        // retryWhen(this.retryStrategy()),
       // timeout(this.getTimeout),
        catchError(this.handleError.bind(this))
      );
    } catch (e) {
      return this.handleError(e);
    }
  }

  protected getRequestHtmlResponseOption(additionalHeaders?: HttpHeaders, responseType?: string) {
    try {
      const options = {};
      let headers = new HttpHeaders(); // HttpHeaders is immutable - every set(), append(), delete() returns a new clone
      headers = headers.append('Accept', 'text/html');
      headers = headers.append('ResponseType', 'text');
      headers = headers.append('Content-Type', 'application/json');

      // Any additional headers
      if (additionalHeaders && additionalHeaders.keys().length) {
        additionalHeaders.keys().forEach((headerKey: string) => {
          if (
            headerKey.toLowerCase() === 'accept' ||
            headerKey.toLowerCase() === 'content-type'
          ) {
            // If headerKey is 'Accept' or 'Content-Type' delete previous one and use new one
            headers = headers.delete(headerKey);
          }
          headers = headers.append(headerKey, additionalHeaders.get(headerKey));
        });
      }

      if (responseType) {
        options['responseType'] = responseType;
      }

      options['headers'] = headers;
      options['responseType'] = 'text';

      return options;
    } catch (e) {
      // console..log(`Exception`, e);
      return this.handleError(e);
    }
  }

  // Can be overridden by caller - for example: retryWhen( this.retryStrategy( { attempts: 5, retryDelay: 1500 } ) )
  private retryStrategy({
    attemps = this.numberOfTries,
    retryDelay = this.delayInRetry
  }: { attemps?: number; retryDelay?: number } = {}): (
      e: Observable<HttpErrorResponse>
    ) => Observable<any> {
    return (errors: Observable<HttpErrorResponse>) => {
      return errors.pipe(
        scan((tried: number, error: HttpErrorResponse) => {
          if (this.skipRetryOnStatuses.indexOf(error.status) > -1) {
            // Need to throw error here so its catched up in chain - cannot return
            throw error;
          }

          tried++;
          if (tried < attemps) {
            return tried;
          } else {
            // Need to throw error here so its catched up in chain - cannot return
            throw error;
          }
        }, 0),
        delay(retryDelay)
        // timeout( this.getTimeout )
      );
    };
  }

  // Get data from server using auth token
  httpAuthPostDownload(
    URL: string,
    data: any,
    requestOptions: any = this.getRequestOptions()
  ): Observable<any> {
    try {
      return this.http
        .post(URL, data, {
          observe: 'response',
          responseType: 'blob',
        })
        .pipe(
          map((res) => {
            let filename = 'export_file';
            const disposition = res.headers.get('Content-Disposition');
            if (disposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(disposition);
              filename = matches
                ? matches[1].replace(/['"]/g, '')
                : 'export_file';
            }
            return {
              filebolb: new Blob([res.body ? res.body : ''], {
                type: res.headers.get('Content-Type') ? res.headers.get('Content-Type')   : '',
              }),
              filename,
            };
          }),
          timeout(this.getTimeout),
          catchError(this.handleError.bind(this))
        );
    } catch (e) {
      return this.handleError(e);
    }
  }

  // Get data from server using auth token
  httpAuthGetCompleteResponse(
    URL: string,
    // tslint:disable-next-line: typedef
    requestOptions = this.getRequestOptions()
  ): Observable<any> {
    const requestOptionsNew = requestOptions;

    // Observe complete response
    requestOptionsNew['observe'] = 'response';
    try {
      // Check if user is logged in
      if (this.isTokenExpired()) {
        return observableThrowError('You are not logged in!');
      }
      return this.http.get(URL, requestOptions).pipe(
        retryWhen(this.retryStrategy()),
        timeout(this.getTimeout),
        map((res: HttpResponse<any>) => res), // Return entire response including headers (res.headers)
        catchError(this.handleError.bind(this))
      );
    } catch (e) {
      return this.handleError(e);
    }
  }

  // Send data to server using auth token
  httpAuthPost(
    URL: string,
    data: any,
    requestOptions: any = this.getRequestOptions()
  ): Observable<any> {
    try {
      // Check if user is logged in
      if (this.isTokenExpired()) {
        return observableThrowError('You are not logged in!');
      }

      return this.http
        .post(URL, data, requestOptions)
        .pipe(catchError(this.handleError.bind(this)));
    } catch (e) {
      return this.handleError(e);
    }
  }

  // Send data to server using auth token
  httpAuthPut(
    URL: string,
    data: any,
    requestOptions: any = this.getRequestOptions()
  ): Observable<any> {
    try {
      // Check if user is logged in
      if (this.isTokenExpired()) {
        return observableThrowError('You are not logged in!');
      }

      return this.http
        .put(URL, data, requestOptions)
        .pipe(catchError(this.handleError.bind(this)));
    } catch (e) {
      return this.handleError(e);
    }
  }

  httpAuthDelete(
    URL: string,
    requestOptions: any = this.getRequestOptions()
  ): Observable<any> {
    try {
      // Check if user is logged in
      if (this.isTokenExpired()) {
        return observableThrowError('You are not logged in!');
      }

      return this.http
        .delete(URL, requestOptions)
        .pipe(catchError(this.handleError.bind(this)));
    } catch (e) {
      return this.handleError(e);
    }
  }

  // Usage:
  /*
    let requestHeaders = this.getRequestOptions( new HttpHeaders({'My-new-header': 'its-value'}) );

    responseType: 'arraybuffer' | 'blob' | 'json' | 'text'
  */
  protected getRequestOptions(additionalHeaders?: HttpHeaders, responseType?: string) {
    try {
      const options = {};
      let headers = new HttpHeaders(); // HttpHeaders is immutable - every set(), append(), delete() returns a new clone
      headers = headers.append('Accept', 'application/json');
      headers = headers.append('Content-Type', 'application/json');

      // Any additional headers
      if (additionalHeaders && additionalHeaders.keys().length) {
        additionalHeaders.keys().forEach((headerKey: string) => {
          if (
            headerKey.toLowerCase() === 'accept' ||
            headerKey.toLowerCase() === 'content-type'
          ) {
            // If headerKey is 'Accept' or 'Content-Type' delete previous one and use new one
            headers = headers.delete(headerKey);
          }
          headers = headers.append(headerKey, additionalHeaders.get(headerKey));
        });
      }

      if (responseType) {
        options['responseType'] = responseType;
      }

      options['headers'] = headers;

      return options;
    } catch (e) {
      // console..log(`Exception`, e);
      return this.handleError(e);
    }
  }

  protected extractData(res: HttpResponse<any>) {
    const body = res.body;
    if (body) {
      return body.data != null ? body.data : body;
    }

    return null;
  }

  protected handleError(error: any): Observable<ErrorModel> {
    let errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    let errCode = error.status ? error.status : null;

    // Sometimes handleError gets ErrorModel already so have to capture data from there
    if (error.code) {
      errCode = error.code;
    }

    let isExcludedLink = false;
    if (error.url) {
      isExcludedLink = error.url.endsWith('/login');
    }

    if (errCode === 401 && !isExcludedLink) {
      // Logout user if server sends 401 for requests other than ending with /authenticate/login (for wrong email/pass)
      this._router.navigate(['/auth/logout']);
      return observableThrowError(new ErrorModel(errCode, 'Unauthorized User'));
    }

    // FIXME: not sure what's navigator here, causing trouble in mobile-app
    // if (navigator && !navigator.onLine) {
    //   return observableThrowError(
    //     new ErrorModel(errCode, 'No Internet Connection')
    //   );
    // }

    try {
      // If error is a valid JSON - pull out the responseStatus message (sent from server)
      const errorObj = error.error; // .json();	// Sometimes exists in response and sometime doesn't
      if (errorObj) {
        if (errorObj.message) {
          errMsg = errorObj.message;
        }

        if (typeof errorObj === 'string') {
          errMsg = errorObj;
        }

        if (errorObj.errorCode) {
          errCode = errorObj.errorCode;
        }
      }
    } catch (e) {
      // Cannot throw error here otherwise its shape changes
      // error.json was invalid
    }

    return observableThrowError(new ErrorModel(errCode, errMsg));
  }

  // Generates string i.e: param1=paramValue1&param2=paramValue2 from {param1: paramValue1, param2: paramValue2 }
  generateQueryString(paramsObject: any[]): string {
    const str = Object.keys(paramsObject)
      .map(key => {
        return key + '=' + encodeURIComponent(paramsObject[key]);
      })
      .join('&');

    return str;
  }

  // Generates odata string i.e: $param1=paramValue1&$param2=paramValue2 from {param1: paramValue1, param2: paramValue2 }
  generateODataQueryString(paramsObject: any[]): string {
    const str = Object.keys(paramsObject)
      .map(key => {
        if (key === 'vfilter') {
          return '$filter=' + encodeURIComponent(paramsObject[key]);
        } else {
          return '$' + key + '=' + encodeURIComponent(paramsObject[key]);
        }
      })
      .join('&');

    return str;
  }

  isTokenExpired(): boolean {
    // return this.jwtHelper.isTokenExpired();
    return false;
  }
}
