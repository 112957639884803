<div class="mat-table__wrapper">
  <div class="header-container">
    <a href="javascript:void(0);" mat-raised-button [routerLink]="" (click)="backClicked.emit()" class="kt-margin-r-10 back-button">
      <i class="la la-arrow-left" style="color: black; font-size: 2.0rem; margin-right: 10px;"></i>
      <span class="kt-hidden-mobile">Back</span>
    </a>
    <label class="table-title"> Print Invoices </label>
  </div>
  <mat-table class="otrack-table" style="margin-top:0px;" [dataSource]="dataSource" matSort
    matSortActive="invoiceDate" matSortDirection="desc" matSortDisableClear>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="report-table-header-cell">
        DATE</mat-header-cell>
      <mat-cell *matCellDef="let report" class="report-table-cell">
        {{report.invoiceDate | date:'dd MMM, yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionNumber">
      <mat-header-cell *matHeaderCellDef class="report-table-header-cell report-column-small-cell"> NUM</mat-header-cell>
      <mat-cell *matCellDef="let report" class="report-table-cell report-column-small-cell">
        <a style="cursor: pointer; text-decoration: underline;"
          (click)="openTransaction(report.orderId, report.orderType)"> {{ report.invoiceNumber }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef class="report-table-header-cell">CUSTOMER
      </mat-header-cell>
      <mat-cell *matCellDef="let report" class="report-table-cell">
        {{report.customerName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef class="report-table-header-cell report-column-small-cell report-cell-right">AMOUNT
      </mat-header-cell>
      <mat-cell *matCellDef="let report" class="report-table-cell report-column-small-cell report-cell-right" >
        {{ (report?.totalAmount + report?.totalTax).toFixed(2) | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amountDue">
      <mat-header-cell *matHeaderCellDef 
        class="report-table-header-cell report-column-small-cell report-cell-right">BALANCE
      </mat-header-cell>
      <mat-cell *matCellDef="let report"
        class="report-table-cell report-column-small-cell report-cell-right"
        style="padding-right: 30px;">
        {{report?.amountDue?.toFixed(2)  | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionBy">
      <mat-header-cell *matHeaderCellDef class="report-table-header-cell">CREATED BY
      </mat-header-cell>
      <mat-cell *matCellDef="let report" class="report-table-cell">
        {{report.invoiceBy}}
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{'inactive-product': !row.isActive}"></mat-row>
  </mat-table>
  
  <ng-container *ngIf="!dataSource.hasItems">
    <div class="flex-column">
      <i class="kt-nav__link-icon flaticon-rocket no-record-icon"></i>
      <p style="width: 100%; text-align: center;">No Record Found!</p>
    </div>
  </ng-container>

  <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
</div>