import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';

import { DiscountType } from './../../enums/discount-type.enum';
import { Injectable, Injector } from '@angular/core';
import { OrderTypes } from '@otrack-lib/enums';
import { ChargeCard } from '@otrack-lib/models/charge/charge-card.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { IHistory } from '@otrack-lib/models/history,model';
import { IDeliveryInfo } from '@otrack-lib/models/order/delivery-info.model';
import { IInvoice, IInvoiceInfo } from '@otrack-lib/models/order/invoice.model';
import { ICategorizedOrderList, IOrder } from '@otrack-lib/models/order/order.model';
import { IPickupTimeSlot } from '@otrack-lib/models/order/pickup-info.model';
import { ITransaction } from '@otrack-lib/models/order/transaction.model';
import { ICategoryProduct, IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { ProductService } from './product.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseApiService {
  static InvoiceService: IOrder[];
  constructor(_injector: Injector, protected userService: UserService, protected utilsService: UtilsService) {
    super(_injector);
  }
  readonly API_URLS = {
    orders: `${this.baseApiUrl}/v1/orders`,
    publicOrders: `${this.baseApiUrl}/v1/public/orders`,
    ordersInfo: `${this.baseApiUrl}/v1/orders/info`,
    salesOrder: `${this.baseApiUrl}/v1/salesorders`,
    publicSalesOrder: `${this.baseApiUrl}/v1/public/salesorders`,
    addSaleOrders: `${this.baseApiUrl}/v1/salesorders/add`,
    addPublicSaleOrders: `${this.baseApiUrl}/v1/public/salesorders/add`,
    updatePublicSaleOrders: `${this.baseApiUrl}/v1/public/salesorders/edit`,
    availablePickupDate: `${this.baseApiUrl}/v1/public/salesorders/delivery/availableDates`,
    availableTimeSlots: `${this.baseApiUrl}/v1/public/salesorders/pickup/availableTimeSlots`,
    editSaleOrders: `${this.baseApiUrl}/v1/salesorders/edit`,
    salesOrderInfo: `${this.baseApiUrl}/v1/salesOrders/info`,
    creditMemo: `${this.baseApiUrl}/v1/orders/creditmemo`,
    preferredOrders: `${this.baseApiUrl}/v1/orders/preferred`
  };

  static TransformOrderListIntoCategoryGrouping(orderList: IOrder[]): ICategorizedOrderList[] {
    const unique = orderList.map(d => d.categoryId).filter((item, i, ar) => ar.indexOf(item) === i);
    const orderListByCategory: any[] = [];
    unique.forEach(u => {
      let totalItems = 0;
      let totalSRP = 0;
      let totalPrice = 0;
      let categoryName = '';
      const tempList: any[] = [];

      const list = orderList.filter(c => c.categoryId === u).forEach(order => {
        categoryName = order.categoryName;
        totalItems += order.quantity;
        totalPrice += order.price * order.quantity;
        totalSRP += (order.unitPerCase * order.suggestedRetailPrice * order.quantity);
        tempList.push(order);
      }
      );

      orderListByCategory.push({ totalItems, totalSRP, totalPrice, categoryName, orders: tempList });
    });
    return orderListByCategory;
  }

  static getInvoiceModel(res: any): IInvoice {
    return {
      companyName: res.companyName,
      customerAddress: res.customerAddress,
      customerCity: res.customerCity,
      customerEmail: res.customerEmail,
      customerId: res.customerId,
      customerName: res.customerName,
      customerOpenBalance: res.customerOpenBalance,
      customerPostalCode: res.customerPostalCode,
      customerState: res.customerState,
      itemsCount: res.itemsCount,
      syncId: res.syncId,
      invoiceNumber: res.invoiceNumber,
      orderId: res.orderId ? res.orderId : res.salesOrderId,
      invoiceDate: res.invoiceDate ? res.invoiceDate : res.orderDate, // hack for sales order
      invoiceBy: res.invoiceBy,
      totalAmount: res.totalAmount,
      taxPercent: res.taxPercent,
      totalTax: res.totalTax,
      status: res.saleOrderStatus ? res.saleOrderStatus : res.status,
      isSync: res.isSync,
      memo: res.memo,
      taxId: res.taxId,
      orderType: res.orderType,
      isPaid: res.isPaid,
      isQBCustomer: res.isQBCustomer,
      salesOrderId: res.salesOrderId,
      deliveryFee: res.deliveryFee ? res.deliveryFee : 0,
      customer: InvoiceService.getCustomerModel(res),
      // company: InvoiceService.getCompanyModel(res.company),
      items: res.items != null ? InvoiceService.getOrderModel(res.items) : null,
      amountDue: res.amountDue,
      totalSuggestedRetailPrice: res.totalSuggestedRetailPrice,
      billingAddress: res.billingAddress,
      contactPhone: res.contactPhone,
      pickupDate: res.pickupDate,
      pickupTime: res.pickupTime,
      chargeStatus: res.chargeStatus,
      deliveryType: res.deliveryType,
      shippingAddress: res.shippingAddress,
      salesOrderType: res.salesOrderType,
      totalCredit: res.totalCredit ? res.totalCredit : 0,
      isTaxable: res.isTaxable,
      IsTaxable: res.IsTaxable,
      isTaxableInvoice: res.isTaxableInvoice,
      savingsText: res.savingsText,
      isDiscounted: res.isDiscounted,
      discountType: res.discountType ? res.discountType : null,
      discountValue: res.discountValue ? res.discountValue : null
    };
  }

  static getCustomerModel(res: any): ICustomer {
    return {
      customerId: res['customerId'],
      customerName: res['customerName'],
      postalCode: res['customerPostalCode'],
      state: res['customerState'],
      address1: res['customerAddress'],
      city: res['customerCity'],
      openBalance: res['customerOpenBalance'],
      companyName:
        res['companyName'] == null ? res['customerName'] : res['companyName']
    };
  }

  static getInvoiceInfoModel(res: any): IInvoiceInfo {
    return {
      invoiceBy: res['invoicedBy'],
      amountDue: res['amountDue'],
      invoiceNumber: res['invoiceNumber'],
      invoiceType: res['orderType'],
      saleOrderStatus: res['saleOrderStatus'],
      history: InvoiceService.getInvoiceInfoHistoryModel(res['history'])
    };
  }

  static getInvoiceInfoHistoryModel(res: any): IHistory[] {
    if (!res) {
      return null;
    }
    const output: IHistory[] = [];
    res.forEach(element => {
      output.push({
        eventDate: element['eventDate'],
        detail: element['eventDetail'],
        user: element['user']
      });
    });
    return output;
  }

  static getOrderModel(res: any): IOrder[] {
    const output: IOrder[] = [];
    res.forEach(item => {
      output.push({
        id: item.id,
        isTaxable: item.isTaxable,
        productId: item.productId,
        productName: item.productName,
        quantity: item.quantity,
        price: item.price,
        originalPrice: item.originalPrice,
        cost: item.cost,
        isDamaged: item.isDamaged,
        taxAmount: item.taxAmount,
        description: item.description,
        barcode: item.barcode,
        suggestedRetailPrice: item.suggestedRetailPrice,
        orderDate: item.orderDate,
        unitPerCase: item.unitPerCase,
        categoryName: item.rootCategoryName,
        categoryId: item.categoryId,
        imageUrls: item.imageUrl ? [item.imageUrl] : null,
        productUnit: item.productUnit,
        substitutable: item.substitutable,
        sortOrder: item.sortOrder,
        isAgeRestricted: item.isAgeRestricted ? item.isAgeRestricted : false,
        minAge: item.minAge ? item.minAge : 0,
        options: ProductService.mapProductOptionFromProductOrOrder(item.options)
      });
    });
    return output;
  }

  static geTransactionModel(res: any): ITransaction {
    return {
      amount: +res['amount'],
      date: res['date'],
      id: res['id'],
      amountDue: res['invoiceBalance'],
      isSynced: res['isSynced'],
      transaction: res['transaction'],
      transactionNumber: res['transactionNumber'],
      transactionType: res['transactionType']
    };
  }

  static getProductCategory(res: any): ICategoryProduct {
    return {
      categoryName: res.categoryName,
      products: res.products.map(p => {
        return <IQuickProductDetail>{
          productId: p.productId,
          name: p.productName,
          basePrice: p.price,
          price: p.price,
          isTaxable: p.isTaxable,
          purchaseCost: p.cost,
          tempId: 1,
          quantityInHand: p.quantity,
          rootCategoryName: p.rootCategoryName,
          quantity: res.quantity ? res.quantity : 0,
          categoryName: p.categoryName
        };
      })
    };
  }

  getOrder(orderId: number): Observable<IInvoice> {
    return this.httpAuthGet(`${this.API_URLS.orders}/${orderId}`).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = InvoiceService.getInvoiceModel(res);

          output.isTaxableInvoice = output.totalTax > 0;
          output.IsTaxable = output.totalTax > 0;
          output.isTaxable = output.totalTax > 0;

          output.company = this.userService.getUserCompany();
          if (output.orderType === OrderTypes.CreditMemo) {
            this.UpdatForCreditMemo(output);
          }
        }

        return output;
      })
    );
  }

  getPublicOrder(orderId: number): Observable<IInvoice> {
    return this.httpAuthGet(`${this.API_URLS.publicOrders}/${orderId}`).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = InvoiceService.getInvoiceModel(res);
          output.company = this.userService.getUserCompany();
          if (output.orderType === OrderTypes.CreditMemo) {
            this.UpdatForCreditMemo(output);
          }
        }

        return output;
      })
    );
  }

  getSalesOrder(orderId: number): Observable<IInvoice> {
    return this.httpAuthGet(`${this.API_URLS.salesOrder}/${orderId}`).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = InvoiceService.getInvoiceModel(res);
          output.company = this.userService.getUserCompany();
          if (output.orderType === OrderTypes.CreditMemo) {
            this.UpdatForCreditMemo(output);
          }
        }

        return output;
      })
    );
  }

  /**
   *  This API will be requrie to send right after CreditCard Payment- In this API backend will load the stripe status
   *  and update the db and return the final sale order with chargeStatus to frontend
   * @param saleOrderId
   */
  getChargeStatus(saleOrderId: number): Observable<IInvoice> {
    return this.httpAuthGet(`${this.API_URLS.publicSalesOrder}/${saleOrderId}/chargestatus`).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res && res.data) {
          output = InvoiceService.getInvoiceModel(res.data);
          output.company = this.userService.getUserCompany();
          if (output.orderType === OrderTypes.CreditMemo) {
            this.UpdatForCreditMemo(output);
          }
        }

        return output;
      })
    );
  }

  chargeCard(data: ChargeCard, salesOrderId: number): Observable<any> {

    // return this.handleError(new HttpErrorResponse({ error: '10:00 - 11:00 pickup slot is already used.', status: 500 }));
    return this.httpAuthPost(`${this.API_URLS.publicSalesOrder}/${salesOrderId}/chargecard`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // submit cash on delivery
  submitCOD(data: { deliveryType?: any, pickUp?: any }, salesOrderId: number) {
    return this.httpAuthPost(`${this.API_URLS.publicSalesOrder}/${salesOrderId}/cod`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getPublicSalesOrder(orderId: number): Observable<IInvoice> {
    return this.httpAuthGet(`${this.API_URLS.publicSalesOrder}/${orderId}`).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = InvoiceService.getInvoiceModel(res);
          output.company = this.userService.getUserCompany();
        }

        return output;
      })
    );
  }


  getOrderInformation(orderId: number): Observable<IInvoiceInfo> {
    return this.httpAuthGet(`${this.API_URLS.ordersInfo}/${orderId}`).pipe(
      map(res => {
        let output: IInvoiceInfo = {};
        if (res) {
          output = InvoiceService.getInvoiceInfoModel(res);
        }
        return output;
      })
    );
  }

  getSalesOrderInformation(orderId: number): Observable<IInvoiceInfo> {
    return this.httpAuthGet(`${this.API_URLS.salesOrderInfo}/${orderId}`).pipe(
      map(res => {
        let output: IInvoiceInfo = {};
        if (res) {
          output = InvoiceService.getInvoiceInfoModel(res);
        }
        return output;
      })
    );
  }

  UpdatForCreditMemo(order: IInvoice) {
    order.totalAmount =
      order.totalAmount > 0 ? order.totalAmount * -1 : order.totalAmount;
    order.totalTax = order.totalTax > 0 ? order.totalTax * -1 : order.totalTax;
  }

  postSaleOrderOnServer(order: IInvoice) {
    return this.postOrder(order, OrderTypes.SalesOrder);
  }

  postCreditMemoOnServer(order: IInvoice) {
    return this.postOrder(order, OrderTypes.CreditMemo);
  }

  updateCreditMemoOnServer(order: IInvoice) {
    return this.postOrder(order, OrderTypes.CreditMemo, true);
  }

  postInvoiceOnServer(order: IInvoice) {
    return this.postOrder(order, OrderTypes.Invoice);
  }

  updateInvoiceOnServer(order: IInvoice) {
    return this.postOrder(order, OrderTypes.Invoice, true);
  }

  updateSaleOrderOnServer(salesOrder: IInvoice) {
    return this.postOrder(salesOrder, OrderTypes.SalesOrder, true);
  }



  getCustomerPreferedList(customerId: number): Observable<ICategoryProduct[]> {
    const postOrderUrl = this.API_URLS.preferredOrders;
    return this.httpAuthGet(`${postOrderUrl}/${customerId}`).pipe(
      map((res: any[]) =>
        res.map(item => InvoiceService.getProductCategory(item))
      )
    );
  }

  addPublicSaleOrder(order: IInvoice): Observable<IInvoice> {
    const dataToSend = {
      memo: order.memo,
      items: order.items,
    };

    const url = this.API_URLS.addPublicSaleOrders;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
        throw new ErrorModel(null, 'order not found');
      })
    );
  }


  deletePublicSaleOrder(saleOrderId: number): Observable<boolean> {
    const deleteOrderUrl = `${this.API_URLS.publicSalesOrder}/${saleOrderId}`;
    return this.httpAuthDelete(`${deleteOrderUrl}`);
  }

  deleteOrder(orderId: number): Observable<boolean> {
    const deleteOrderUrl = `${this.API_URLS.orders}/${orderId}`;
    return this.httpAuthDelete(`${deleteOrderUrl}`);
  }


  updatePublicSaleOrder(order: IInvoice): Observable<IInvoice> {
    const url = this.API_URLS.updatePublicSaleOrders;
    return this.httpAuthPost(url, order).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
        throw new ErrorModel(null, 'order not found');
      })
    );
  }

  addUpdateSaleOrderDeliveryInfo(deliveryInfo: IDeliveryInfo): Observable<boolean> {
    // deliveryInfo.deliveryType = DeliveryType.PickUp;
    // deliveryInfo.billingAddress = {
    //   address1: 'temp-TODO',
    //   address2: 'temp-TODO',
    //   city: 'temp-TODO',
    //   state: 'temp-TODO',
    //   postalCode: 'aa'
    // }
    const dataToSend: any = deliveryInfo;  // we could do mapping
    dataToSend.deliveryType = deliveryInfo.deliveryMethod;

    const url = `${this.API_URLS.publicSalesOrder}/${deliveryInfo.saleOrderId}/delivery`;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => {
        if (res) {
          return true;
        }
        throw new ErrorModel(null, 'Delivery information not updated');
      })
    );
  }

  postOrder(
    order: IInvoice,
    orderType: OrderTypes,
    isEdit: boolean = false
  ): Observable<IInvoice> {
    const dataToSend = {
      customerId: order.customerId,
      taxId: order.taxId,
      memo: order.memo,
      orderDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : null,
      orderId: order.orderId > 0 ? order.orderId : undefined, // in case of edit
      IsTaxableInvoice: order.isTaxable !== undefined ? order.isTaxable : order.IsTaxable,
      items: order.items,
      salesOrderId: order.salesOrderId,
      isDiscounted: order.isDiscounted,
      discountType: order.discountType && order.discountType !== DiscountType.None ? order.discountType : null,
      discountValue: order.discountType && order.discountType !== DiscountType.None ? order.discountValue : null
    };

    let url = '';

    if (orderType === OrderTypes.CreditMemo) {
      url = this.API_URLS.creditMemo;
    } else if (orderType === OrderTypes.Invoice) {
      url = this.API_URLS.orders;
    } else if (orderType === OrderTypes.SalesOrder) {
      url = isEdit ? this.API_URLS.editSaleOrders : this.API_URLS.addSaleOrders;
    }

    if (url && isEdit && orderType === OrderTypes.Invoice) {
      return this.httpAuthPut(url, dataToSend).pipe(
        map(res => {
          if (res) {
            return InvoiceService.getInvoiceModel(res);
          }
          throw new ErrorModel(null, 'order not found');
        })
      );
    } else {
      return this.httpAuthPost(url, dataToSend).pipe(
        map(res => {
          if (res) {
            const model = InvoiceService.getInvoiceModel(res);
            if (orderType === OrderTypes.SalesOrder) {
              model.orderType = OrderTypes.SalesOrder;
            }
            return model;
          }
          throw new ErrorModel(null, 'order not found');
        })
      );
    }
  }


  getPickupDates(): Observable<string[]> {
    const pickupDateUrl = this.API_URLS.availablePickupDate;
    return this.httpAuthGet(pickupDateUrl).pipe(
      map((res) => res.data));
  }

  getPickupTimeSlots(date: string): Observable<IPickupTimeSlot[]> {
    const pickupDateUrl = `${this.API_URLS.availableTimeSlots}?inputDate=${date}`;
    return this.httpAuthGet(pickupDateUrl).pipe(
      map(res => {
        if (res) {
          const values = [];
          res.data.forEach(element => {
            const slot: IPickupTimeSlot = {
              key: element.key,
              value: element.value,
              isAvailable: element.isAvailable
            };
            values.push(slot);
          });
          return values;
        }
      })
    );
  }

}
