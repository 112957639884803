import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { IAgingSummary } from '@otrack-lib/models/reports/aging-summary.model';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import * as moment from 'moment';

@Component({
  selector: 'app-printagingreport',
  templateUrl: './print-aging-report.component.html',
  styleUrls: ['./print-aging-report.component.scss']
})
export class PrintAgingReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  transactions: IAgingSummary[];
  currentTotal = 0;
  oneToThirtyTotal = 0;
  thirtyOneToSixtyTotal = 0;
  sixtyOneToNinetyTotal = 0;
  moreThanNinetyTotal = 0;
  totalTotal = 0;
  user: IAuthUser;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchAgingSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchAgingSummaryReport() {
    this.reportService.getAgingSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.transactions = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.transactions.forEach(item => {
      this.currentTotal += item.current;
      this.oneToThirtyTotal += item.oneToThirty;
      this.thirtyOneToSixtyTotal += item.thirtyOneToSixty;
      this.sixtyOneToNinetyTotal += item.sixtyOneToNinety;
      this.moreThanNinetyTotal += item.moreThanNinety;
      this.totalTotal += item.total;
    });

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
