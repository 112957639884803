import { AngularFireAuth } from '@angular/fire/auth';
import { getMenuByRouteId, HeaderMenu } from './../common/routes-consts';
// Angular
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class MenuDBService {

  private headerFavoriteMenuIds$ = new BehaviorSubject<string[]>([]);
  private userId: string;

  constructor(private firestore: AngularFirestore,  private afAuth: AngularFireAuth) {
    //  this.afAuth.authState.subscribe(user => {
    //     if  ( user) {
    //       this.userId = user.uid;
    //     }
    //  });
  }

  fetchUserPreference(inUserId: string): Observable<any> {
    return this.firestore.collection(`userAdminUIPreferences`).doc(this.userId).valueChanges();
  }

  fetchUserHeaderPreferences(inUserId: string): Observable<HeaderMenu[]> {
    this.userId = inUserId;
    return this.fetchUserPreference(inUserId).pipe(map((data: any) => {
      this.headerFavoriteMenuIds$.next(data ? data.favoriteHeaderMenu : []);
      return data && data.favoriteHeaderMenu
            ? data.favoriteHeaderMenu.filter(routeId => getMenuByRouteId(routeId)).map(val => getMenuByRouteId(val))
            : [];
    }));
  }

  getFavoriteHeaderIds(): Observable<string[]> {
    return this.headerFavoriteMenuIds$.asObservable();
  }

  addFavoriteHeaderMenu(routeId: string):  Promise<any> {
    const alreadyExist = this.headerFavoriteMenuIds$.value && this.headerFavoriteMenuIds$.value.includes(routeId)
                 ? true : false;
    if (alreadyExist) {
        return Promise.resolve();
    }
    const values = this.headerFavoriteMenuIds$.value ? this.headerFavoriteMenuIds$.value : [];
    values.push(routeId);
    return this.firestore.collection('userAdminUIPreferences').doc(this.userId).set({favoriteHeaderMenu: values});
  }

  removeFavoriteHeaderMenu(routeId: string): Promise<any> {
    const alreadyExist = this.headerFavoriteMenuIds$.value && this.headerFavoriteMenuIds$.value.includes(routeId)
                 ? true : false;
   if (!alreadyExist) {
      return Promise.resolve();
   }
    const values = this.headerFavoriteMenuIds$.value ? this.headerFavoriteMenuIds$.value.filter(obj => obj !== routeId) : [];
   return this.firestore.collection('userAdminUIPreferences').doc(this.userId).set({favoriteHeaderMenu: values});
  }


}
