// Angular
import { Component } from '@angular/core';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent { }
