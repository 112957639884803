import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAccount from './account.reducer';

export const selectAccountState = createFeatureSelector<fromAccount.State>(
  fromAccount.accountFeatureKey
);

export const getAccounts = createSelector(
  selectAccountState,
  (state: fromAccount.State) => {
    return state &&  state.accounts && !state.accounts.loading
              ? state.accounts.data
              : [];
  }
);

export const getAccountCategories = createSelector(
  selectAccountState,
  (state: fromAccount.State) => {
    return state &&  state.accountsCategoryList && !state.accountsCategoryList.loading
              ? state.accountsCategoryList.data
              : [];
  }
);

export const isAccountsLoaded = createSelector(
  selectAccountState,
  (state: fromAccount.State) => {
    return state && state.accountsCategoryList && state.accounts.success;
  }
);

export const isAccountsCategoryLoaded = createSelector(
  selectAccountState,
  (state: fromAccount.State) => {
    return state && state.accountsCategoryList && state.accounts.success;
  }
);
