export class PageConfig {
	public defaults: any = {
		/* dashboard: {
			page: {
				'title': 'Dashboard',
				'desc': 'Latest updates and statistic charts'
			},
		}, */
    customer: {
      page: { title: 'Customers', desc: 'Compnay Customer List' }
    },
    product: {
      list: {
        page: { title: 'Products', desc: '' }
      },
      pricing: {
        page: { title: 'Product Pricing', desc: '' }
      },
      categories: {
        page: { title: 'Product Categories', desc: '' }
      }
    },
    orders: {
      page: { title: 'Orders', desc: '' }
    },
		ecommerce: {
			customers: {
				page: {title: 'Customers', desc: ''}
			},
			product: {
				list: {
					page: {title: 'Products', desc: ''}
				},
				categories: {
					page: {title: 'Create product', desc: ''}
				}
			},
			orders: {
				page: {title: 'Orders', desc: ''}
			}
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
