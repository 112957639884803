import { Injectable } from '@angular/core';
import { DataService } from '@otrack-lib/core/facade/data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root', })
export class AccessControlService {
  permissions: string[];
  constructor(private dataService: DataService) {

    this.dataService.getUserPermissions().subscribe(data => {
      this.permissions = data;
    });
  }

  isUserPermitted(inPermission: string): Observable<boolean> {
    return this.dataService.getUserPermissions()
      .pipe(
        map(permissions => permissions.findIndex(permission => permission && inPermission ? permission.toLowerCase().trim() === inPermission.toLowerCase().trim() : false) > -1)
      );
  }


  applyPermissions(list: any[]): any[] {
    if (!list) {
      return list;
    }
    return list.filter(i => {
      if (Array.isArray(i)) {
        return this.applyPermissions(i);
      } else {
        return !i.permission ||
          this.permissions.findIndex(permission => permission ? permission.toLowerCase().trim() === i.permission.toLowerCase().trim() : false) > -1;
      }
    });
  }



}
