import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-middle-page-loader',
  template: `<div class="loading">Loading&#8230;</div>`,
  styleUrls: ['./middle-page-loader.component.scss']
})
export class MiddlePageLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
