import {
  Component, OnInit, Input, Output, EventEmitter,
  ChangeDetectionStrategy, ViewEncapsulation, ViewRef, ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-barcode-generator',
  templateUrl: './barcode-generator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BarcodeGeneratorComponent implements OnInit {

  elementType = 'img';
  // value = '428711';
  format = 'CODE128';
  lineColor = '#000000';
  width = 1;
  height = 22;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 0;
  fontSize = 10;
  background = '#ffffff';
  margin = 0;
  marginTop = 0;
  marginBottom = 0;
  marginLeft = 0;
  marginRight = 0;
  isValid = true;
  @Input() value = '';
  @Input() includetext = false;
  @Input() barcodeOptions = {}; // width, height, and fontSize
  @Output() invalidCodeFound = new EventEmitter<any>();

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.width = this.barcodeOptions['width'];
    this.height = this.barcodeOptions['height'];
    this.fontSize = this.barcodeOptions['fontSize'];
    this.elementType = this.barcodeOptions['elementType'];

    // if (this.value && (this.value.length === 6 || this.value.length === 8)) {
    //   this.format = 'UPCE';
    // } else if (this.value && (this.value.length === 11 || this.value.length === 12)) {
    //   this.format = 'UPC';
    // } else if (this.value && this.value.length === 13) {
    //   this.format = 'EAN13';
    // } else {
    //   this.invalidCodeFound.emit('Invalid bar code');
    //   return;
    // }
    this.format = 'CODE128';
    if (!(this.value && this.isNumber(this.value))) {
      this.invalidCodeFound.emit('Invalid bar code');
      return;
    }
  }

  isBarcodeValid = (valid) => {
    this.isValid = valid;
    if (!valid) {
      if (this.value && this.format === 'UPC' && this.value.length === 12) {
        this.format = 'EAN13';

        // render barcode again
        this.ref.detach();
        setInterval(() => {
          if (this.ref && !(this.ref as ViewRef).destroyed) {
            this.ref.detectChanges();
          }
        }, 0);
      }
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

}
