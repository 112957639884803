import { ConfigurationConfigService, ConfigurationService } from '@otrack-lib/core/helper-services/configuration.service';
import { ErrorService } from '@otrack-lib/core/services/error.service';
import { Subscription } from 'rxjs';
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Inject
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService
} from '@admin-app/common/layout-core';
// language list
import { locale as enLang } from '@admin-app/shared/config/i18n/en';

import { locale as frLang } from '@admin-app/shared/config/i18n/fr';
import { NotificationService } from '@otrack-lib/core/helper-services/notification.service';
// import { WINDOW } from '@otrack-lib/core/guards/window';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Metronic';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    //  @Inject(WINDOW) private window: Window,
    private translationService: TranslationService,
    private router: Router,
    private configurationService: ConfigurationService,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, frLang);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('loader.enabled');


    const logOutSubscription = this.errorService.IsUserLogOut.subscribe(val => {
      if (val === true) {
        window.location.href = this.configurationService.config.loginPageUrl;
      }

    });

    this.unsubscribe.push(logOutSubscription);


    const messageShowSubscription = this.errorService.IsErrorMessage.subscribe(val => {
      if (val && val.length > 0) {
        this.notificationService.showError(val);
        console.error(val);
      }
    });
    this.unsubscribe.push(messageShowSubscription);

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 100);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
