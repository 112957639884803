<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ customerName }} </h3>
    <!-- <h5> {{ this.reportFilter ? getDateRangeInString() : '' }} </h5> -->
    <h5> Open Balance Report </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left;padding-left: 5px;">DATE</th>
        <th style="text-align: left; padding-left: 5px;">MODIFIED DATE</th>
        <th style="text-align: left; padding-left: 5px;">TYPE</th>
        <th style="text-align: left; padding-left: 5px;">NUMBER</th>
        <th style="text-align: right; padding-right: 5px;">AMOUNT</th>
        <th style="text-align: right; padding-right: 5px;">INVOICE BALANCE</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let item of items">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.date | date:'dd MMM, yyyy' }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.modifiedDate | date:'dd MMM, yyyy' }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.transaction }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.transactionNumber }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> 
          {{ item.transactionType === OrderType.Payment || item.transactionType === OrderType.CreditMemo ?  (-1 * item.amount | currency ) : ( item.amount | currency )  }}
        </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> 
          {{ item.transactionType === OrderType.Payment || item.transactionType === OrderType.CreditMemo ?  (-1 * item.invoiceBalance | currency ) : ( item.invoiceBalance | currency )  }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: right;padding-right: 5px;">{{ totalInvoiceBalance | currency }}</td>
      </tr>
    </tfoot>

  </table>
</div>
