<div class="kt-portlet" [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : false}">
  <div class="kt-portlet__head kt-portlet__head__custom modal-header gradient-modal-header">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title" style="color:white !important; font-size: 2.0rem; font-weight: 600;">Payment</h3>
    </div>
  </div>
  <form class="kt-form" [formGroup]="form">
    <mat-dialog-content class="mat-dialog-style">
      <div class="kt-portlet__body modal-body p-0">

        <div class="form-group kt-form__group row p-0 mr-0 ml-0">
          <div class="col-lg-4 kt-margin-bottom-20-mobile balances-container">
            <div class="form-group row">
              <label  class="col-5 col-form-label">Net Total</label>
              <div class="col-7 amount-value">
                <span>{{ order?.netTotal.toFixed(2) | currency }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-5 col-form-label">Cash</label>
              <div class="col-7 amount-value">
                <span>{{ cashAmount.toFixed(2) | currency }}</span>
                <label class="last-amount-text">(Received: {{ lastReceivedAmount.toFixed(2) | currency }})</label>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-5 col-form-label">Check</label>
              <div class="col-7 amount-value">
                <span>{{ checkAmount.toFixed(2) | currency }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-5 col-form-label">Credit Card</label>
              <div class="col-7 amount-value">
                <span>{{ creditCardAmount.toFixed(2) | currency }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-5 col-form-label">On Account</label>
              <div class="col-7 amount-value">
                <span>{{ accountAmount.toFixed(2) | currency }}</span>
              </div>
            </div>
            <div class="kt-separator kt-separator--dashed m-0"></div>
            <div style="text-align: end;">
              <label class="amount-due-label"> {{ dueOrChangeAmount < 0 ? 'Change Back' : 'Due Amount' }}  </label>
            </div>
            <div>
              <div class="amount-due-value">
                <span >{{ dueOrChangeAmount.toFixed(2) | currency }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-8 kt-margin-bottom-20-mobile amount-input-container">
            <div class="form-group row">
              <label  class="col-4 col-form-label">Amount Received</label>
              <div class="col-8" style="text-align: end;">
                <input class="form-control form-item rounded-input" type="number" placeholder="Enter Amount Received" formControlName="payAmount" name="payAmount" />
              </div>
             </div>
             <div class="form-group row">
              <label  class="col-4 col-form-label">Check #</label>
              <div class="col-8">
                <input class="form-control form-item rounded-input" type="text" placeholder="Enter Check #" autocomplete="off" formControlName="checkNumber" name="checkNumber" [disabled]="!form.valid" />
              </div>
             </div>
             <div class="form-group row">
              <div class="col-12" style="text-align: center;">
                <button type="button" mat-raised-button class="rounded-button payment-action-button" (click)="onAmountUpdated(PaymentMethod.CASH)" [disabled]="!validatePayAmount() || validateCheckNumber()" > Cash </button>
                <button type="button" mat-raised-button class="rounded-button payment-action-button" (click)="onAmountUpdated(PaymentMethod.CHECK)" [disabled]="!validatePayAmount() || !validateCheckNumber()" > Check </button>
                <button type="button" mat-raised-button class="rounded-button payment-action-button" (click)="onAmountUpdated(PaymentMethod.CREDIT_CARD)" [disabled]="!validatePayAmount() || validateCheckNumber()" > Credit Card </button>
                <button type="button" mat-raised-button class="rounded-button payment-action-button" (click)="onAmountUpdated(PaymentMethod.ON_ACCOUNT)" [disabled]="disableOnAccountButton()" > On Account </button>
              </div>
             </div>
             <div class="form-group row mb-0">
              <div class="col-12 footer-button-container">
                <button type="button" mat-raised-button class="rounded-button cancel-button" [mat-dialog-close]="" cdkFocusInitial [disabled]="loading"> Cancel </button>
                <button type="button" mat-raised-button class="rounded-button secondary-action-button" (click)="onClearClicked()" [disabled]="totalPaid <= 0 || loading"> Clear </button>
                <button type="button" mat-raised-button class="rounded-button save-button" (click)="onSubmit(false)" [class.spinner]="loading" [disabled]="disableSaveButton"> Save & Close</button>
                <button type="button" mat-raised-button class="rounded-button save-button" (click)="onSubmit(true)" [class.spinner]="loading" [disabled]="disableSaveButton"> Save & Print</button>
              </div>
             </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <!-- <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
      <div class="kt-form__actions kt-form__actions--solid modal-footer">
        <div class="row text-right">
          <div class="col-lg-12">
            <div class="col-lg-4 kt-margin-bottom-20-mobile">
              <div class="form-group row">
                <label  class="col-6 col-form-label">Due Amount / Change Back </label>
                <div class="col-6 amount-value">
                  <span>{{ dueOrChangeAmount.toFixed(2) | currency }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-8 kt-margin-bottom-20-mobile">
              <button type="button" mat-raised-button class="rounded-button secondary-action-button" (click)="onClearClicked()" [disabled]="totalPaid <= 0"> Clear </button>
              <button type="button" mat-raised-button class="rounded-button cancel-button" [mat-dialog-close]="" cdkFocusInitial matTooltip="Cancel changes"> Cancel </button>
              <button type="button" mat-raised-button class="rounded-button save-button" (click)="onSubmit()" [disabled]="totalPaid < order?.netTotal" matTooltip="Save changes"> Save </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </form>
</div>
