// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
// Partials for CRUD
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
  SaleOrderChangeStateEntityDialogComponent,
  ConfirmEntityDialogComponent
} from '@admin-app/shared/content/crud';

export enum MessageType {
  Create,
  Read,
  Update,
  Delete,
  Authentication,
  Success,
  Error,
}

@Injectable()
export class LayoutUtilsService {
	/**
	 * Service constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param dialog: MatDialog
	 */
  constructor(private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

	/**
	 * Showing (Mat-Snackbar) Notification
	 *
	 * @param message: string
	 * @param type: MessageType
	 * @param duration: number
	 * @param showCloseButton: boolean
	 * @param showUndoButton: boolean
	 * @param undoButtonDuration: number
	 * @param verticalPosition: 'top' | 'bottom' = 'top'
	 */
  showActionNotification(
    _message: string,
    _type: MessageType = MessageType.Create,
    _duration: number = 10000,
    _showCloseButton: boolean = true,
    _showUndoButton: boolean = true,
    _undoButtonDuration: number = 3000,
    _verticalPosition: 'top' | 'bottom' = 'bottom'
  ) {
    const _data = {
      message: _message,
      snackBar: this.snackBar,
      showCloseButton: _showCloseButton,
      showUndoButton: _showUndoButton,
      undoButtonDuration: _undoButtonDuration,
      verticalPosition: _verticalPosition,
      type: _type,
      action: 'Undo'
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: _duration,
      data: _data,
      verticalPosition: _verticalPosition
    });
  }

  showNotification(
    _message: string,
    _type: MessageType = MessageType.Create,
    _duration: number = 10000,
    _showCloseButton: boolean = true,
    _showUndoButton: boolean = false,
    _undoButtonDuration: number = 3000,
    _verticalPosition: 'top' | 'bottom' = 'bottom'
  ) {
    const _data = {
      message: _message,
      snackBar: this.snackBar,
      showCloseButton: _showCloseButton,
      showUndoButton: _showUndoButton,
      undoButtonDuration: _undoButtonDuration,
      verticalPosition: _verticalPosition,
      type: _type,
      action: 'Undo'
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: _duration,
      data: _data,
      verticalPosition: _verticalPosition
    });
  }

	/**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
  deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px'
    });
  }


  /**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
  customConfirmationElement(title: string = '', description: string = '', waitDesciption: string = '', confirmButton: string = 'Confirm') {
    return this.dialog.open(ConfirmEntityDialogComponent, {
      data: { title, description, waitDesciption, confirmButton },
      hasBackdrop: true,
      disableClose: true,
      width: '440px'
    });
  }

  /**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
  confirmationElement(title: string = '', description: string = '', waitDesciption: string = '') {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px'
    });
  }

	/**
	 * Showing Fetching Window(Mat-Dialog)
	 *
	 * @param _data: any
	 */
  fetchElements(_data: any) {
    return this.dialog.open(FetchEntityDialogComponent, {
      data: _data,
      width: '400px'
    });
  }

	/**
	 * Showing Update Status for Entites Window
	 *
	 * @param title: string
	 * @param statuses: string[]
	 * @param messages: string[]
	 */
  updateStatusForEntities(title: any, statuses: any, messages: any) {
    return this.dialog.open(UpdateStatusDialogComponent, {
      data: { title, statuses, messages },
      width: '480px'
    });
  }


  changeSaleOrderState(title: string = '', description: string = '',
    waitDesciption: string = '', fromSaleOrderStatus: any, toSaleOrderStatus: any) {
    return this.dialog.open(SaleOrderChangeStateEntityDialogComponent, {
      data: { title, description, waitDesciption, fromSaleOrderStatus, toSaleOrderStatus },
      width: '440px'
    });
  }
}
