import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DataService } from '@otrack-lib/core/facade/data.service';
import { ProductService } from '@otrack-lib/core/services/product.service';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class PosDataResolver implements Resolve<boolean> {
  constructor(private dataService: DataService, private productService: ProductService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.dataService.reloadCustomers();
    this.dataService.reloadProducts();
    this.productService.loadPricingTiers().subscribe();
    return this.dataService.isDataLoaded().pipe(filter(data => !data), take(1));
  }
}
