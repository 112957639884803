import { PointOfSaleHelperService } from './../services/point-of-sale-helper.service';
import { ICompany, InvoiceTemplateType } from '@otrack-lib/models/company/company.model';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { LayoutUtilsService, MessageType } from '@admin-app/services/layout-utils.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IPosOrder } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { UserService } from '@otrack-lib/core/services/user.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { OrderTypeConvertor, OrderTypes } from '@otrack-lib/enums';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';

export enum PaymentMethod {
  CASH = 'cash',
  CHECK = 'check',
  CREDIT_CARD = 'credit-card',
  ON_ACCOUNT = 'on-acount'
}

export interface ICheckPayment {
  amount: number;
  description: string;
}

export interface IInternalPayment {
  cashAmount: number;
  checkPayments: ICheckPayment[];
  creditCardAmount: number;
  accountAmount: number;
  dueOrChangeAmount: number;
}

@Component({
  selector: 'pos-payment',
  templateUrl: './pos-payment.component.html',
  styleUrls: ['./pos-payment.component.scss']
})
export class PosPaymentComponent implements OnInit {
  form: FormGroup;
  order: IPosOrder;
  shiftId: number;

  hasFormErrors = false;
  errorMessage: string = null;

  loading = false;

  // there is possiblity of multiple checks per transaction
  // maintain a list of checks been used for payemnt
  checkPayments: ICheckPayment[] = [];
  cashAmount = 0;
  checkAmount = 0;
  creditCardAmount = 0;
  accountAmount = 0;
  dueOrChangeAmount = 0;

  memo = '';

  company: ICompany;

  lastReceivedAmount = 0;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly dialogRef: MatDialogRef<PosPaymentComponent>,
    private readonly fb: FormBuilder,
    private readonly layoutService: LayoutUtilsService,
    private readonly posService: PointOfSaleService,
    private readonly userService: UserService,
    private readonly printService: PrintService,
    private readonly helperService: PointOfSaleHelperService,
    private readonly utilService: UtilsService
  ) {

  }

  ngOnInit() {
    this.company = this.userService.getUserCompany();
    this.order = this.data.order;
    this.shiftId = this.data.shiftId;
    this.dueOrChangeAmount = +this.order.netTotal.toFixed(2);

    this.form = this.createForm();

  }

  createForm() {
    return this.fb.group({
      payAmount: [null, Validators.compose([Validators.min(1), Validators.minLength(1)])],
      checkNumber: []
    });
  }

  onSubmit(print: boolean) {
    this.loading = true;
    const payment: IInternalPayment = {
      cashAmount: this.cashAmount,
      checkPayments: this.checkPayments,
      creditCardAmount: this.creditCardAmount,
      accountAmount: this.accountAmount,
      dueOrChangeAmount: this.dueOrChangeAmount,
    };

    const orderDate = this.utilService.getStringDate(new Date());
    const dataToSend = this.helperService.createOrderPaymentPayload(this.order, this.shiftId, payment, orderDate);
    this.memo = dataToSend.order.memo;

    this.posService.submitOrderWithPayment(dataToSend).subscribe(
      res => {
        if (res) {
          if (print) {
            this.printInvoice(res.order);
          }
          this.layoutService.showNotification(
            'Payment has been made successfully!',
            MessageType.Error,
            20000
          );

          this.dialogRef.close({ success: true, lastOrderMemo: this.memo });
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.layoutService.showNotification(
          'Sorry, we had an unexpected problem processing your request (make payment). Please try again or contact support.',
          MessageType.Error,
          20000
        );
      }
    );
  }

  printInvoice(invoice: any) {
    let docName = '';
    if (invoice.orderType === OrderTypes.Invoice &&
      this.company.invoiceTemplate &&
      this.company.invoiceTemplate === InvoiceTemplateType.DetailTemplate) {
      docName = InvoiceTemplateType.DetailTemplate;
    } else if (invoice.orderType === OrderTypes.Invoice
      && this.company.invoiceTemplate === InvoiceTemplateType.ItemCodeInvoiceTemplate) {
      docName = InvoiceTemplateType.ItemCodeInvoiceTemplate;
    } else {
      docName = OrderTypeConvertor.getDocName(invoice.orderType);
    }

    this.printService.printDocument(docName, [invoice]);
  }

  onAmountUpdated(method: PaymentMethod) {
    const payAmount = this.form.controls.payAmount;
    const checkNumber = this.form.controls.checkNumber;
    const receivedAmount = payAmount.value ? +payAmount.value.toFixed(2) : 0;

    const netTotal = +this.order.netTotal.toFixed(2);
    const paid = +this.totalPaid.toFixed(2);
    const remainigAmount = +(netTotal - paid).toFixed(2);


    if (!this.validateOverPaymentCase(method, receivedAmount, remainigAmount)) {
      return;
    }

    // find out what's remaining to pay the due_amount
    // - check how much is paid so far
    // - remaining_amount = net_total - paid_amount
    // - allow to pay if remaining is greater than 0
    // - pay which ever amount is minimum from remaining or received
    // - check if the received_amount is greater than remaming_amount -> overpayment
    //    - apply true, then take that overpayemnt and apply to change back

    if (remainigAmount > 0) {
      this.applyPayment(receivedAmount, remainigAmount, method, checkNumber.value);

      // find-out if this was an over payment
      let overPayment = 0;
      if (receivedAmount > remainigAmount) {
        overPayment = receivedAmount - remainigAmount;
      }

      this.dueOrChangeAmount = +(this.order.netTotal - this.totalPaid - overPayment).toFixed(2);

    } else {
      this.dueOrChangeAmount -= receivedAmount;
    }

    payAmount.patchValue('');
    checkNumber.patchValue('');
  }

  applyPayment(receivedAmt: number, remainingAmt: number, method: PaymentMethod, checkNumber: any) {
    const received = Math.min(receivedAmt, remainingAmt);

    switch (method) {
      case PaymentMethod.CASH:
        this.cashAmount += received;
        this.lastReceivedAmount += receivedAmt;
        break;

      case PaymentMethod.CHECK:
        this.checkPayments.push({
          amount: +received,
          description: checkNumber
        });

        this.checkAmount += received;
        break;

      case PaymentMethod.CREDIT_CARD:
        this.creditCardAmount += received;
        break;

      case PaymentMethod.ON_ACCOUNT:
        this.accountAmount += remainingAmt;
        break;
    }
  }

  get totalPaid() {
    return +(this.cashAmount + this.checkAmount + this.creditCardAmount + this.accountAmount).toFixed(2);
  }

  get PaymentMethod() {
    return PaymentMethod;
  }


  onClearClicked() {
    this.cashAmount = 0;
    this.checkAmount = 0;
    this.creditCardAmount = 0;
    this.accountAmount = 0;
    this.dueOrChangeAmount = +this.order.netTotal.toFixed(2);
    this.checkPayments = [];
    this.form.controls.payAmount.patchValue(null);
    this.lastReceivedAmount = 0;
  }

  validatePayAmount(): boolean {
    const value = this.form.controls.payAmount.value;
    return value && value > 0;
  }

  validateCheckNumber(): boolean {
    const value = this.form.controls.checkNumber.value;
    return value ? true : false;
  }

  disableOnAccountButton(): boolean {
    if (this.validatePayAmount() || this.totalPaid >= this.order.netTotal) {
      return true;
    }

    return false;
  }

  validateOverPaymentCase(method: PaymentMethod, received: number, remainigAmount: number) {
    if (method === PaymentMethod.CHECK || method === PaymentMethod.CREDIT_CARD) {
      if (received > remainigAmount) {
        this.layoutService.showNotification(
          'Over payment is now allowed with Check or Credit Card. Please update the amount and try again.',
          MessageType.Error,
          20000,
          true,
          false,
          0,
          'top'
        );
        return false;
      }
    }

    return true;
  }

  get disableSaveButton() {
    return this.totalPaid.toFixed(2) < this.order.netTotal.toFixed(2) || this.loading;
  }

  onAlertClose() {
    this.hasFormErrors = false;
    this.errorMessage = null;
  }
}
