import { IUserList } from './../../../models/user-management/user.model';

import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { ICompany } from '@otrack-lib/models/company/company.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { Action, createReducer, on } from '@ngrx/store';
import { IProduct } from '@otrack-lib/models/product/product.model';
import * as DataActions from './data.actions';
import { IAccounts, IAccountsCategory } from '@otrack-lib/models/expense/expense.model';
import { IDriver } from '@otrack-lib/models/driver/driver.model';
import { IUser } from '@otrack-lib/models/user-management/user.model';

export const dataFeatureKey = 'otrack-datastore';

export interface IStateBase {
  loading: boolean;
  success: boolean;
  error: ErrorModel;
}

export interface CustomerDataState extends IStateBase {
  data: ICustomer[];
}

export interface VendorDataState extends IStateBase {
  data: IVendor[];
}

export interface ProductDataStore extends IStateBase {
  data: IProduct[];
}

export interface DriverDataStore extends IStateBase {
  data: IDriver[];
}

export interface EmployeesDataState extends IStateBase {
  data: IUserList[];
}
// export interface AccountTypesDataStore extends IStateBase {
//   data: IAccounts[];
// }

// export interface AccountsCategoryDataStore extends IStateBase {
//   data: IAccountsCategory[];
// }

export interface UserDataStore extends IStateBase {
  permissions: string[];
  userId: number;
  role: string;
}

export interface State {
  customers: CustomerDataState;
  vendors: VendorDataState;
  products: ProductDataStore;
  drivers: DriverDataStore;
  employees: EmployeesDataState;
  user: UserDataStore;
  company: ICompany;
}

export const initialState: State = {
  customers: { data: null, loading: false, success: false, error: null },
  products: { data: null, loading: false, success: false, error: null },
  vendors: { data: null, loading: false, success: false, error: null },
  drivers: { data: null, loading: false, success: false, error: null },
  employees: { data: null, loading: false, success: false, error: null },
  user: { userId: 0, role: null, permissions: null, loading: false, success: false, error: null },
  company: null,
};

const dataReducer = createReducer(
  initialState,
  on(DataActions.clearData, (state) => ( initialState )),
  on(DataActions.loadCustomers, (state) => ( {...state,
                                                      customers: {...state.customers,
                                                                     loading: true,
                                                                     success: false,
                                                                     error: null } }
                                                  )),
  on(DataActions.loadVendors, (state) => ( {...state,
                                                    vendors: {...state.vendors,
                                                                   loading: true,
                                                                   success: false,
                                                                   error: null } }
                                                )),
  on(DataActions.loadProducts, (state) => ( {...state,
                                                    products: {...state.products,
                                                                   loading: true,
                                                                   success: false,
                                                                   error: null } }
                                                )),
  on(DataActions.loadDrivers, (state) => ( {...state,
                                                  drivers: {...state.drivers,
                                                                 loading: true,
                                                                 success: false,
                                                                 error: null } }
                                              )),
  on(DataActions.loadEmployees, (state) => ( {...state,
                                                employees: {...state.employees,
                                                               loading: true,
                                                               success: false,
                                                               error: null } }
                                            )),
  on(DataActions.loadUserPermissions, (state) => ( {...state,
                                                  user: {...state.user,
                                                                 loading: true,
                                                                 success: false,
                                                                 error: null } }
                                              )),
  on(DataActions.loadCustomerFailure, (state, {error}) => ( {...state,
                                                  customer: {...state.customers,
                                                                 loading: false,
                                                                 success: false,
                                                                 error: error } }
                                           )),
  on(DataActions.loadVenderFailure, (state, {error}) => ( {...state,
                                            vendors: {...state.vendors,
                                                           loading: false,
                                                           success: false,
                                                           error: error } }
                                     )),
  on(DataActions.loadUserPermissionsFailure, (state, {error}) => ( {...state,
                                            user: {...state.user,
                                                          loading: false,
                                                          success: false,
                                                          error: error } }
                                    )),
  on(DataActions.loadProductFailure, (state, {error}) => ( {...state,
                                            products: {...state.products,
                                                           loading: false,
                                                           success: false,
                                                           error: error } }
                                     )),
  on(DataActions.loadDriversFailure, (state, {error}) => ( {...state,
                                      drivers: {...state.drivers,
                                                     loading: false,
                                                     success: false,
                                                     error: error } }
                               )),
  on(DataActions.loadEmployeesFailure, (state, {error}) => ( {...state,
                                employees: {...state.employees,
                                               loading: false,
                                               success: false,
                                               error: error } }
                         )),
  on(DataActions.loadCustomerSuccess, (state,  {data }) => ( {...state,
                                      customers: {...state.customers,
                                                     data: data,
                                                     loading: false,
                                                     success: true,
                                                     error: null } }
                               )),
  on(DataActions.loadVenderSuccess, (state,  {data }) => ( {...state,
                                vendors: {...state.vendors,
                                               data: data,
                                               loading: false,
                                               success: true,
                                               error: null } }
                         )),
  on(DataActions.loadProductSuccess, (state,  {data }) => ( {...state,
                                      products: {...state.products,
                                                     data: data,
                                                     loading: false,
                                                     success: true,
                                                     error: null } }
                               )),
   on(DataActions.loadDriversSuccess, (state,  {data }) => ( {...state,
                                drivers: {...state.drivers,
                                               data: data,
                                               loading: false,
                                               success: true,
                                               error: null } }
                         )),
   on(DataActions.loadEmployeesSuccess, (state,  {data }) => ( {...state,
                          employees: {...state.employees,
                                         data: data,
                                         loading: false,
                                         success: true,
                                         error: null } }
                   )),
  on(DataActions.loadUserPermissionsSuccess, (state,  {data }) => ( {...state,
                                user: {...state.user,
                                               userId: data.userId,
                                               role: data.role,
                                               permissions: data.permissions,
                                               loading: false,
                                               success: true,
                                               error: null } }
                         )),
);

export function reducer(state: State | undefined, action: Action) {
  return dataReducer(state, action);
}
