import { TableAction, ACTIONTYPES } from './../models/ag-grid-models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnInit } from '@angular/core';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'otrack-web-table-action-buttons',
  templateUrl: './table-action-buttons.component.html',
  styleUrls: ['./table-action-buttons.component.scss']
})
export class TableActionButtonsComponent implements ICellRendererAngularComp {

  public cell: any;
  params: any;

  refresh(params: any): boolean {
    return false;
  }

  agInit(cellRenderParams: any): void {
    this.mapButtonConfig(cellRenderParams);
  }

  public onClick(id: string): void {
     const rowData = this.params.data;
     this.params.clicked({data: rowData, buttonId: id});
  }

  isButtonVisible(button: TableAction, params: any) : boolean {
    return !button.showActionFieldKey ||
           (button.showActionFieldKey && button.showActionGFieldValue.includes(params.data[button.showActionFieldKey]));
  }

  isButtonEnabled(button: TableAction, params: any): boolean {
    return button.isActionEnabled  ? button.isActionEnabled(params.data) : true;
  }

  isButtonDisabled(button: TableAction, params: any): boolean {
    return !this.isButtonEnabled(button, params);
  }

  private mapButtonConfig(cellRenderParams: any): void {
    this.params = cellRenderParams;
    this.params.actions = cellRenderParams.actions.map((action: TableAction) => {
        const renderModel: any = action;

        switch( action.actionType) {
          case ACTIONTYPES.edit:
              renderModel.label = 'Edit';
              renderModel.id = 'edit';
              renderModel.matSuffix = 'check';
              break;
           case ACTIONTYPES.delete:
                renderModel.label = 'Delete';
                renderModel.id = 'delete';
                renderModel.matSuffix = 'close';
                break;
           case ACTIONTYPES.pay:
                  renderModel.label = 'Pay'
                  renderModel.id = 'pay';
                  renderModel.matSuffix = 'money';
                  break;
          default:
                  break;
        }
        return renderModel;
    });
  }


}
