<div class="product-search" [formGroup]="form">
  <ng-template #rt let-r="result" let-t="term">
    <div class="flex-column" style="width:100%; font-size: 1.3rem">
      <div style="display: flex; justify-content: space-between; flex-direction: row; width:100%;">
        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
        <div style="font-style: italic; color:gray">{{r.parentCategoryName}}</div>
      </div>
      <div>{{r.description}}</div>
    </div>
  </ng-template>
  <input
      *ngIf="!showBarcodeSearchField"
      [attr.disabled]="!(customer$ | async) ? '' : null"
      placeholder="Search Product by Name"
      id="typeahead-focus"
      type="search"
      (selectItem)="onItemSelected($event)"
      class="form-control autocomplete-input product-search-input"
      formControlName="productSearch" 
      name="productSearch"
      [ngbTypeahead]="searchProduct"
      [focusFirst]="false"
      (blur)="onSearchProductBlur($event)"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      #searchInput
      #instance="ngbTypeahead" />
  <input
      *ngIf="showBarcodeSearchField"
      [attr.disabled]="!(customer$ | async) ? '' : null"
      placeholder="Scan Barcode"
      id="barcodeSearch"
      name="barcodeSearch"
      formControlName="barcodeSearch" 
      type="search"
      class="form-control product-search-input"
      (keyup)="onEnterKeyPressed($event)"
      autocomplete="off"
      #barcodeinput />
  <button type="button" mat-raised-button class="scanner-on-button" [disabled]="!(customer$ | async)" (click)="onScannerClicked()">{{ showBarcodeSearchField ?  'Search Product' : 'Scan Barcode' }}</button>
</div>