
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { HttpUtilsService } from '../_base/crud';
import {
  IPaymentInvoice, IUnpaidInvoice, IPaymentHistoryInfo, ICustomerPrePaymentDetail,
  IPayment, IPagedPaymentsList
} from '@otrack-lib/models/payment/payment.model';
import { PaymentMethodTypes } from '@otrack-lib/enums';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseApiService {
  readonly API_URLS = {
    paymentDetail: `${this.baseApiUrl}/v1/payments`,
    getUnpaidInvoices: `${this.baseApiUrl}/v1/orders/unpaid`,
    customerPaymentHistoryInfo: `${this.baseApiUrl}/v1/payments/historyinfo`, // {{customerId}}
    creditApply: `${this.baseApiUrl}/v1/payments/creditreference`,
    getPaymentHistoryList: `${this.baseApiUrl}/v1/payments/paymenthistorylist`,
  };

  constructor(private _injector: Injector, private httpUtils: HttpUtilsService, protected utilsService: UtilsService) {
    super(_injector);
  }

  static getPaidInvoicesModel(res: any): IPaymentInvoice[] {
    const output: IPaymentInvoice[] = [];

    res.forEach((item: { [x: string]: any; }) => {
      output.push({
        orderId: +item['orderId'],
        appliedAmount: item['appliedAmount'],
        invoiceNumber: item['invoiceNumber'],
        isPaid: item['isPaid'],
        orderDate: item['orderDate'],
        paymentApplyDate: item['paymentApplyDate'],
        paymentAppliedBy: item['paymnetAppliedBy'],
        totalAmount: item['totalAmount'],
        amountDue: item['amountDue']
      });
    });
    return output;
  }

  static getUnPaidInvoicesModel(res: any): IUnpaidInvoice[] {
    const output: IUnpaidInvoice[] = [];
    res.forEach((item: { [x: string]: any; }) => {
      output.push({
        invoiceId: +item['orderId'],
        invoiceDate: item['invoiceDate'],
        invoiceNumber: item['invoiceNo'],
        amount: item['totalAmount'],
        amountDue: item['amountDue'],
        appliedAmount: 0,
        select: false
      });
    });

    return output;
  }

  static getCustomerPaymentHistoryModel(res: any): IPaymentHistoryInfo[] {
    const output: IPaymentHistoryInfo[] = [];
    if (res) {
      res.forEach((item: { [x: string]: any; }) => {
        output.push({
          eventDate: item['eventDate'],
          detail: item['eventDetail'],
          user: item['user'],
          paymentMethod: item['paymentMethod']
        });
      });
    }
    return output;
  }

  static getCustomerPrePaymentDetail(res: { [x: string]: any; }): ICustomerPrePaymentDetail {
    const output: ICustomerPrePaymentDetail = {};
    if (res) {
      (output.address1 = res['address1']),
        (output.openBalance = res['openBalance']),
        (output.customerId = res['customerId']),
        (output.companyName = res['companyName']),
        (output.availableCreditAmount = res['availableCreditAmount']),
        (output.lastPaymentDate = res['lastPaymentDate']),
        (output.address1 = res['address1']),
        (output.address2 = res['address2']),
        (output.unpaidInvoices = PaymentService.getUnPaidInvoicesModel(res['unpaidInvoices']));
    }
    return output;
  }

  static getPaymentModel(res: any): IPayment {
    return {
      paymentId: +res['paymentId'],
      companyName: res['companyName'],
      unusedAmount: res['unusedAmount'],
      customerId: res['customerId'],
      customerName: res['customerName'],
      paidInvoices: PaymentService.getPaidInvoicesModel(res['paidInvoices']),
      paymentBy: res['paymentBy'],
      paymentDate: res['paymentDate'],
      paymentMethod: res['paymentMethod'],
      chequeNo: res['paymentMethod'] === "Check" ? res['description'] : null,
      paymentMethodText: res['paymentMethod'] === "Check" ? `Check # ${res['description']}` : res['paymentMethod'],
      paymentNumber: res['paymentNumber'],
      paymentAmount: res['paymentAmount'],
      totalAmount: res['totalAmount'],
      memo: res['memo'],
      accountId: res['accountId']
    };
  }

  static getPaidInvoiceDetail(res: IPaymentInvoice[]): any {
    const output: any[] = [];
    res.forEach(element => {
      output.push({ orderId: element.orderId, appliedAmount: element.appliedAmount });
    });
    return output;
  }

  getPaymentDetail(paymentId: number): Observable<IPayment> {
    return this.httpAuthGet(`${this.API_URLS.paymentDetail}/${paymentId}`).pipe(
      map(res => {
        const output: IPayment = PaymentService.getPaymentModel(res);
        return output;
      })
    );
  }

  getCustomerPaymentHistoryInfo(customerId: number): Observable<IPaymentHistoryInfo[]> {
    return this.httpAuthGet(`${this.API_URLS.customerPaymentHistoryInfo}/${customerId}`).pipe(
      map(res => {
        const output: IPaymentHistoryInfo[] = PaymentService.getCustomerPaymentHistoryModel(res);
        return output;
      })
    );
  }

  getCustomerUnpaidInvoices(customerId: number): Observable<ICustomerPrePaymentDetail> {
    return this.httpAuthGet(`${this.API_URLS.getUnpaidInvoices}/${customerId}`).pipe(
      map(res => {
        const output: ICustomerPrePaymentDetail = PaymentService.getCustomerPrePaymentDetail(res);
        return output;
      })
    );
  }

  postPayment(payment: IPayment): Observable<any> {
    const dataToSend = {
      customerId: payment.customerId,
      accountId: payment.accountId,
      description: payment.chequeNo,
      memo: payment.memo,
      paymentAmount: +payment.paymentAmount,
      paymentDate: payment.paymentDate ? this.utilsService.getStringDate(payment.paymentDate) : null,
      paymentMethod: PaymentMethodTypes[payment.paymentMethod],
      paidInvoices: PaymentService.getPaidInvoiceDetail(payment.paidInvoices)
    };

    return this.httpAuthPost(`${this.API_URLS.paymentDetail}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res ? res.paymentId : 0;
        }
        throw new ErrorModel(null, 'Payment not posted');
      })
    );
  }


  editPayment(payment: IPayment): Observable<any> {
    const dataToSend = {
      paymentId: payment.paymentId,
      accountId: payment.accountId,
      paymentNumber: payment.paymentNumber,
      customerId: payment.customerId,
      description: payment.chequeNo,
      memo: payment.memo,
      paymentAmount: +payment.paymentAmount,
      paymentDate: payment.paymentDate ? this.utilsService.getStringDate(payment.paymentDate) : null,
      paymentMethod: PaymentMethodTypes[payment.paymentMethod],
      paidInvoices: PaymentService.getPaidInvoiceDetail(payment.paidInvoices)
    };

    return this.httpAuthPut(`${this.API_URLS.paymentDetail}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res ? res.paymentId : 0;
        }
        throw new ErrorModel(null, 'Payment not found');
      })
    );
  }


  deletePayment(paymentId: number): Observable<boolean> {
    const deletePaymentUrl = `${this.API_URLS.paymentDetail}/${paymentId}`;
    return this.httpAuthDelete(`${deletePaymentUrl}`);
  }

  applyCreditPayment(payment: IPayment): Observable<any> {
    const dataToSend = {
      customerId: payment.customerId,
      creditAmount: payment.paymentAmount,
      // description: payment.chequeNo,
      // memo: payment.memo,
      // paymentAmount: +payment.paymentAmount,
      // paymentDate: payment.paymentDate,
      // paymentMethod: PaymentMethodTypes[payment.paymentMethod],
      paidInvoices: PaymentService.getPaidInvoiceDetail(payment.paidInvoices)
    };

    return this.httpAuthPost(`${this.API_URLS.creditApply}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Person not found');
      })
    );
  }


  getPaymentReport(fromDate: string, toDate: string, pageNumber: number, pageSize: number): Observable<IPagedPaymentsList> {
    return this.httpAuthGet(
      this.API_URLS.getPaymentHistoryList,
      {
        fromDate,
        toDate,
        pageNumber,
        pageSize
      }
    );
  }

}
