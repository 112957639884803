import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromData from './data.reducer';

export const selectDataState = createFeatureSelector<fromData.State>(
  fromData.dataFeatureKey
);

export const getCustomers = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.customers && !state.customers.loading
              ? state.customers.data
              : [];
  }
);

export const getDrivers = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.drivers && !state.customers.loading
              ? state.drivers.data
              : [];
  }
);

export const getEmployeesList = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.employees && !state.employees.loading
              ? state.employees.data
              : [];
  }
);

export const getVendors = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.vendors && !state.vendors.loading
              ? state.vendors.data
              : [];
  }
);

export const getProducts = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.products && !state.products.loading
              ? state.products.data
              : [];
  }
);

export const isCustomerLoading = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.customers
              ? state.customers.loading
              : false;
  }
);

export const isDriversLoaded = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state && state.drivers && state.drivers.data && state.drivers.success;
  }
);

export const isVendorLoading = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.vendors
              ? state.vendors.loading
              : false;
  }
);


export const isDataLoaded = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.products && state.customers && state.vendors
              ? !state.customers.loading && !state.products.loading && !state.vendors.loading
              : false;
  }
);

export const getUserPermissions = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.user && state.user.permissions
              ? state.user.permissions
              : [];
  }
);

export const isPermissionLoaded = createSelector(
  selectDataState,
  (state: fromData.State) => {
    return state &&  state.user
              ? !state.user.loading && state.user.success
              : false;
  }
);
