import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
// import { InMemoryPurchaseOrderService } from "@client-app/services/purchaseOrder.service";
import { PurchaseOrderService } from '@otrack-lib/core/services/purchase-order.service';
import { OrderTypes } from '@otrack-lib/enums';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { SalesOrderType } from '@otrack-lib/enums/sales-order-type.enum';
import { DeliveryType } from '@otrack-lib/enums/deliverytypes.enum';
import { ConfigurationService } from '@otrack-lib/core/helper-services/configuration.service';
import { BusinessType } from '@otrack-lib/enums/business-type.enum';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'otrack-printorder-custom',
  templateUrl: './printorder-custom.component.html',
  styleUrls: ['./printorder-custom.component.scss']
})
export class PrintOrderCustomComponent implements OnInit, AfterViewInit {
  // TODO: not in use, may be remove it ?
  public displayedColumns: string[] = ['qty', 'item', 'unitprice', 'cost'];
  // TODO: not in use, may be remove it ?
  public displayedColumns2: string[] = ['key', 'value'];

  public invoice: IInvoice;
  public orderType = OrderTypes;
  public isCustomerWithSRP = false;
  public totalQuantity = 0;
  public totalPrice = 0;
  public retailTotal = 0;
  public isBarCodeShow = false;
  public barcodeOptions = {};
  public isOTOCustomer = false;
  public businessType = BusinessType;

  constructor(
    private route: ActivatedRoute,
    protected invoiceService: InvoiceService,
    // public poService: InMemoryPurchaseOrderService,
    public purchaseOrderService: PurchaseOrderService,
    protected config: ConfigurationService,
    private ref: ChangeDetectorRef,
    private printService: PrintService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.params['data']) {
      this.loadInvoice(this.route.snapshot.params['data'].split(','));
    }
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  loadInvoice(data: string[]) {
    this.isBarCodeShow = data[2] ? JSON.parse(data[2]) : false;
    const orderId: number = +data[1];

    if (data[0] === '1' || data[0] === '2') {
      this.invoiceService.getOrder(orderId).subscribe(res => {
        if (res) {
          this.invoice = res;
          this.onInvoiceReady();
        }
      });
    } else if (data[0] === '4') {
      this.invoiceService.getSalesOrder(orderId).subscribe(res => {
        this.invoice = res;
        this.invoice.orderType = OrderTypes.SalesOrder;
        this.onInvoiceReady();
      });
    } else if (data[0] === '5') {
      this.purchaseOrderService.getBill(orderId).subscribe(res => {
        this.invoice = res;
        this.onInvoiceReady();
      });
    } else if (data[0] === '6') {
      this.purchaseOrderService.getPurchaseOrder(orderId).subscribe(res => {
        this.invoice = res;
        this.onInvoiceReady();
      });
    }
  }

  onInvoiceReady() {
    this.barcodeOptions = {
      'width': 2,
      'height': 50,
      'fontSize': 14,
      'elementType': 'svg'
    };

    this.isCustomerWithSRP = this.invoice.totalSuggestedRetailPrice > 0;

    // add SRP columns
    if (this.isCustomerWithSRP) {
      this.displayedColumns.splice(2, 0, 'suggestedRetailPrice');
      this.displayedColumns.splice(3, 0, 'retail');
    }

    // add barcode column
    if (this.isBarCodeShow) {
      this.displayedColumns.splice(1, 0, 'barcode');
    }

    this.calculatTotals();
    this.updateOTOCustomer();

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
    }, 100);
  }

  calculatTotals() {
    if (this.invoice) {
      this.invoice.items.forEach(c => {
        if (c.quantity > 0) {
          this.totalQuantity += c.quantity;
        }

        this.totalPrice += c.price * c.quantity;
      });

      this.retailTotal = this.invoice.totalSuggestedRetailPrice;
    }
  }

  updateOTOCustomer() {
    this.isOTOCustomer = this.invoice.isQBCustomer ? true : false;
  }

  get SalesOrderType() {
    return SalesOrderType;
  }

  get DeliveryType() {
    return DeliveryType;
  }
}
