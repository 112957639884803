import { CoreModule } from './../../../../../../../libs/core/core.module';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvoiceDetailSideInfoComponent } from './invoice-detail-sideinfo/invoice-detail-sideinfo.component';
import { ModalInvoiceViewComponent } from './modal-invoice-view.component';
import { ModalEmailViewComponent } from '../modal-email-view/modal-email-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateModule } from '@otrack-lib/common/templates/template.module';
import { SharedModule } from '@admin-app/shared/shared.module';
import { ModalEmailViewModule } from '../modal-email-view/modal-email-view.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectiveModule } from '@otrack-lib/directive/directive.module';


@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    TemplateModule,
    FormsModule,
    CoreModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    ModalEmailViewModule,
    DirectiveModule,
  ],
  declarations: [InvoiceDetailSideInfoComponent, ModalInvoiceViewComponent],
  entryComponents: [ModalInvoiceViewComponent, ModalEmailViewComponent]
})
export class ModalInvoiceViewModule { }
