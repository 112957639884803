<div class="kt-portlet pos-container"
  [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : loading }">

  <div class="loading" *ngIf="loading">
    <div class="loader"></div>
  </div>

  <div class="header">
    <div class="header-first-line">
      <div class="customer-company">
        <pos-customer-search [customerId$]="customerId$"
                             [resetSelection$]="resetCustomerSelection$"
                             (changeDealer)="onCustomerChange($event)">
        </pos-customer-search>
        <div class="customer-company-name" *ngIf="customer$ | async as customer">
          <span>
            {{ customer?.address1 }}{{ customer?.city ? ', ' + customer?.city : '' }}{{ customer?.state ? ', ' + customer?.state : '' }}
            {{ customer?.postalCode }}
          </span>
          <span>Ph: <span class="value-color">{{ customer?.phoneNo ? customer?.phoneNo : 'N/A' }}</span> Email: <span
              class="value-color">{{ customer?.email  ? customer?.email : 'N/A' }}</span></span>
          <span>
            Tax Exempt: <span class="value-color"> {{ customer ? (customer?.isTaxable ? 'NO' : 'YES') : 'N/A' }}</span>
          </span>
        </div>
      </div>

      <div class="customer-additional-details" *ngIf="customer$ | async as customer">
        <span class="opening-balance-text"> Opening Balance: <span
            class="value-color">{{ customer?.openBalance ? (customer?.openBalance | currency) : 'N/A' }} </span></span>
        <span>
          Sales Tax # <span class="value-color"> {{customer?.salesTaxId ? customer?.salesTaxId : 'N/A'}} </span>
          Expiry Date: <span class="value-color">
            {{customer?.salesTaxIdExpiryDate  ? (customer?.salesTaxIdExpiryDate | date: 'MM-dd-yyyy' ) : 'N/A' }}</span>
        </span>
        <span>
          Tobacco Permit # <span class="value-color"> {{customer?.tobaccoPermitId ? customer?.tobaccoPermitId : 'N/A'}}
          </span>
          Expiry Date: <span class="value-color">
            {{customer?.tobaccoPermitIdExpiryDate ? (customer?.tobaccoPermitIdExpiryDate | date: 'MM-dd-yyyy') : 'N/A' }}
          </span>
        </span>
      </div>

      <div class="date-time">
        <span> {{ companyName }} </span>
        <span> {{ (dateTime$ | async) | date: 'MMM dd, yyyy' }} </span>
        <span> {{ (dateTime$ | async) | date: 'EEEE hh:mm a' }} </span>
        <span> {{ username }} </span>
        <span> <a mat-button (click)="onLogoutClicked()">Logout</a> </span>
      </div>
    </div>

  </div>

  <div class="body">
    <div class="left-side">
      <div *ngIf="!showPrintInvoiceView && !showRestoreHoldView">
        <pos-product-search [autoFocus$]="autoFocus$" [restoreOrderProducts$]="restoreOrderProducts$"
          [pendingTransactions$]="pendingTransactions$" [productList]="productList$ | async"
          [currentOrderItems]="currentOrderItems" [customer$]="customer$" [customerLastPrices$]="customerLastPrices$"
          [shiftId]="shiftId" (itemUpdated)="onItemUpdated($event)">
        </pos-product-search>
      </div>
      <div class="table-containers scroll-div" *ngIf="!showPrintInvoiceView && !showRestoreHoldView">
        <!-- <pos-product-search [autoFocus$]="autoFocus$"
                            [restoreOrderProducts$]="restoreOrderProducts$"
                            [pendingTransactions$]="pendingTransactions$"
                            [productList]="productList$ | async"
                            [currentOrderItems]="currentOrderItems"
                            [customer$]="customer$"
                            [customerLastPrices$]="customerLastPrices$"
                            [shiftId]="shiftId"
                            (itemUpdated)="onItemUpdated($event)">
        </pos-product-search> -->

        <pos-order-table [customer]="customer$ | async" [currentOrderItems]="currentOrderItems" [shiftId]="shiftId"
          (itemRemoved)="onItemRemoved($event)" (itemUpdated)="onItemUpdated($event)">
        </pos-order-table>
      </div>

      <ng-container *ngIf="showPrintInvoiceView || showRestoreHoldView">
        <div class="table-containers">
          <ng-container *ngIf="showPrintInvoiceView">
            <pos-invoices-table [shiftId]="shiftId" (backClicked)="onBackPressFromOtherView()"></pos-invoices-table>
          </ng-container>

          <ng-container *ngIf="showRestoreHoldView">
            <pos-restore-table [shiftId]="shiftId" (backClicked)="onBackPressFromOtherView()"
              (restoreClicked)="onRestoreClicked($event)"></pos-restore-table>
          </ng-container>
        </div>
      </ng-container>

      <div class="footer-container"
        *ngIf="(this.userSelectedKeyboardScreen$ | async) === POSKeyboardScreenEnum.Screen2">

        <div class="memo-container">
          <label>Last Order Notes:</label>
          <div *ngIf="lastOrderMemo" [innerHTML]="lastOrderMemo" class="memo-text"></div>
        </div>

        <ng-container>
          <div class="quick-products-container">
            <pos-quick-products [customer]="customer$ | async" [products]="posProducts$ | async"
              (productSelected)="onQuickProductSelected($event)"></pos-quick-products>
          </div>
        </ng-container>
      </div>

    </div>

    <div class="right-side">
      <div class="button-count-containers">
        <div>
          <div class="current-order-details">
            <div class="item-count">
              <span>Item Count</span>
              <span class="count">{{ itemCount }}</span>
            </div>
            <div class="totals">
              <table class="total-summary-table">
                <tr>
                  <td>Total</td>
                  <td>{{ total | currency }}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>{{ totalTax | currency }}</td>
                </tr>
                <tr>
                  <td clas>Net Total</td>
                  <td class="net-total-value">{{ netTotal | currency }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="action-buttons">
            <button type="button" mat-raised-button class="action-button secondary-action-button"
              [disabled]="(customer$ | async) || (currentOrderItems.length > 0)" (click)="onPrintInvoiceClicked()">Print
              Invoice</button>
            <button type="button" mat-raised-button class="action-button secondary-action-button" disabled="true">Price
              Check</button>
            <button type="button" mat-raised-button class="action-button secondary-action-button"
              [disabled]="!(customer$ | async) || !(netTotal > 0)" (click)="onVoidSalesClicked()">Void Sales</button>

            <button type="button" mat-raised-button class="action-button secondary-action-button"
              [disabled]="loading || !(customer$ | async) || !(netTotal > 0)" (click)="onHoldClicked()">Hold</button>
            <button type="button" mat-raised-button class="action-button secondary-action-button"
              [disabled]="(customer$ | async) || (currentOrderItems.length > 0)"
              (click)="onRestoreHoldClicked()">Restore
              Hold</button>
            <button type="button" mat-raised-button class="action-button secondary-action-button"
              disabled="true">Product
              Search</button>

            <button type="button" mat-raised-button class="action-button secondary-action-button"
              (click)="changeScreen()">{{ (this.userSelectedKeyboardScreen$ | async) === POSKeyboardScreenEnum.Screen1 ? 'Screen 2' : 'Screen 1' }}</button>
            <button type="button" mat-raised-button class="action-button primary-action-button" [disabled]="!shiftId"
              (click)="onEndShiftClicked()">End Shift</button>
            <!-- <button type="button" mat-raised-button class="action-button primary-action-button" (click)="onLogoutClicked()">Logout</button> -->
            <button type="button" mat-raised-button class="action-button primary-action-button"
              [disabled]="!(customer$ | async) || !(netTotal > 0)" (click)="onPayClicked()">Pay</button>
          </div>
          <div>
            <ng-container>
              <div class="memo-container memo-right"
                *ngIf="(this.userSelectedKeyboardScreen$ | async) === POSKeyboardScreenEnum.Screen1 && lastOrderMemo">
                <label>Last Order Notes:</label>
                <div *ngIf="lastOrderMemo" [innerHTML]="lastOrderMemo" class="memo-text"></div>
              </div>
            </ng-container>
          </div>
        </div>
        <div>
          <ng-container *ngIf="(this.userSelectedKeyboardScreen$ | async) === POSKeyboardScreenEnum.Screen2">
            <pos-keypad [customer]="(customer$ | async)" [quickProductSelected$]="quickProductSelected$"
              (quantityUpdated)="onQuantityUpdatedFromKeypad($event)"
              (amountUpdated)="onAmountUpdatedFromKeypad($event)" (cancelPressed)="onVoidSalesClicked()"></pos-keypad>
          </ng-container>
        </div>

      </div>

    </div>
  </div>

</div>
