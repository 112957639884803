import { NgModule } from "@angular/core";
import { MediaPreviewDirective } from "./mediapreview.directive";
import { PermissionDirective } from "./permission.directive";

@NgModule({
  imports: [],
  declarations: [
    MediaPreviewDirective,
    PermissionDirective
  ],
  exports: [
    MediaPreviewDirective,
    PermissionDirective
  ]
})
export class DirectiveModule { }