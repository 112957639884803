import { IUserPermission } from './../../models/user.permission.model';
import { CompanyService } from '@otrack-lib/core/services/company.service';
import { Injectable, Injector } from '@angular/core';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { BaseApiService } from './baseapi.service';

// Models
import { ICompany } from '@otrack-lib/models/company/company.model';
import {
  IAuthUser,
} from '@otrack-lib/models/company/auth-user.model';
import { ErrorModel } from '@otrack-lib/models/error.model';

// Firebase
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { ErrorCodes } from '@otrack-lib/enums/error-code.enums';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {
  user: IAuthUser;
  private user$ = new BehaviorSubject<IAuthUser>(undefined);
  readonly tokenName: string = 'token';
  readonly userStorageKey: string = 'user';
  public userSettings: any;
  // API URLS
  readonly API_URLS = {
    logout: `${this.baseApiUrl}/v1/auth/logout`,
    login: `${this.baseApiUrl}/v1/auth/login`,
    user: `${this.baseApiUrl}/v1/user`,
    publicUser: `${this.baseApiUrl}/v1/public/users`,
    changePassword: `${this.baseApiUrl}/v1/auth/changepassword`,
    userSettings: `${this.baseApiUrl}/v1/users/settings`,
    userPermissions: `${this.baseApiUrl}/v1/users/permissions`
  };

  constructor(
    _injector: Injector,
    private authService: AuthService
  ) {
    super(_injector);
    this.authService.userSignoutEvent$.subscribe(() => this.user = null);
  }

  getUserInformation() {
    // if (!this.user) {
    return this.httpAuthGet(`${this.API_URLS.user}`).pipe(
      //  return this.httpAuthGet('./assets/data/user.info.json').pipe(
      map(res => {
        if (res) {
          this.user = this.getUserModel(res);
          this.user$.next(this.user); /// in order to dispatch the event if user change
          return this.user;
        }
        throw new ErrorModel(
          ErrorCodes.USER_NOT_FOUND,
          'User information not found on server'
        );
      })
    );
    // }
  }

  getPublicUserInformation() {
    // if (!this.user) {
    return this.httpAuthGet(`${this.API_URLS.publicUser}`).pipe(
      //  return this.httpAuthGet('./assets/data/user.info.json').pipe(
      map(res => {
        if (res) {
          this.user = this.getUserModel(res);
          this.user$.next(this.user); /// in order to dispatch the event if user change
          return this.user;
        }
        throw new ErrorModel(
          ErrorCodes.USER_NOT_FOUND,
          'User information not found on server'
        );
      })
    );
    // }
  }

  getUser(): Observable<IAuthUser> {
    return this.user$.asObservable();
  }

  private getUserModel(res: any): IAuthUser {
    return {
      uid: res['userId'],
      id: +res['id'],
      firstName: res['firstName'],
      displayName: res['displayName'],
      username: res['userName'],
      email: res['email'],
      lastName: res['lastName'],
      signInProvider: res['signInProvider'],
      canLogin: res['canLogin'],
      customerId: res['customerId'],
      company: res['company']
        ? CompanyService.getCompanyModel(res['company'])
        : undefined
    };
  }

  resetPassword(email: string) {
    // return this.httpPost(`${ this.API_URLS.resetPassword }`, dataToSubmit ).pipe(map( res => res ));
  }

  verifyUser(userId: number, token: string) {
    // return this.httpPost(`${ this.API_URLS.verification }`, dataToSubmit ).pipe(map( res => res ));
  }

  // Get user from storage
  private getAuthUser(): IAuthUser {
    return null;
  }

  getUserCompany(): ICompany {
    return this.user ? this.user.company : null;
  }

  getUserRoles(): string[] {
    const user = this.getAuthUser();
    if (user && user.roles) {
      return this.getAuthUser().roles;
    }
    return null;
  }


  saveSettings(settings: { [id: string]: any; }): Observable<any> {
    this.userSettings = Object.assign(this.userSettings || {}, settings);
    return this.httpAuthPost(this.API_URLS.userSettings, this.userSettings);
  }


  getUserPermissions(): Observable<any> {
     // return this.httpAuthGet('./assets/data/user.permissions.json').pipe(
      return this.httpAuthGet(`${this.API_URLS.userPermissions}`).pipe(
       map(res => this.getUserPermissionModel(res))
     );
  }

  private getUserPermissionModel(res: any): IUserPermission {
    if (res && res.data) {
      const data = res.data;
      return {
        userId: data.userId,
        role: data.role,
        permissions: data.permissions ? this.parseReadWritePermission(data.permissions) : []
      };
    }
    return null;
  }


  private parseReadWritePermission(permissions: string[]): string[] {
    let newPermissions = [];
    permissions.forEach(element => {
        if (element.includes('.')) {
          const splitStr = element.split('.');
          newPermissions.push(splitStr[0]);
        }
    });

    return [...permissions, ...newPermissions];
  }

}
