import { PosOrderTableQtyInputComponent } from './pos-order-table/pos-order-table-qty-input/pos-order-table-qty-input.component';
import { PosQuickProductsComponent } from './pos-quick-products/pos-quick-products.component';
import { PosKeypadComponent } from './pos-keypad/pos-keypad.component';
import { PosRestoreTableComponent } from './pos-restore-table/pos-restore-table.component';
import { PosPriceSelectorComponent } from './pos-order-table/pos-price-selector/pos-price-selector.component';
import { ModalInvoiceViewModule } from '@admin-app/common/modals/modal-invoice-view/modal-invoice-view.module';
import { SharedModule } from '@admin-app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { DropdownModule } from 'primeng/primeng';
import { PointOfSaleRoutingModule } from './point-of-sale-routing.module';
import { PointOfSaleComponent } from './point-of-sale.component';
import { PosConfirmationDialogComponent } from './pos-confirmation-dialog/pos-confirmation-dialog.component';
import { PosCustomerSearchComponent } from './pos-customer-search/pos-customer-search.component';
import { PosEndShiftComponent } from './pos-end-shift/pos-end-shift.component';
import { PosEntryComponent } from './pos-entry/pos-entry.component';
import { PosHomeComponent } from './pos-home/pos-home.component';
import { PosInvoicesTableComponent } from './pos-invoices-table/pos-invoices-table.component';
import { PosItemScannerComponent } from './pos-item-scanner/pos-item-scanner.component';
import { PosOrderTableComponent } from './pos-order-table/pos-order-table.component';
import { PosPaymentComponent } from './pos-payment/pos-payment.component';
import { PosProductSearchComponent } from './pos-product-search/pos-product-search.component';
import { PosZReportComponent } from './pos-z-report/pos-z-report.component';
import { PointOfSaleHelperService } from './services/point-of-sale-helper.service';
//import { CurrencyMaskModule } from 'ng2-currency-mask';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MaterialModule,
    DropdownModule,
    NgbModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    PointOfSaleRoutingModule,
    ModalInvoiceViewModule,
    // CurrencyMaskModule
  ],
  exports: [
    PosCustomerSearchComponent,
    PosItemScannerComponent,
    PosProductSearchComponent,
    PosOrderTableComponent,
    PosZReportComponent,
    PosInvoicesTableComponent,
    PosRestoreTableComponent,
    PosKeypadComponent,
    PosQuickProductsComponent
  ],
  declarations: [
    PointOfSaleComponent,
    PosEntryComponent,
    PosHomeComponent,
    PosCustomerSearchComponent,
    PosItemScannerComponent,
    PosProductSearchComponent,
    PosOrderTableComponent,
    PosPaymentComponent,
    PosEndShiftComponent,
    PosZReportComponent,
    PosConfirmationDialogComponent,
    PosInvoicesTableComponent,
    PosPriceSelectorComponent,
    PosRestoreTableComponent,
    PosKeypadComponent,
    PosQuickProductsComponent,
    PosOrderTableQtyInputComponent
  ],
  entryComponents: [
    PosPaymentComponent,
    PosEndShiftComponent,
    PosConfirmationDialogComponent,
    PosPriceSelectorComponent
  ],
  providers: [
    PointOfSaleHelperService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px'
      }
    }
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class PointOfSaleModule { }
