import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { IPosShiftSummary } from '@otrack-lib/models/point-of-sale/point-of-sale.models';

@Component({
  selector: 'pos-z-report',
  templateUrl: './pos-z-report.component.html',
  styleUrls: ['./pos-z-report.component.scss']
})
export class PosZReportComponent implements OnInit {

  @Input() posShiftSummary: IPosShiftSummary;
  @Input() closed = false;
  @Input() closingCash$: Observable<number>;

  constructor() { }

  ngOnInit() {
  }

  pad(num: number): string {
    let s = num + '';
    while (s.length < 9) {
      s = '0' + s;
    }
    return s;
  }
}
