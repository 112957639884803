
import { DataService } from '@otrack-lib/core/facade/data.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class UserPermissionResolver implements Resolve<boolean> {
  constructor(private dataService: DataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.dataService.loadUserPermissions();
    return this.dataService.isUserPermissionLoaded().pipe(filter(data => data ), take(1));
  }
}
