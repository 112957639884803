<div [formGroup]="form" class="container-keypad">
  <!-- <input
      currencyMask
      [attr.disabled]="!customer ? '' : null"
      placeholder="0.00"
      id="amount"
      name="amount"
      formControlName="amount"
      type="text"
      class="form-control amount-input"
      autocomplete="off"
      #amountinput /> -->
     <input
      [attr.disabled]="!customer ? '' : null"
      placeholder="0.00"
      id="amount"
      name="amount"
      formControlName="amount"
      type="text"
      class="form-control amount-input"
      autocomplete="off"
      #amountinput />
  <div class="grid-container">
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(1)" [disabled]="!customer"> 1 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(2)" [disabled]="!customer"> 2 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(3)" [disabled]="!customer"> 3 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput('+')" [disabled]="!customer"> + </button>

    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(4)" [disabled]="!customer"> 4 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(5)" [disabled]="!customer"> 5 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(6)" [disabled]="!customer"> 6 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput('-')" [disabled]="!customer"> - </button>

    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(7)" [disabled]="!customer"> 7 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(8)" [disabled]="!customer"> 8 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(9)" [disabled]="!customer"> 9 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" disabled="true">  </button>

    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput(0)" [disabled]="!customer"> 0 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput('00')" [disabled]="!customer"> 00 </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" disabled="true">  </button>
    <button type="button" mat-raised-button class="action-button primary-action-button" (click)="handledKeyPadInput('CLR')" [disabled]="!customer"> CLR </button>
  </div>
</div>
