<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> {{ this.reportFilter ? getDateRangeInString() : '' }} </h5>
    <h5> Vendor Item by Purchase Summary </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left;padding-left: 5px;">VENDOR</th>
        <th style="text-align: right; padding-right: 5px;">QTY</th>
        <th style="text-align: right; padding-right: 5px;">AMOUNT</th>
        <th style="text-align: right; padding-right: 5px;">TAX</th>
        <th style="text-align: right; padding-right: 5px;">TOTAL AMOUNT</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let item of items">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.vendorCompanyName ?  item.vendorCompanyName : item.vendorName }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.quantity }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.subTotal ? (item.subTotal | currency) : '-' }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.totalTax ? (item.totalTax | currency) : '-' }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.netTotal | currency }} </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: right;padding-right: 5px;">{{ totalQuantity | number : '1.2-2' }}</td>
        <td style="text-align: right;padding-right: 5px;">{{ totalSubTotal | currency }}</td>
        <td style="text-align: right;padding-right: 5px;">{{ totalTax | currency }}</td>
        <td style="text-align: right;padding-right: 5px;">{{ totalNetTotal | currency }}</td>
      </tr>
    </tfoot>

  </table>
</div>
