import { AccessControlService } from './../core/services/access-control.service';
import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[otrack-permission]',
})
export class PermissionDirective implements OnInit, OnDestroy {

  @Input('otrack-permission')
  permission: string;

  destory$ = new Subject();

  constructor(private renderer: Renderer2,
    private el: ElementRef,
    private accessControlService: AccessControlService) {
  }

  /**
   * on init - Check the resource name and current programType exist in the Program allowed
   * configuration - if the resource is not allowed then add the carm-acl-program-hide css class
   * to the element.
   */
  ngOnInit() {
    this.accessControlService.isUserPermitted(this.permission).subscribe((result) => {
      if (!result) {
        const parent = this.renderer.parentNode(this.el.nativeElement);
        this.renderer.removeChild(parent, this.el.nativeElement);
      }
    });
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }

}
