import { Component, Input, OnInit } from '@angular/core';
import { OrderTypes } from '@otrack-lib/enums';
import { DiscountType } from '@otrack-lib/enums/discount-type.enum';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';

@Component({
  selector: 'app-invoice-footer-template1',
  templateUrl: './invoice-footer.component.html',
  styleUrls: ['./invoice-footer.component.scss']
})
export class InvoiceFooterComponent implements OnInit {

  @Input() invoice: IInvoice;
  @Input() totalExtRetail: number;
  @Input() totalItems: number;

  orderType = OrderTypes;

  constructor() { }

  ngOnInit(): void {

  }

  get DiscountType() {
    return DiscountType;
  }
}
