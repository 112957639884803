import { IUserList } from './../../models/user-management/user.model';
import { ICustomerSummary } from './../../models/customer/customer-summary.model';
import { getUserPermissions } from './../store/data/data.selectors';
import { Store, select } from '@ngrx/store';
import { IProduct } from '@otrack-lib/models/product/product.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, filter, tap } from 'rxjs/operators';
import { DataActions, DataStore, DataSelectors } from '../store';
import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
import { IDriver } from '@otrack-lib/models/driver/driver.model';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private dataStore: Store<DataStore.State>) { }

  getCustomerList(): Observable<ICustomer[]> {
    return this.dataStore.pipe(select(DataSelectors.getCustomers), map(cust => cust ? cust.filter(c => c.isActive): []));
  }

  getCustomer(customerId: number): Observable<ICustomer> {
    return this.getCustomerList().pipe(map(custs => custs.filter(customer => customer.customerId === customerId)[0]));
  }

  getVendorList(): Observable<IVendor[]> {
    return this.dataStore.pipe(select(DataSelectors.getVendors), map(cust => cust ? cust.filter(c => c.isActive) : []));
  }

  getVendor(vendorId: number): Observable<IVendor> {
    return this.getVendorList().pipe(map(vends => vends.filter(vendor => vendor.vendorId === vendorId)[0]));
  }

  getProductList(): Observable<IProduct[]> {
    return this.dataStore.pipe(select(DataSelectors.getProducts), map(prd => prd ? prd.filter(p => p.isActive): []));
  }


  getProductListWithAllStatus(): Observable<IProduct[]> {
    return this.dataStore.pipe(select(DataSelectors.getProducts));
  }

  getProductUsingBarCode(barcode: string): Observable<IProduct> {
    return this.getProductList().pipe(map(products => products.filter(prd => prd.barcode === barcode)[0]));
  }

  isDataLoaded(): Observable<boolean> {
    return this.dataStore.pipe(select(DataSelectors.isDataLoaded));
  }

  preloadData() {
    [DataActions.loadCustomers(), DataActions.loadProducts(), DataActions.loadVendors(), DataActions.loadEmployees()].forEach((element) => {
      this.dataStore.dispatch(element);
    });
  }

  reloadCustomers() {
    this.dataStore.dispatch(DataActions.loadCustomers());
  }

  reloadProducts() {
    this.dataStore.dispatch(DataActions.loadProducts());
  }

  reloadVendors() {
    this.dataStore.dispatch(DataActions.loadVendors());
  }

  getUserPermissions(): Observable<string[]> {
    return this.dataStore.pipe(select(DataSelectors.getUserPermissions));
  }

  loadUserPermissions() {
    this.dataStore.dispatch(DataActions.loadUserPermissions());
  }

  isUserPermissionLoaded(): Observable<boolean> {
    return this.dataStore.pipe(select(DataSelectors.isPermissionLoaded));
  }

  resetData() {
    this.dataStore.dispatch(DataActions.clearData());
  }


  getDriverList(): Observable<IDriver[]> {
    return this.dataStore.pipe(select(DataSelectors.getDrivers));
  }

  getEmployeesList(): Observable<IUserList[]> {
    return this.dataStore.pipe(select(DataSelectors.getEmployeesList));
  }

  getDriver(driverId: number): Observable<IDriver> {
    return this.getDriverList().pipe(map(vends => vends.filter(vendor => vendor.driverId === driverId)[0]));
  }


  reloadDrivers() {
    this.dataStore.dispatch(DataActions.loadDrivers());
  }

  loadDriversIfNotExist(): Observable<boolean> {
    return this.dataStore.pipe(select(DataSelectors.isDriversLoaded), tap(data => {
      if (!data) {
        this.reloadDrivers();
      }
    }));
  }

}
