import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ContentAnimateDirective,
  HeaderDirective,
  MenuDirective,
  OffcanvasDirective,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  ToggleDirective,
} from '.';

@NgModule({
	imports: [CommonModule],
	declarations: [
		// directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,


	],
	exports: [
		// directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,

	],
	providers: []
})
export class LayoutCoreModule {}
