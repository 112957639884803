import { AccessControlService } from '@otrack-lib/core/services/access-control.service';
// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Object-Path
import * as objectPath from 'object-path';

/**
 * Returns object from parent object
 */
@Pipe({
	name: 'permission'
})
export class PermissionPipe implements PipeTransform {

  constructor(private accessControlService: AccessControlService){ }
	/**
	 * Transform
	 *
	 * @param value: any
	 * @param args: any
	 */
	transform(value: any[], args?: any): any[] {
		return this.accessControlService.applyPermissions(value);
	}
}
