import { OrderTypes } from '@otrack-lib/enums';
import { Component, Input, OnInit } from '@angular/core';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';

@Component({
  selector: 'app-invoice-header-template1',
  templateUrl: './invoice-header.component.html',
  styleUrls: ['./invoice-header.component.scss']
})
export class InvoiceHeaderComponent implements OnInit {

  @Input() invoice: IInvoice;


  orderType = OrderTypes;

  constructor() {
  }

  ngOnInit(): void {
  }

}
