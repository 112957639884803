import { PaymentMethodPipe } from '@otrack-lib/core/pipes/payment-method.pipe';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { IPaymentSummary } from '@otrack-lib/models/reports/paymentSummary.model';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import * as moment from 'moment';

@Component({
  selector: 'app-printpaymentreport',
  templateUrl: './print-payment-report.component.html',
  styleUrls: ['./print-payment-report.component.scss']
})
export class PrintPaymentReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  transactions: IPaymentSummary[];
  paymentAmountTotal = 0;
  paymentBalanceTotal = 0;
  totalCashAmount = 0;
  totalChequeAmount = 0;
  totalOtherAmount = 0;
  totalCashCount = 0;
  totalChequeCount = 0;
  totalOtherCount = 0;
  totalCount = 0;
  user: IAuthUser;
  paymentMethod = new PaymentMethodPipe();

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchPaymentSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchPaymentSummaryReport() {
    this.reportService.getPaymentSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.transactions = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.transactions.forEach(item => {
      this.paymentAmountTotal += item.paymentAmount;
      this.paymentBalanceTotal += item.unusedAmount;
      this.totalCount++;

      if (item.paymentType === 1) {
        this.totalCashAmount += item.paymentAmount;
        this.totalCashCount++;
      }

      if (item.paymentType === 2) {
        this.totalChequeAmount += item.paymentAmount;
        this.totalChequeCount++;
      }

      if (item.paymentType !== 1 && item.paymentType !== 2) {
        this.totalOtherAmount += item.paymentAmount;
        this.totalOtherCount++;
      }
    });

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

  getPaymentMethod(item: IPaymentSummary) {
    return this.paymentMethod.transform(item.paymentType);
  }
}
