import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { MiddlePageLoaderComponent } from './middle-screen-loader/middle-page-loader.component';
import { DemoBarComponent } from './demo-bar/demo-bar.component';

@NgModule({
  imports: [CommonModule, NgxBarcodeModule],
  declarations: [
    MiddlePageLoaderComponent,
    DemoBarComponent
  ],
  exports: [
    MiddlePageLoaderComponent,
    DemoBarComponent
  ]
})
export class CommonComponentModule { }
