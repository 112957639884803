<div class="kt-portlet" [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : viewLoading }">
    <ng-template [ngIf]="!emailViewVisible && !converToInvoiceViewVisible" >
        <div class="kt-portlet__head kt-portlet__head__custom modal-header gradient-modal-header d-print-none">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title" style="color:white !important;">{{modelTitle}} {{ orderNumber }}</h3>
                <!-- <button type="button" (click)="editInvoice()" mat-raised-button color="primary" [disabled]="viewLoading">
                  Edit
              </button>&nbsp; -->
            </div>
            <div class="kt-portlet__head-toolbar" *ngIf="showEditButton">
              <div *ngIf="( invoice?.orderType !== orderTypeEnum.Invoice && invoice?.orderType !== orderTypeEnum.PurchaseBill ) || (invoice && !invoice.isPaid && (invoice.totalAmount + invoice.totalTax).toFixed(2) === invoice.amountDue.toFixed(2))">
                <button [otrack-permission]="permissions.Invoice_Edit" (click)="editInvoice()" type="button" class="btn btn-brand">
                  <i class="la la-pencil"></i>
                  <span class="kt-hidden-mobile">Edit</span>
                </button>
                <button [otrack-permission]="permissions.Invoice_Edit" *ngIf="this.company.businessType === BusinessType.B2B && order.orderType === orderTypeEnum.SalesOrder && isConverToInvoiceAllowed(invoice?.status)" 
                  (click)="convertToInvoice(invoice?.status)" type="button" class="btn btn-brand ml-2">
                  <i class="la la-sync"></i>
                  <span class="kt-hidden-mobile">Convert to Invoice</span>
                </button>
              </div>
            </div>
        </div>
        <mat-dialog-content class="mat-dialog-style">
            <div class="kt-portlet__body modal-body invoice-modal">
                <div style="width:100%; display:flex; flex-direction: row;">
                    <div class="modal-invoice-part">
                      <div class="loading " *ngIf="(invoice || adjustment ) == undefined" >
                        <div class="loader"></div>
                      </div>
                      <ng-container *ngIf="invoice">
                          <app-invoice-template [invoice]="invoice"></app-invoice-template>
                      </ng-container>
                      <ng-container *ngIf="!invoice && adjustment">
                        <app-adjustment-template [adjustment]="adjustment"></app-adjustment-template>
                      </ng-container>
                    </div>
                    <div class="modal-invoice-description d-print-none">
                        <p *ngIf="invoiceInfo == undefined && !adjustment" > Loading  ...</p> <!-- replace with loader -->

                        <ng-container *ngIf="invoiceInfo">
                            <app-invoice-detail-sideinfo [invoiceInfo]="invoiceInfo"></app-invoice-detail-sideinfo>
                        </ng-container>

                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit d-print-none">
            <div class="kt-form__actions kt-form__actions--solid" style="padding:10px !important;">
                <div class="row text-right">
                  <div class="col-lg-12">
                    <button mat-button class="btn-primary mr-3" mat-raised-button [matMenuTriggerFor]="actionMenu">
                      <span class="mr-1">Action</span>
                      <i class="la la-angle-down"></i>
                    </button>
                    <mat-menu #actionMenu="matMenu" xPosition="before" yPosition="above">
                      <ng-container *ngIf="!(isSaleorder$ | async)">
                        <button mat-menu-item (click)="sendEmail()">
                          <mat-icon>send</mat-icon>
                          <span>Send Email</span>
                        </button>
                        <mat-divider></mat-divider>
                      </ng-container>
                      
                      <button mat-menu-item (click)="printTemplate(false)" [disabled]="viewLoading">
                        <mat-icon>print</mat-icon>
                        <span>Print (beta)</span>
                      </button>
                      <button mat-menu-item (click)="printTemplate(true)" [disabled]="viewLoading">
                        <mat-icon>print</mat-icon>
                        <span>Print with Barcode (beta)</span>
                      </button>
                      <button mat-menu-item *ngIf="order.orderType === orderTypeEnum.Invoice || order.orderType === orderTypeEnum.SalesOrder" (click)="printShippingSlip(true)">
                        <mat-icon>print</mat-icon>
                        <span>Print Shipping Slip</span>
                      </button>
                      <button mat-menu-item *ngIf="order.orderType === orderTypeEnum.SalesOrder" (click)="printOrderPull()">
                        <mat-icon>print</mat-icon>
                        <span>Print Order Pull</span>
                      </button>
                    </mat-menu>

                    <button type="button" mat-raised-button [mat-dialog-close]="" cdkFocusInitial matTooltip="Cancel changes">
                      Close
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template [ngIf]="emailViewVisible" #showEmailView>
        <app-modal-email-view (onCancelPress)="onCancelEmail()"  [data]="emailData">

        </app-modal-email-view>
    </ng-template>
    <ng-template [ngIf]="converToInvoiceViewVisible" #showConvertToInvoiceView>
      <kt-saleorder-statechange-entity-dialog (actionPressed)="onConvertToInvoiceAction($event)" [data]="convertToInvoiceData">

      </kt-saleorder-statechange-entity-dialog>
    </ng-template>
</div>
