import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '@otrack-lib/core/facade/data.service';
import { SelectItem } from 'primeng/primeng';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pos-customer-search',
  templateUrl: './pos-customer-search.component.html',
  styleUrls: ['./pos-customer-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PosCustomerSearchComponent implements OnInit, OnDestroy {
  @Input() selectedId: number;
  @Input() customerId$: Observable<number>;
  @Input() resetSelection$: Observable<boolean>;
  @Output() changeDealer = new EventEmitter<number>();

  public resetEverything$ = new BehaviorSubject(false);
  public selectedDealerName$ = new BehaviorSubject('');
  public viewLoading = false;
  form = new FormGroup({});
  public loader = false;
  dealerList: SelectItem[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService
  ) {
    this.form = this.formBuilder.group({
      dealerId: [this.selectedId, Validators.required]
    });
  }


  ngOnInit() {
    this.dataService.getCustomerList().subscribe(custList => {
      if (custList) {
        custList.forEach(c => {
          this.dealerList.push({ label: c.customerName, value: c.customerId });
        });
        const [customerName] = this.dealerList.filter(
          c => c.value === this.selectedId
        ); // get 1st element
        if (customerName) {
          this.selectedDealerName$.next(customerName.label);
        }
      }
    });

    if (this.resetSelection$) {
      const resetSub = this.resetSelection$.subscribe(res => {
        if (res) {
          this.form.controls.dealerId.patchValue(null);
          this.selectedId = null;
        }
      });
      this.subscriptions.push(resetSub);
    }

    if (this.customerId$) {
      const idSub = this.customerId$.subscribe(customerId => {
        if (customerId) {
          this.form.controls.dealerId.patchValue(customerId);
        }
      });
      this.subscriptions.push(idSub);
    }
  }

  onItemSelected($event: any) {
    this.changeDealer.emit($event.value);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

}
