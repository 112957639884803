<div class="flex-container invoice print-invoice">
  <div>
    <table class="invoice-table"
           style="margin-bottom: 10px;">
      <colgroup>
        <col style="width: 40%" />
        <col style="width: 20%" />
        <col style="width: 30%" />
      </colgroup>
      <tr class="company-header-detail">
        <td colspan="2">
          <!-- show company name only for the invoice is synced -->
          <div *ngIf="isOTOCustomer"
               style="display: inline-flex;">
            <!-- <img *ngIf="invoice?.company?.logoUrl" class="company-logo" src="{{ invoice?.company?.logoUrl }}"> -->
            <img *ngIf="invoice?.company?.logoUrl"
                 class="company-logo"
                 src="{{ invoice?.company?.logoUrl }}">
            <span>
              <h2 class="company-heading">
                {{ invoice?.company?.name }}
              </h2>
              <h3 class="header-subheading">
                <span *ngIf="invoice.company && invoice.company.address">{{ invoice?.company?.address }}<br></span>
                <span *ngIf="invoice.company && (invoice.company.city || invoice.company.state || invoice.company.postalCode)">{{
                  invoice?.company?.city }} {{ invoice?.company?.state }} {{ invoice?.company?.postalCode }}<br></span>
                <span *ngIf="invoice.company && invoice.company.country">{{ invoice?.company?.country }}<br></span>
                <span *ngIf="invoice.company && invoice.company.email">{{ invoice?.company?.email }}<br></span>
                <span *ngIf="invoice.company && invoice.company.phoneNo">{{ invoice?.company?.phoneNo }}</span>
              </h3>
            </span>
          </div>
        </td>
        <td>
          <div class="headerinvoicediv">
            <ng-container *ngIf="invoice?.orderType === orderType.Invoice">
              <h2 class="company-heading"> INV # {{ invoice?.invoiceNumber }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.CreditMemo">
              <h2 class="company-heading"> CM # {{ invoice?.invoiceNumber }} </h2>
            </ng-container>
            <ng-container *ngIf="invoice?.invoiceNumber == null">
              <h2 class="company-heading"> SO # {{ invoice?.orderId }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.PurchaseBill">
              <h2 class="company-heading"> BILL # {{ invoice?.invoiceNumber }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.PurchaseOrder">
              <h2 class="company-heading"> PO # {{ invoice?.invoiceNumber }}</h2>
            </ng-container>
            <h3 class="header-subheading">{{ invoice?.invoiceDate | date:'dd MMM, yyyy' }}</h3>
            <ng-container *ngIf="invoice?.orderType === orderType.Invoice && invoice?.salesOrderId">
              <h2 class="header-subheading"> SO # {{ invoice?.salesOrderId }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.PurchaseBill && invoice?.referenceNumber">
              <h2 class="header-subheading"> REF # {{ invoice?.referenceNumber }}</h2>
            </ng-container>
          </div>
        </td>
      </tr>
      <tr *ngIf="invoice?.invoiceNumber == null">
        <td colspan="3">
          <p class="notinvoicemsg">This is not the actual invoice</p>
        </td>
      </tr>
      <tr *ngIf="invoice?.orderType === orderType.PurchaseOrder">
        <td colspan="3">
          <p class="notinvoicemsg">This is not the actual bill</p>
        </td>
      </tr>
      <tr>
        <td style="padding-top: 15px; vertical-align: top;">
          <div>
            <div class="bold-text">BILL TO</div>
            <div class="normal-text">{{ invoice?.customer.companyName ? invoice?.customer.companyName : invoice?.customer.customerName }}
            </div>
            <div class="normal-text">{{ this.invoice?.customer?.address1 }}</div>
            <div class="normal-text">{{ this.invoice?.customer?.city }} {{ this.invoice?.customer?.state }}</div>
            <div class="normal-text">{{ this.invoice?.customer?.postalCode }} {{ this.invoice?.customer?.country }}</div>
            <div class="normal-text"
                 *ngIf="invoice?.contactPhone">{{ invoice.contactPhone }}</div>
          </div>
        </td>
        <td>
          <div *ngIf="invoice?.isPaid && (invoice?.orderType === orderType.Invoice || invoice?.orderType === orderType.PurchaseBill)">
            <h5 class="paid">
              [PAID]
            </h5>
          </div>
        </td>
        <td style="padding-top: 15px; vertical-align: top;">
          <div *ngIf="invoice?.invoiceNumber != null && isOTOCustomer">
            <div class="bold-text"
                 *ngIf="invoice?.orderType === orderType.Invoice"
                 style="text-align: right;">INOVICE BY</div>
            <div class="bold-text"
                 *ngIf="invoice?.orderType === orderType.CreditMemo"
                 style="text-align: right;">CREDITMEMO BY</div>
            <div class="bold-text"
                 *ngIf="invoice?.orderType === orderType.PurchaseBill"
                 style="text-align: right;">BILLED BY</div>
            <div class="bold-text"
                 *ngIf="invoice?.invoiceNumber == null"
                 style="text-align: right;">ORDER BY</div>
            <div class="normal-text"
                 style="text-align: right;">{{ invoice?.invoiceBy | uppercase }}</div>
          </div>
          <div>
            <div class="bold-text"
                 style="text-align: right;">TOTAL AMOUNT</div>
            <div class="normal-text"
                 style="text-align: right;">{{ invoice?.totalAmount + invoice?.totalTax + invoice?.deliveryFee | currency : currencyCode :
              'symbol-narrow' }}</div>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div>
    <!-- invoice -->
    <table>
      <thead>
        <tr class="invoice-header">
          <th class="column-small-cell"
              style="text-align: center;padding-right: 5px;">QTY</th>
          <th *ngIf="isBarCodeShow"
              style="text-align: left; padding-left: 5px;">BARCODE</th>
          <th style="text-align: left; padding-left: 5px;">DESCRIPTION</th>
          <th *ngIf="isCustomerWithSRP"
              style="text-align: right;padding-right: 5px;">SRP</th>
          <th *ngIf="isCustomerWithSRP"
              style="text-align: right;padding-right: 5px;">RETAIL</th>
          <th style="text-align: right;padding-right: 5px;">{{ invoice?.orderType === orderType.PurchaseBill ? 'COST' : 'PRICE'}}</th>
          <th style="text-align: right;padding-right: 5px;">TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr class="invoice-items"
            *ngFor="let transaction of invoice?.items">
          <td style="text-align: center;padding-right: 5px; vertical-align: top;"> {{transaction.quantity}} <small><b>{{
                transaction.productUnit ? transaction.productUnit : ''}} </b></small> </td>
          <td *ngIf="isBarCodeShow"
              style="text-align: left;padding-left: 5px; vertical-align: top;">
            <app-barcode-generator [barcodeOptions]="barcodeOptions"
                                   [value]="transaction?.barcode"></app-barcode-generator>
          </td>
          <td style="text-align: left;padding-left: 5px;">
            {{ transaction.description ? transaction.description : transaction.productName }}
            {{ transaction.isDamaged ? ' **DAMAGED** ': ''}}
            <b>{{ transaction.substitutable ? ' - (Subs)' : '' }}</b>
            <ng-container *ngIf="transaction.options">
              <br>
              <span class="span-option"
                    *ngFor="let option of transaction.options">
                {{ option.type}} : {{option.value}}
              </span>
            </ng-container>


          </td>
          <td *ngIf="isCustomerWithSRP"
              style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.suggestedRetailPrice | currency :
            currencyCode : ' ' }} </td>
          <td *ngIf="isCustomerWithSRP"
              style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.unitPerCase *
            transaction.suggestedRetailPrice * transaction.quantity | currency : currencyCode : ' ' }} </td>
          <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ transaction.price | currency : currencyCode : ' ' }}
          </td>
          <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.price * transaction.quantity | currency :
            currencyCode : ' ' }} <span>{{ transaction.isTaxable ? 'T' : '' }}</span></td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="invoice-footer">
          <td style="text-align: center;padding-right: 5px;"> {{ totalQuantity }} </td>
          <td *ngIf="isBarCodeShow"
              style="text-align: left;padding-left: 5px;"> TOTAL ITEMS </td>
          <td style="text-align: left;padding-left: 5px;">{{ isBarCodeShow ? '' : 'TOTAL ITEMS' }}</td>
          <td *ngIf="isCustomerWithSRP"
              style="text-align: center;padding-right: 5px;"> </td>
          <td *ngIf="isCustomerWithSRP"
              style="text-align: right;padding-right: 5px;"> {{ retailTotal | currency : currencyCode : 'symbol-narrow' }} </td>
          <td style="text-align: center;padding-right: 5px;"> </td>
          <td style="text-align: right;padding-right: 5px;"> {{ totalPrice | currency : currencyCode : 'symbol-narrow' }} </td>
        </tr>
      </tfoot>
    </table>

    <!-- invoice summary -->
    <div style="display: flex;">
      <div style="width: 60%;">
        <div *ngIf="invoice?.deliveryType && invoice?.deliveryType !== DeliveryType.None">
          <div class="bold-text">Delivery Type</div>
          <div class="normal-text">{{ invoice.deliveryType }}</div>
        </div>

        <div *ngIf="invoice?.pickupDate && invoice?.deliveryType === DeliveryType.PickUp">
          <div class="bold-text">Pickup Date</div>
          <div class="normal-text">{{ invoice.pickupDate | date:'dd MMM, yyyy' }}</div>
        </div>
        <div *ngIf="invoice?.pickupTime && invoice?.deliveryType === DeliveryType.PickUp">
          <div class="bold-text">Pickup Time</div>
          <div class="normal-text">{{ invoice.pickupTime }}</div>
        </div>

        <!-- only display when it is a sales order -->
        <div *ngIf="invoice?.shippingAddress && invoice?.deliveryType === DeliveryType.Delivery ">
          <div class="bold-text">Shipping Address</div>
          <div class="normal-text">{{ invoice.shippingAddress.address1 }}</div>
          <div *ngIf="invoice.shippingAddress.address2"
               class="normal-text">{{ invoice.shippingAddress.address2 }}</div>
          <div class="normal-text">{{ invoice.shippingAddress.city }} {{ invoice.shippingAddress.state }} {{
            invoice.shippingAddress.postalCode }} </div>
        </div>
      </div>

      <table class="invoice-summary-table">
        <tr *ngIf="invoice?.discountType && invoice?.discountType !== DiscountType.None">
          <td class="summary-table-key"> Discount </td>
          <td class="summary-table-value"> {{ (invoice?.discountType === DiscountType.Percentage ? (totalPrice * (invoice?.discountValue /
            100)) : invoice?.discountValue) * -1 | currency : currencyCode : 'symbol-narrow' }} </td>
        </tr>
        <tr>
          <td class="summary-table-key"> Total </td>
          <td class="summary-table-value"> {{ invoice?.totalAmount | currency : currencyCode : 'symbol-narrow' }} </td>
        </tr>
        <tr>
          <td class="summary-table-key"> Sales Tax </td>
          <td class="summary-table-value"> {{ invoice?.totalTax | currency : currencyCode : 'symbol-narrow' }} </td>
        </tr>
        <tr *ngIf="invoice?.totalCredit > 0 ">
          <td class="summary-table-key"> Credit </td>
          <td class="summary-table-value"> ({{ invoice?.totalCredit | currency : currencyCode : 'symbol-narrow' }}) </td>
        </tr>
        <tr *ngIf="invoice?.deliveryFee > 0 ">
          <td class="summary-table-key"> Delivery Fee </td>
          <td class="summary-table-value"> {{ invoice?.deliveryFee | currency : currencyCode : 'symbol-narrow' }} </td>
        </tr>
        <tr>
          <td class="summary-table-key"> Net Amount </td>
          <td class="summary-table-value"> {{ invoice?.totalTax + invoice?.totalAmount + invoice?.deliveryFee - invoice?.totalCredit |
            currency : currencyCode : 'symbol-narrow' }} </td>
        </tr>
      </table>
    </div>
    <!-- invoice summary -->
    <!-- opening balance and disclaimer -->
    <table>
      <tr *ngIf="invoice?.customer?.openBalance && invoice?.orderType !== orderType.SalesOrder && invoice?.salesOrderType !== SalesOrderType.Online && invoice?.company?.businessType !==  businessType.B2C"
          style="text-align:center;">
        <td class="opening-balance-text">
          CURRENT OPEN BALANCE TOTAL: {{ invoice?.customer?.openBalance | currency : currencyCode : 'symbol-narrow' }}
        </td>
      </tr>
      <tr *ngIf="invoice?.memo">
        <td>
          <div class="disclaimer-heading">
            MEMO:
          </div>
          <p class="memo-text">
            {{ invoice?.memo }}
          </p>
        </td>
      </tr>
      <tr class="d-none d-print-table-row"
          *ngIf="isOTOCustomer">
        <td>
          <div class="disclaimer-heading">
            DISCLAIMER:
          </div>
          <p *ngIf="!invoice?.company?.disclaimer"
             class="disclaimer-text">
            ALL RETURN CHECKS ARE SUBJECT TO A $50.00 RETURN FEE.
            BY ACCEPTING THIS INVOICE CUSTOMER AGREE TO PAY {{ invoice?.company?.name | uppercase }} FOR THIS INVOICE IN FULL ACCORDING TO
            THEIR TERMS.
            A SERVICE CHARGE OF 18% APR PER MONTH WILL BE ADDED TO ALL PAST DUE INVOICES CUSTOMER WILL BE RESPONSIBLE FOR ALL LEGAL AND
            COLLECTION FEES.
            CUSTOMER IS RESPONSIBLE FOR ALL APPLICABLE SALES TAX.
            ALL RETURNS OR EXCHANGES MUST BE MADE WITHIN 30 DAYS WITH A RECEIPT.
          </p>
          <div *ngIf="invoice?.company?.disclaimer">
            <div [innerHTML]="invoice?.company?.disclaimer"></div>
          </div>
        </td>
      </tr>
    </table>
    <!-- opening balance and disclaimer -->
  </div>
</div>
