import { IProduct } from '@otrack-lib/models/product/product.model';
import { Observable } from 'rxjs';
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';

@Component({
  selector: 'pos-keypad',
  templateUrl: './pos-keypad.component.html',
  styleUrls: ['./pos-keypad.component.scss']
})
export class PosKeypadComponent implements OnInit {
  @ViewChild('amountinput', { static: false }) amountinput: ElementRef;

  @Input() customer: ICustomer;
  @Input() quickProductSelected$: Observable<IProduct>;
  @Output() cancelPressed = new EventEmitter();
  @Output() quantityUpdated = new EventEmitter<number>();
  @Output() amountUpdated = new EventEmitter<number>();

  // increment/decrement config:
  // 1 = increases one cents
  // 10 = increases two cents
  // 100 = increases one dollar
  private step = 100;
  public form: FormGroup;
  private amountHold = 0;

  constructor(
    private readonly fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.createForm();
    if (this.quickProductSelected$) {
      this.quickProductSelected$.subscribe(res => {
        if (res) {
          this.resetAmount();
        }
      });
    }
    this.form.controls.amount.valueChanges.subscribe(value => {
      if (value) {
        // console.log('amount', value);
        this.amountUpdated.emit(+value);
        this.amountHold = +(+value * 100).toFixed(2);
        // console.log('amountHold', this.amountHold);
      }
    });
  }

  private createForm(): FormGroup {
    return this.fb.group({
      amount: []
    });
  }

  handledKeyPadInput(value: any) {
    // console.log('handledKeyPadInput', value);
    const amountControl = this.form.controls.amount;

    if (value === 'CLR') {
      this.resetAmount();

    } else if (value === '+') {
      this.quantityUpdated.emit(1);

    } else if (value === '-') {
      this.quantityUpdated.emit(-1);

    } else if (value === '✕') {
      this.cancelPressed.emit();

    } else {
      // for all the number input, should be handled here
      const newAmount = Number(this.amountHold.toString() + value.toString());

      // console.log('newAmount', newAmount);
      const convertedAmount = Number(newAmount / 100).toFixed(2);
      amountControl.patchValue(convertedAmount);
      // console.log('amountInputPatched', amountControl.value);
    }
  }

  onValueChanged($event: any) {
    // console.log('valueChanged', $event.target.value);
    const value = $event.target.value;
    if (value) {
      const amount = value.split(' ')[1];
      // console.log('amount', amount);
      this.amountHold = Number(amount) * 100;
      // console.log('amountHold', this.amountHold);
      this.amountUpdated.emit(this.amountHold / 100);
    }
  }

  resetAmount() {
    const amountControl = this.form.controls.amount;
    amountControl.patchValue(null);
    this.amountHold = 0;
  }

}
