import { UserService } from '@otrack-lib/core/services/user.service';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from '@otrack-lib/core/services/baseapi.service';
import { ICategoryProduct, IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { IOrder } from '@otrack-lib/models/order/order.model';
import { IInvoice, IInvoiceInfo } from '@otrack-lib/models/order/invoice.model';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService extends BaseApiService {
  readonly API_URLS = {
    vendors: `${this.baseApiUrl}/v1/vendors`,
    bills: `${this.baseApiUrl}/v1/bills`,
    billInfo: `${this.baseApiUrl}/v1/bills/info`,
    purchaseorders: `${this.baseApiUrl}/v1/bills/purchaseorder`,
    editPurchaseOrder: `${this.baseApiUrl}/v1/bills/purchaseorder`,
    purchaseOrderInfo: `${this.baseApiUrl}/v1/bills/purchaseorder/info`,
    preferredPOOrders: `${this.baseApiUrl}/v1/orders/preferredPO`,
    productDetailbyNameForVendor: `${this.baseApiUrl
      }/v1/odata/Products/ForVendor`,
    getBillList: `${this.baseApiUrl}/v1/bills/billhistorylist`,
    getPurchaseOrderList: `${this.baseApiUrl}/v1/bills/pohistorylist`,
    purhaseBillCreditMemo: `${this.baseApiUrl}/v1/bills/creditmemo`
  };

  constructor(_injector: Injector, protected userService: UserService, protected utilsService: UtilsService) {
    super(_injector);
  }

  static getPurchaseBillModel(res: any): IInvoice {
    return {
      companyName: res.companyName,
      customerAddress: res.customerAddress,
      customerCity: res.customerCity,
      customerEmail: res.customerEmail,
      customerId: res.customerId,
      customerName: res.customerName,
      customerOpenBalance: res.customerOpenBalance,
      customerPostalCode: res.customerPostalCode,
      customerState: res.customerState,
      itemsCount: res.itemsCount,
      syncId: res.syncId,
      invoiceNumber: res.invoiceNumber,
      orderId: res.orderId,
      invoiceDate: res['invoiceDate'],
      invoiceBy: res.invoiceBy,
      totalAmount: res.totalAmount,
      taxPercent: res.taxPercent,
      totalCredit: res.totalCredit ? res.totalCredit : 0,
      deliveryFee: res.deliveryFee ? res.deliveryFee : 0,
      totalTax: res.totalTax,
      status: res.status,
      isSync: res.isSync,
      isQBCustomer: res.isQBCustomer,
      memo: res.memo,
      taxId: res.taxId,
      orderType: res.orderType,
      isPaid: res.isPaid,
      customer: PurchaseOrderService.getVendorModel(res),
      // company: InvoiceService.getCompanyModel(res.company),
      items: res.items != null ? PurchaseOrderService.getOrderModel(res.items) : null,
      amountDue: res.amountDue,
      totalSuggestedRetailPrice: res.totalSuggestedRetailPrice,
      referenceNumber: res.referenceNumber
    };
  }

  static getOrderModel(res: any): IOrder[] {
    const output: IOrder[] = [];
    res.forEach(item => {
      output.push({
        id: item['id'],
        isTaxable: item['isTaxable'],
        productId: item['productId'],
        productName: item['productName'],
        quantity: item['quantity'],
        cost: item['cost'],
        price: item['price'],
        taxAmount: item['taxAmount'],
        description: item['description'],
        barcode: item['barcode'],
        suggestedRetailPrice: item['suggestedRetailPrice'],
        unitPerCase: item['unitPerCase']
      });
    });
    return output;
  }

  static getVendorModel(res: any): ICustomer {
    return {
      customerId: res['customerId'],
      customerName: res['customerName'],
      postalCode: res['customerPostalCode'],
      state: res['customerState'],
      address1: res['customerAddress'],
      city: res['customerCity'],
      openBalance: res['customerOpenBalance'],
      companyName: res['companyName'] == null ? res['customerName'] : res['companyName'],
      isQBCustomer: res['isQBCustomer'],
    };
  }

  getVendorPreferedList(vendorId: number): Observable<ICategoryProduct[]> {
    const postOrderUrl = this.API_URLS.preferredPOOrders;
    return this.httpAuthGet(`${postOrderUrl}/${vendorId}`).pipe(
      map(data => this.mapICategoryProductModel(data))
    );
  }

  getVendorProductByName(
    vendorId: number,
    productName: string
  ): Observable<IQuickProductDetail> {
    const productsNameSearchUrl = this.API_URLS.productDetailbyNameForVendor;
    const productNameEncoded = encodeURIComponent(productName);
    return this.httpAuthGet(
      `${productsNameSearchUrl}(VendorId = ${vendorId})?$filter=ProductName eq '${productNameEncoded}'`
    ).pipe(map(res => this.mapQuickProductDetail(res.value[0])));
  }

  getVendorProductDetail(
    vendorId: number,
    productId: number
  ): Observable<IQuickProductDetail> {
    const productsNameSearchUrl = this.API_URLS.productDetailbyNameForVendor;
    return this.httpAuthGet(
      `${productsNameSearchUrl}(VendorId = ${vendorId})?$filter=ProductId eq ${productId}`
    ).pipe(map(res => this.mapQuickProductDetail(res.value[0])));
  }

  updatePOOrderOnServer(orderId: number, vendorId: number, orderList: IOrder[], memo: string, taxApply: boolean, orderDate?: Date): Observable<IInvoice> {
    const dataToSend = {
      customerId: vendorId,
      memo: memo,
      IsTaxable: taxApply,
      items: orderList,
      saleOrderId: orderId,
      orderDate: orderDate ? this.utilsService.getStringDate(orderDate) : null
    };

    const postOrderUrl = this.API_URLS.editPurchaseOrder;
    return this.httpAuthPost(postOrderUrl, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }

  convertToBill(purchaseOrderId: number): Observable<IInvoice> {
    //  return this._http.get<IInvoice>('./assets/dummy/postorders.json');
    const body = '';
    const url = `${this.API_URLS.bills}/${purchaseOrderId}/convertToBill`;
    return this.httpAuthPost(`${url}`, body).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }


  postPOOrderOnServer(vendorId: number, orderList: IOrder[], memo: string,
    taxApply: boolean, purchaseOrderId: number = 0): Observable<IInvoice> {
    let dataToSend: any = {
      customerId: vendorId,
      memo: memo,
      IsTaxable: taxApply,
      items: orderList,
      orderDate: this.utilsService.getStringDate(new Date()),
    };

    if (purchaseOrderId > 0) {
      dataToSend = { ...dataToSend, saleOrderId: purchaseOrderId }; // in case of edit- store the id in salesOrderId
    }

    const postOrderUrl = this.API_URLS.purchaseorders;
    return this.httpAuthPost(postOrderUrl, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }

  postPurchaseOrder(order: IInvoice) {
    const dataToSend = {
      ...order,
      billDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : this.utilsService.getStringDate(new Date()),
      orderDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : this.utilsService.getStringDate(new Date()),
    };
    const postOrderUrl = this.API_URLS.purchaseorders;
    return this.httpAuthPost(postOrderUrl, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }

  postBillOnServer(vendorId: number, orderList: IOrder[], memo: string, taxApply: boolean): Observable<IInvoice> {
    const dataToSend = {
      customerId: vendorId,
      memo: memo,
      orderDate: this.utilsService.getStringDate(new Date()),
      IsTaxable: taxApply,
      items: orderList
    };

    const postOrderUrl = this.API_URLS.bills;

    return this.httpAuthPost(postOrderUrl, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }

  postPurchaseBill(order: IInvoice) {
    order.items.forEach(data => delete data.id);
    const dataToSend = {
      ...order,
      orderDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : this.utilsService.getStringDate(new Date()),
      billDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : this.utilsService.getStringDate(new Date()),
    };


    const postOrderUrl = this.API_URLS.bills;
    return this.httpAuthPost(postOrderUrl, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }


  postPurchaseBillCreditMemo(order: IInvoice) {
    order.items.forEach(data => delete data.id);
    const dataToSend = {
      ...order,
      billDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : this.utilsService.getStringDate(new Date()),
      orderDate: order.invoiceDate ? this.utilsService.getStringDate(order.invoiceDate) : this.utilsService.getStringDate(new Date()),
    };
    const postPBCreditMemoUrl = this.API_URLS.purhaseBillCreditMemo;
    return this.httpAuthPost(postPBCreditMemoUrl, dataToSend).pipe(
      map(res => {
        if (res) {
          return InvoiceService.getInvoiceModel(res);
        }
      })
    );
  }


  getPurchaseOrder(orderNo: number): Observable<IInvoice> {
    const url = `${this.API_URLS.purchaseorders}/${orderNo}`;
    return this.httpAuthGet(url).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = PurchaseOrderService.getPurchaseBillModel(res);
          output.company = this.userService.getUserCompany();
        }
        return output;
      })
    );
  }



  getBill(billId: number): Observable<IInvoice> {
    const url = `${this.API_URLS.bills}/${billId}`;
    return this.httpAuthGet(url).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = PurchaseOrderService.getPurchaseBillModel(res);
          output.company = this.userService.getUserCompany();
        }
        return output;
      })
    );
  }


  deleteBill(billId: number): Observable<boolean> {
    const deleteOrderUrl = `${this.API_URLS.bills}/${billId}`;
    return this.httpAuthDelete(`${deleteOrderUrl}`);
  }


  getBillCreditMemo(billCMId: number): Observable<IInvoice> {
    const url = `${this.API_URLS.bills}/${billCMId}`;
    return this.httpAuthGet(url).pipe(
      map(res => {
        let output: IInvoice = {};
        if (res) {
          output = PurchaseOrderService.getPurchaseBillModel(res);
          output.company = this.userService.getUserCompany();
        }
        return output;
      })
    );
  }

  getPurchaseOrderInformation(orderId: number): Observable<IInvoiceInfo> {
    return this.httpAuthGet(`${this.API_URLS.purchaseOrderInfo}/${orderId}`).pipe(
      map(res => {
        let output: IInvoiceInfo = {};
        if (res) {
          output = InvoiceService.getInvoiceInfoModel(res);
        }
        return output;
      })
    );
  }

  getBillInformation(orderId: number): Observable<IInvoiceInfo> {
    return this.httpAuthGet(`${this.API_URLS.billInfo}/${orderId}`).pipe(
      map(res => {
        let output: IInvoiceInfo = {};
        if (res) {
          output = InvoiceService.getInvoiceInfoModel(res);
        }
        return output;
      })
    );
  }

  // updatePurchaseOrder(po: IPurchaseOrder): Observable<any> {
  //   //  return this._http.get<IInvoice>('./assets/dummy/postorders.json');
  //   const body = JSON.stringify(po);
  //   const postOrderUrl = this.API_URLS.editPurchaseOrder;
  //   return this.httpAuthPost(`${postOrderUrl}`, body);
  // }

  private mapICategoryProductModel(res: any[]): ICategoryProduct[] {
    const output: ICategoryProduct[] = [];
    if (res && res.length > 0) {
      res.forEach(element => {
        output.push({
          categoryName: element.categoryName,
          products: this.mapQuickProductDetailList(element.products)
        });
      });
    }
    return output;
  }

  private mapQuickProductDetailList(res: any): IQuickProductDetail[] {
    const output: IQuickProductDetail[] = [];
    if (res && res.length > 0) {
      res.forEach(element => {
        output.push(this.mapQuickProductDetail(element));
      });
    }
    return output;
  }

  private mapQuickProductDetail(res: any): IQuickProductDetail {
    if (res) {
      return {
        customCost: res.cost,
        actualCost: res.cost,
        rootCategoryName: res.rootCategoryName,
        productId: res.productId,
        name: res.productName,
        quantity: res.quantity ? res.quantity : 0,
        actualPrice: res.price,
        price: res.price,
        isTaxable: res.isTaxable,
        categoryName: res.categorayName,
        lastOrders: res.lastOrders ? InvoiceService.getOrderModel(res.lastOrders) : []
      };
    }
  }



}
