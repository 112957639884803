<div class="flex-container invoice print-payment" *ngIf="paymentDetails">
  <div>
    <table style="margin-bottom: 10px;">
      <tr class="company-header-detail">
        <td>
          <div>
            <h2 class="company-heading">
              {{ companyInfo?.name }}
            </h2>
            <h3 class="header-subheading">
              <span *ngIf="companyInfo && companyInfo.address">{{ companyInfo?.address }}<br></span>
              <span *ngIf="companyInfo && (companyInfo.city || companyInfo.state || companyInfo.postalCode)">{{ companyInfo?.city }} {{ companyInfo?.state }} {{ companyInfo?.postalCode }}<br></span>
              <span *ngIf="companyInfo && companyInfo.country">{{ companyInfo?.country }}<br></span>
              <span *ngIf="companyInfo && companyInfo.email">{{ companyInfo?.email }}<br></span>
              <span *ngIf="companyInfo && companyInfo.phoneNo">{{ companyInfo?.phoneNo }}</span>
            </h3>
          </div>
        </td>
        <td>
          <div class="headerinvoicediv" >
            <ng-container>
              <h2 class="company-heading"> PAY # {{ paymentDetails?.paymentNumber }}</h2>
            </ng-container>
            <h3 class="header-subheading">{{ paymentDetails?.paymentDate | date:'dd MMM, yyyy' }}</h3>
          </div>
        </td>
      </tr>
      <tr>
        <td style="padding-top: 15px; vertical-align: top;">
          <div *ngIf="paymentType === paymentTypesEnum.Payment">
            <div class="bold-text">Customer</div>
            <div class="normal-text">{{ paymentDetails?.companyName ? paymentDetails?.companyName : paymentDetails['customerName'] }}</div>
          </div>
          <div *ngIf="paymentType === paymentTypesEnum.BillPayment">
            <div class="bold-text">Vendor</div>
            <div class="normal-text">{{ paymentDetails?.companyName ? paymentDetails?.companyName : paymentDetails['vendorName'] }}</div>
          </div>
          <div>
            <div class="bold-text">Payment Amount</div>
            <div class="normal-text">{{ paymentDetails?.totalAmount | currency }}</div>
          </div>
        </td>
        <td style="padding-top: 15px; vertical-align: top;">
          <div *ngIf="paymentDetails?.paymentNumber != null">
            <div class="bold-text" style="text-align: right;">Payment By</div>
            <div class="normal-text" style="text-align: right;">{{ paymentDetails?.paymentBy | uppercase }}</div>
          </div>
          <div>
            <div class="bold-text" style="text-align: right;">Payment Method</div>
            <div class="normal-text" style="text-align: right;">{{ paymentDetails?.paymentMethod }}</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  
  <div>
    <table>
      <thead>
        <tr class="invoice-header">
          <th style="text-align: center;padding-right: 5px;">{{ paymentType === paymentTypesEnum.BillPayment ? 'BILL' :'INVOICE' }} </th>
          <th style="text-align: right; padding-left: 5px;">{{ paymentType === paymentTypesEnum.BillPayment ? 'BILL TOTAL' :'INVOICE TOTAL' }} </th>
          <th style="text-align: right;padding-right: 5px;">APPLIED AMOUNT</th>
          <th style="text-align: right;padding-right: 5px;">APPLIED DATE</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="paymentType !== paymentTypesEnum.BillPayment">
          <tr class="invoice-items" *ngFor="let transaction of paymentDetails['paidInvoices']">
            <td style="text-align: center;padding-right: 5px;"> {{transaction.invoiceNumber}} </td>
            <td style="text-align: right;padding-left: 5px;"> {{transaction.totalAmount | currency}} </td>
            <td style="text-align: right;padding-right: 5px;"> {{ transaction.appliedAmount | currency }} </td>
            <td style="text-align: right;padding-right: 5px;"> {{transaction.paymentApplyDate | date:'dd MMM, yyyy' }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="paymentType === paymentTypesEnum.BillPayment">
          <tr class="invoice-items" *ngFor="let transaction of paymentDetails['paidBills']">
            <td style="text-align: center;padding-right: 5px;"> {{transaction.billNumber}} </td>
            <td style="text-align: right;padding-left: 5px;"> {{transaction.totalAmount | currency}} </td>
            <td style="text-align: right;padding-right: 5px;"> {{ transaction.appliedAmount | currency }} </td>
            <td style="text-align: right;padding-right: 5px;"> {{transaction.paymentApplyDate | date:'dd MMM, yyyy' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    
  </div>
</div>  