
import { Action, createReducer, on } from '@ngrx/store';
import * as AccountActions from './account.actions';
import { IAccounts, IAccountsCategory } from '@otrack-lib/models/expense/expense.model';
import { IStateBase } from '../data.reducer';

export const accountFeatureKey = 'otrack-accounts-config';

export interface AccountTypesDataState extends IStateBase {
  data: IAccounts[];
}

export interface AccountsCategoryDataState extends IStateBase {
  data: IAccountsCategory[];
}

export interface State {
  accounts: AccountTypesDataState;
  accountsCategoryList: AccountsCategoryDataState;
}

export const initialState: State = {
  accounts: { data: null, loading: false, success: false, error: null },
  accountsCategoryList: { data: null, loading: false, success: false, error: null }
};

const accountReducer = createReducer(
  initialState,
  on(AccountActions.clearAccounts, () => ( initialState )),
  on(AccountActions.loadAccounts, (state) => ( {...state,
                                                        accounts: {...state.accounts,
                                                                     loading: true,
                                                                     success: false,
                                                                     error: null } }
                                                  )),
  on(AccountActions.loadAccountCategories, (state) => ( {...state,
    accountsCategoryList: {...state.accountsCategoryList,
                                                                   loading: true,
                                                                   success: false,
                                                                   error: null } }
                                                )),

  on(AccountActions.loadAccountsFailure, (state, {error}) => ( {...state,
                                                              accounts: {...state.accounts,
                                                                 loading: false,
                                                                 success: false,
                                                                 error: error } }
                                           )),
  on(AccountActions.loadAccountCategoriesFailure, (state, {error}) => ( {...state,
    accountsCategoryList: {...state.accountsCategoryList,
                                                           loading: false,
                                                           success: false,
                                                           error: error } }
                                     )),

  on(AccountActions.loadAccountsSuccess, (state,  {data }) => ( {...state,
                                                   accounts: {...state.accounts,
                                                     data: data,
                                                     loading: false,
                                                     success: true,
                                                     error: null } }
                               )),
  on(AccountActions.loadAccountCategoriesSuccess, (state,  {data }) => ( {...state,
    accountsCategoryList: {...state.accountsCategoryList,
                                               data: data,
                                               loading: false,
                                               success: true,
                                               error: null } }
                         )),
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
