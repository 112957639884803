import { ConfirmEntityDialogComponent } from './content/crud/confirm-entity-dialog/confirm-entity-dialog.component';
import { LayoutCoreModule } from './../common/layout-core/layout.core.module';
import { DatepickerRangeComponent } from './components/datepicker-range/datepicker-range.component';
// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule, ConfirmationService, MultiSelectModule } from 'primeng/primeng';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material';
// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbModule, } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '@otrack-lib/core/core.module';
// CRUD Partials
import {
  ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent,
  UpdateStatusDialogComponent, SaleOrderChangeStateEntityDialogComponent,
} from './content/crud';
// Layout partials
import {
  ContextMenu2Component, ContextMenuComponent, LanguageSelectorComponent, NotificationComponent,
  QuickActionComponent, QuickPanelComponent, ScrollTopComponent, SearchDefaultComponent,
  SearchDropdownComponent, SearchResultComponent, SplashScreenComponent, Subheader1Component,
  Subheader2Component, Subheader3Component, Subheader4Component, Subheader5Component, SubheaderSearchComponent,
  UserProfileComponent
} from './layout';
// General
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { CartComponent } from './layout/topbar/cart/cart.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { TableColumnsFilterComponent } from './components/table-columns-filter/table-columns-filter.component';
import { ReportExportComponent } from './components/report-export/report-export.component';
import { ReportPrintHeaderComponent } from './components/report-print-header/report-print-header.component';
import { AdvanceFilterPanelComponent } from './components/report-filter/advance-filter/advance-filter.component';
import { MoveStatusWidgetComponent } from './content/general/move-status-widget/move-status-widget.component';
import { DealerSearchDropDownComponent } from './components/dealer-searchdropdown/dealer-searchdropdown.component';
import { FavoriteHeaderMenuComponent } from './components/favorite-header-menu/favorite-header-menu.component';
import { NgxDropzonePreviewComponent } from './components/ngx-image-preview/ngx-dropzone-preview.component';
// tslint:disable-next-line: max-line-length
import { NgxDropzoneImagePreviewComponent } from './components/ngx-image-preview/ngx-dropzone-image-preview/ngx-dropzone-image-preview.component';
import { TableAgGridComponent } from './components/table-ag-grid/table-ag-grid.component';
import { TableActionButtonsComponent } from './components/table-ag-grid/table-action-buttons/table-action-buttons.component';
import { TableAgGridLinkComponent } from './components/table-ag-grid/table-ag-grid-link/table-ag-grid-link.component';
import { TableAgGridRadioComponent } from './components/table-ag-grid/table-ag-grid-radio/table-ag-grid-radio.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReportLinkAccordionComponent } from './components/report-link-accordion/report-link-accordion.component';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { PlusMinusInputComponent } from './components/plus-minus-input/plus-minus-input.component';
import { PopoverIconBtn } from './components/popover-icon-btn/popover-icon-btn.component';
import { EmptyState } from './components/empty-state/empty-state.component';
import { OTButtonComponent } from './components/ot-button/ot-button.component';
import { ReportCogsOptionComponent } from './components/report-cogs-option/report-cogs-option.component';
import { OtReportFilterComponent } from './components/ot-report-filter/ot-report-filter.component';
import { BindQueryParamsFactory } from '@ngneat/bind-query-params';
import { OtAdvanceFilterComponent } from './components/ot-report-filter/ot-advance-filter/ot-advance-filter.component';

@NgModule({
  declarations: [
    ScrollTopComponent,
    DealerSearchDropDownComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    ConfirmEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    MoveStatusWidgetComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    Subheader4Component,
    Subheader5Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    CartComponent,
    DatepickerRangeComponent,
    ReportFilterComponent,
    TableColumnsFilterComponent,
    ReportExportComponent,
    ReportPrintHeaderComponent,
    AdvanceFilterPanelComponent,
    SaleOrderChangeStateEntityDialogComponent,
    ErrorComponent,
    FavoriteHeaderMenuComponent,
    NgxDropzonePreviewComponent,
    NgxDropzoneImagePreviewComponent,
    TableAgGridComponent,
    TableActionButtonsComponent,
    TableAgGridLinkComponent,
    TableAgGridRadioComponent,
    ReportLinkAccordionComponent,
    PlusMinusInputComponent,
    PopoverIconBtn,
    EmptyState,
    OTButtonComponent,
    ReportCogsOptionComponent,
    OtReportFilterComponent,
    OtAdvanceFilterComponent
    // directives

  ],
  providers: [
    ConfirmationService,
    BindQueryParamsFactory,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px'
      }
    },
    DecimalPipe
  ],
  exports: [
    WidgetModule,
    PortletModule,
    LayoutCoreModule,
    AgGridModule,
    SaleOrderChangeStateEntityDialogComponent,
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    ConfirmEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    ConfirmDialogModule,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    Subheader4Component,
    Subheader5Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    CartComponent,
    DatepickerRangeComponent,
    ReportFilterComponent,
    TableColumnsFilterComponent,
    ReportExportComponent,
    ReportPrintHeaderComponent,
    AdvanceFilterPanelComponent,
    ErrorComponent,
    DealerSearchDropDownComponent,
    FavoriteHeaderMenuComponent,
    NgxDropzonePreviewComponent,
    NgxDropzoneImagePreviewComponent,
    TableAgGridComponent,
    ReportLinkAccordionComponent,
    PlusMinusInputComponent,
    PopoverIconBtn,
    EmptyState,
    OTButtonComponent,
    ReportCogsOptionComponent,
    OtReportFilterComponent,
    OtAdvanceFilterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    FormsModule,
    AgGridModule.withComponents([]),
    DropdownModule,
    MultiSelectModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    LayoutCoreModule,
    PortletModule,
    WidgetModule,
    NgbModule,
    ConfirmDialogModule,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    MaterialModule,
  ],

  entryComponents: [ActionNotificationComponent, ConfirmEntityDialogComponent, TableActionButtonsComponent]
})
export class SharedModule { }
