import { QueryResultsModel } from './../../models/query-results.model';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';

import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './baseapi.service';
import { HttpUtilsService } from '../_base/crud';
import { IOrderItemSummary, IOrderItemDetailSummary } from '@otrack-lib/models/reports/orderItemSummary.model';
import { IPaymentSummary } from '@otrack-lib/models/reports/paymentSummary.model';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { map } from 'rxjs/operators';
import { IProductAdjustment, IProductAdjustmentLineItem } from '@otrack-lib/models/product/product.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryAdjustmentService extends BaseApiService {
  readonly API_URLS = {
    getStockOnHandDetails: `${this.baseApiUrl}/v1/inventory/stock-list`,
    adjustmentslist: `${this.baseApiUrl}/v1/inventory/adjustments/list`,
    adjustments: `${this.baseApiUrl}/v1/inventory/adjustments`,
  };


  constructor(private _injector: Injector, protected utilsService: UtilsService) {
    super(_injector);
  }

  static getAvailableStockModel(res: any): { productId: number, availableStock: number, availableStockValue: number} {
    return {
      productId: res.productId,
      availableStock: res.quantity,
      availableStockValue: res.value
    };
  }


  static getProductAdjustment(res: any): IProductAdjustment {
    return {
      id: res.id,
      accountId: res.accountId,
      refNum: res.refNum,
      memo: res.memo,
      reason: res.reason,
      adjustmentType: res.adjustmentType,
      adjustmentDate: res.adjustmentDate,
      adjustedBy: res.createdBy,
      createdDate: res['createdDate'],
      lineItems: res.lineItems ? res.lineItems.map(item => InventoryAdjustmentService.getProductAdjustmentItemsModel(item)) : null,
    };
  }

  static getProductAdjustmentItemsModel(res: any): IProductAdjustmentLineItem {
    return {
        productId: res.productId,
        description: res.description,
        adjustedValue: res.adjustedValue,
        sortOrder: res.sortOrder,
    };
  }


  getStockOnHandByDate(productIds: number[], stockOnHandDate: string ): Observable< { productId: number, availableStock: number, availableStockValue: number}[]> {

     const dateToSend = {
      date: stockOnHandDate ? stockOnHandDate : this.utilsService.getStringDate(new Date()),
      productIds: productIds
     };
      return this.httpAuthPost(`${this.API_URLS.getStockOnHandDetails}`, dateToSend)
            .pipe(map(resp => resp && resp.data && resp.data.stocks ?  resp.data.stocks.map(stock=>  InventoryAdjustmentService.getAvailableStockModel(stock)) : []));
    //return of([{ productId: 7512, availableStock: 250, availableStockValue: 25000}, { productId: 9453, availableStock: -50, availableStockValue: 3000}]);
  }

  //DELETE TODO
  createUpdateAdjustment(dataToSend: IProductAdjustment): Observable<IProductAdjustment> {
    if (dataToSend.id) {
      // update adjustment
      return this.httpAuthPut(`${this.API_URLS.adjustments}`, dataToSend).pipe(
        map(res => {
          return InventoryAdjustmentService.getProductAdjustment(res.data);
        })
      );
    } else {
      // create new adjustment
      return this.httpAuthPost(`${this.API_URLS.adjustments}`, dataToSend).pipe(
        map(res => {
          return InventoryAdjustmentService.getProductAdjustment(res.data);
        })
      );
    }
  }

  createAdjustment(dataToSend: IProductAdjustment) : Observable<IProductAdjustment> {
    return this.httpAuthPost(`${this.API_URLS.adjustments}`, dataToSend).pipe(
      map(res => {
        return InventoryAdjustmentService.getProductAdjustment(res.data);
      })
    );
  }

  updateAdjustment(dataToSend: IProductAdjustment) : Observable<IProductAdjustment> {
    return this.httpAuthPut(`${this.API_URLS.adjustments}/${dataToSend.id}`, dataToSend).pipe(
      map(res => {
        return InventoryAdjustmentService.getProductAdjustment(res.data);
      })
    );
  }

  deleteAdjustment(adjustmentId: number): Observable<boolean> {
    return this.httpAuthDelete(`${this.API_URLS.adjustments}/${adjustmentId}`).pipe(
      map(res => true));
  }

  getAdjustment(id: number): Observable<IProductAdjustment> {
    return this.httpAuthGet(`${this.API_URLS.adjustments}/${id}`).pipe(
      map(res => {
        const model = InventoryAdjustmentService.getProductAdjustment(res.data);
        return model;
      })
    );
  }

  getAdjustmentListByFilter(params: any): Observable<QueryResultsModel> {
    const dataToSend = {
      fromDate: params.fromDate,
      toDate: params.toDate,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      searchText: params.searchText
    };
    return this.httpAuthPost(`${this.API_URLS.adjustmentslist}`, dataToSend).pipe(
      map(res => {
        const output: IProductAdjustment[] = [];
        const total = res.pagination.total;
        for (const item of res.data) {
          const model = InventoryAdjustmentService.getProductAdjustment(item);
          output.push(model);
        }
        return new QueryResultsModel(output, total);
      })
    );
  }
}
