import { Injectable, OnDestroy } from '@angular/core';

import { StorageService } from './storage.service';


@Injectable({
    providedIn: 'root',
})
export class LocalStorageService extends StorageService implements OnDestroy {
    private intervalRef;
    protected storageName = 'LocalStorage';


    constructor(
    ) {
        super();
        this.intervalRef = setInterval( () => { this.purgeAllExpired(); }, 20000 );
    }


    protected getAllKeys(): string[] {
        if ( localStorage ) {
            return Object.keys( localStorage );
        } else {
            return Object.keys( window.localStorage );
        }
    }


    protected setValue( key: string, value: string ): void {
        try {
            if ( localStorage ) {
                localStorage.setItem( key, value );
            } else {
                window.localStorage.setItem( key, value );
            }
        } catch ( e ) {
            // console..log(`${this.storageName} Exception: `, e);
        }
    }


    protected getValue( key: string ): string {
        try {
            if ( localStorage ) {
                return localStorage.getItem( key );
            } else {
                return window.localStorage.getItem( key );
            }
        } catch ( e ) {
            // console..log(`${this.storageName} Exception: `, e);
        }
    }


    protected removeValue( key: string ): void {
        try {
            if ( localStorage ) {
                localStorage.removeItem( key );
            } else {
                window.localStorage.removeItem( key );
            }
        } catch ( e ) {
            // console..log(`${this.storageName} Exception: `, e);
        }
    }


    ngOnDestroy() {
        if ( this.intervalRef ) {
            clearInterval( this.intervalRef );
        }
    }

}
