import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITransactionList } from '@otrack-lib/models/order/transaction.model';
import { BaseApiService } from './baseapi.service';
import { OrderTypes } from '@otrack-lib/enums';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';


@Injectable({
  providedIn: 'root'
})
export class TransactionReportService extends BaseApiService {
  readonly API_URLS = {
    transactionReport: `${this.baseApiUrl}/v1/Orders/Transactions`
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  static getTransactionModel(res: any): ITransactionList {
    return {
      customerName: res['customerName'] || res['vendorName'] ,
      companyName: res['companyName'] || res['vendorCompany'] || res['vendorName'],
      customerId: +res['customerId'],
      id: +res['id'],
      transaction: res['transaction'],
      transactionType: +res['transactionType'],
      transactionNumber: res['transactionNumber'],
      modifiedDate: res['modifiedDate'],
      date: res['date'],
      totalTax: res['totalTax'] ? +res['totalTax'] : 0,
      subTotal: res['subTotal'] ? +res['subTotal'] : 0,
      amount: +res['amount'],
      invoiceBalance: +res['transactionType'] === OrderTypes.Payment ?   +res['remainingCreditAmount'] :   +res['invoiceBalance'] || +res['billBalance'],
      isSynced: res['isSynced'],
      memo: res['memo']
    };
  }

  getTransactionReport(
    fromDate: string,
    toDate: string,
    transactionType: OrderTypes,
    pageSize: number = 100000,
    page: number = 1
  ): Observable<QueryResultsModel> {
    const dataParams = [];
    dataParams['FromDate'] = fromDate;
    dataParams['ToDate'] = toDate;
    dataParams['pageSize'] = pageSize;
    dataParams['page'] = page;
    dataParams['transactionType'] = transactionType;

    return this.httpAuthGet(
      `${this.API_URLS.transactionReport}?${this.generateQueryString(dataParams)}`
    ).pipe(
      map(res => {
        const output: ITransactionList[] = [];
        for (const item of res) {
          output.push(TransactionReportService.getTransactionModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }
}
