<div class="kt-portlet" [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : viewLoading }">
    <!-- <div class="kt-portlet__head kt-portlet__head__custom modal-header d-print-none">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title" style="color:white !important;">Email Invoice</h3>
        </div>
    </div> -->
    <form class="kt-form" [formGroup]="emailInvoiceForm">
        <mat-dialog-content class="mat-dialog-style">
            <div class="kt-portlet__body modal-body">
                <div style="width:100%; display:flex; flex-direction: row;">
                    <div class="modal-email-aside">
                        <i class="material-icons aside-icon"> email</i>
                        <h1 class="aside-heading">Email Invoice</h1>
                    </div>
                    
                    
                    <div class="modal-email-form">
                        <!-- <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                            {{ errorMessage ? errorMessage : 'Oh snap! Change a few things up and try submitting again.'}}
                        </kt-alert> -->

                        <div class="form-group kt-form__group row">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <label class="form-label" >From<span style="color: red">*</span></label>
                                <input class="form-control form-item" type="email" placeholder="From" required disabled formControlName="fromEmail" name="fromEmail" />
                                <div *ngIf="utils.fieldErrorCondition(this.emailInvoiceForm.controls.fromEmail)" style="color:red"><small>From email address can't be blank</small></div>
                            </div>
                        </div>
                        <div class="form-group kt-form__group row">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <label class="form-label" >To<span style="color: red">*</span></label>
                                <input class="form-control form-item" type="email" placeholder="To" required formControlName="toEmail" name="toEmail" />
                                <div *ngIf="utils.fieldErrorCondition(this.emailInvoiceForm.controls.toEmail)" style="color:red"><small>To email address can't be blank</small></div>
                            </div>
                        </div>
                        <div class="form-group kt-form__group row">
                            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                <label class="form-label" >CC</label>
                                <input class="form-control form-item" type="email" placeholder="CC" formControlName="ccEmail" name="ccEmail" />
                                <div *ngIf="utils.fieldErrorCondition(this.emailInvoiceForm.controls.ccEmail)" style="color:red"><small>Please enter valid email address</small></div>
                            </div>
                            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                <label class="form-label" >BCC</label>
                                <input class="form-control form-item" type="email" placeholder="BCC" formControlName="bccEmail" name="bccEmail" />
                                <div *ngIf="utils.fieldErrorCondition(this.emailInvoiceForm.controls.bccEmail)" style="color:red"><small>Please enter valid email address</small></div>
                            </div>
                        </div>
                        <div class="form-group kt-form__group row">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <label class="form-label" >Message</label>
                                <textarea class="form-control form-item" rows="4" type="textarea" placeholder="Email message" formControlName="message" name="message"></textarea>
                            </div>
                        </div>


                        <div class="form-group kt-form__group row text-right">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <button type="button"mat-raised-button color="primary" (click)="sendEmail()" cdkFocusInitial matTooltip="Send Email"> Send Email </button>
                                &nbsp;
                                <button type="button" (click)="cancelEmail()" mat-raised-button cdkFocusInitial matTooltip="Cancel changes"> Cancel </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </form>
    <!-- <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit d-print-none">
        <div class="kt-form__actions kt-form__actions--solid" style="padding:10px !important;">
            <div class="row text-right">
                <div class="col-lg-12">
                    <button type="button"mat-raised-button color="primary" (click)="sendEmail()" cdkFocusInitial matTooltip="Send Email"> Send Email </button>
                    &nbsp;
                    <button type="button" (click)="cancelEmail()" mat-raised-button cdkFocusInitial matTooltip="Cancel changes"> Cancel </button>
                </div>
            </div>
        </div>
    </div> -->
</div>
