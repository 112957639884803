import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpUtilsService } from '../_base/crud';
import { BaseApiService } from './baseapi.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { IPosTrailItem } from '@otrack-lib/models/point-of-sale/point-of-sale.models';

@Injectable({
  providedIn: 'root'
})
export class PointOfSaleTrailService extends BaseApiService {
  // not allowed to accessable from outside of this class
  private shiftId = null;
  private readonly API_URLS = {
    shiftTrail: `${this.baseApiUrl}/v1/pos/trail/shifts`,
    changeCustomer: `${this.baseApiUrl}/v1/pos/trail/shifts/${this.shiftId}/action/changecustomer`,
    voidSale: `${this.baseApiUrl}/v1/pos/trail/shifts/${this.shiftId}/action/voidsale`,
    deleteHoldOrder: `${this.baseApiUrl}/v1/pos/trail/shifts/${this.shiftId}/holdorder`,
  };

  constructor(
    private readonly _injector: Injector,
    private readonly httpUtils: HttpUtilsService,
    private readonly userService: UserService,
  ) {
    super(_injector);
  }

  public static getPosTrailItemModel(res: any): IPosTrailItem {
    return {
      id: res.id,
      lineNo: res.lineNo,
      productId: res.productId,
      customerId: res.customerId,
      quantity: res.quantity,
      price: res.price,
      taxable: res.taxable
    };
  }

  public addItemTrail(shiftId: number, data: any): Observable<IPosTrailItem> {
    return this.httpAuthPost(`${this.API_URLS.shiftTrail}/${shiftId}/action/add`, data).pipe(map(res => {
      if (res && res.data) {
        return PointOfSaleTrailService.getPosTrailItemModel(res.data);
      }
    }));
  }

  public deleteItemTrail(shiftId: number, data: any): Observable<any> {
    return this.httpAuthPost(`${this.API_URLS.shiftTrail}/${shiftId}/action/delete`, data).pipe(map(res => {
      if (res && res.data) {
        return PointOfSaleTrailService.getPosTrailItemModel(res.data);
      }
    }));
  }

  public changeItemQtyTrail(shiftId: number, data: any): Observable<any> {
    return this.httpAuthPost(`${this.API_URLS.shiftTrail}/${shiftId}/action/changequantity`, data).pipe(map(res => {
      if (res && res.data) {
        return PointOfSaleTrailService.getPosTrailItemModel(res.data);
      }
    }));
  }

  public changeItemPriceTrail(shiftId: number, data: any): Observable<any> {
    return this.httpAuthPost(`${this.API_URLS.shiftTrail}/${shiftId}/action/changeprice`, data).pipe(map(res => {
      if (res && res.data) {
        return PointOfSaleTrailService.getPosTrailItemModel(res.data);
      }
    }));
  }

  public changeCustomerTrail(shiftId: number, data: any): Observable<boolean> {
    return this.httpAuthPost(`${this.API_URLS.shiftTrail}/${shiftId}/changecustomer`, data).pipe(map(res => (res && res.data) ? res.data : false));
  }

  public voidSaleTrail(shiftId: number): Observable<any> {
    return this.httpAuthPost(`${this.API_URLS.shiftTrail}/${shiftId}/voidsale`, {}).pipe(map(res => (res && res.data) ? res.data : false));
  }

  public deleteHoldOrderTrail(shiftId: number, data: any, orderId: number): Observable<any> {
    this.shiftId = shiftId;
    return this.httpAuthPost(`${this.API_URLS.deleteHoldOrder}/${orderId}`, data).pipe(map(res => (res && res.data) ? res.data : res));
  }

  public getPendingTransactions(shiftId: number): Observable<IPosTrailItem[]> {
    return this.httpAuthGet(`${this.API_URLS.shiftTrail}/${shiftId}/pendingtransactions`).pipe(map(res => {
      const items = [];
      if (res && res.data) {
        res.data.forEach(item => {
          items.push(PointOfSaleTrailService.getPosTrailItemModel(item));
        });
      }
      return items;
    }));
  }
}
