import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ReportService} from "@otrack-lib/core/services/report.service";
import {PrintService} from "@otrack-lib/core/helper-services/print.service";
import {ReportFiltersModel} from "@otrack-lib/models/report-filters.model";
import {ITransactionList} from "@otrack-lib/models/order/transaction.model";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@otrack-lib/core/services/user.service";
import {IAuthUser} from "@otrack-lib/models/company/auth-user.model";
import * as moment from "moment";
import {IOrderItemSummary} from "@otrack-lib/models/reports/orderItemSummary.model";

@Component({
  selector: 'app-printtransactionreport',
  templateUrl: './print-item-order-report.component.html',
  styleUrls: ['./print-item-order-report.component.scss']
})
export class PrintItemOrderReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  items: IOrderItemSummary[];
  totalQuantity: number = 0;
  totalAmount: number = 0;
  totalCogs: number = 0;
  user: IAuthUser;
  barcodeOptions = {
    'width': 2,
    'height': 22,
    'fontSize': 14,
    'elementType': 'svg'
  };

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem("report_print"));
    if (this.reportFilter) {
      this.fetchItemOrderSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchItemOrderSummaryReport() {
    this.reportService.getOrderItemSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.items = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.totalAmount = this.items.map(t => t.amount).reduce((acc, value) => acc + value, 0);
    this.totalQuantity = this.items.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
    this.totalCogs = this.items.map(t => t.cogs).reduce((acc, value) => acc + value, 0);

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem("report_print");
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
