import { Observable, of } from 'rxjs';
import { catchError, finalize, tap, filter } from 'rxjs/operators';
import { BaseDataSource } from '../_base.datasource';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';


export class ReportsDataSource extends BaseDataSource {
  constructor(private reportService: ReportService) {
    super();
  }

  loadPOSTransactionTrailSummary(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPOSTxnTrailSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadPOSTransactionSummary(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPOSTranSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
          this.additionalFieldsSubject.next(res.additionalFields);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadEmailStatusReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getEmailStatusReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadOrderItemSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getOrderItemSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadDamageItemSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getDamageItemSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }


  loadIndividualItemSaleDetailReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getIndividualItemSaleDetailReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadPurchaseItemSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPurchaseItemSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadStockSummaryReport(reportfilter: ReportFiltersModel): Observable<QueryResultsModel> {
    this.loadingSubject.next(true);
    return this.reportService
      .getStockSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  loadItemQuickSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    this.loadingSubject.next(true);
    return this.reportService
      .getItemQuickSummaryReport(reportFilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  loadCustomerItemSaleSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getCustomerItemSaleSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadStateReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getStateReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadVendorItemSaleSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getVendorItemSaleSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }


  loadPaymentSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPaymentSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadPaymentPayoutReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPaymentPayoutReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadPaymentUnpaidPayoutReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPaymentPayoutUnpaidReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadPaymentPayoutDetail(id?: number) {
    return this.reportService.getPaymentPayoutDetailReport(id)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadOrderItemDetailSummaryReport(productId: number, reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getOrderItemDetailSummaryReport(productId, reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadDamageItemDetailSummaryReport(productId: number, reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getDamageItemDetailSummaryReport(productId, reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }



  loadPurchaseItemDetailSummaryReport(productId: number, reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getPuchaseItemDetailSummaryReport(productId, reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadAgingSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getAgingSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }


  loadVendorAgingSummaryReport(reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getVendorAgingSummaryReport(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadAgingDetailReport(customerId: number, reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getAgingDetailReport(customerId, reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadVendorAgingDetailReport(vendorId: number, reportfilter: ReportFiltersModel) {
    this.loadingSubject.next(true);
    this.reportService
      .getVendorAgingDetailReport(vendorId, reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }


  loadZReport(reportfilter: ReportFiltersModel): Observable<QueryResultsModel> {
    this.loadingSubject.next(true);
    return this.reportService
      .getZReports(reportfilter)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      );
  }



  getTotalQuantity() {
    return this.entitySubject.value.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
  }

  getTotalAmount() {
    return this.entitySubject.value.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }


  getTotal(fieldName: string) {
    return this.entitySubject.value.map(t => t[fieldName]).reduce((acc, value) => acc + value, 0);
  }

  getTotalTaxAmount() {
    return this.entitySubject.value.map(t => t.totalTax).reduce((acc, value) => acc + value, 0);
  }

  getTotalCost() {
    return this.entitySubject.value.map(t => t.cogs).reduce((acc, value) => acc + value, 0);
  }
}
