import { CompanySettingsService } from '@otrack-lib/core/services/company-settings.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SettingsActions } from '.';


@Injectable()
export class SettingsEffects {

  loadDeliveryContainerSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsActions.loadDeliveryContainerSettings),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.companySettingsService.getDeliveryContainersList().pipe(
          map(data => SettingsActions.loadDeliveryContainerSettingsSuccess({ data })),
          catchError(error => of(SettingsActions.loadDeliveryContainerSettingsFailure({ error }))))
      )
    );
  });


  deleteDeliveryContainer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsActions.deleteDeliveryContainer),
      concatMap((payload) =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.companySettingsService.deleteDeliveryContainer(payload.data).pipe(
          map(data => SettingsActions.loadDeliveryContainerSettings()),
          catchError(error => of(SettingsActions.deleteDeliveryContainerFailure({ error }))))
      )
    );
  });

  constructor(private actions$: Actions,
              private companySettingsService: CompanySettingsService, ) {
              }

}
