export const environment = {
  env: 'dev',
  build: 'v9.0.0',
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'ce9d77b308c149d5992a80073637e4d5',
  company: {
    companyId: 58,
    companyCode: 'KXTR_VzGVUoOY',
    imageBaseUrl: 'https://otrack-public.s3.amazonaws.com/companies/TEST++Telfier+Spices/category_images'
  },
  stripeKey: 'pk_test_5dne1pqGFD7IlUnvAEZxBtNJ00YKQuMYTa',
  firebase: {
    apiKey: 'AIzaSyCaG31MK3G-5b3ru-U7GoTLXa05mvWrjHE',
    authDomain: 'otrack-dev.firebaseapp.com',
    databaseURL: 'https://otrack-dev.firebaseio.com',
    projectId: 'otrack-dev',
    storageBucket: 'otrack-dev.appspot.com',
    messagingSenderId: '825341143666',
    appId: '1:825341143666:web:177ddbc7f440cc8c84f0eb',
    measurementId: 'G-D6JPQTWW66',
  },
  mode: 'Development Environment',
  apiURL: 'https://api.pinonclick.com',
  appDomain: '.pinonclick.com',  // include dot as prefix
  signupFunction: 'https://app.pinonclick.com/users_signin',
  checkstatusFunction: 'https://app.pinonclick.com/users_checkstatus',
  signoutFunction: 'https://app.pinonclick.com/users_signout',
  loginPageUrl: 'http://auth.pinonclick.com/login',
  sentryDsn: '',
  localToken:  '',
};
