import { BaseDataSource } from '@otrack-lib/common/data-sources/_base.datasource';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';


export class PosTransactionDataSource extends BaseDataSource {
  constructor(private posService: PointOfSaleService) {
    super();
  }


  loadOrdersByShiftId(shiftId: number) {
    this.loadingSubject.next(true);
    this.posService
      .getOrderByShiftId(shiftId)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }

  loadHoldOrdersByShiftId(shiftId: number) {
    this.loadingSubject.next(true);
    this.posService
      .getHoldOrdersByShiftId(shiftId)
      .pipe(
        tap(res => {
          this.entitySubject.next(res.items);
          this.paginatorTotalSubject.next(res.totalCount);
        }),
        catchError(err => of(new QueryResultsModel([], err))),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe();
  }
}
