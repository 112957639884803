import { AppplicationPermissions } from '@otrack-lib/core/const.application.permission';
import { OrderTypes } from '@otrack-lib/enums/ordertype.enum';

export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [/* 
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: 'dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        }, */
        {
          title: 'Orders Received',
          root: true,
          icon: 'flaticon2-supermarket',
          page: '/sales-orders',
          badge: 'N',
          translate: 'MENU.DASHBOARD',
        },
        {
          title: 'Sales',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-plus-1',
          submenu: [
            {
              title: 'Sale Order',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.SalesOrder
            },
            {
              title: 'Invoice',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.Invoice
            },
            {
              title: 'Credit Memo',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.CreditMemo
            },
            {
              title: 'Payment Received',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.Payment,
              permission: AppplicationPermissions.PaymentReceived_Read,
            },
          ]
        },
        { section: '' },
        {
          title: 'Customer',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-user',
          submenu: [
            {
              title: 'Customer List',
              bullet: 'dot',
              page: '/customer/list',
            },
            {
              title: 'Customer Detail',
              bullet: 'dot',
              page: '/customer/customerdetail',
            },
          ]
        },
        {
          title: 'Inventory',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-digital-marketing',
          submenu: [
            {
              title: 'Categories',
              page: '/product/categories'
            },
            {
              title: 'Products',
              permission: AppplicationPermissions.Product_Read,
              page: '/product/list'
            },
            {
              title: 'Price Tiers',
              permission: AppplicationPermissions.PriceTier_Read,
              page: '/price/list'
            },
            {
              title: 'Adjustment',
              permission: AppplicationPermissions.Adjustment_Read,
              page: '/product/adjustment'
            }
          ]
        },
        {
          title: 'Vendor',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-user',
          submenu: [
            {
              title: 'Vendor List',
              bullet: 'dot',
              page: '/vendor/list',
              permission: AppplicationPermissions.Vendor_Read // AppplicationPermissions.Vendor_List,
            },
            {
              title: 'Vendor Detail',
              bullet: 'dot',
              page: '/vendor/vendordetail',
              permission: AppplicationPermissions.Vendor_Read // AppplicationPermissions.Vendor_Detail,
            },
          ]
        },
        {
          title: 'Purchase',
          root: true,
          bullet: 'dot',
          permission: AppplicationPermissions.Purchase_Read,
          icon: 'flaticon2-plus-1',
          submenu: [
            {
              title: 'Purchase Order',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.PurchaseOrder
            },
            {
              title: 'Purchase Bill',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.PurchaseBill
            },
            {
              title: 'Purchase CreditMemo',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.BillCreditMemo
            },
            {
              title: 'Bill Payment',
              bullet: 'dot',
              page: '/transactionlist/type/' + OrderTypes.BillPayment
            },
          ]
        },
        {
          title: 'Expense',
          root: true,
          icon: 'flaticon-interface-9',
          page: '/expense',
          permission: AppplicationPermissions.Expense_Read,
          translate: 'MENU.DASHBOARD',
        },
        {
          title: 'Point Of Sale',
          root: true,
          bullet: 'dot',
          permission: AppplicationPermissions.Pos_Read,
          icon: 'fas fa-store-alt',
          submenu: [
            {
              title: 'Pos Terminal',
              bullet: 'dot',
              page: '/pos'
            },
            {
              title: 'Pos Transaction',
              bullet: 'dot',
              page: '/reports/pos/trail',
            },
            {
              title: 'Pos Settings',
              bullet: 'dot',
              page: '/pos-settings',
            },
          ]
        },
        {
          title: 'Delivery',
          root: true,
          bullet: 'dot',
          permission: AppplicationPermissions.Delivery_Read,
          icon: 'flaticon-truck',
          submenu: [
            {
              title: 'Driver',
              bullet: 'dot',
              page: '/driver/list',
            }
          ]
        },

        { section: 'Reports' },
        {
          title: 'Reports',
          root: true,
          bullet: 'dot',
          permission: AppplicationPermissions.Reports,
          icon: 'flaticon-analytics',
          page: '/reports',
        },
        { section: 'Management' },
        {
          title: 'Chart Of Accounts',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-folder-1',
          permission: AppplicationPermissions.ChartOfAccounts_Read,
          page: '/chart-of-accounts',
        },
        {
          title: 'Email Queue',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-email',
          page: '/reports/email/status',
        },
        {
          title: 'User Management',
          root: true,
          bullet: 'dot',
          permission: AppplicationPermissions.UserManagement_Read,
          icon: 'flaticon-user',
          submenu: [
            {
              title: 'Users',
              page: '/user-management/users'
            },
            {
              title: 'Roles',
              page: '/user-management/roles'
            }
          ]
        },
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
