import { UserService } from '@otrack-lib/core/services/user.service';
import { IPickupTimeSlot } from '@otrack-lib/models/order/pickup-info.model';
import { DriverService } from './driver.service';
import { IAddress } from './../../models/order/delivery-info.model';
import { ISaleOrderDeliveryInfo, OrderContainer } from './../../models/sales-order/sale-order.model';
import { Injectable, Injector } from '@angular/core';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { QueryParamsModel } from '@otrack-lib/models/query-params.model';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { ISalesOrder } from '@otrack-lib/models/sales-order/sale-order.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { InvoiceService } from './invoice.service';
import { ChargeStatus } from '@otrack-lib/enums/charge-status.enum';
import { IHighlightedTags } from '@otrack-lib/models/highlighted-tags.model';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService extends BaseApiService {
  readonly ApiUrls = {
    odataSalesOrders: `${this.baseApiUrl}/v1/odata/SalesOrders/SalesOrderList`,
    salesOrders: `${this.baseApiUrl}/v1/salesorders`,
    updateStatus: `${this.baseApiUrl}/v1/salesorders/updateStatus`,
    pickupSlip: `${this.baseApiUrl}/v1/deliveries`,
    driverAssigned: `${this.baseApiUrl}/v1/deliveries/assign`,
    driverUnAssigned: `${this.baseApiUrl}/v1/deliveries/unassign`,
    availableDates: `${this.baseApiUrl}/v1/salesOrders/delivery/availableDates`,
    availableTimeSlots: `${this.baseApiUrl}/v1/salesOrders/pickUp/availableTimeSlots`
  };

  constructor(private _injector: Injector, private userService: UserService) {
    super(_injector);
  }

  static getSalesOrderModel(res: any): ISalesOrder {
    return {
      salesOrderId: res.salesOrderId,
      customerName: res.customerName,
      totalAmount: res.totalAmount,
      orderBy: res.orderBy,
      orderDate: res.orderDate,
      status: res.status,
      convertInvoiceNumber: res.convertInvoiceNumber,
      convertInvoiceId: res.convertInvoiceId,
      convertDate: res.convertDate,
      convertedBy: res.convertedBy,
      deliveryType: res.deliveryType,
      shippingAddress: res.shippingAddress,
      pickupDate: res.pickupDate,
      pickupTime: res.pickupTime,
      contactPhone: res.contactPhone,
      salesOrderType: res.salesOrderType,
      isCashOnDelivery: res.isCashOnDelivery,
      chargeStatus: res.isCashOnDelivery && res.isCashOnDelivery === true ? ChargeStatus.CashOnDelivery : res.chargeStatus,
      totalTax: res.totalTax,
      deliveryFee: res.deliveryFee ? res.deliveryFee : 0,
      delivery: this.getSaleOrderDeliverInfo(res.delivery),
      tags: res.tags ? res.tags : []
    };
  }

  static getSaleOrderDeliverInfo(delivery: any): ISaleOrderDeliveryInfo {
    if (delivery) {
      return {
        address: this.getAddressModel(delivery.address),
        status: delivery.status,
        containers: delivery.containers ? this.getContainers(delivery.containers) : [],
        driver: delivery.driver ? DriverService.getDriverModel(delivery.driver) : undefined,
      };
    } else {
      return null;
    }
  }

  static getContainers(res: any[]): OrderContainer[] {
    const output: OrderContainer[] = [];
    res.forEach(cont => {
      output.push({ id: cont.id, quantity: cont.quantity, description: cont.description });
    });
    return output;
  }

  static getAddressModel(res: any): IAddress {
    if (res) {
      return {
        id: res['id'],
        city: res['city'],
        state: res['state'],
        country: res['country'],
        address1: res['address1'],
        address2: res['address2'],
        postalCode: res['postalCode'],
      }
    } else {
      return null;
    }
  }


  isOrderRegFlagged(highlightedTags: IHighlightedTags[], order: ISalesOrder): boolean {
    if ((highlightedTags && highlightedTags.length === 0)
      || order.tags && order.tags.length === 0) {
      return false;
    }

    let flag = false;
    const highlightTags: IHighlightedTags = this.userService.getUserCompany().highlightedTags.find(t => t.type === 'product');
    if (highlightTags) {
      for (const highlight of highlightTags.tags) {
        const productTag = order.tags.find(t => t === highlight.tag);
        if (productTag) {
          flag = true;
          break;
        }
      }
    }

    return flag;
  }

  getSalesOrders(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const params = queryParams.toQueryParams();
    const queryString = params ? `?${params}` : '';
    return this.httpAuthGet(`${this.ApiUrls.salesOrders}${queryString}`).pipe(
      map(res => {
        const highlightedTags = this.userService.getUserCompany().highlightedTags ?
          this.userService.getUserCompany().highlightedTags : [];
        const items = res.records.map((item: any) => {
          const order = SalesOrderService.getSalesOrderModel(item);
          order.isRedFlagged = this.isOrderRegFlagged(highlightedTags, order);
          return order;
        }
        );
        return new QueryResultsModel(items, +res.totalRecords, null, res.extended, res.extended);
      })
    );
  }

  getSalesOrdersByFilter(
    queryParams: QueryParamsModel
  ): Observable<QueryResultsModel> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = queryParams.pageNumber;
    dataParams['top'] = queryParams.pageSize;
    if (queryParams.getODataSearchFilterString().length > 0) {
      dataParams['vfilter'] = queryParams.getODataSearchFilterString();
    }

    if (queryParams.getODataOrderByFilterString()) {
      dataParams['orderby'] = queryParams.getODataOrderByFilterString();
    }

    // console..log('httpAuthGet', `${this.ApiUrls.salesOrders}`);
    return this.httpAuthGet(
      `${this.ApiUrls.odataSalesOrders}?${this.generateODataQueryString(
        dataParams
      )}`
    ).pipe(
      map(res => {
        const highlightedTags = this.userService.getUserCompany().highlightedTags ?
          this.userService.getUserCompany().highlightedTags : [];
        const total = +res['@odata.count'];
        const output: ISalesOrder[] = res['value'].map((item: any) => {
          const order = SalesOrderService.getSalesOrderModel(item);
          order.isRedFlagged = this.isOrderRegFlagged(highlightedTags, order);
          return order;
        });
        return new QueryResultsModel(output, total);
      })
    );
  }

  updateStatus(orderIds: any[], newStatus: SalesOrderStatus) {
    const params = orderIds.map(id => ({ salesOrderId: id, newStatus }));
    return this.httpAuthPost(`${this.ApiUrls.updateStatus}`, params);
  }


  deleteSaleOrder(saleOrderId: number): Observable<boolean> {
    const deleteOrderUrl = `${this.ApiUrls.salesOrders}/${saleOrderId}`;
    return this.httpAuthDelete(`${deleteOrderUrl}`);
  }

  updatePickupSlip(saleOrderId: number,
    containers: { id: number, quanityt: number, description: string }[]): Observable<any> {
    const dataToSend = {
      salesOrderId: saleOrderId,
      containers: containers,
    };
    return this.httpAuthPut(`${this.ApiUrls.pickupSlip}`, dataToSend);
  }

  assignedDrivers(saleOrderIds: number[], driverId: number): Observable<any> {
    const dataToSend = {
      driverId: driverId,
      entityType: 'SalesOrder',
      orderIds: saleOrderIds,
    };

    return this.httpAuthPost(`${this.ApiUrls.driverAssigned}`, dataToSend);
  }

  unAssignedDrivers(saleOrderIds: number[]): Observable<any> {
    const dataToSend = {
      // driverId: driverId,
      entityType: 'SalesOrder',
      orderIds: saleOrderIds,
    };

    return this.httpAuthPost(`${this.ApiUrls.driverUnAssigned}`, dataToSend);
  }

  addPickupSlip(saleOrderId: number,
    containers: { id: number, quanityt: number, description: string }[]): Observable<any> {
    const dataToSend = {
      salesOrderId: saleOrderId,
      containers: containers,
    };
    return this.httpAuthPost(`${this.ApiUrls.pickupSlip}`, dataToSend);
  }

  convertToInvoice(saleOrderId: number): Observable<IInvoice> {
    const body = '';
    const convertToInvoiceUrl = `${this.ApiUrls.salesOrders
      }/${saleOrderId}/convertToInvoice`;
    return this.httpAuthPost(`${convertToInvoiceUrl}`, body).pipe(
      map(res => {
        res.customer = InvoiceService.getCustomerModel(res);
        return res;
      })
    );
  }

  updatePickupDateAndTime(salesOrderId: number, date: string, time: number): Observable<any> {
    const dataToSend = {
      date: date,
      time: time
    };

    return this.httpAuthPut(`${this.ApiUrls.salesOrders}/${salesOrderId}/pickupSlot`, dataToSend).pipe(
      map(res => res.data)
    );
  }

  getAvailalblePickupDates(): Observable<string[]> {
    return this.httpAuthGet(`${this.ApiUrls.availableDates}`).pipe(
      map(res => res.data)
    );
  }

  getAvailalblePickupTimeSlots(date: string): Observable<IPickupTimeSlot[]> {
    const pickupDateUrl = `${this.ApiUrls.availableTimeSlots}?inputDate=${date}`;
    return this.httpAuthGet(pickupDateUrl).pipe(
      map(res => {
        if (res) {
          const values = [];
          res.data.forEach(element => {
            const slot: IPickupTimeSlot = {
              key: element.key,
              value: element.value,
              isAvailable: element.isAvailable
            };
            values.push(slot);
          });
          return values;
        }
      })
    );
  }
}
