<div class="order-list-container">
  <div class="order-item-container" style="background-color:#eae7e7;">
    <table class="item-header-table">
      <thead>
        <tr>
          <!-- <th class="move-icon-width">
            <div class="order-item-handle" cdkDragHandle>
              <i class="flaticon-arrows kt-font-success drag-icon"></i>
            </div>
          </th> -->
          <th class="item-width"> ITEM  </th>
          <th class="qty-width"> QTY   </th>
          <th class="price-width"> PRICE </th>
          <th class="total-width"> TOTAL </th>
          <th class="tax-width"> NET TOTAL </th>
          <th class="delete-icon-width"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of currentOrderItems.slice().reverse(); let i = index" id="row-{{ i }}">
          <tr> 
        <!-- <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <tr *ngFor="let item of currentOrderItems;let i = index" cdkDrag cdkDragLockAxis="y">
            <td class="move-icon-width" >
              <ng-container>
                <div class="order-item-container-placeholder" *cdkDragPlaceholder></div>
                <div class="order-item-handle" cdkDragHandle>
                  <i class="flaticon-arrows drag-icon"></i>
                </div>
              </ng-container>
            </td> -->
            <td class="item-width">
              <ng-container>
                {{ item.productName ? item.productName : item.productDescription }}
              </ng-container>
            </td>
            <td class="qty-width">
              <ng-container>
                <!-- {{ item.quantity }} -->
                <!-- <input type="number" class="form-control input-qty-price" formControlName="qty" [min]="min" [max]="max" maxlength="4" (blur)="onQtyUpdate($event, item)" (click)="qtyinput.select()" #qtyinput> -->
                <pos-order-table-qty-input
                  [item]="item"
                  [shiftId]="shiftId"
                  [customer]="customer"
                  [form]="item.formGroup"
                  (itemUpdated)="onItemUpdate($event)">
                  
                </pos-order-table-qty-input>
              </ng-container>
            </td>
            <td class="price-width price-col" [ngClass]="{ 'text-red' : isPriceLessThanBasePrice(item) }" (click)="onPriceSelect(item)">
              <ng-container>
                {{ item.price.toFixed(2) }}
                <!-- <input type="number" class="form-control input-qty-price" [ngModel]="item.price" (ngModelChange)="onPriceChange($event, item)"> -->
              </ng-container>
            </td>
            <td class="total-width">
              <ng-container>
                {{ (item.price * item.quantity).toFixed(2) }}
              </ng-container>
            </td>
            <td class="tax-width">
              <ng-container>
                {{ ((item.price * item.quantity) + (item.price * item.quantity * item.taxPercent)).toFixed(2) }} {{ item.taxPercent > 0 ? 'T' : '' }}
              </ng-container>
            </td>
            <td class="delete-icon-width">
              <button  tabindex="-1" (click)="onItemRemove(item)" mat-icon-button class="delete-btn">
                <i class="flaticon-delete"></i>
              </button>
            </td>
          </tr>

      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="!customer" class="text-message">
  <label style="font-weight: 500; color: red;">Please Select Customer</label>
</div>
