import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MatListOption, MatSelectionList, MatSelectionListChange, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { IPosLineItem, IPosPrice, PosPriceType } from '@otrack-lib/models/point-of-sale/point-of-sale.models';

@Component({
  selector: 'pos-price-selector',
  templateUrl: './pos-price-selector.component.html',
  styleUrls: ['./pos-price-selector.component.scss']
})
export class PosPriceSelectorComponent implements OnInit {
  @Input() title: string;
  @Input() item: IPosLineItem;

  @ViewChild(MatSelectionList, { static: true })
  private selectionList: MatSelectionList;

  newPrice: IPosPrice = null;
  priceList = null;
  selectedOptions = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private readonly data: any,
    private readonly bottomSheetRef: MatBottomSheetRef<PosPriceSelectorComponent>
  ) {
    this.priceList = data.item.prices;
  }

  ngOnInit() {
    this.newPrice = {
      name: 'New Price',
      type: PosPriceType.NEW_PRICE,
      price: null
    };

    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
  }

  onPriceChange(value: any) {
    this.newPrice.price = value;
  }

  onSubmit() {
    if (this.selectedOptions.length > 0) {
      const selectedPrice = this.selectedOptions[0];
      if (selectedPrice && selectedPrice.price > 0) {
        this.bottomSheetRef.dismiss({ updatedPrice: +selectedPrice.price.toFixed(2) });
      }
    }
  }

  onClose() {
    this.bottomSheetRef.dismiss();
  }

  get PosPriceType() {
    return PosPriceType;
  }
}
