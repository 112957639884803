import { AngularFireAuth } from '@angular/fire/auth';
import { getMenuByRouteId, HeaderMenu } from '../common/routes-consts';
// Angular
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface TableColumnHiddenList {
    [tableName: string]: string[];
}

@Injectable({ providedIn: 'root' })
export class ColumnPreferenceService {

  private tableColumnsList$ = new BehaviorSubject<TableColumnHiddenList>({});
  private tableColumnList: TableColumnHiddenList;
  private userId: string;

  constructor(private firestore: AngularFirestore,  private afAuth: AngularFireAuth) {
    //  this.afAuth.authState.subscribe(user => {
    //     if  ( user) {
    //       this.userId = user.uid;
    //     }
    //  });
  }

  fetchUserPreference(userId: string): Observable<any> {
    return this.firestore.collection(`userColumnsPreferences`).doc(this.userId).valueChanges();
  }

  fetchUserColumnsPreferences(userId: string): Observable<any> {
    this.userId = userId;
    return this.fetchUserPreference(userId).pipe(map((data: any) => {
      this.tableColumnList = data ? data : {};
      this.tableColumnsList$.next( this.tableColumnList );
      return this.tableColumnList;
    }));
  }

  getUserHiddenColumnList(tableName: string): Observable<string[]> {
    return this.tableColumnsList$.asObservable().pipe(map(obj => obj[tableName]));
  }

  saveTableColumnHiddenList(tableName: string, columnsList: string[]):  Promise<any> {
    return this.firestore.collection('userColumnsPreferences').doc(this.userId).set({...this.tableColumnList, [tableName]: columnsList });
  }

}
