import { Injectable, Injector } from '@angular/core';
import { ICustomerSummary } from '@otrack-lib/models/customer/customer-summary.model';
import {
  CustomerOnlineAccessType,
  ICustomer,
  ICustomerDetail
} from '@otrack-lib/models/customer/customer.model';
import { ErrorModel } from '@otrack-lib/models/error.model';
// Models
import { ITransaction } from '@otrack-lib/models/order/transaction.model';
import { QueryParamsModel } from '@otrack-lib/models/query-params.model';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseApiService {
  readonly API_URLS = {
    customerList: `${this.baseApiUrl}/v1/customers/all`,
    publicCustomers: `${this.baseApiUrl}/v1/public/customers`,
    customerSummary: `${this.baseApiUrl}/v1/customers/summary`,
    odataCustomerList: `${this.baseApiUrl}/v1/odata/Customers/CustomerList`,
    customers: `${this.baseApiUrl}/v1/customers`,
    updateCustomer: `${this.baseApiUrl}/v1/customers/edit`,
    addCustomer: `${this.baseApiUrl}/v1/customers/add`,
    customerTransactionList: `${this.baseApiUrl}/v1/odata/Orders/CustomerTransactionList`,
    customerInvite: `${this.baseApiUrl}/v1/users/customers`,
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  static getCustomerModel(res: any): ICustomer {
    return {
      customerId: +res.customerId,
      firstName: res.firstName,
      lastName: res.lastName,
      address1: res.address1,
      address2: res.address2,
      city: res.city,
      companyName: res.companyName,
      country: res.country,
      customerName: res.customerName,
      isActive: res.isActive,
      isQBCustomer: res.isQBCustomer,
      isQBSynced: res.isQBSynced,
      isTaxable: res.isTaxable,
      notes: res.notes,
      openBalance: +res.openBalance,
      postalCode: res.postalCode,
      priceTierId: res.priceTierId,
      priceTierName: res.priceTierName,
      state: res.state,
      faxNo: res.faxNo,
      email: res.email,
      phoneNo: res.phoneNo,
      taxPercent: res.taxPercent,
      taxId: res.taxId,
      billingAddress: res.billingAddress,
      shippingAddress: res.shippingAddress,
      activeOrderId: res.activeOrderId,
      salesTaxId: res.salesTaxId,
      tobaccoPermitId: res.tobaccoPermitId,
      deliveryCertificateNumber: res.deliveryCertificateNumber,
      tabcId: res.tabcId,
      salesTaxIdExpiryDate: res.salesTaxIdExpiryDate,
      tobaccoPermitIdExpiryDate: res.tobaccoPermitIdExpiryDate,
      deliveryCertificateNumberExpiryDate: res.deliveryCertificateNumberExpiryDate,
      tabcIdExpiryDate: res.tabcIdExpiryDate,
      onlineAccess: res.onlineAccess
    };
  }

  static getCustomerDetailModel(res: any): ICustomerDetail {
    const result: ICustomerDetail = CustomerService.getCustomerModel(res);
    if (res.transactions) {
      result.transactions = res.transactions; // TODO map manually each propery to UI Object
    }
    if (res.salesOrders) {
      result.salesOrders = res.salesOrders; // TODO map manually each propery to UI Object
    }
    return result;

  }

  static getCustomerSummaryModel(res: any): ICustomerSummary {
    return {
      totalInvoicesAmount: res['totalInvoiceAmount'],
      totalOpenInvoices: res['totalOpenInvoices']
    };
  }

  static geTransactionModel(res: any): ITransaction {
    return {
      amount: +res['amount'],
      date: res['date'],
      id: res['id'],
      amountDue: res['invoiceBalance'],
      isSynced: res['isSynced'],
      transaction: res['transaction'],
      transactionNumber: res['transactionNumber'],
      transactionType: res['transactionType']
    };
  }

  getCustomerList(): Observable<ICustomer[]> {
    return this.httpAuthGet(`${this.API_URLS.customerList}`).pipe(
      map(res => {
        const output: ICustomer[] = [];
        for (const item of res) {
          output.push(CustomerService.getCustomerModel(item));
        }
        return output;
      })
    );
  }

  getCustomerLastProductPrices(customerId: number): Observable<{ productId: number, lastPrice: number }[]> {
    return this.httpAuthGet(
      `${this.API_URLS.customers}/${customerId}/prices`
    ).pipe(
      map(res => {
        if (res) {
          return res;
        } else {
          return [];
        }
      })
    );
  }

  getCustomerListByFilter(
    queryParams: QueryParamsModel
  ): Observable<QueryResultsModel> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = queryParams.pageNumber * queryParams.pageSize;
    dataParams['top'] = queryParams.pageSize;
    if (queryParams.getODataSearchFilterString().length > 0) {
      dataParams['vfilter'] = queryParams.getODataSearchFilterString();
    }

    if (queryParams.getODataOrderByFilterString()) {
      dataParams['orderby'] = queryParams.getODataOrderByFilterString();
    }

    return this.httpAuthGet(
      `${this.API_URLS.odataCustomerList}?${this.generateODataQueryString(
        dataParams
      )}`
    ).pipe(
      map(res => {
        const output: ICustomer[] = [];
        const total = +res['@odata.count'];

        for (const item of res['value']) {
          output.push(CustomerService.getCustomerModel(item));
        }
        return new QueryResultsModel(output, total);
      })
    );
  }

  getCustomerListById(customerId: number): Observable<ICustomer> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['vfilter'] = `customerId eq ${customerId}`;
    return this.httpAuthGet(
      `${this.API_URLS.odataCustomerList}?${this.generateODataQueryString(
        dataParams
      )}`
    ).pipe(
      map(res => {
        const output: ICustomer[] = [];
        const total = +res['@odata.count'];
        if (total >= 1) {
          return CustomerService.getCustomerModel(res['value'][0]);
        } else {
          return null;
        }
      })
    );
  }

  getCustomerSummary(customerId: number): Observable<ICustomerSummary> {
    return this.httpAuthGet(
      `${this.API_URLS.customerSummary}/${customerId}`
    ).pipe(
      map(res => {
        if (res) {
          return CustomerService.getCustomerSummaryModel(res);
        } else {
          return null;
        }
      })
    );
  }

  updateCustomer(customerData: ICustomer): Observable<ICustomer> {
    const dataToSend = {
      customerId: customerData.customerId,
      customerName: customerData.customerName,
      companyName: customerData.companyName,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      email: customerData.email,
      address1: customerData.address1,
      phoneNo: customerData.phoneNo,
      faxNo: customerData.faxNo,
      city: customerData.city,
      state: customerData.state,
      postalCode: customerData.postalCode,
      notes: customerData.notes,
      isActive: customerData.isActive,
      isQBCustomer: customerData.isQBCustomer,
      isTaxable: customerData.isTaxable,
      priceTierId: +customerData.priceTierId === -1 || +customerData.priceTierId === 0 ? null : +customerData.priceTierId,
      salesTaxId: customerData.salesTaxId,
      tobaccoPermitId: customerData.tobaccoPermitId,
      deliveryCertificateNumber: customerData.deliveryCertificateNumber,
      tabcId: customerData.tabcId,
      salesTaxIdExpiryDate: customerData.salesTaxIdExpiryDate,
      tobaccoPermitIdExpiryDate: customerData.tobaccoPermitIdExpiryDate,
      deliveryCertificateNumberExpiryDate: customerData.deliveryCertificateNumberExpiryDate,
      tabcIdExpiryDate: customerData.tabcIdExpiryDate,
    };

    return this.httpAuthPost(
      `${this.API_URLS.updateCustomer}`,
      dataToSend
    ).pipe(
      map(res => {
        if (res) {
          const customer: ICustomer = CustomerService.getCustomerModel(res);
          return customer;
        }
        throw new ErrorModel(null, 'Person not found');
      })
    );
  }

  addCustomer(customerData: ICustomer): Observable<ICustomer> {
    const dataToSend = {
      customerName: customerData.customerName,
      companyName: customerData.companyName,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      email: customerData.email,
      address1: customerData.address1,
      phoneNo: customerData.phoneNo,
      faxNo: customerData.faxNo,
      city: customerData.city,
      state: customerData.state,
      postalCode: customerData.postalCode,
      notes: customerData.notes,
      isActive: customerData.isActive,
      isQBCustomer: customerData.isQBCustomer,
      isTaxable: customerData.isTaxable,
      openBalance: customerData.openBalance,
      priceTierId: +customerData.priceTierId === -1 || +customerData.priceTierId === 0 ? null : +customerData.priceTierId,
      priceTierName: customerData.priceTierName,
      salesTaxId: customerData.salesTaxId,
      tobaccoPermitId: customerData.tobaccoPermitId,
      deliveryCertificateNumber: customerData.deliveryCertificateNumber,
      tabcId: customerData.tabcId,
      salesTaxIdExpiryDate: customerData.salesTaxIdExpiryDate,
      tobaccoPermitIdExpiryDate: customerData.tobaccoPermitIdExpiryDate,
      deliveryCertificateNumberExpiryDate: customerData.deliveryCertificateNumberExpiryDate,
      tabcIdExpiryDate: customerData.tabcIdExpiryDate,
    };

    return this.httpAuthPost(`${this.API_URLS.addCustomer}`, dataToSend).pipe(
      map(res => {
        if (res) {
          const customer: ICustomer = CustomerService.getCustomerModel(res);
          return customer;
        }
        throw new ErrorModel(null, 'Person not found');
      })
    );
  }

  getCustomerTransactionList(
    customerId: number,
    queryParams: QueryParamsModel
  ): Observable<any> {
    const url = `${this.API_URLS.customerTransactionList
      }(customerId=${customerId})`;

    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = queryParams.pageNumber * queryParams.pageSize;
    dataParams['top'] = queryParams.pageSize;
    if (queryParams.getODataSearchFilterString().length > 0) {
      dataParams['vfilter'] = queryParams.getODataSearchFilterString();
    }

    if (queryParams.getODataOrderByFilterString()) {
      dataParams['orderby'] = queryParams.getODataOrderByFilterString();
    }

    return this.httpAuthGet(
      `${url}?${this.generateODataQueryString(dataParams)}`
    ).pipe(
      map(res => {
        const output: ITransaction[] = [];
        const total = +res['@odata.count'];

        for (const item of res['value']) {
          output.push(CustomerService.geTransactionModel(item));
        }
        return new QueryResultsModel(output, total);
      })
    );
  }

  getCustomerTransactionListByFilter(
    customerId: number,
    reportFilter: ReportFiltersModel
  ): Observable<any> {
    const url = `${this.API_URLS.customerTransactionList}`;
    return this.httpAuthPost(
      `${this.API_URLS.customerTransactionList}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ITransaction[] = [];
        for (const item of res) {
          output.push(CustomerService.geTransactionModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getcustomersDetail(customerId: number): Observable<ICustomerDetail> {

    const customerDetailUrl = `${this.API_URLS.customers}/${customerId}`;
    return this.httpAuthGet(customerDetailUrl).pipe(
      map(res => CustomerService.getCustomerDetailModel(res))
    );
  }

  getPublicCustomersDetail(customerId: number): Observable<ICustomerDetail> {

    const customerDetailUrl = `${this.API_URLS.publicCustomers}`;
    return this.httpAuthGet(customerDetailUrl).pipe(
      map(res => CustomerService.getCustomerDetailModel(res))
    );
  }

  public sendInvite(customer: ICustomer): Observable<any> {
    return this.httpAuthPost(`${this.API_URLS.customerInvite}/${customer.customerId}/invite`, null);
  }
}
