import { IUserPermission } from './../../../models/user.permission.model';
import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { IProduct } from '@otrack-lib/models/product/product.model';
import { createAction, props } from '@ngrx/store';
import { IDriver } from '@otrack-lib/models/driver/driver.model';
import { IUser, IUserList } from '@otrack-lib/models/user-management/user.model';

export const loadCustomers = createAction(
  '[Data] Load Customers'
);

export const loadProducts = createAction(
  '[Data] Load Products'
);

export const loadCustomerSuccess = createAction(
  '[Data] Load Customer Success',
  props<{ data: ICustomer[] }>()
);

export const loadProductSuccess = createAction(
  '[Data] Load Products Success',
  props<{ data: IProduct[] }>()
);

export const loadCustomerFailure = createAction(
  '[Data] Load Customer Failure',
  props<{ error: any }>()
);

export const loadProductFailure = createAction(
  '[Data] Load Product Failure',
  props<{ error: any }>()
);

export const loadVendors = createAction(
  '[Data] Load Vender'
);

export const loadVenderSuccess = createAction(
  '[Data] Load Vender Success',
  props<{ data: IVendor[] }>()
);

export const loadVenderFailure = createAction(
  '[Data] Load Vender Failure',
  props<{ error: any }>()
);

export const loadUserPermissions = createAction(
  '[Data] Load User Permissions'
);

export const loadUserPermissionsSuccess = createAction(
  '[Data] Load User Permissions Success',
  props<{ data: IUserPermission }>()
);

export const loadUserPermissionsFailure = createAction(
  '[Data] Load User Permissions Failure',
  props<{ error: any }>()
);


export const loadDrivers = createAction(
  '[Data] Load Drivers'
);

export const loadDriversSuccess = createAction(
  '[Data] Load Drivers Success',
  props<{ data: IDriver[] }>()
);

export const loadDriversFailure = createAction(
  '[Data] Load Drivers Failure',
  props<{ error: any }>()
);


export const clearData = createAction(
  '[Data] Clear Data'
);


export const loadEmployees = createAction(
  '[Data] Load Emplyees'
);

export const loadEmployeesSuccess = createAction(
  '[Data] Load Emplyees Success',
  props<{ data: IUserList[] }>()
);

export const loadEmployeesFailure = createAction(
  '[Data] Load Emplyees Failure',
  props<{ error: any }>()
);
