import { SalesOrderService } from '@otrack-lib/core/services/sales-order.service';
import { InventoryAdjustmentService } from './../../../../../../../libs/core/services/inventory-adjustment.service';
import { AbstractBaseComponent } from '@otrack-lib/core/abstract-base.component';
import { LayoutUtilsService, MessageType } from './../../../services/layout-utils.service';

import { IProductAdjustment } from '@otrack-lib/models/product/product.model';
import { EmailService } from '@otrack-lib/core/services/email.service';
import { PurchaseOrderService } from '@otrack-lib/core/services/purchase-order.service';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { BehaviorSubject } from 'rxjs';
// Angular
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// Material
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IInvoice, IInvoiceInfo } from '@otrack-lib/models/order/invoice.model';
import { ICompany, InvoiceTemplateType } from '@otrack-lib/models/company/company.model';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { OrderTypeConvertor, OrderTypes } from '@otrack-lib/enums';
import { Router } from '@angular/router';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
import { ProductService } from '@otrack-lib/core/services/product.service';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';
import { BusinessType } from '@otrack-lib/enums/business-type.enum';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-invoice-view-modal',
  templateUrl: './modal-invoice-view.component.html',
  styleUrls: ['./modal-invoice-view.component.scss'],
  //  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ModalInvoiceViewComponent extends AbstractBaseComponent implements OnInit, OnDestroy {
  public invoice: IInvoice;
  public adjustment: IProductAdjustment;
  public company: ICompany;
  public invoiceInfo: IInvoiceInfo;
  public viewLoading = false;
  public emailViewVisible = false;
  public emailData: any;
  public isSaleorder$ = new BehaviorSubject<boolean>(false);
  public orderTypeEnum = OrderTypes;
  public showEditButton = true;
  public convertToInvoiceData: any;
  public converToInvoiceViewVisible = false;
  public SalesOrderStatus = SalesOrderStatus;
  public BusinessType = BusinessType;


  constructor(
    public dialogRef: MatDialogRef<ModalInvoiceViewComponent>,
    @Inject(MAT_DIALOG_DATA) public order: {
      orderType?: OrderTypes,
      orderId?: number,
      invoice?: IInvoice,
      returnUrl?: string,
      tab?: SalesOrderStatus,
      accountName?: string, // used for adjustment
      showEdit?: boolean
    },
    protected invoiceService: InvoiceService,
    protected emailService: EmailService,
    protected salesOrderService: SalesOrderService,
    protected purchaseOrderService: PurchaseOrderService,
    protected productService: ProductService,
    protected userService: UserService,
    protected router: Router,
    protected utils: UtilsService,
    protected layoutUtilsService: LayoutUtilsService,
    protected inventoryAdjustmentService: InventoryAdjustmentService,
    protected printService: PrintService
  ) {
    super()
    if (this.order.showEdit) {
      this.showEditButton = this.order.showEdit;
    }
  }

  ngOnInit() {
    this.viewLoading = true;
    this.company = this.userService.getUserCompany();

    if (+this.order.orderType === OrderTypes.SalesOrder) {
      this.isSaleorder$.next(true);
      this.invoiceService
        .getSalesOrderInformation(this.order.orderId)
        .subscribe(res => {
          this.invoiceInfo = res;
          // this.viewLoading = false;
        });

      if (this.order.invoice) {
        this.invoice = this.order.invoice; // in case coming from outside
        this.viewLoading = false;
      } else {
        this.invoiceService.getSalesOrder(this.order.orderId).subscribe(res => {
          this.invoice = res;
          this.invoice.orderType = OrderTypes.SalesOrder;
          this.viewLoading = false;
        });
      }

    } else if (+this.order.orderType === OrderTypes.PurchaseBill) {
      this.purchaseOrderService.getBillInformation(this.order.orderId)
        .subscribe(res => {
          this.invoiceInfo = res;
        });

      if (this.order.invoice) {
        this.invoice = this.order.invoice; // in case coming from outside
        this.viewLoading = false;
      } else {
        this.purchaseOrderService.getBill(this.order.orderId).subscribe(res => {
          this.invoice = res;
          this.viewLoading = false;
        });
      }
    } else if (+this.order.orderType === OrderTypes.PurchaseOrder) {
      this.purchaseOrderService.getPurchaseOrderInformation(this.order.orderId)
        .subscribe(res => {
          this.invoiceInfo = res;
        });

      if (this.order.invoice) {
        this.invoice = this.order.invoice; // in case coming from outside
        this.viewLoading = false;
      } else {
        this.purchaseOrderService.getPurchaseOrder(this.order.orderId).subscribe(res => {
          this.invoice = res;
          this.viewLoading = false;
        });
      }
    } else if (+this.order.orderType === OrderTypes.BillCreditMemo) {
      this.purchaseOrderService.getBillCreditMemo(this.order.orderId).subscribe(res => {
        this.invoice = res;
        this.viewLoading = false;
      });
    } else if (+this.order.orderType === OrderTypes.InventoryAdjustment) {
      this.inventoryAdjustmentService.getAdjustment(this.order.orderId).subscribe(res => {
        if (res) {
          this.adjustment = res;
          let _total = 0;
          this.adjustment.lineItems.forEach(item => {
            _total += (item.adjustedValue) * item.adjustedValue;
          });
          this.adjustment.totalAmount = _total;
          this.adjustment.accountName = this.order.accountName;
        }
        this.viewLoading = false;
      });
    } else {
      this.invoiceService
        .getOrderInformation(this.order.orderId)
        .subscribe(res => {
          this.invoiceInfo = res;
          // this.viewLoading = false;
        });

      if (this.order.invoice) {
        this.invoice = this.order.invoice; // in case coming from outside
        this.viewLoading = false;
      } else {
        this.invoiceService.getOrder(this.order.orderId).subscribe(res => {
          this.invoice = res;
          this.viewLoading = false;
        });
      }
    }
  }

  get modelTitle() {
    switch (this.order.orderType) {
      case OrderTypes.CreditMemo:
        return 'CREDIT MEMO';
      case OrderTypes.SalesOrder:
        return 'SALES ORDER';
      case OrderTypes.PurchaseBill:
        return 'PURCHASE BILL';
      case OrderTypes.PurchaseOrder:
        return 'PURCHASE ORDER';
      case OrderTypes.BillCreditMemo:
        return 'PURCHASE CREDIT MEMO';
      case OrderTypes.Invoice:
      default:
        return 'INVOICE';
    }
  }

  get orderNumber() {
    let orderNumber;
    switch (this.order.orderType) {
      case OrderTypes.SalesOrder:
        orderNumber = this.order.orderId;
        break;
      case OrderTypes.CreditMemo:
      case OrderTypes.PurchaseBill:
        if (this.invoice) { orderNumber = this.invoice.referenceNumber ? this.invoice.referenceNumber : this.invoice.invoiceNumber; }
        break;
      case OrderTypes.Invoice:
        if (this.invoice) { orderNumber = this.invoice.invoiceNumber; }
        break;
      default:
        return '';
    }

    return '- ' + orderNumber;
  }

  printInvoice(withBarcode: boolean) {
    let docName = '';
    if (this.invoice.orderType === OrderTypes.Invoice &&
      this.company.invoiceTemplate &&
      this.company.invoiceTemplate === InvoiceTemplateType.DetailTemplate) {
      docName = InvoiceTemplateType.DetailTemplate;
    } else if (this.invoice.orderType === OrderTypes.Invoice
      && this.company.invoiceTemplate === InvoiceTemplateType.ItemCodeInvoiceTemplate) {
      docName = InvoiceTemplateType.ItemCodeInvoiceTemplate;
    } else {
      docName = OrderTypeConvertor.getDocName(this.invoice.orderType);
    }
    this.printService.printDocument(docName, [this.invoice, withBarcode]);
  }

  printTemplate(withBarcode: boolean) {
    let docName = '';
    switch (this.invoice.orderType) {
      case OrderTypes.Invoice:
      case OrderTypes.SalesOrder:
      case OrderTypes.CreditMemo:
        docName = 'template';   // support server side templating
        break;
      default:
        docName = OrderTypeConvertor.getDocName(this.invoice.orderType);
    }
    this.printService.printDocument(docName, [this.invoice, withBarcode]);
  }

  printOrderPull() {
    this.printService.printDocument(InvoiceTemplateType.OrderPullTemplate, [this.invoice, true]);
  }

  printShippingSlip(withBarcode: boolean) {
    if (this.order.orderType === OrderTypes.Invoice
      || this.order.orderType === OrderTypes.SalesOrder) {
      this.printService.printDocument('shippingslip', [this.invoice, withBarcode]);
    }
  }

  sendEmail() {
    this.emailData = {
      orderId: this.invoice.orderId,
      fromName: this.company.name,
      fromEmail: this.company.email,
      customerName: this.invoice.customerName,
      invoiceTotal: this.invoice.totalAmount,
      invoiceNumber: this.invoice.invoiceNumber,
      orderType: this.invoice.orderType,
      invoiceDate: this.invoice.invoiceDate,
      customerEmail: this.invoice.customerEmail,
      emailType: this.emailService.getEmailType(this.invoice),
      emailMessage: this.emailService.getEmailMessage(this.invoice),
    };
    this.emailViewVisible = true;
  }

  onCancelEmail() {
    this.emailViewVisible = false;
  }

  isConverToInvoiceAllowed(status: SalesOrderStatus): boolean {
    return status === SalesOrderStatus.Received
      || status === SalesOrderStatus.Ready
      || status === SalesOrderStatus.Freezed;
  }

  convertToInvoice(currentStatus: SalesOrderStatus) {
    this.convertToInvoiceData = {
      title: 'Sale Order Satus Change',
      description: 'Are you sure you want to update ?',
      waitDesciption: 'Please wait, while we are updating',
      fromSaleOrderStatus: currentStatus,
      toSaleOrderStatus: SalesOrderStatus.ConvertedToInvoice,
      asATemplate: true
    };
    this.converToInvoiceViewVisible = true;
  }

  onConvertToInvoiceAction($event) {
    if ($event) {
      this.salesOrderService.convertToInvoice(this.invoice.orderId).subscribe(invoice => {
        this.dialogRef.close({
          refresh: $event,
          openTransaction: true,
          invoice
        });
      });
    } else {
      this.converToInvoiceViewVisible = false;
      this.convertToInvoiceData = null;
    }
  }

  editInvoice() {
    if (this.order.orderType === OrderTypes.InventoryAdjustment) {
      this.editAdjustment(this.adjustment);
    } else {
      const queryParams = {
        returnUrl: null,
        tab: null
      };

      if (this.order.returnUrl) {
        queryParams.returnUrl = this.order.returnUrl;
      }

      if (this.order.tab) {
        queryParams.tab = this.order.tab;
      }

      this.router.navigate([`/transaction/edit/${this.invoice.orderType}/${this.invoice.orderId}`], { queryParams: queryParams });
      this.dialogRef.close();
    }
  }

  editAdjustment(adjustment: IProductAdjustment) {
    this.router.navigate(['/product/editadjustment/' + adjustment.id]);
    this.dialogRef.close();
  }

  ngOnDestroy() { }
}
