import { IHighlightedTags } from './../highlighted-tags.model';
import { BusinessType } from '@otrack-lib/enums/business-type.enum';
import { DeliveryType } from '@otrack-lib/enums/deliverytypes.enum';
import { CompanyPaymentAllowedMethod } from '@otrack-lib/enums';
import { PricingPolicy } from '@otrack-lib/enums/pricing-policy,enum';

export interface ICompany {
  companyId: number;
  name: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  address?: string;
  ownername?: string;
  phoneNo?: string;
  email?: string;
  isSRPShowOnInvoice?: boolean;
  disclaimer?: string;
  invoicePrefix?: string;
  replyToEmail?: string;
  isDemoCompany?: boolean;
  permissions?: CompanyPermission[];
  isSetupCompleted?: boolean;
  sequences?: ICompanySequences;
  logoUrl?: string;
  businessType?: BusinessType;
  paymentAccountId?: string;
  taxPercent?: number;
  deliveryMethods?: DeliveryType[];
  paymentMethods?: CompanyPaymentAllowedMethod[];
  usePickupSlots?: boolean;
  baseDomain?: string;
  pricingPolicy?: PricingPolicy;
  taxPermitId?: string;
  tobaccoPermitNo?: string;
  invoiceTemplate?: InvoiceTemplateType;
  highlightedTags?: IHighlightedTags[];
}

export enum CompanyPermission {
  CanManageNoSync = 1,
  CanManageExpenses = 2,
  AlphaModules = 3,
  BetaModules = 4,
  B2CModules = 5,
  B2BModules = 6
}

export enum InvoiceTemplateType {
  DefaultTemplate = 'template_default',
  DetailTemplate = 'template_detail',  // map to printorder-template1
  ItemCodeInvoiceTemplate = 'template_itemcode',  // map to printorder-template1
  PetroMaxTemplate = 'petromax_template',  // map to printorder-template1
  OrderPullTemplate = 'orderpull_template',
}


interface ICompanySequences {
  invoicePrefix?: string;
  invoiceStart?: number;
  creditMemoPrefix?: string;
  creditMemoStart?: number;
  billPrefix?: string;
  billStart?: number;
}
