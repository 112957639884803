<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> {{ this.reportFilter ? getDateRangeInString() : '' }} </h5>
    <h5> Transaction Summary </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th class="column-small-cell" style="text-align: left;padding-left: 5px;">DATE</th>
        <th style="text-align: left; padding-left: 5px;">MODIFIED DATE</th>
        <th style="text-align: left; padding-left: 5px;">TYPE</th>
        <th style="text-align: left; padding-left: 5px;">NUM</th>
        <th style="text-align: left; padding-left: 5px;">CUSTOMER</th>
        <th style="text-align: left; padding-left: 5px;">MEMO</th>
        <th style="text-align: right; padding-right: 5px;">SUB TOTAL</th>
        <th style="text-align: right; padding-right: 5px;">TAX</th>
        <th style="text-align: right; padding-right: 5px;">AMOUNT</th>
        <th style="text-align: right; padding-right: 5px;">BALANCE</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let report of transactions">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.date | date:'dd MMM, yyyy'}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.modifiedDate | date:'dd MMM, yyyy'}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.transaction}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.transactionNumber}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.customerName}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.memo}} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.subTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.totalTax | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.amount | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.invoiceBalance  | currency }} </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: right;padding-right: 5px;"> {{ subTotalAmount | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ totalTax | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ totalAmount | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ totalInvoiceBalance | currency }} </td>
      </tr>
    </tfoot>

  </table>
</div>
