<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> As of {{ this.reportFilter ? getDateInString() : '' }} </h5>
    <h5> Inventory Item Detail </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left;padding-left: 5px;">NAME</th>
        <th style="text-align: left; padding-left: 5px;">DATE</th>
        <th style="text-align: left; padding-left: 5px;">TYPE</th>
        <th style="text-align: left; padding-left: 5px;">NUMBER</th>
        <th style="text-align: right; padding-right: 5px;">QUANTITY</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let item of items">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.name }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.date | date:'dd MMM, yyyy' }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.type }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.number }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.quantity }} </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: right;padding-right: 5px;">{{ totalQuantity }}</td>
      </tr>
    </tfoot>

  </table>
</div>
