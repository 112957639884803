import { ExpenseService } from '@otrack-lib/core/services/expense.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AccountActions } from '.';


@Injectable()
export class AccountEffects {

  loadAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loadAccounts),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.expenseService.fetchAllAccounts().pipe(
          map(data => AccountActions.loadAccountsSuccess({ data })),
          catchError(error => of(AccountActions.loadAccountsFailure({ error }))))
      )
    );
  });


  loadAccountCategoriesDatas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loadAccountCategories),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.expenseService.fetchAllAccountsCategory().pipe(
          map(data => AccountActions.loadAccountCategoriesSuccess({ data })),
          catchError(error => of(AccountActions.loadAccountCategoriesFailure({ error }))))
      )
    );
  });

  constructor(private actions$: Actions,
              private expenseService: ExpenseService, ){}

}
