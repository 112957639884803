
import { ErrorService } from './../services/error.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(public errorService: ErrorService, ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        // if (error.status === 401) {
        //   // refresh token - currently handle by firebase but write logic if require further action
        // } else {
        if (error && error.error ) {
         this.errorService.setError(error.error);
       } else {
          this.errorService.setError(error);
       }
       return throwError(error);

        // }
      })
    );
  }

}
