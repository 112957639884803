<div class="kt-mat-snackbar">
	<div class="kt-mat-snackbar__message" style="width: 90%;">{{data.message}}
    <div *ngIf="data.showUndoButton" class="kt-mat-snackbar__btn">
      <button  type="button" mat-button color="primary" (click)="onDismissWithAction()">
        Undo
      </button>
    </div>
  </div>
	
	<div class="kt-mat-snackbar__close" style="width: 10%;">
		<button *ngIf="data.showCloseButton" 
			mat-icon-button 
			(click)="onDismiss()"
			color="warn">
			<mat-icon>clear</mat-icon>
		</button>
	</div>
</div>
