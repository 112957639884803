import { ICategorizedOrderList, IOrder } from '@otrack-lib/models/order/order.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-itemlist-template1',
  templateUrl: './invoice-itemlist.component.html',
  styleUrls: ['./invoice-itemlist.component.scss']
})
export class InvoiceItemListComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  @Input() categorizedItemList: ICategorizedOrderList[];

  constructor() {}

  ngOnInit(): void {
  }
}
