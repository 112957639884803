import { PaymentService } from '@otrack-lib/core/services/payment.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICompany } from '@otrack-lib/models/company/company.model';
import { IPayment } from '@otrack-lib/models/payment/payment.model';
import { UserService } from '@otrack-lib/core/services/user.service';
import { BillPaymentService } from '@otrack-lib/core/services/bill-payment.service';
import { IBillPayment } from '@otrack-lib/models/payment/bill-payment.model';
import { OrderTypes } from '@otrack-lib/enums';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'otrack-printpayment',
  templateUrl: './printpayment.component.html',
  styleUrls: ['./printpayment.component.scss']
})
export class PrintPaymentComponent implements OnInit, AfterViewInit {

  public paymentDetails: IPayment | IBillPayment;
  public companyInfo: ICompany;
  public paymentTypesEnum = OrderTypes;
  public paymentType: OrderTypes.BillPayment | OrderTypes.Payment;

  public displayedColumns: string[] = ['invoiceNo', 'invoiceAmount', 'applyAmount', 'applyDate'];
  public displayedColumns2: string[] = ['key', 'value'];

  constructor(
    private route: ActivatedRoute,
    protected paymentService: PaymentService,
    protected billPaymentSerivce: BillPaymentService,
    private ref: ChangeDetectorRef,
    private printService: PrintService,
    private userService: UserService,
    private elementRef: ElementRef) {

  }

  ngOnInit(): void {
    if (this.route.snapshot.params['data']) {
      this.loadPayment(this.route.snapshot.params['data'].split(','));
    }
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
  }

  loadPayment(data: string[]) {
    const paymentId: number = +data[1];
    if (data[0] === '3') {
      this.paymentService.getPaymentDetail(paymentId).subscribe(res => {
        if (res) {
          this.companyInfo = this.userService.user.company;
          this.paymentDetails = res;
          this.paymentType = OrderTypes.Payment;
          this.onPaymentReady();
        }
      });
    } else if (data[0] === '8') {
      this.billPaymentSerivce.getPaymentDetail(paymentId).subscribe(res => {
        if (res) {
          this.companyInfo = this.userService.user.company;
          this.paymentDetails = res;
          this.paymentType = OrderTypes.BillPayment;
          this.onPaymentReady();
        }
      });
    }
  }

  onPaymentReady() {
    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
    }, 100);
  }

}
