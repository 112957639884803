import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    readonly _duration: number = 6000;

    constructor(
        public snackBar: MatSnackBar,
        private zone: NgZone) { }

    dismiss() {
      this.snackBar.dismiss();
    }

    showSuccess(message: string): void {
        // Had an issue with the snackbar being ran outside of angular's zone.
        this.zone.run(() => {
            this.snackBar.open(message, 'X', { duration: this._duration, horizontalPosition : 'center' , verticalPosition: 'top',  panelClass: ['success-snackbar'] });
        });
    }

    showWarn(message: string): void {
        // Had an issue with the snackbar being ran outside of angular's zone.
        this.zone.run(() => {
            this.snackBar.open(message, 'X', { duration: this._duration, horizontalPosition : 'center' , verticalPosition: 'top', panelClass: ['warn-snackbar'] });
        });
    }

    showWaiting(component: any): void {
      // Had an issue with the snackbar being ran outside of angular's zone.
      this.zone.run(() => {
          this.snackBar.openFromComponent(component, { horizontalPosition : 'center' , verticalPosition: 'top', panelClass: ['waiting-snackbar'] });
      });
   }

    showError(message: string): void {
        this.zone.run(() => {
            // The second parameter is the text in the button.
            // In the third, we send in the css class for the snack bar.
            this.snackBar.open(message, 'X', { duration: this._duration,  horizontalPosition : 'center' , verticalPosition: 'top', panelClass: ['error-snackbar'] });
        });
    }


}
