import { Injectable, Injector } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InvoiceTemplateType } from '@otrack-lib/models/company/company.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderTypes } from './../../enums/ordertype.enum';
import { BaseApiService } from './baseapi.service';

@Injectable({
  providedIn: 'root'
})
export class OrderTemplateService extends BaseApiService {
  readonly API_URLS = {
    viewSalesOrder: `${this.baseApiUrl}/v1/views/salesorders`,
    viewInvoice: `${this.baseApiUrl}/v1/views/invoice`,
  };

  constructor(_injector: Injector, private sanitizer: DomSanitizer) {
    super(_injector);
  }

  getTemplate(orderId: number, orderType: OrderTypes, templateType: InvoiceTemplateType, displayBarCode: boolean) {
    const templateId = this.mapTemplateTypeToEnumNumber(templateType);
    switch (orderType) {
      case OrderTypes.Invoice:
        return this.getInvoiceHtml(orderId, templateId, displayBarCode);
      case OrderTypes.SalesOrder:
        return this.getSaleOrderHtml(orderId, templateId, displayBarCode);
      case OrderTypes.CreditMemo:
        // 1 = defailt type
        return this.getInvoiceHtml(orderId, 1, displayBarCode);
    }
  }

  private getInvoiceHtml(orderId: number, templateId: number, displayBarCode: boolean): Observable<SafeHtml> {
    return this.httpAuthGetHtml(`${this.API_URLS.viewInvoice}/${orderId}?invoiceTemplate=${templateId}&showBarcode=${displayBarCode}`)
      .pipe(map(res => res ? this.sanitizer.bypassSecurityTrustHtml(res) : ''));
  }

  private getSaleOrderHtml(orderId: number, templateId: number, displayBarCode: boolean): Observable<SafeHtml> {
    return this.httpAuthGetHtml(`${this.API_URLS.viewSalesOrder}/${orderId}?invoiceTemplate=${templateId}&showBarcode=${displayBarCode}`)
      .pipe(map(res => res ? this.sanitizer.bypassSecurityTrustHtml(res) : ''));
  }

  private mapTemplateTypeToEnumNumber(templateType: InvoiceTemplateType) {
    switch (templateType) {
      case InvoiceTemplateType.DefaultTemplate:
        return 1;
      case InvoiceTemplateType.DetailTemplate:
        return 2;
      case InvoiceTemplateType.ItemCodeInvoiceTemplate:
        return 3;
      case InvoiceTemplateType.PetroMaxTemplate:
        return 5;
      case InvoiceTemplateType.OrderPullTemplate:
        return 6;
      default:
        return 1;
    }
  }

}
