
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { IEmail } from '@otrack-lib/models/email/email.model';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { OrderTypes } from '@otrack-lib/enums';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseApiService {
  readonly API_URLS = {
    email: `${this.baseApiUrl}/v1/email`
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  sendInvoiceEmail(email: IEmail): Observable<number> {
    return this.httpAuthPost(`${this.API_URLS.email}/invoice`, email).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Email invoice error');
      })
    );
  }

  sendEmail(email: IEmail, type: string): Observable<number> {
    return this.httpAuthPost(`${this.API_URLS.email}/${type}`, email).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, `Email ${type} error`);
      })
    );
  }

  getEmailTitle(invoice: IInvoice) {
    const orderTypeMap = new Map<OrderTypes, string>();
    orderTypeMap[OrderTypes.Invoice] = 'Invoice';
    orderTypeMap[OrderTypes.PurchaseBill] = 'Purchase Bill';
    orderTypeMap[OrderTypes.PurchaseOrder] = 'Purchase Order';
    orderTypeMap[OrderTypes.BillCreditMemo] = 'Purchase Bill Credit Memo';

    return `Email ${orderTypeMap[invoice.orderType]}`;
  }

  getEmailMessage(invoice: IInvoice) {
    const orderTypeMap = new Map<OrderTypes, string>();
    orderTypeMap[OrderTypes.Invoice] = 'invoice';
    orderTypeMap[OrderTypes.PurchaseBill] = 'purchase bill';
    orderTypeMap[OrderTypes.PurchaseOrder] = 'purchase order';
    orderTypeMap[OrderTypes.CreditMemo] = 'credit memo';
    orderTypeMap[OrderTypes.BillCreditMemo] = 'purchase bill credit memo';

    switch (invoice.orderType) {
      case OrderTypes.Invoice:
        return `Hi ${invoice.customerName},\n\nPlease find the attached invoice for your order.\n\n\nThank you`;
      case OrderTypes.CreditMemo:
      case OrderTypes.PurchaseOrder:
      case OrderTypes.PurchaseBill:
      case OrderTypes.BillCreditMemo:
        return `Hi ${invoice.customerName},\n\nPlease find the attached ${orderTypeMap[invoice.orderType]}.\n\n\nThank you`;
    }
  }

  getEmailType(invoice: IInvoice) {
    const orderTypeMap = new Map<OrderTypes, string>();
    orderTypeMap[OrderTypes.Invoice] = 'invoice';
    orderTypeMap[OrderTypes.PurchaseBill] = 'bill';
    orderTypeMap[OrderTypes.CreditMemo] = 'creditmemo';
    orderTypeMap[OrderTypes.PurchaseOrder] = 'purchaseorder';
    orderTypeMap[OrderTypes.BillCreditMemo] = 'billcreditmemo';

    return orderTypeMap[invoice.orderType];
  }
}
