import { LayoutUtilsService, MessageType } from '@admin-app/services/layout-utils.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatSort } from '@angular/material';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { OrderTypes } from '@otrack-lib/enums';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
import { IPosOrderList } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { PosTransactionDataSource } from '../data-sources/pos-transcation.datasource';
import { PosConfirmationDialogComponent } from '../pos-confirmation-dialog/pos-confirmation-dialog.component';

@Component({
  selector: 'pos-restore-table',
  templateUrl: './pos-restore-table.component.html',
  styleUrls: ['./pos-restore-table.component.scss']
})
export class PosRestoreTableComponent implements OnInit {
  @Input() shiftId: number;
  @Output() backClicked = new EventEmitter();
  @Output() restoreClicked = new EventEmitter();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // tslint:disable-next-line: max-line-length
  displayedColumns = ['select', 'date', 'transactionNumber', 'customerName', 'amount', 'action'];
  dataSource: PosTransactionDataSource;
  orderTypeEnum = OrderTypes;
  SalesOrderStatus = SalesOrderStatus;
  selection = new SelectionModel<IPosOrderList>(false, []);
  itemSelected = false;

  constructor(
    private readonly posService: PointOfSaleService,
    private readonly layoutService: LayoutUtilsService,
    private readonly dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.dataSource = new PosTransactionDataSource(this.posService);
    this.loadHoldOrdersList();

    this.sort.sortChange.subscribe(val => {
      this.dataSource.sortClientSide(val.active, val.direction);
    });
  }

  loadHoldOrdersList() {
    if (this.shiftId) {
      this.dataSource.loadHoldOrdersByShiftId(this.shiftId);
    }
  }

  onRemoveOrder(orderId: number) {
    const message = `Are you sure you want to delete hold sale # ${orderId}?`;

    const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
      data: {
        title: `Confirm Delete Sale ${orderId}`,
        message
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.confirm) {
        // this.loading = true;
        this.posService.removeOrder(orderId).subscribe(
          res => {
            if (res) {
              this.layoutService.showNotification(
                `Successfully deleted hold sale # ${orderId}.`,
                MessageType.Error,
                20000
              );
              this.loadHoldOrdersList();
            } else {
              this.layoutService.showNotification(
                `Failed to delete hold sale # ${orderId}.`,
                MessageType.Error,
                20000
              );
            }
            // this.loading = false;
          },
          error => {
            this.layoutService.showNotification(
              `Failed to delete hold sale # ${orderId}.`,
              MessageType.Error,
              20000
            );
            // this.loading = false;
          }
        );
      }
    });
  }

}
