import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { PointOfSaleTrailService } from '@otrack-lib/core/services/point-of-sale-trail.service';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { IShiftPendingTransaction } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PointOfSaleHelperService } from './../services/point-of-sale-helper.service';

@Injectable({ providedIn: 'root' })
export class PosShiftResolver implements Resolve<IShiftPendingTransaction> {
  constructor(
    private readonly router: Router,
    private readonly posService: PointOfSaleService,
    private readonly posTrailService: PointOfSaleTrailService,
    private readonly helperService: PointOfSaleHelperService
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IShiftPendingTransaction> {
    return this.posService.getRegisters().pipe(
      switchMap(res => {
        if (res && res.length > 0) {
          return this.getActiveShift().pipe(
            switchMap(
              shiftId => shiftId ? this.getPendingTransactionsWithShiftId(shiftId) : of({ shiftId: null, pendingTransactions: [] })
            )
          );
        } else {
          return this.posService.addRegister().pipe(
            switchMap(
              () => this.getActiveShift().pipe(
                switchMap(
                  shiftId => shiftId ? this.getPendingTransactionsWithShiftId(shiftId) : of({ shiftId: null, pendingTransactions: [] })
                )
              )
            )
          );
        }
      })
    );
  }

  getActiveShift(): Observable<number> {
    return this.posService.getActiveShifts()
      .pipe(
        map(shifts => {
          /* if (shifts.length > 0) {
            this.router.navigate(['/pos/home']);
          } else {
            this.router.navigate(['/pos/entry']);
          } */
          return shifts[0].shiftId;
        })
      )
      .catch(() => of(null));
  }

  getPendingTransactionsWithShiftId(shiftId: number): Observable<IShiftPendingTransaction> {
    return this.posTrailService.getPendingTransactions(shiftId).pipe(
      switchMap(pendingTransactions => of({ shiftId, pendingTransactions }))
    )
      .catch(() => of({ shiftId: null, pendingTransactions: [] }));
  }
}
