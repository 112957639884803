import { PrintTemplateComponent } from './../../../../libs/common/components/print/print-template/print-template.component';
import { PrintShippingSlipComponent } from './../../../../libs/common/components/print/print-shipping-slip/print-shipping-slip.component';
import { PrintZReportComponent } from './../../../../libs/common/components/print/print-z-report/print-z-report.component';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// tslint:disable: max-line-length
import { PrintItemDetailReportComponent } from '@otrack-lib/common/components/print/print-item-detail-report/print-item-detail-report.component';
import { PrintOpenBalanceReportComponent } from '@otrack-lib/common/components/print/print-open-balance-report/print-open-balance-report.component';
import { PrintStockSummaryReportComponent } from '@otrack-lib/common/components/print/print-stock-summary-report/print-stock-summary-report.component';
import { PrintVendorAgingReportComponent } from '@otrack-lib/common/components/print/print-vendor-aging-report/print-vendor-aging-report.component';
import { PrintVendorItemPurchaseReportComponent } from '@otrack-lib/common/components/print/print-vendor-item-purchase-report/print-vendor-item-purchase-report.component';
import { PrintComponent } from '@otrack-lib/common/components/print/print.component';
import { PrintAgingReportComponent } from '@otrack-lib/common/components/print/printagingreport/print-aging-report.component';
import { PrintCustomerItemReportComponent } from '@otrack-lib/common/components/print/printcustomeritemreport/print-customer-item-report.component';
import { PrintItemOrderReportComponent } from '@otrack-lib/common/components/print/printitemorderreport/print-item-order-report.component';
import { PrintItemPurchaseReportComponent } from '@otrack-lib/common/components/print/printitempurchasereport/print-item-purchase-report.component';
import { PrintOrderCustomComponent } from '@otrack-lib/common/components/print/printorder-custom/printorder-custom.component';
import { PrintOrderTemplate1Component } from '@otrack-lib/common/components/print/printorder-template1/printorder-template1.component';
import { PrintOrderComponent } from '@otrack-lib/common/components/print/printorder/printorder.component';
import { PrintPaymentComponent } from '@otrack-lib/common/components/print/printpayment/printpayment.component';
import { PrintPaymentReportComponent } from '@otrack-lib/common/components/print/printpaymentreport/print-payment-report.component';
// tslint:disable-next-line: max-line-length
import { PrintTransactionReportComponent } from '@otrack-lib/common/components/print/printtransactionreport/print-transaction-report.component';
import { GlobalAuthGuard } from '@otrack-lib/core/guards/global-auth.guard';
import { PermissionGuard } from '@otrack-lib/core/guards/permission.guard';
import { CompanyResolver } from '@otrack-lib/core/resolvers/company-resolver.service';
import { UserPermissionResolver } from '@otrack-lib/core/resolvers/user-permission.resolver';
import { DataResolver } from './common/resolvers/data.resolver';
import { CallbackComponent } from './modules/callback/callback.component';
import { PointOfSaleComponent } from './modules/point-of-sale/point-of-sale.component';
import { PosDataResolver } from './modules/point-of-sale/resolvers/pos-data.resolver';
import { BaseComponent } from './themes/base/base.component';
import { ErrorPageComponent } from './themes/content/error-page/error-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PrintZReportListComponent } from '@otrack-lib/common/components/print/print-z-report-list/print-z-report-list.component';
import { PrintIndividualItemSaleDetailReportComponent } from '@otrack-lib/common/components/print/print-individual-item-sale-detail-report/print-individual-item-sale-detail-report.component';



const routes: Routes = [
  {
    path: 'callback/payments/stripe',
    canActivate: [GlobalAuthGuard], resolve: { cres: CompanyResolver },
    component: CallbackComponent,
  },
  {
    path: 'print',
    canActivate: [GlobalAuthGuard], resolve: { cres: CompanyResolver },
    component: PrintComponent,
    children: [
      {
        path: 'invoice/:data',
        component: PrintOrderComponent
      },
      {
        path: 'shippingslip/:data',
        component: PrintShippingSlipComponent
      },
      {
        path: 'template_itemcode/:data',
        component: PrintOrderCustomComponent
      },
      {
        path: 'template_detail/:data',
        component: PrintOrderTemplate1Component
      },
      {
        path: 'orderpull_template/:data',
        component: PrintTemplateComponent
      },
      {
        path: 'template/:data',
        component: PrintTemplateComponent
      },
      {
        path: 'salesorder/:data',
        component: PrintOrderComponent
      },
      {
        path: 'creditmemo/:data',
        component: PrintOrderComponent
      },
      {
        path: 'purchasebill/:data',
        component: PrintOrderComponent
      },
      {
        path: 'purchaseorder/:data',
        component: PrintOrderComponent
      },
      {
        path: 'payment/:data',
        component: PrintPaymentComponent
      },
      {
        path: 'billpayment/:data',
        component: PrintPaymentComponent
      },
      {
        path: 'transactionreport',
        component: PrintTransactionReportComponent
      },
      {
        path: 'itemorderreport',
        component: PrintItemOrderReportComponent
      },
      {
        path: 'individualitemsaledetailreport',
        component: PrintIndividualItemSaleDetailReportComponent
      },
      {
        path: 'stocksummaryreport',
        component: PrintStockSummaryReportComponent
      },
      {
        path: 'agingreport',
        component: PrintAgingReportComponent
      },
      {
        path: 'vendoragingreport',
        component: PrintVendorAgingReportComponent
      },
      {
        path: 'paymentreport',
        component: PrintPaymentReportComponent
      },
      {
        path: 'customeritemsalereport',
        component: PrintCustomerItemReportComponent
      },
      {
        path: 'itembypurchasereport',
        component: PrintItemPurchaseReportComponent
      },
      {
        path: 'vendoritempurchasereport',
        component: PrintVendorItemPurchaseReportComponent
      },
      {
        path: 'itemdetailreport',
        component: PrintItemDetailReportComponent
      },
      {
        path: 'openbalancereport',
        component: PrintOpenBalanceReportComponent
      },
      {
        path: 'zreport',
        component: PrintZReportComponent
      },
      {
        path: 'zreportlist',
        component: PrintZReportListComponent
      }
    ]
  },
  {
    path: 'pos',
    canActivate: [GlobalAuthGuard],
    resolve: {
      cres: CompanyResolver,
      posdata: PosDataResolver
    },
    component: PointOfSaleComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@admin-app/modules/point-of-sale/point-of-sale.module').then(m => m.PointOfSaleModule)
      }
    ]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [GlobalAuthGuard], resolve: { cres: CompanyResolver, data: DataResolver, permissions: UserPermissionResolver },
    children: [
      {
        path: 'dashboard',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'customer',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/customer/customer.module').then(m => m.CustomerModule),
        // data: { permission : AppplicationPermissions.Customer }
      },
      {
        path: 'driver',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/delivery/driver/driver.module').then(m => m.DriverModule),
        // data: { permission : AppplicationPermissions.Customer }
      },
      {
        path: 'product',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/product/product.module').then(m => m.ProductModule)
      },
      {
        path: 'product/import',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/product-import/product-import.module').then(m => m.ProductImportModule)
      },
      {
        path: 'price',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/product/price-tier/price-tier.module').then(m => m.PriceTierModule)
      },
      {
        path: 'reports',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'reports/stock',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/reports/stock-report/stock-report.module').then(m => m.StockReportModule)
      },
      {
        path: 'reports/email/status',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/reports/email-status-report/email-status-report.module').then(m => m.EmailStatusReportModule)
      },
      {
        path: 'reports/shifts',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/reports/pos-z-report/pos-z-report.module').then(m => m.POSZReportModule)
      },
      {
        path: 'reports/itemquick',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/reports/item-quick-report/item-quick-report.module').then(m => m.ItemQuickReportModule)
      },
      {
        path: 'reports',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'sales-orders',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/sales-order/sales-order.module').then(m => m.SalesOrderModule)
      },
      {
        path: 'vendor',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/vendor/vendor.module').then(m => m.VendorModule)
      },
      {
        path: 'transaction',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/transaction/transaction.module').then(m => m.TransactionModule)
      },
      {
        path: 'transactionlist',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/transaction-view/transaction-view.module').then(m => m.TransactionViewModule)
      },
      {
        path: 'expense',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/expense/expense.module').then(m => m.ExpenseModule)
      },
      {
        path: 'payment',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/payment-creator/payment-creator.module').then(m => m.PaymentCreatorModule)
      },
      {
        path: 'chart-of-accounts',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/chart-of-accounts/chart-of-accounts.module').then(m => m.ChartOfAccountsModule)
      },
      {
        path: 'profile',
        canActivate: [PermissionGuard],
        loadChildren: () => import('@admin-app/modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import('@admin-app/modules/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'pos-settings',
        loadChildren: () => import('@admin-app/modules/settings/pos-settings/pos-settings.module').then(m => m.PosSettingsModule)
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          'type': 'error-v6',
          'code': 403,
          'title': '403... Access forbidden',
          'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
        }
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'sales-orders', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
