<div class="report-container">
  <table class="report-table">
    <tr>
      <td colspan="2" style="text-align: center; font-weight: 600; background: #eae7e7; padding: 10px;">
        <span> Z - REPORT - Otrack POS </span>
        <span> - Shift # - {{ posShiftSummary?.sequenceNumber }}</span>
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Start Time
      </td>
      <td class="value-right">
        {{ posShiftSummary?.startTime | date : 'MMM dd, yyyy EEEE hh:mm a' }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        End Time
      </td>
      <td class="value-right">
        {{ posShiftSummary?.closeTime ?  (posShiftSummary?.closeTime | date : 'MMM dd, yyyy EEEE hh:mm a') : '-' }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Starting Cash
      </td>
      <td class="value-right">
        {{ posShiftSummary?.startingCash?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Cash Sales
      </td>
      <td class="value-right">
        {{ posShiftSummary?.cashSales?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Closing Cash
      </td>
      <td class="value-right">
        {{ posShiftSummary?.closingCash?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr *ngIf="!closed">
      <td class="value-left" style="color: blue; font-weight: 600;">
        Theoretical Closing Cash
      </td>
      <td class="value-right" style="color: blue; font-weight: 600;">
        {{ (closingCash$ ? (closingCash$ | async)?.toFixed(2) : 0) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left" style="color: red; font-weight: 600;">
        Over and Short
      </td>
      <td class="value-right" style="color: red; font-weight: 600;">
        {{ closed ? (posShiftSummary?.closingCash - posShiftSummary?.startingCash - posShiftSummary?.cashSales).toFixed(2) : (+((closingCash$ | async)?.toFixed(2)) - posShiftSummary?.startingCash - posShiftSummary?.cashSales).toFixed(2) | currency }}
      </td>
    </tr>
  </table>
  <table class="report-table">
    <tr>
      <td colspan="2" style="text-align: center; font-weight: 600; background: #eae7e7; padding: 10px;">
        <span> Shift Summary </span>
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Cash Sales
      </td>
      <td class="value-right">
        {{ posShiftSummary?.cashSales?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Check Sales
      </td>
      <td class="value-right">
        {{ posShiftSummary?.checkSales?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Credit Card Sales
      </td>
      <td class="value-right">
        {{ posShiftSummary?.creditCardSales?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        On Account Sales
      </td>
      <td class="value-right">
        {{ posShiftSummary?.onAccountSales?.toFixed(2) | currency }}
      </td>
    </tr>
    <tr>
      <td class="value-left">
        Total Sales
      </td>
      <td class="value-right">
        {{ (posShiftSummary?.cashSales + posShiftSummary?.checkSales + posShiftSummary?.creditCardSales + posShiftSummary?.onAccountSales).toFixed(2) | currency }}
      </td>
    </tr>
  </table>
</div>