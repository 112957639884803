import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { IInvoiceInfo } from '@otrack-lib/models/order/invoice.model';
import { OrderTypes } from '@otrack-lib/enums';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';


@Component({
  // tslint:disable-next-line:component-selector

  selector: 'app-invoice-detail-sideinfo',
  templateUrl: './invoice-detail-sideinfo.component.html',
  styleUrls: ['./invoice-detail-sideinfo.component.scss']
})
export class InvoiceDetailSideInfoComponent implements OnInit, OnDestroy {
  @Input() invoiceInfo: IInvoiceInfo;
  public data: any = [];
  public salesOrderType = OrderTypes.SalesOrder;
  constructor(private utils: UtilsService) {}

  ngOnInit() {
    if (this.invoiceInfo.history) {
     let count = 0;
      this.invoiceInfo.history.forEach(item => {
        this.data.push({
          time: this.utils.getStringTime(item.eventDate),
          icon: count === 0 ? 'fa fa-genderless kt-font-brand' : 'fa fa-genderless kt-font-success',
          text: item.detail,
          date: this.utils.getCustomDateString(item.eventDate, 'DD MMM-YY'),
          user: item.user
        });
        count++;
      });
    }
    // this.data = [
    //   {
    //     time: '10:00',
    //     icon: 'fa fa-genderless kt-font-danger',
    //     text: 'Sales Order Created',
    //     date: '20 May-19'
    //   },
    //   {
    //     time: '12:45',
    //     icon: 'fa fa-genderless kt-font-success',
    //     text: 'Invoice Created',
    //     date: '22 May-19'
    //   },
    //   {
    //     time: '14:00',
    //     icon: 'fa fa-genderless kt-font-brand',
    //     text: 'Payment - Amount Paid <b>700$</b>',
    //     date: '22 May-19'
    //   },
    //   {
    //     time: '14:00',
    //     icon: 'fa fa-genderless kt-font-brand',
    //     text: 'Payment - Amount Paid <b>56.42$</b>',
    //     date: '22 May-19'
    //   }
    // ];
  }

  get orderStatus() {
    switch (this.invoiceInfo.saleOrderStatus) {
    case SalesOrderStatus.Freezed:
        return 'Freezed';
    case SalesOrderStatus.Ready:
        return 'Ready';
        case SalesOrderStatus.Received:
          return 'New Order';
          case SalesOrderStatus.ConvertedToInvoice:
        return 'Converted';
        case SalesOrderStatus.OnHold:
          return 'On Hold';
        default:
          return 'Unknown';
    }
  }

  ngOnDestroy() {}
}
