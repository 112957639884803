<div class="kt-portlet" [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : false}">
  <div class="kt-portlet__head kt-portlet__head__custom modal-header gradient-modal-header">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title" style="color:white !important; font-size: 2.0rem; font-weight: 600;"> {{ title }}</h3>
    </div>
  </div>
  <form class="kt-form">
    <mat-dialog-content class="mat-dialog-style">
      <div class="kt-portlet__body modal-body p-0">

        <div class="form-group kt-form__group row p-0 mr-0 ml-0">
          <div class="col-lg-12 kt-margin-bottom-20-mobile amount-input-container">
            <label  class="col-form-label">{{ message }}</label>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
      <div class="kt-form__actions kt-form__actions--solid modal-footer">
        <div class="row text-right">
          <div class="col-lg-12">
            <button type="button" mat-raised-button class="rounded-button cancel-button" (click)="onNoClicked()" cdkFocusInitial> No </button>
            <button type="button" mat-raised-button class="rounded-button save-button" (click)="onYesClicked()"> Yes </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
