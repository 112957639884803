<div class="flex-container invoice print-invoice">
  <div>
    <table style="margin-bottom: 10px;">
      <colgroup>
        <col style="width: 40%" />
        <col style="width: 25%" />
        <col style="width: 35%" />
      </colgroup>
      <tr class="company-header-detail">
        <td>
          <!-- show company name only for the invoice is synced -->
          <div *ngIf="isOTOCustomer"
               style="display: inline-flex;">
            <!-- <img *ngIf="invoice?.company?.logoUrl" class="company-logo" src="{{ invoice?.company?.logoUrl }}"> -->
            <img *ngIf="invoice?.company?.logoUrl"
                 class="company-logo"
                 src="{{ invoice?.company?.logoUrl }}">
            <span>
              <h2 class="company-heading">
                {{ invoice?.company?.name }}
              </h2>
              <h3 class="header-subheading">
                <span *ngIf="invoice.company && invoice.company.address">{{ invoice?.company?.address }}<br></span>
                <span *ngIf="invoice.company && (invoice.company.city || invoice.company.state || invoice.company.postalCode)">{{
                  invoice?.company?.city }} {{ invoice?.company?.state }} {{ invoice?.company?.postalCode }}<br></span>
                <span *ngIf="invoice.company && invoice.company.country">{{ invoice?.company?.country }}<br></span>
                <span *ngIf="invoice.company && invoice.company.email">{{ invoice?.company?.email }}<br></span>
                <span *ngIf="invoice.company && invoice.company.phoneNo">{{ invoice?.company?.phoneNo }}</span>
              </h3>
            </span>
          </div>
        </td>
        <td>
          <div style="display: inline-flex;">
            <span>
              <h2>
                {{ invoice?.orderType === orderType.Invoice ? 'DELIVERY TICKET' : 'SALES ORDER' }}
              </h2>
              <h3 class="header-subheading">{{ invoice?.orderType === orderType.Invoice ? 'Order Received by' : 'Prepared by' }}</h3>
              <h3 class="header-subheading">{{ invoice?.orderType === orderType.Invoice ? '_________________________' :
                '_________________________' }}</h3>
            </span>
          </div>
        </td>
        <td>
          <div class="headerinvoicediv">
            <ng-container *ngIf="invoice?.orderType === orderType.Invoice">
              <h2 class="company-heading"> INV # {{ invoice?.invoiceNumber }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.CreditMemo">
              <h2 class="company-heading"> CM # {{ invoice?.invoiceNumber }} </h2>
            </ng-container>
            <ng-container *ngIf="invoice?.invoiceNumber == null">
              <h2 class="company-heading"> SO # {{ invoice?.orderId }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.PurchaseBill">
              <h2 class="company-heading"> BILL # {{ invoice?.invoiceNumber }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.PurchaseOrder">
              <h2 class="company-heading"> PO # {{ invoice?.invoiceNumber }}</h2>
            </ng-container>
            <h3 class="header-subheading">{{ invoice?.invoiceDate | date:'dd MMM, yyyy' }}</h3>
            <ng-container *ngIf="invoice?.orderType === orderType.Invoice && invoice?.salesOrderId">
              <h2 class="header-subheading"> SO # {{ invoice?.salesOrderId }}</h2>
            </ng-container>
            <ng-container *ngIf="invoice?.orderType === orderType.PurchaseBill && invoice?.referenceNumber">
              <h2 class="header-subheading"> REF # {{ invoice?.referenceNumber }}</h2>
            </ng-container>
          </div>
        </td>
      </tr>
      <tr *ngIf="invoice?.invoiceNumber == null">
        <td colspan="3">
          <p class="notinvoicemsg">This is not the actual invoice</p>
        </td>
      </tr>
      <tr *ngIf="invoice?.orderType === orderType.PurchaseOrder">
        <td colspan="3">
          <p class="notinvoicemsg">This is not the actual bill</p>
        </td>
      </tr>
      <tr>
        <td style="padding-top: 15px; vertical-align: top;">
          <div>
            <div class="bold-text">BILL TO</div>
            <div class="normal-text">{{ invoice?.customer.customerName }}</div>
            <div class="normal-text">{{ this.invoice?.customer?.address1 }}</div>
            <div class="normal-text">{{ this.invoice?.customer?.city }} {{ this.invoice?.customer?.state }}</div>
            <div class="normal-text">{{ this.invoice?.customer?.postalCode }} {{ this.invoice?.customer?.country }}</div>
            <div class="normal-text"
                 *ngIf="invoice?.contactPhone">{{ invoice.contactPhone }}</div>
          </div>
        </td>
        <td>
          <div *ngIf="invoice?.isPaid && invoice?.orderType === orderType.Invoice">
            <h5 class="paid">
              [PAID]
            </h5>
          </div>
        </td>
        <td style="padding-top: 15px; vertical-align: top;">
          <div *ngIf="invoice?.invoiceNumber != null && isOTOCustomer">
            <div class="bold-text"
                 *ngIf="invoice?.orderType === orderType.Invoice"
                 style="text-align: right;">INOVICE BY</div>
            <div class="bold-text"
                 *ngIf="invoice?.orderType === orderType.CreditMemo"
                 style="text-align: right;">CREDITMEMO BY</div>
            <div class="bold-text"
                 *ngIf="invoice?.orderType === orderType.PurchaseBill"
                 style="text-align: right;">BILLED BY</div>
            <div class="bold-text"
                 *ngIf="invoice?.invoiceNumber == null"
                 style="text-align: right;">ORDER BY</div>
            <div class="normal-text"
                 style="text-align: right;">{{ invoice?.invoiceBy | uppercase }}</div>
          </div>
          <!-- <div>
            <div class="bold-text" style="text-align: right;">TOTAL AMOUNT</div>
            <div class="normal-text" style="text-align: right;">{{ invoice?.totalAmount + invoice?.totalTax | currency }}</div>
          </div> -->
        </td>
      </tr>
    </table>
  </div>

  <div>
    <!-- invoice -->
    <table>
      <thead>
        <tr class="invoice-header">
          <th *ngIf="isBarCodeShow"
              style="text-align: left; padding-left: 5px;">BARCODE</th>
          <th class="column-small-cell"
              style="text-align: center;padding-right: 5px;">QTY</th>
          <th style="text-align: left; padding-left: 5px;">NAME</th>
          <th style="text-align: left; padding-left: 5px;">DESCRIPTION</th>
          <th style="text-align: left; padding-left: 5px;">CATEGORY</th>
          <!-- <th *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px;">SRP</th>
          <th *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px;">RETAIL</th>
          <th style="text-align: right;padding-right: 5px;">{{ invoice?.orderType === orderType.PurchaseBill ? 'COST' : 'PRICE'}}</th>
          <th style="text-align: right;padding-right: 5px;">TOTAL</th> -->
        </tr>
      </thead>
      <tbody>
        <tr class="invoice-items"
            *ngFor="let transaction of invoice?.items">
          <td *ngIf="isBarCodeShow"
              style="text-align: left;padding-left: 5px; vertical-align: top;">
            <app-barcode-generator [barcodeOptions]="barcodeOptions"
                                   [value]="transaction?.barcode"></app-barcode-generator>
          </td>
          <td style="text-align: center;padding-right: 5px; vertical-align: top;"> {{transaction.quantity}} <small><b>{{
                transaction.productUnit ? transaction.productUnit : ''}} </b></small>
          </td>
          <td style="text-align: left;padding-left: 5px;">
            {{ transaction?.productName }}
            <b>{{ transaction?.substitutable ? ' - (Subs)' : '' }}</b>
          </td>
          <td style="text-align: left;padding-left: 5px;">
            {{ transaction?.description }}
          </td>
          <td style="text-align: left;padding-left: 5px;">
            {{ transaction?.categoryName }}
          </td>
          <!-- <td *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.suggestedRetailPrice | currency:' ' }} </td>
          <td *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.unitPerCase * transaction.suggestedRetailPrice * transaction.quantity | currency:' ' }} </td>
          <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ transaction.price | currency:' ' }} </td>
          <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.price * transaction.quantity | currency:' ' }} <span >{{ transaction.isTaxable ? 'T' : '' }}</span></td> -->
        </tr>
      </tbody>
      <tfoot>
        <tr class="invoice-footer">
          <td *ngIf="isBarCodeShow"
              style="text-align: left;padding-left: 5px;"> TOTAL </td>
          <td style="text-align: center;padding-right: 5px;"> {{ totalQuantity }} </td>
          <td style="text-align: left;padding-left: 5px;"></td>
          <td style="text-align: left;padding-left: 5px;"></td>
          <td style="text-align: left;padding-left: 5px;"></td>
          <!-- <td *ngIf="isCustomerWithSRP" style="text-align: center;padding-right: 5px;"> </td>
          <td *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px;"> {{ retailTotal | currency }} </td>
          <td style="text-align: center;padding-right: 5px;"> </td>
          <td style="text-align: right;padding-right: 5px;"> {{ totalPrice | currency }} </td> -->
        </tr>
      </tfoot>
    </table>

    <!-- invoice summary -->
    <div style="display: flex;">
      <div style="width: 60%;">
        <div *ngIf="invoice?.deliveryType && invoice?.deliveryType !== DeliveryType.None">
          <div class="bold-text">Delivery Type</div>
          <div class="normal-text">{{ invoice.deliveryType }}</div>
        </div>

        <div *ngIf="invoice?.pickupDate">
          <div class="bold-text">Pickup Date</div>
          <div class="normal-text">{{ invoice.pickupDate | date:'dd MMM, yyyy' }}</div>
        </div>

        <div *ngIf="invoice?.shippingAddress && invoice?.deliveryType === DeliveryType.Delivery ">
          <div class="bold-text">Shipping Address</div>
          <div class="normal-text">{{ invoice.shippingAddress.address1 }}</div>
          <div *ngIf="invoice.shippingAddress.address2"
               class="normal-text">{{ invoice.shippingAddress.address2 }}</div>
          <div class="normal-text">{{ invoice.shippingAddress.city }} {{ invoice.shippingAddress.state }} {{
            invoice.shippingAddress.postalCode }} </div>
        </div>

      </div>

    </div>
    <!-- invoice summary -->

    <table>
      <tr *ngIf="invoice?.memo">
        <td>
          <div class="disclaimer-heading">
            MEMO:
          </div>
          <p class="memo-text">
            {{ invoice?.memo }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</div>