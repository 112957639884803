<div [formGroup]="form">
  <p-dropdown
    id="dealerlist"
    name="dealerlist"
    [options]="dealerList"
    [style]="{'width':'400px'}"
    filterPlaceholder="Search Customer"
    formControlName="dealerId"
    placeholder="Select Customer"
    (onChange)="onItemSelected($event)"
    [virtualScroll]="true"
    [itemSize]="31"
    [resetFilterOnHide]="true"
    [filter]="true">
  </p-dropdown>
</div>
