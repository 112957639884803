import { LocalStorageService } from './../helper-services/local-storage.service';
import { ErrorService } from './../services/error.service';
import { CookieService } from 'ngx-cookie-service';
import { ConfigurationService } from './../helper-services/configuration.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { Observable, from } from 'rxjs';
import { AuthService } from '../services/auth.service';


export const ONBOARD_API =  '/v1/onboard';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public userService: UserService,
    private authService: AuthService,
    private cookieService: CookieService,
    private errorService: ErrorService,
    private localStorageService: LocalStorageService,
    private configurationService: ConfigurationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:max-line-length
    // in order to sense, user is already signOut , check if user is laready loggedIn but not session cookie findout then signout from currect session
    if (this.configurationService.config.env.toUpperCase() !== 'LOCAL' && !request.url.includes(ONBOARD_API)) {
      const cssToken = this.authService.csstCookie;
      // console..log('intercept csst: ' + cssToken);
      if (!cssToken) {// mean user is signOut
        return from(this.handleSessionExpired(request, next));
      } else if (!this.authService.isEqualLocalAndCookieCsst) {
        return from(this.handleSessionChangedOnAnother(request, next, cssToken));
      }
    }

    return from(this.handleAccess(request, next));
  }

  private async handleSessionChangedOnAnother(request: HttpRequest<any>, next: HttpHandler, cssToken: string): Promise<HttpEvent<any>> {
    this.errorService.setUserLoggedOut();
    // redirect to window origin so global auth guard can reload session
    await this.authService.logoutClient(false, true);
    return next.handle(request).toPromise();
  }

  private async handleSessionExpired(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    this.errorService.setUserLoggedOut();
    await this.authService.logoutClient(true);
    return next.handle(request).toPromise();
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = <string>await this.authService.getIdToken();
    const headers = request.headers
      .set('Authorization', 'Bearer ' + token);
    // .append('Content-Type', 'application/json');
    const reqClone = request.clone({
      headers
    });
    return next.handle(reqClone).toPromise();
  }

}
