import { IPosShiftSummary } from './../../../../models/point-of-sale/point-of-sale.models';
import { ICompany } from '@otrack-lib/models/company/company.model';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'otrack-web-print-z-report',
  templateUrl: './print-z-report.component.html',
  styleUrls: ['./print-z-report.component.scss']
})
export class PrintZReportComponent implements OnInit {
  company: ICompany;
  today: Date;
  posShiftSummary: IPosShiftSummary;
  reportData: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly posService: PointOfSaleService,
    private readonly printService: PrintService,
    private readonly ref: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    const data = JSON.parse(localStorage.getItem('report_print'));
    this.company = this.userService.getUserCompany();
    this.today = new Date();

    if (data.posShiftSummary) {
      this.posShiftSummary = data.posShiftSummary;
      this.ready();
    } else {
      this.loadSummary(+data.shiftId);
    }
  }

  loadSummary(shiftId: number) {
    this.posService.getShiftSummaryReport(shiftId).subscribe(res => {
      this.posShiftSummary = res;
      this.ready();
    });
  }

  ready() {
    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
    }, 100);
  }

  pad(num: number): string {
    let s = num + '';
    while (s.length < 9) {
      s = '0' + s;
    }
    return s;
  }

}
