import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PosEntryComponent } from './pos-entry/pos-entry.component';
import { PosHomeComponent } from './pos-home/pos-home.component';
import { PosShiftResolver } from './resolvers/pos-shift.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'entry',
    pathMatch: 'full',
  },
  {
    path: 'entry',
    resolve: { shiftDetails: PosShiftResolver },
    component: PosEntryComponent,
  },
  {
    path: 'home',
    resolve: { shiftDetails: PosShiftResolver },
    component: PosHomeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PointOfSaleRoutingModule { }
