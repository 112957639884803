import { ConfigurationService } from './../helper-services/configuration.service';
import { IAuthUser } from './../../models/company/auth-user.model';
import { UserService } from './../services/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class CompanyResolver implements Resolve<IAuthUser> {
  constructor(private userService: UserService, private configurationService: ConfigurationService, private authService: AuthService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IAuthUser> {
    const userPromise = this.userService.getUserInformation().toPromise();
    const data = await userPromise;
    if (!data.company.isSetupCompleted && route.routeConfig.path !== 'landing') {
      window.location.href = `${this.configurationService.config.loginPageUrl}`;
    }
    else if (!data.canLogin) {
      await this.authService.logout();
    }
    return userPromise;
  }
}
