import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { BehaviorSubject } from 'rxjs';
import { LayoutUtilsService, MessageType } from '@admin-app/services/layout-utils.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IPosShiftSummary } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { PosPaymentComponent } from '../pos-payment/pos-payment.component';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';

@Component({
  selector: 'pos-end-shift',
  templateUrl: './pos-end-shift.component.html',
  styleUrls: ['./pos-end-shift.component.scss']
})
export class PosEndShiftComponent implements OnInit {
  form: FormGroup;
  shiftId: number;
  loading = true;
  posShiftSummary: IPosShiftSummary;
  closed = false;
  closingCash$ = new BehaviorSubject<number>(0);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly dialogRef: MatDialogRef<PosPaymentComponent>,
    private readonly fb: FormBuilder,
    private readonly layoutService: LayoutUtilsService,
    private readonly posService: PointOfSaleService,
    private readonly printService: PrintService
  ) { }

  ngOnInit() {
    this.shiftId = this.data.shiftId;
    this.closed = this.data.closed;
    this.form = this.createForm();

    this.form.controls.closingCash.valueChanges.subscribe(closingCash => {
      this.closingCash$.next(closingCash);
    });

    this.loadSummaryReport(false);
  }

  createForm() {
    return this.fb.group({
      closingCash: [null, Validators.min(0)],
    });
  }

  loadSummaryReport(print: boolean) {
    this.loading = true;
    this.posService.getShiftSummaryReport(this.shiftId).subscribe(
      res => {
        if (res) {
          this.posShiftSummary = res;
          if (print) {
            this.onPrint();
          }

        } else {
          this.layoutService.showNotification(
            'Sorry, we had an unexpected problem processing your request (Z-Report). Please try again or contact support.',
            MessageType.Error,
            10000
          );
        }
        this.loading = false;
      },
      error => {
        this.layoutService.showNotification(
          'Sorry, we had an unexpected problem processing your request (Z-Report). Please try again or contact support.',
          MessageType.Error,
          10000
        );
        this.loading = false;
      });
  }

  onSubmit() {
    this.loading = true;
    const controls = this.form.controls;
    const closingCash = controls.closingCash.value;
    this.posService.endShift(closingCash, this.shiftId).subscribe(
      res => {
        if (res) {
          this.layoutService.showNotification(
            'Shift ended successfully!',
            MessageType.Error,
            20000
          );

          this.closed = true;
          this.loadSummaryReport(true);
        }

        this.loading = false;
      },
      err => {
        this.loading = false;
        var errorMsg = 'Sorry, we had an unexpected problem processing your request (close shift). Please try again or contact support.';
        if (err.message && err.message.includes('closed')) {
          errorMsg = 'The current shift is closed maybe from another computer, please try refreshing the page to start a new shift.';
        }

        this.layoutService.showNotification(
          errorMsg,
          MessageType.Error,
          20000
        );
      });
  }

  onPrint() {
    if (this.closed && this.posShiftSummary) {
      this.printService.printDocument('zreport', [{ shiftId: this.shiftId, posShiftSummary: this.posShiftSummary }]);
    }
  }

  onClose() {
    if (this.closed) {
      this.dialogRef.close({ success: true });
    } else {
      this.dialogRef.close();
    }
  }

}
