<div>
  <div class="invoice-header-container">
    <div class="company-info">
      <div>
        <img  *ngIf="company?.logoUrl"  src="{{ company?.logoUrl }}"  />
      </div>
      <div>
        <h2>  {{ company?.name }} </h2>
        <small *ngIf="company && company.address">{{ company?.address }} </small>
        <small *ngIf="company && (company.city || company.state || company.postalCode)">{{ company?.city }} {{ company?.state }} {{ company?.postalCode }}</small>
        <small *ngIf=" company && company.country">{{ company?.country }}<br></small>
        <small *ngIf=" company && company.phoneNo">Phone: {{ company?.phoneNo }} </small>
        <small *ngIf="company && company.email">Email: {{ company?.email }}<br></small>
        <small *ngIf="company && company.tobaccoPermitNo">Tobacco ID: {{ company?.tobaccoPermitNo }} </small>
         <small *ngIf="company && company.taxPermitId">Tax ID: {{ company?.taxPermitId }}<br></small>
      </div>
    </div>
    <div class="invoice-header-info">
      <h3>Printed on: {{ today | date:'dd MMM, yyyy' }}</h3>
    </div>
  </div>

  <div class="report-container">
    <table class="report-table">
      <tr>
        <td colspan="2" style="text-align: center; font-weight: 600; background: #eae7e7; padding: 10px;">
          <span> Z - REPORT - Otrack POS </span>
          <span> - Shift # - {{ pad(posShiftSummary?.sequenceNumber) }}</span>
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Start Time
        </td>
        <td class="value-right">
          {{ posShiftSummary?.startTime | date : 'MMM dd, yyyy EEEE hh:mm a' }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          End Time
        </td>
        <td class="value-right">
          {{ posShiftSummary?.closeTime ?  (posShiftSummary?.closeTime | date : 'MMM dd, yyyy EEEE hh:mm a') : '-' }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Starting Cash
        </td>
        <td class="value-right">
          {{ posShiftSummary?.startingCash?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Cash Sales
        </td>
        <td class="value-right">
          {{ posShiftSummary?.cashSales?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Closing Cash
        </td>
        <td class="value-right">
          {{ posShiftSummary?.closingCash?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left" style="color: red; font-weight: 600;">
          Over and Short
        </td>
        <td class="value-right" style="color: red; font-weight: 600;">
          {{ (posShiftSummary?.closingCash - posShiftSummary?.startingCash - posShiftSummary?.cashSales).toFixed(2) | currency }}
        </td>
      </tr>
    </table>
    <table class="report-table" style="margin-top: 50px;">
      <tr>
        <td colspan="2" style="text-align: center; font-weight: 600; background: #eae7e7; padding: 10px;">
          <span> Shift Summary </span>
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Cash Sales
        </td>
        <td class="value-right">
          {{ posShiftSummary?.cashSales?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Check Sales
        </td>
        <td class="value-right">
          {{ posShiftSummary?.checkSales?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Credit Card Sales
        </td>
        <td class="value-right">
          {{ posShiftSummary?.creditCardSales?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          On Account Sales
        </td>
        <td class="value-right">
          {{ posShiftSummary?.onAccountSales?.toFixed(2) | currency }}
        </td>
      </tr>
      <tr>
        <td class="value-left">
          Total Sales
        </td>
        <td class="value-right">
          {{ (posShiftSummary?.cashSales + posShiftSummary?.checkSales + posShiftSummary?.creditCardSales + posShiftSummary?.onAccountSales).toFixed(2) | currency }}
        </td>
      </tr>
    </table>
  </div>
</div>