<div class="invoice-items-container">
<table cellpadding="0" cellspacing="0" class="invoice-item-table" *ngFor="let categoryOrder of categorizedItemList">
  <tr>
      <td colspan="4" class="catgory"> <b>{{ categoryOrder?.categoryName | uppercase}}</b> </td>
      <td class="right"></td>
      <td colspan="5" class="group-heading" style="text-align:center; "> RETAIL INFORMATION </td>
  </tr>
  <tr class="heading">
      <td class="cl-md"> SKU/UPC </td>
      <td  class="cl-lg"> Description </td>
      <td  class="cl-sm"> Qty </td>
      <td class="cl-sm right"> Price </td>
      <td class=" cl-sm right"> Total </td>
      <td class=" cl-sm right srp srp-left"> Case Qty </td>
      <td class=" cl-sm right srp"> Unit Price </td>
      <td class=" cl-sm right srp"> SRP </td>
      <td class=" cl-sm right srp"> Margin </td>
      <td class=" cl-sm right  srp srp-right"> Ext. Retail </td>
  </tr>

  <tr class="item" *ngFor="let order of categoryOrder.orders" >
      <td class="cl-md"> {{order.barcode}} </td>
      <td class="cl-lg">  {{ order.description ? order.description : order.productName }} </td>
      <td class="cl-sm"> {{ order.quantity }} </td>
      <td class="cl-sm right"> {{ order.price | currency }} </td>
      <td class="cl-sm right"> {{ order.price * order.quantity | currency }} </td>
      <td class="cl-sm right srp srp-left"> <i>{{ order.unitPerCase }} </i> </td>
      <td class="cl-sm right srp"> {{ (order.suggestedRetailPrice > 0 ? (order.unitPerCase > 1 ? (order.unitPerCase /  order.suggestedRetailPrice) :  order.suggestedRetailPrice)  : 0 )| currency:'':'' }} </td>
      <td class="cl-sm right srp"> {{ order.suggestedRetailPrice | currency:'':'' }} </td>
      <td class="cl-sm right srp"> {{ (order.suggestedRetailPrice > 0 ? ((order.unitPerCase * order.suggestedRetailPrice * order.quantity) - (order.price * order.quantity) / (order.unitPerCase * order.suggestedRetailPrice * order.quantity) * 100) : 0) | currency:'':''   }}% </td>
      <td class="cl-sm right  srp srp-right"> <i> {{order.unitPerCase * order.suggestedRetailPrice * order.quantity | currency }} </i> </td>
  </tr>

  <tr class="item total">
      <td colspan=""></td>
      <td colspan="">Total</td>
      <td colspan="" class="cl-sm">{{ categoryOrder.totalItems }} {{categoryOrder.totalItems > 1 ? 'Items' : 'Item'}} </td>
      <td colspan="2" class="cl-sm right">{{ categoryOrder.totalPrice | currency }} </td>
      <td class="right"> </td>
      <td class="right "></td>
      <td colspan="2" class="right "> Ext Retail:</td>
      <td class="cl-sm right"> {{ categoryOrder.totalSRP | currency }}  </td>
  </tr>
</table>
</div>
