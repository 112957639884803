<div class="invoice-header-container">
  <div class="company-info">
    <div>
        <!-- <img src="https://otrack-public.s3.amazonaws.com/companies/86/assets/logo.png" /> -->
        <img  *ngIf="invoice?.company?.logoUrl"  src="{{ invoice?.company?.logoUrl }}"  />
    </div>
    <div>
      <h2>  {{ invoice?.company?.name }} </h2>
      <small *ngIf="invoice && invoice.company && invoice.company.address">{{ invoice?.company?.address }} </small>
      <small *ngIf="invoice && invoice.company && (invoice.company.city || invoice.company.state || invoice.company.postalCode)">{{ invoice?.company?.city }} {{ invoice?.company?.state }} {{ invoice?.company?.postalCode }}</small>
      <small *ngIf="invoice &&  invoice.company && invoice.company.country">{{ invoice?.company?.country }}<br></small>
      <small *ngIf="invoice &&  invoice.company && invoice.company.phoneNo">Phone: {{ invoice?.company?.phoneNo }} </small>
      <small *ngIf="invoice && invoice.company && invoice.company.email">Email: {{ invoice?.company?.email }}<br></small>
      <small *ngIf="invoice && invoice.company && invoice.company.tobaccoPermitNo">Tobacco ID: {{ invoice?.company?.tobaccoPermitNo }} </small>
       <small *ngIf="invoice && invoice.company && invoice.company.taxPermitId">Tax ID: {{ invoice?.company?.taxPermitId }}<br></small>
    </div>
  </div>
  <div *ngIf="invoice?.isPaid" class="paid-div">
        <h2 class="paid-stamp">PAID</h2>
  </div>
  <div class="invoice-header-info">
      <ng-container *ngIf="invoice?.orderType === orderType.Invoice">
        <h2 class="company-heading"> INV # {{ invoice?.invoiceNumber }}</h2>
      </ng-container>
      <ng-container *ngIf="invoice?.orderType === orderType.CreditMemo">
        <h2 class="company-heading"> CM # {{ invoice?.invoiceNumber }} </h2>
      </ng-container>
      <h3>{{ invoice?.invoiceDate | date:'dd MMM, yyyy' }}</h3>
  </div>
</div>
