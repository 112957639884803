import { Subscription } from 'rxjs';
import { UserPreferenceService } from './../../services/user-preference.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'otrack-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PointOfSaleComponent implements OnInit, OnDestroy {

  private unsubscribe: Subscription[] = [];

  constructor(private userPreferenceService: UserPreferenceService,
              private afAuth: AngularFireAuth,) { }

  ngOnInit() {
    document.title = 'Otrack | POS';


    this.afAuth.authState.subscribe(user => {
      if  ( user) {
        const userSelectedPOSKeyboard = this.userPreferenceService.fetchUserPOSKeyboardPreferences(user.uid).subscribe();
        this.unsubscribe.push(userSelectedPOSKeyboard);
      }
   });


  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

}
