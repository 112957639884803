import { PosActionTypes } from './../../enums/pos-trail.enums';
import { OrderTypes } from '@otrack-lib/enums';
// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'posActionTypeName'
})
export class PosActionTypeNamePipe implements PipeTransform {

	/**
	 * Transform
	 *
	 * @param value: any
	 * @param args: any
	 */
  transform(value: PosActionTypes, args?: any): any {
    switch (value) {
      case PosActionTypes.Added:
        return 'Added';
      case PosActionTypes.Deleted:
        return 'Deleted';
      case PosActionTypes.ChangePrice:
        return 'Price Modified';
      case PosActionTypes.ChangeQuantity:
        return 'Qty Modified';
      case PosActionTypes.OnHold:
        return 'OnHold';
      case PosActionTypes.OnHoldDelete:
        return 'Hold Deleted';
      case PosActionTypes.PaymentAdded:
        return 'Payment';
      case PosActionTypes.ShiftClose:
        return 'Shift Close';
      case PosActionTypes.ShiftOpen:
        return 'Shift Open';
      case PosActionTypes.VoidSale:
          return 'Void Sale';
    }
    return '';
  }
}
