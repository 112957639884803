import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { OrderTypes } from '@otrack-lib/enums';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import { ITransactionList } from '@otrack-lib/models/order/transaction.model';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import * as moment from 'moment';

@Component({
  selector: 'app-print-open-balance-report',
  templateUrl: './print-open-balance-report.component.html',
  styleUrls: ['./print-open-balance-report.component.scss']
})
export class PrintOpenBalanceReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  items: ITransactionList[];
  totalInvoiceBalance = 0;
  user: IAuthUser;
  customerName = '';

  constructor(
    userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.customerName = this.reportFilter.selectedCustomerName;
      this.fetchItemOrderSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchItemOrderSummaryReport() {
    this.reportFilter.openBalance = true;
    this.reportFilter.toDate = moment(this.reportFilter.toDate).endOf('day').toISOString();
    this.reportService.getTransactionSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          const filteredData = res.items.filter(val => {
            if (val.transactionType === OrderTypes.Invoice && val.invoiceBalance > 0) {
              return true;
            } else if ((val.transactionType === OrderTypes.Payment || val.transactionType === OrderTypes.CreditMemo)
              && val.invoiceBalance > 0) {
              return true;
            }
            return false;
          });
          this.items = filteredData;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    // tslint:disable-next-line: max-line-length
    this.totalInvoiceBalance = this.items.map(t => t.transactionType === OrderTypes.Payment || t.transactionType === OrderTypes.CreditMemo ? -1 * t.invoiceBalance : t.invoiceBalance)
      .reduce((acc, value) => acc + value, 0);

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateInString(): string {

    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(toDate)
        .format('YYYY-MMM-DD')
    );
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

  get OrderType() {
    return OrderTypes;
  }

}
