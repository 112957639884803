import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { ITransactionList } from '@otrack-lib/models/order/transaction.model';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import * as moment from 'moment';
import { IOrderItemSummary } from '@otrack-lib/models/reports/orderItemSummary.model';
import { ReportsDataSource } from '@otrack-lib/common/data-sources/reports-datasources/reports.datasource';

@Component({
  selector: 'app-print-item-purchase-report',
  templateUrl: './print-item-purchase-report.component.html',
  styleUrls: ['./print-item-purchase-report.component.scss']
})
export class PrintItemPurchaseReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  dataSource = new ReportsDataSource(this.reportService);
  items: IOrderItemSummary[];
  totalQuantity = 0;
  totalAmount = 0;
  user: IAuthUser;
  barcodeOptions = {
    'width': 2,
    'height': 22,
    'fontSize': 14,
    'elementType': 'svg'
  };

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchItemOrderSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchItemOrderSummaryReport() {
    this.reportService.getPurchaseItemSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.items = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.totalAmount = this.items.map(t => t.amount).reduce((acc, value) => acc + value, 0);
    this.totalQuantity = this.items.map(t => t.quantity).reduce((acc, value) => acc + value, 0);

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
