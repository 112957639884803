import { PaymentMethodTypes } from '../../enums/paymentMethod.enums';
import { FormGroup } from '@angular/forms';

export interface IPosOrderPayment {
  shiftId: number;
  sequenceNumber?: number;
  order: IPosOrder;
  payment?: IPosPayment;
  orderDate?: string;
}

export interface IPosOrder {
  customerId?: number;
  discountType?: string;
  discountValue?: number;
  lines?: IPosLineItem[];
  memo?: string;
  // internal used fields
  itemCount?: number;
  total?: number;
  totalTax?: number;
  netTotal?: number;
  isTaxable?: boolean;
}

export interface IPosLineItem {
  id?: number;
  lineNo?: number;
  sortOrder?: number;
  productId: number;
  productName?: string;
  productDescription?: string;
  price: number;
  prices?: IPosPrice[];
  quantity: number;
  isTaxable?: boolean;
  taxPercent?: number;
  formGroup?: FormGroup;
}

export interface IPosTrailItem {
  id?: number;
  lineNo?: number;
  productId: number;
  customerId: number;
  quantity: number;
  price: number;
  taxable: boolean;
}

export interface IPosPrice {
  name: string;
  type: PosPriceType;
  price: number;
}

export enum PosPriceType {
  NEW_PRICE,
  BASE_PRICE,
  TIER_PRICE,
  LAST_PRICE,
  PROMO_PRICE
}

export interface IPosPayment {
  lines?: IPosPaymentLineItem[];
}

export interface IPosPaymentLineItem {
  amount: number;
  paymentMethod: PaymentMethodTypes;
  description?: string;
}

export interface IPosShift {
  shiftId?: number;
  sequenceNumber?: number;
  startedByUserId?: number;
  closedByUserId?: number;
  startingCash?: number;
  closingCash?: number;
}

export interface IShiftPendingTransaction {
  shiftId?: number;
  sequenceNumber?: number;
  pendingTransactions: IPosTrailItem[];
}

export interface IPosRegister {
  id?: number;
  name?: string;
  description?: string;
}

export interface IPosShiftSummary {
  shiftId: number;
  sequenceNumber?: number;
  startedByUserId: number;
  startTime: string;
  closeTime: string;
  taxCollected: number;
  startingCash: number;
  closingCash: number;
  cashSales: number;
  checkSales: number;
  creditCardSales: number;
  onAccountSales: number;
  overPayment: number;
}

export interface IPosOrderList {
  customerId?: number;
  customerName?: string;
  companyName?: string;
  customerAddress?: string;
  customerCity?: string;
  customerState?: string;
  customerPostalCode?: string;
  customerEmail?: string;
  customerOpenBalance?: number;
  orderId?: number;
  salesOrderId?: number;
  isQBCustomer?: boolean;
  openBalance?: number;
  itemsCount?: number;
  totalAmount?: number;
  totalSuggestedRetailPrice?: number;
  taxPercent?: number;
  totalTax?: number;
  totalCredit?: number;
  deliveryFee?: number;
  amountDue?: number;
  invoiceNumber?: string;
  isPaid?: boolean;
  taxId?: number;
  memo?: string;
  invoiceDate?: string;
  orderDate?: string;
  convertDate?: string;
  convertInvoiceId?: number;
  isTaxable?: boolean;
  isTaxableInvoice?: boolean;
  invoiceBy?: string;
  isSync?: boolean;
  deliveryType?: string;
  orderType?: string;
  isDiscounted?: boolean;
  discountType?: string;
  discountValue?: number;
  chargeStatus?: string;
  status?: number;
  salesOrderType?: string;
  items?: IPosOrderItem[];
}

export interface IPosOrderItem {
  id?: number;
  lineNo?: number;
  productId?: number;
  quantity?: number;
  originalPrice?: number;
  price?: number;
  cost?: number;
  sortOrder?: number;
  isTaxable?: boolean;
  substitutable?: boolean;
  minAge?: number;
  isAgeRestricted?: boolean;
  isWishListItem?: boolean;
  options?: any[];
}
