import { Observable } from 'rxjs';
import { ColDef } from 'ag-grid-community';


export interface AgGridConfig {
  columnDefs: ColDef[];
  actions?: TableAction[];
  checkbox?: boolean;
  checkboxSelectable?: (node: any) => boolean;
  radiobutton?: boolean;
  isExternalFilterPresent?: () => boolean;
  doesExternalFilterPass?: (node: any) => boolean;
  rowModelType?: 'clientSide' | 'infinite';
  hideQuickFilter?: boolean;
}

export interface ActionConfig {
   actionFieldKey: string;      // optional reference to the field that determines dyanmic display
   actionFieldValues: (string | number)[];  // optional list of values of the field determining display condition
}

export interface TableAction {
   actionType: ACTIONTYPES;
   showActionFieldKey?: string;
   showActionGFieldValue?: (string | number)[];
   isActionEnabled?: (rowData: any) => boolean; // function called to enable/disable an action button
}

export const linkTarget = {
  blank: '_blank',
  self: '_self',
  parent: '_parent',
  top: '_top',
}

export enum ACTIONTYPES {
  edit = 'edit',
  delete = 'delete',
  modify = 'modify',
  reject = 'reject',
  pay = 'pay'
}

export interface IHandleServerDataSource {
  defaultSortColumn: string;
  sortByColumns: IKeyValue[];
  hasPageIndexPad?: boolean;
  getRows(pageIndex: number, pageSize: number, sort: string): Observable<IHavePaginatedResult<any>>;
  handleError(err: any): void;
}

export interface IKeyValue {
  key: string;
  value: string;
}

export interface IHavePaginatedResult<T>{
  pagination: IPaginate;
  recordCount?: number;
  result: T[];
}

export interface IPaginate {
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  totalCount: number;
  totalPage: number;
}
