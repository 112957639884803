export enum OrderTypes {
  All = 0,
  Invoice = 1,
  CreditMemo = 2,
  Payment = 3,
  SalesOrder = 4,
  PurchaseBill = 5,
  PurchaseOrder = 6,
  BillCreditMemo = 7,
  BillPayment = 8,
  InventoryAdjustment = 9,
  Initial = 10
}

export class OrderTypeConvertor {
  public static StringToType = new Map<string, number>([
    ['All', OrderTypes.All],
    ['Invoice', OrderTypes.Invoice],
    ['CreditMemo', OrderTypes.CreditMemo],
    ['Payment', OrderTypes.Payment],
    ['SalesOrder', OrderTypes.SalesOrder],
    ['PurchaseBill', OrderTypes.PurchaseBill],
    ['Bill', OrderTypes.PurchaseBill],
    ['PurchaseOrder', OrderTypes.PurchaseOrder],
    ['BillCreditMemo', OrderTypes.BillCreditMemo],
    ['BillPayment', OrderTypes.BillPayment],
    ['InventoryAdjustment', OrderTypes.InventoryAdjustment],
    ['Inventory Adjust', OrderTypes.Initial],
  ]);

  public static TypeToText = new Map<number, string>([
    [OrderTypes.All, 'All'],
    [OrderTypes.Invoice, 'Invoice'],
    [OrderTypes.CreditMemo, 'Credit Memo'],
    [OrderTypes.Payment, 'Payment'],
    [OrderTypes.SalesOrder, 'Sales Order'],
    [OrderTypes.PurchaseBill, 'Purchase Bill'],
    [OrderTypes.PurchaseOrder, 'Purchase Order'],
    [OrderTypes.BillCreditMemo, 'Bill CreditMemo'],
    [OrderTypes.BillPayment, 'Bill Payment'],
    [OrderTypes.InventoryAdjustment, 'Inventory Adjustment'],
    [OrderTypes.Initial, 'Inventory Adjust'],
  ]);

  public static getDocName(orderType: OrderTypes): string {
    switch (orderType) {
      case OrderTypes.Invoice:
        return 'invoice';
      case OrderTypes.CreditMemo:
        return 'creditmemo';
      case OrderTypes.SalesOrder:
        return 'salesorder';
      case OrderTypes.Payment:
        return 'payment';
      case OrderTypes.PurchaseOrder:
        return 'purchaseorder';
      case OrderTypes.PurchaseBill:
        return 'purchasebill';
      case OrderTypes.InventoryAdjustment:
        return 'inventoryadjustment';
      default:
        return '';
    }
  }
}
