import { Injectable, Injector } from '@angular/core';
import { ICompany } from '@otrack-lib/models/company/company.model';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { IDeliveryFeeSetting } from '@otrack-lib/models/settings/delivery-fee.models';
import { IDeliveryContainer } from '@otrack-lib/models/settings/delivery-settings.model';
import { IGeneralSettings, IInvoiceTemplateType, ITimeZone } from '@otrack-lib/models/settings/general-settings.model';
import { IPaymentIntegration } from '@otrack-lib/models/settings/payment-integration-settings.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService extends BaseApiService {
  companyInfo: ICompany = undefined;

  readonly API_URLS = {
    container: `${this.baseApiUrl}/v1/delivery/containers`,
    paymentIntegration: `${this.baseApiUrl}/v1/paymentgateways`,
    deliveryFee: `${this.baseApiUrl}/v1/company/settings/deliveryFees`,
    calculateDeliveryFee: `${this.baseApiUrl}/v1/company/calculatedeliveryfee`,
    // deliverySettings: `${this.baseApiUrl}/v1/deliverysetting`,
    updateProfileInfo: `${this.baseApiUrl}/v1/companyinfo/edit`,
    generalSettings: `${this.baseApiUrl}/v1/company/settings/generalsetting`,
    invoiceTemplateTypes: `${this.baseApiUrl}/v1/company/settings/InvoiceTemplateType`,
    timeZones: `${this.baseApiUrl}/v1/company/settings/TimeZone`,
    updateGeneralSetting: `${this.baseApiUrl}/v1/company/settings/UpdateGeneralSetting`,
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  static getDeliveryContainerModal(res: any): IDeliveryContainer {
    return {
      containerId: +res['id'],
      containerName: res['label'],
      iconName: res['iconName'],
      description: res['description'],
      isDeleted: res['isDeleted']
    };
  }


  static getDeliveryFeeSettingModal(res: any): IDeliveryFeeSetting {

    if ( res && res.data) {
      return res.data; // to
    } else {
      return {
        hasAmountRangeCriteria : false,
        hasDistanceRangeCriteria: false,
        hasFreeDeliveryCriteria: false,
        hasNoDeliveryCriteria: false,
      };
    }
  }


  static getPaymentIntegration(res: any): IPaymentIntegration {
    return {
      paymentGateway: res['gatewayType'],
      authUrl: res['settings'] ? res['settings'].authUrl : null,
      isConnected: res['settings'] ? res['settings'].isConnected : null,
      expressLink: res['settings'] ? res['settings'].dashboardUrl : null,
    };
  }

  static getInvoiceTemplateType(res: any): IInvoiceTemplateType {
    return {
      key: res['key'],
      value: res['value'],
    };
  }

  static getTimeZone(res: any): ITimeZone {
    return {
      key: res['key'],
      value: res['value'],
    };
  }

  static generalSettings(res: any): IGeneralSettings {
    var _company = res['company'];
    var _settings = _company['settings'];
    var _sms = _settings['sms'];
    var _tax = res['tax'];

    var sms = {
      orderReceived: _sms['orderReceived'],
      orderReady: _sms['orderReady']
    };

    var settings = {
      canManageNoSync: _settings['canManageNoSync'],
      sms: sms,
      paymentMethods: _settings['paymentMethods'],
      invoiceTemplate: _settings['invoiceTemplate'],
      showBarcodeInEmail: _settings['showBarcodeInEmail'],
      enabledModules: _settings['enabledModules'],
      pricingPolicy: _settings['pricingPolicy'],
      timeZone: _settings['timeZone'],
    };

    return {
      company: {
        id: _company['id'],
        name: _company['name'],
        disclaimer: _company['disclaimer'],
        settings: settings,
        logoUrl: _company['logoUrl']
      },
      tax: {
        id: _tax['id'],
        name: _tax['name'],
        taxPercent: _tax['taxPercent']
      }
    }
  }

  getDeliveryContainersList(): Observable<IDeliveryContainer[]> {
     //return this.httpAuthGet('./assets/data/delivey-containers.json').pipe(
    return this.httpAuthGet(`${this.API_URLS.container}`).pipe(
        map(res => {
          if (res && res.data) {
            const output: IDeliveryContainer[] = [];
            res.data.forEach(dta => {
                  output.push(CompanySettingsService.getDeliveryContainerModal(dta));
            });
            return output;
          }
          throw new ErrorModel(null, 'Person not found');
        })
      );
  }

  addDeliveryContainer(containerInfo: IDeliveryContainer): Observable<IDeliveryContainer> {
    const dataToSend = {
      description: containerInfo.description,
      label: containerInfo.containerName,
      iconName: containerInfo.iconName,
    };
    return this.httpAuthPost(`${this.API_URLS.container}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Add container failed');
      })
    );
  }


  updateDeliveryContainer(containerInfo: IDeliveryContainer): Observable<IDeliveryContainer> {
    const dataToSend = {
      label: containerInfo.containerName,
      iconName: containerInfo.iconName,
      description: containerInfo.description,
    };
    return this.httpAuthPut(`${this.API_URLS.container}/${containerInfo.containerId}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Edit container failed');
      })
    );
  }


  updateDeliveryFeesSettings(containerInfo: IDeliveryFeeSetting): Observable<boolean> {
    const dataToSend = containerInfo;
    return this.httpAuthPost(`${this.API_URLS.deliveryFee}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return true;
        }
        throw new ErrorModel(null, 'Delivery Fee update Failed');
      })
    );
  }

  getDeliveryFeeSettings(): Observable<IDeliveryFeeSetting> {
     return this.httpAuthGet(`${this.API_URLS.deliveryFee}`).pipe(
      map(res => {
        if (res) {
          return CompanySettingsService.getDeliveryFeeSettingModal(res);
        }
        throw new ErrorModel(null, 'Delivery Fee update Failed');
      })
    );
    //  return  of({
    //   hasFreeDeliveryCriteria: true,
    //   freeDeliveryCriteria: {
    //     minAmount: 23,
    //     minItem: 2,
    //     maxDistance: 2
    //   },
    //   hasNoDeliveryCriteria: true,
    //   noDeliverySettings: {
    //     maxDistanceLimit: 2,
    //     minAmountLimit: 23
    //   },
    //   hasAmountRangeCriteria: false,
    //   feeAmountRange: [
    //     {
    //       minAmount: 93,
    //       maxAmount: 3,
    //       feeType: 'flat',
    //       feeRate: 3
    //     },
    //     {
    //       minAmount: 3,
    //       maxAmount: 3,
    //       feeType: 'flat',
    //       feeRate: 3
    //     }
    //   ],
    //   hasDistanceRangeCriteria: true,
    //   feeDistanceRange: [
    //     {
    //       minDistance: 3,
    //       maxDistance: 33,
    //       feeType: 'flat',
    //       feeRate: 33
    //     },
    //     {
    //       minDistance: 3,
    //       maxDistance: 35,
    //       feeType: 'flat',
    //       feeRate: 0.3
    //     }
    //   ]
    // });
  }

  calculateDeliveryFees(amount: number, itemCount: number, distance: number): Observable<number> {
    const dataToSend = {
      amount,
      itemCount,
      distance
    };
    return this.httpAuthPost(`${this.API_URLS.calculateDeliveryFee}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res.amount;
        }
        throw new ErrorModel(null, 'Delivery Fee update Failed');
      })
    );
  }

  deleteDeliveryContainer(containerId: number): Observable<boolean> {
    const deleteContainerUrl = `${this.API_URLS.container}/${containerId}`;
    return this.httpAuthDelete(`${deleteContainerUrl}`);
  }


  getPaymentIntegrationList(): Observable<IPaymentIntegration[]> {
    return this.httpAuthGet(`${this.API_URLS.paymentIntegration}`).pipe(
        map(res => {
          if (res && res.data) {
            const output: IPaymentIntegration[] = [];
            res.data.forEach(dta => {
                  output.push(CompanySettingsService.getPaymentIntegration(dta));
            });
            return output;
          }
          throw new ErrorModel(null, 'payment Integration not found');
        })
      );
  }


  updateStripeAuthenticationCode(authenticationCode: string, state: string): Observable<boolean> {
    const dataToSend = {
      authorizationCode: authenticationCode,
      csrfToken: state
    };
    return this.httpAuthPost(`${this.API_URLS.paymentIntegration}/stripe`, dataToSend).pipe(
        map(res => {
          if (res) {
            return res;
          }
          throw new ErrorModel(null, 'Stripe Payment Linking Failed!- Pleae contact team@otrack.io');
        })
      );
  }

  getInvoiceTemplateTypes(): Observable<IInvoiceTemplateType[]> {
    return this.httpAuthGet(this.API_URLS.invoiceTemplateTypes).pipe(
      map(res => {
        if (res) {
          const output: IInvoiceTemplateType[] = [];
          res.forEach(dta => {
            output.push(CompanySettingsService.getInvoiceTemplateType(dta));
          });
          return output;
        }
        throw new ErrorModel(null, 'Failed to load Invoice Tempaltes');
      })
    );
  }

  getTimeZones(): Observable<ITimeZone[]> {
    return this.httpAuthGet(this.API_URLS.timeZones).pipe(
      map(res => {
        if (res) {
          const output: ITimeZone[] = [];
          res.forEach(dta => {
            output.push(CompanySettingsService.getTimeZone(dta));
          });
          return output;
        }
        throw new ErrorModel(null, 'Failed to load Time Zones');
      })
    );
  }

  getGeneralSettings(companyId: number): Observable<IGeneralSettings> {
    const options = this.getRequestOptions();
    options['params'] = companyId ? { companyId: companyId } : {};

    return this.httpAuthGet(this.API_URLS.generalSettings, options).pipe(
      map(res => {
        if (res) {
          return CompanySettingsService.generalSettings(res);
        }
        throw new ErrorModel(null, 'Failed to get compamy general settings');
      })
    );
  }

  updateGeneralSettings(data: any): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append("LogoImage", data.logoUrl);
    formData.append("InvoiceTemplate", data.invoiceTemplate);
    formData.append("Disclaimer", data.disclaimer);
    formData.append("ShowBarcodeInEmail", data.showBarcodeInEmail);
    formData.append("pricingPolicy", data.pricingPolicy);
    formData.append("timeZone", data.timeZone);
    formData.append("taxPercent", data.taxPercent);

    return this.httpAuthPut(`${this.API_URLS.updateGeneralSetting}`, formData).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Failed to update general settings.');
      })
    )
  }
}
