// Layout Services
import {
  DataTableService, KtDialogService, LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService,
  PageConfigService, SplashScreenService, SubheaderService
} from '@admin-app/common/layout-core';
// Config
import { LayoutConfig } from '@admin-app/shared/config/layout.config';
// Partials
import { SharedModule } from '@admin-app/shared/shared.module';
// Env
import { environment } from '@admin-env/environment';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// ngrx
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { PrintComponent } from '@otrack-lib/common/components/print/print.component';
import { PrintModule } from '@otrack-lib/common/components/print/print.module';
import { CoreModule } from '@otrack-lib/core/core.module';
import { GlobalErrorHandler } from '@otrack-lib/core/global-error-handler';
import { TokenInterceptor } from '@otrack-lib/core/interceptors/token.interceptor.service';
import { AppEffects } from '@otrack-lib/core/store/app.effects';
import { appReducers } from '@otrack-lib/core/store/app.reducer';
import { IConfiguration } from '@otrack-lib/models/configuration.model';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
// Hammer JS
import 'hammerjs';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { CookieService } from 'ngx-cookie-service';
// Perfect Scroll bar
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { CommonComponentModule } from './../../../../libs/common/components/common-component.module';
// Modules
import { AppRoutingModule } from './app-routing.module';
// Copmponents
import { AppComponent } from './app.component';
import { AdminBaseComponent } from './common/admin-base.component';
import { LayoutCoreModule } from './common/layout-core/layout.core.module';
import { CallbackComponent } from './modules/callback/callback.component';
import { PointOfSaleModule } from './modules/point-of-sale/point-of-sale.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
// CRUD
import { LayoutUtilsService } from './services/layout-utils.service';
import { ThemeModule } from './themes/theme.module';





export const configuration: IConfiguration = {
  env: environment.env,
  apiURL: environment.apiURL,
  mode: environment.mode,
  firebase: environment.firebase,
  production: environment.production,
  appDomain: environment.appDomain,
  signupFunction: environment.signupFunction,
  checkstatusFunction: environment.checkstatusFunction,
  signoutFunction: environment.signoutFunction,
  loginPageUrl: environment.loginPageUrl,
  sentryDsn: environment.sentryDsn,
  company: environment.company,
  stripeKey: environment.stripeKey,
  localToken: environment.localToken,
};

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300
};



export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

// export function hljsLanguages(): HighlightLanguage[] {
//   return [
//     { name: 'typescript', func: typescript },
//     { name: 'scss', func: scss },
//     { name: 'xml', func: xml },
//     { name: 'json', func: json }
//   ];
// }

// export function JwtTokenGetter(): string {
//   if (localStorage) {
//     return localStorage.getItem('token');
//   } else {
//     return window.localStorage.getItem('token');
//   }
// }

// Layout Directives
// Services


@NgModule({
  declarations: [AppComponent, PrintComponent, AdminBaseComponent, CallbackComponent, PageNotFoundComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    SharedModule,
    CommonComponentModule,
    CoreModule.forRoot(configuration), // Inject configuration in Library coreModule
    ThemeModule,
    LayoutCoreModule,
    OverlayModule,
    PrintModule,
    PointOfSaleModule,
    // AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    AngularFireModule.initializeApp(configuration.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([AppEffects]),
    !environment.production ? StoreDevtoolsModule.instrument({
      name: 'Otrack Dev Envirnoment',
      maxAge: 25,
    }) : [],
  ],
  exports: [],
  providers: [
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    CookieService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true
    },
    // {
    //   provide: HIGHLIGHT_OPTIONS,
    //   useValue: { languages: hljsLanguages }
    // },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    LayoutUtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
