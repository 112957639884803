import { Injectable, Injector } from '@angular/core';
import { BaseApiService } from './baseapi.service';
import { UserService } from './user.service';
import { IAccounts, IAccountsCategory, IExpense, IExpenseHistory } from '@otrack-lib/models/expense/expense.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { map } from 'rxjs/operators';
import { QueryResultsModel } from '../../models/query-results.model';
import { ReportFiltersModel } from '../../models/report-filters.model';
import { FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { AuthService } from '@otrack-lib/core/services/auth.service';
import { ExportFormat } from '@otrack-lib/enums/exportformat.enum';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService extends BaseApiService {

  readonly API_URLS = {
    categories: `${this.baseApiUrl}/v1/expenses/categories`,
    accounts: `${this.baseApiUrl}/v1/expenses/accounts`,
    expenses: `${this.baseApiUrl}/v1/expenses`,
    expenseDocuments: `${this.baseApiUrl}/v1/expenses/documents`
  };

  private uploadError$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private uploader: FileUploader = null;
  allowedMimeType = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  maxFileSize = 5 * 1024 * 1024;

  constructor(_injector: Injector, protected userService: UserService, private authService: AuthService) {
    super(_injector);
  }

  static createExpense(res: any): IExpense {
    return {
      id: res.id,
      description: res.description,
      totalAmount: res.totalAmount,
      tax: res.tax,
      expenseDate: res.expenseDate,
      accountId: res.accountId,
      accountName: res.accountName,
      categoryId: res.categoryId,
      categoryName: res.categoryName,
      notes: res.notes,
      createdBy: res.createdBy,
      documents: res.documents
    };
  }

  static createExpenseHistory(res: any): IExpenseHistory {
    const records: IExpense[] = [];
    for (const item of res.data) {
      records.push(ExpenseService.createExpense(item));
    }

    return {
      totalRecords: res.totalRecords,
      records: records
    };
  }

  static createAccounts(res: any): IAccounts {
    return {
      id: res.id,
      name: res.name,
      children: res.children
    };
  }

  static createAccountsCategory(res: any): IAccountsCategory {
    return {
      id: res.id,
      name: res.name,
      children: res.children
    };
  }

  fetchExpense(expenseId: any): Observable<IExpense> {
    return this.httpAuthGet(`${this.API_URLS.expenses}/${expenseId}`)
      .pipe(map(res => {
        if (res) {
          return ExpenseService.createExpense(res);
        }

        throw new ErrorModel('', 'Failed to get expense history, please try again.');
      }));
  }

  fetchAllAccounts(): Observable<IAccounts[]> {
    // return this.httpAuthGet('./assets/data/dummyAccounts.json')
    return this.httpAuthGet(`${this.API_URLS.accounts}`)
      .pipe(map(res => {
        if (res) {
          const output: IAccounts[] = [];
          for (const item of res) {
            output.push(ExpenseService.createAccounts(item));
          }
          return output;
        }

        throw new ErrorModel('', 'Failed to get accounts, please try again.');
      }));
  }

  fetchAllAccountsCategory(): Observable<IAccountsCategory[]> {
    // return this.httpAuthGet('./assets/data/dummyAccountsCategory.json')
    return this.httpAuthGet(`${this.API_URLS.categories}`)
      .pipe(map(res => {
        if (res) {
          const output: IAccountsCategory[] = [];
          for (const item of res) {
            output.push(ExpenseService.createAccountsCategory(item));
          }
          return output;
        }

        throw new ErrorModel('', 'Failed to get expense history, please try again.');
      }));
  }

  fetchExpenseHistory(reportFilter: ReportFiltersModel): Observable<IExpenseHistory> {
    // return this.httpAuthGet('./assets/data/dummyExpenseHistory.json')
    return this.httpAuthGet(`${this.API_URLS.expenses}?fromDate=${reportFilter.fromDate}&toDate=${reportFilter.toDate}`)
      .pipe(map(res => {
        if (res) {
          return ExpenseService.createExpenseHistory(res);
        }

        throw new ErrorModel('', 'Failed to get expense history, please try again.');
      }));
  }

  fetchPagedExpenseHistoryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    const url = `${this.API_URLS.expenses}?fromDate=${reportFilter.fromDate}&toDate=${reportFilter.toDate}&pageSize=${reportFilter.pageSize}&pageNumber=${reportFilter.pageNumber}`;
    if (reportFilter.exportFormat) {
      return this.httpAuthGet(
        url,
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    // return this.httpAuthGet('../assets/data/dummyExpenseHistoryReport.json')
    // return this.httpAuthPost(`${this.API_URLS.expenses}`, ReportFiltersModel.withDateRange(reportFilter))
    return this.httpAuthGet(url)
      .pipe(
        map(res => {
          const output: IExpense[] = [];
          for (const item of res.data) {
            output.push(ExpenseService.createExpense(item));
          }
          return new QueryResultsModel(output, res.pagination.total);
        })
      );
  }

  private getExportRequestOptions(exportFormat: ExportFormat) {
    return {
      responseType: 'blob',
      observe: 'response',
      params: new HttpParams().set('export', ExportFormat[exportFormat].toLowerCase())
    }
  }

  addExpense(expense: IExpense): Observable<IExpense> {
    const dataToSend = {
      id: expense.id,
      description: expense.description,
      totalAmount: expense.totalAmount,
      tax: expense.tax,
      expenseDate: expense.expenseDate,
      accountId: expense.accountId,
      accountName: expense.accountName,
      categoryId: expense.categoryId,
      categoryName: expense.categoryName,
      notes: expense.notes
    };

    return this.httpAuthPost(`${this.API_URLS.expenses}`, dataToSend)
      .pipe(map(res => {
        if (res) {
          return ExpenseService.createExpense(res);
        }
        throw new ErrorModel('', 'Failed to add expense, please try again.');
      }));
  }

  updateExpense(expense: IExpense): Observable<IExpense> {
    const dataToSend = {
      id: expense.id,
      description: expense.description,
      totalAmount: expense.totalAmount,
      tax: expense.tax,
      expenseDate: expense.expenseDate,
      accountId: expense.accountId,
      accountName: expense.accountName,
      categoryId: expense.categoryId,
      categoryName: expense.categoryName,
      notes: expense.notes
    };

    return this.httpAuthPut(`${this.API_URLS.expenses}/${expense.id}`, dataToSend)
      .pipe(map(res => {
        if (res) {
          return ExpenseService.createExpense(res);
        }
        throw new ErrorModel('', 'Failed to update expense, please try again.');
      }));
  }

  deleteExpense(expenseId: number) {
    return this.httpAuthDelete(`${this.API_URLS.expenses}/${expenseId}`);
  }

  async updateExpenseDocument(expenseId: number) {
    const uo: FileUploaderOptions = {
      url: `${this.API_URLS.expenseDocuments}`,
      isHTML5: true,
      itemAlias: 'document',
      allowedMimeType: this.allowedMimeType,
      autoUpload: false,
      maxFileSize: this.maxFileSize
    };

    const token = <string>await this.authService.getIdToken();
    uo.headers = [{
      name: 'Authorization',
      value: `Bearer ${token}`
    }];
    this.uploader.setOptions(uo);
    this.uploader.onBuildItemForm = (fileItem, form) => {
      form.append("expenseId", expenseId)
    };
    this.uploader.uploadAll();
    return;
  }

  downloadExpenseDocument(documentId: number): Observable<any> {
    return this.httpAuthGet(`${this.API_URLS.expenseDocuments}/${documentId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  deleteExpenseDocument(docId: number) {
    return this.httpAuthDelete(`${this.API_URLS.expenseDocuments}/${docId}`);
  }

  private onAfterAddingFile() {
    this.uploadError$.next('');
  }

  private onWhenAddingFileFailed(item: FileLikeObject, filter: any) {
    switch (filter.name) {
      case 'fileSize':
        this.uploadError$.next(`Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`);
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        this.uploadError$.next(`Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`);
        break;
      default:
        this.uploadError$.next(`Unknown error (filter is ${filter.name})`);
    }
  }

  getFileUploader(): FileUploader {
    this.uploader = new FileUploader({
      isHTML5: true,
      itemAlias: 'document',
      allowedMimeType: this.allowedMimeType,
      autoUpload: false,
      maxFileSize: this.maxFileSize
    });
    this.uploader.onAfterAddingFile = item => this.onAfterAddingFile();
    this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
      this.onWhenAddingFileFailed(item, filter);
    return this.uploader;
  }
}
