import { IDeliveryContainer } from '@otrack-lib/models/settings/delivery-settings.model';

import { createAction, props } from '@ngrx/store';

export const loadDeliveryContainerSettings = createAction(
  '[Data] Load Delivery Container Setttings'
);

export const deleteDeliveryContainer = createAction(
  '[Data] Delete Delivery Container',
   props<{ data: number }>()
);

export const deleteDeliveryContainerFailure = createAction(
  '[Data] Delete Delivery Container Failure',
   props<{ error: any }>()
);


export const loadDeliveryContainerSettingsSuccess = createAction(
  '[Data] Load Delivery Container Setttings Success',
  props<{ data: IDeliveryContainer[] }>()
);

export const loadDeliveryContainerSettingsFailure = createAction(
  '[Data] Load Delivery Container Setttings Failure',
  props<{ error: any }>()
);

export const clearSettings = createAction(
  '[Data] Clear Settings'
);
