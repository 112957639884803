// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { AccessControlService } from '@otrack-lib/core/services/access-control.service';
import { map } from 'rxjs/operators';
@Injectable()
export class MenuHorizontalService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(private menuConfigService: MenuConfigService,  private accessControlService: AccessControlService,) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
    // const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'header.items');

    // this.menuConfigService.onConfigUpdated$()
    // const filteredMenuItems: any[] = this.accessControlService.applyPermissions(menuItems);
    // this.menuList$.next(filteredMenuItems);
    return this.menuConfigService.onConfigUpdated$.pipe(map( menus => {
       const menuItems: any[] = objectPath.get(menus, 'header.items');
       const filteredMenuItems: any[] = this.accessControlService.applyPermissions(menuItems);
      return filteredMenuItems;
    }));

	}
}
