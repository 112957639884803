export enum SalesOrderStatus {
  None = 0,
  Draft = 50,       // in case of public- Order exist without payment
  Received = 100,  // means - in case of public- Payment already done
  Freezed = 200,
  Ready = 300,
  ConvertedToInvoice = 400,
  OnHold = 500,
}

