<div class="flex-container invoice print-invoice">
  <div class="report-print-header-container">
    <h3>{{ user.company.name }}</h3>
    <h5>{{ this.reportFilter ? getDateRangeInString() : "" }}</h5>
    <h5>Individual Item Sale Detail</h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left;padding-left: 5px;">DATE</th>
        <th style="text-align: left;padding-left: 5px;">ITEM</th>
        <th style="text-align: left; padding-left: 5px;">DESCRIPTION</th>
        <th style="text-align: left; padding-left: 5px;">ORDER</th>
        <th style="text-align: left; padding-left: 5px;">CUSTOMER</th>
        <th style="text-align: right; padding-right: 5px;">QUANTITY</th>
        <th style="text-align: right; padding-right: 5px;">SALE_PRICE</th>
        <th style="text-align: right; padding-right: 5px;">AMOUNT</th>
        <!-- <th style="text-align: right; padding-right: 5px;">COGS</th>
        <th style="text-align: right; padding-right: 5px;">NET</th>
        <th style="text-align: right; padding-right: 5px;">GAIN</th> -->
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let item of items">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          {{ item.orderDate | date: "dd MMM, yyyy" }}
        </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          {{ item.productName }}
        </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          {{ item.description }}
        </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          {{ item.orderNumber }}
        </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          {{ item.customerName }}
        </td>
        <!-- <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          <app-barcode-generator *ngIf="item.barcode && item.barcode.length > 7" [barcodeOptions]="barcodeOptions" [value]="item.barcode"></app-barcode-generator>
        </td> -->
        <td style="text-align: right;padding-right: 5px; vertical-align: top;">
          {{ item.quantity }}
        </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;">
          {{ item.salePrice | currency }}
        </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;">
          {{ item.amount | currency }}
        </td>
        <!-- <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.cogs | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.amount - item.cogs | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ ((item.amount - item.cogs)/item.amount) | percent }} </td> -->
      </tr>
    </tbody>

    <!-- <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: right;padding-right: 5px;">
          {{ totalQuantity | number: "1.2-2" }}
        </td>
        <td style="text-align: right;padding-right: 5px;">
          {{ totalAmount | currency }}
        </td>
        <td style="text-align: right;padding-right: 5px;">
          {{ totalCogs | currency }}
        </td>
        <td style="text-align: right;padding-right: 5px;">
          {{ totalAmount - totalCogs | currency }}
        </td>
        <td style="text-align: right;padding-right: 5px;">
          {{ (totalAmount - totalCogs) / totalAmount | percent }}
        </td>
      </tr>
    </tfoot> -->
  </table>
</div>
