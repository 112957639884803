import { Component, Inject, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'kt-saleorder-statechange-entity-dialog',
  templateUrl: './saleorder-statechange-dialog.component.html',
  styleUrls: ['saleorder-statechange-dialog.component.scss']
})
export class SaleOrderChangeStateEntityDialogComponent implements OnInit {
  // Public properties
  viewLoading = false;
  @Input() data: any;
  @Output() actionPressed = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<SaleOrderChangeStateEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) { }

  ngOnInit() {
    this.data = this.modalData.title !== undefined ? this.modalData : this.data;
  }

  onNoClick(): void {
    this.data.asATemplate ? this.actionPressed.emit() : this.dialogRef.close();
  }

  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.data.asATemplate ? this.actionPressed.emit(true) : this.dialogRef.close(true);  // Keep only this row
    }, 2500);
  }

  newStatusName(status: SalesOrderStatus) {
    switch (status) {
      case SalesOrderStatus.ConvertedToInvoice:
        return 'Convert To Invoice';
      case SalesOrderStatus.Ready:
        return 'Ready';
      case SalesOrderStatus.Freezed:
        return 'Freezed';
      case SalesOrderStatus.Received:
        return 'Received';
    }
  }
}
