import { EmailService } from '@otrack-lib/core/services/email.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';
import { LayoutUtilsService, MessageType } from '@admin-app/services/layout-utils.service';
import { IEmail } from '@otrack-lib/models/email/email.model';

@Component({
  selector: 'app-modal-email-view',
  templateUrl: './modal-email-view.component.html',
  styleUrls: ['./modal-email-view.component.scss']
  //   encapsulation: ViewEncapsulation.None
})
export class ModalEmailViewComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCancelPress = new EventEmitter<any>();
  @Input() data: any;
  email: IEmail;
  public viewLoading = false;
  emailInvoiceForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<ModalEmailViewComponent>,
    private fb: FormBuilder,
    public utils: UtilsService,
    public emailService: EmailService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.viewLoading = true;
    this.data = this.modalData.emailType !== undefined ? this.modalData : this.data;

    this.email = {
      id: this.data.orderId,
      ccEmail: null,
      bccEmail: null,
      message: this.data.emailMessage,
      toEmail: this.data.customerEmail,
      reportParams: this.data.reportParams
    };
    this.createForm();
  }

  createForm() {
    this.emailInvoiceForm = this.fb.group({
      fromEmail: [this.data.fromEmail, Validators.compose([Validators.required, Validators.email])],
      toEmail: [this.email.toEmail, Validators.compose([Validators.required])],
      ccEmail: [this.email.ccEmail],
      bccEmail: [this.email.bccEmail],
      message: [this.email.message]
    });
  }

  cancelEmail() {
    this.modalData.emailType !== undefined ? this.dialogRef.close() : this.onCancelPress.emit();
  }

  sendEmail() {
    if (this.emailInvoiceForm.invalid) {
      return;
    }
    const emailToSend: IEmail = {
      id: this.email.id,
      toEmail: [this.emailInvoiceForm.value.toEmail],
      ccEmail: this.emailInvoiceForm.value.ccEmail ? [this.emailInvoiceForm.value.ccEmail] : null,
      bccEmail: this.emailInvoiceForm.value.bccEmail ? [this.emailInvoiceForm.value.bccEmail] : null,
      message: this.emailInvoiceForm.value && this.emailInvoiceForm.value.message ?  this.emailInvoiceForm.value.message.replace(/\n/g, '<br />') : '',
      reportParams: this.email.reportParams
    };

    const emailType = this.data.emailType;
    this.emailService.sendEmail(emailToSend, emailType).subscribe(
      res => {
        if (!res) {
          return;
        }
        this.onCancelPress.emit();
        this.layoutUtilsService.showActionNotification(
          'Email sent successfully.',
          MessageType.Success,
          10000,
          true,
          false
        );
      },
      error => {
        this.layoutUtilsService.showActionNotification(
          `Error: Email not sent. ${error.message}`,
          MessageType.Error,
          10000,
          true,
          false
        );
      }
    );
  }

  ngOnDestroy(): void { }
}
