export enum RoutesConstants {

  NEW_INVOICE = '/transaction/new/1',
  NEW_SALEORDER = '/transaction/new/4',
  NEW_CREDITMEMO = '/transaction/new/2',
  NEW_PAYMENT = '/transaction/new/3',

  NEW_PO = '/transaction/new/6',
  NEW_BILL = '/transaction/new/5',
  NEW_BILLPAYMENT = '/transaction/new/7',
  NEW_BILLCREDIT = '/transaction/new/8',

  INVOICE_LIST = '/transactionlist/type/1',
  SALEORDER_LIST = '/transactionlist/type/4',
  CREDITMEMO_LIST = '/transactionlist/type/2',
  PAYMENT_LIST = '/transactionlist/type/3',

  PO_LIST = '/transactionlist/type/6',
  BILL_LIST = '/transactionlist/type/5',
  BILLCREDIT_LIST = '/transactionlist/type/7',
  BILLPAYMENT_LIST = '/transactionlist/type/8',

  ORDER_RECEIVED = 'sales-orders',

  CUSTOMER_LIST = '/customer/list',
  CUSTOMER_DETAIL = '/customer/customerdetail',
  PRODUCT_LIST = '/product/list',
  PRODUCT_PRICING_TIER = '/product/pricing',
  CATEGORY_LIST = '/product/categories',
  PRODUCT_ADJUSTMENT = '/product/adjustment',
  PROFILE_SETTINGS = '/profile',

  // price tiers
  PRICE_LIST = '/price/list',
  NEW_PRICE_TIER = '/price/new',

  // reports - Finance
  REPORT_FINANCE_PROFIT_LOSS = '/reports/profitloss',
  REPORT_FINANCE_PAYMENT_COLLECTION = '/reports/paymentcollectionreport',
  REPORT_FINANCE_PAYOUTS = '/reports/paymentpayouts/payouts',
  REPORT_FINANCE_BALANCESHEET = '/reports/balancesheet',

  // reports - Sales
  REPORT_SALE_SALEITEM = '/reports/itemsalereport',
  REPORT_SALE_SALEITEM_DETAIL = '/reports/itemsaledetailreport',
  REPORT_SALE_SALE_CUSTOMER = '/reports/customeritemsalereport',
  REPORT_SALE_TOBACCO_REPORT = '/reports/statereport',

  // reports - Purchase
  REPORT_PURCHASE_PURCHASEITEM = '/reports/itempurchasereport',
  REPORT_PURCHASE_VENDOR = '/reports/vendoritempurchasereport',

  // repots - Inventory
  REPORT_INVENTORY_SUMMARY = '/reports/stock',
  REPORT_INVENTORY_ITEM_DETAIL = '/reports/itemquick',
  REPORT_PRODUCT_RETAIL_BY_CATEGORY = 'reports/productretailbycategory',
  REPORT_DAMAGE_ITEM_SUMMARY = 'reports/damageItemSummary',
  // repots
  REPORT_OPEN_BALANCE = '/reports/openbalance',
  REPORT_TRANSACTION = '/reports/transactionreport',
  REPORT_VENDOR_TRANSACTION = '/reports/vendor-transaction-report',
  REPORT_CUSTOMER_AGING = '/reports/agingreport',
  REPORT_VENDOR_AGING = '/reports/vendoragingreport',
  REPORT_POS_TRANSACTION_SUMMARY = '/reports/pos/transactionsummary',
  REPORT_POS_Z = '/reports/shifts',
  REPORT_POS_TRANSACTION_TRAIL = '/reports/pos/trail'
}

export enum RoutesIds {

  NEW_INVOICE = 'new_invoice',
  NEW_SALEORDER = 'new_saleorder',
  NEW_CREDITMEMO = 'new_creditmemo',
  NEW_PAYMENT = 'new_payment',

  NEW_PO = 'new_purchaseorder',
  NEW_BILL = 'new_bill',
  NEW_BILLPAYMENT = 'new_billpayment',
  NEW_BILLCREDIT = 'new_billcredit',

  INVOICE_LIST = 'invoice_list',
  SALEORDER_LIST = 'saleorder_list',
  CREDITMEMO_LIST = 'creditmemo_list',
  BILL_LIST = 'bill_list',
  PURCHASEORDER_LIST = 'purchaseorder_list',
  CREDITBILL_LIST = 'creditbill_list',
  PAYMENT_LIST = 'payment_list',
  BILLPAYMENT_LIST = 'billpayment_list',

  ORDER_RECEIVED = 'sales_orders',

  CUSTOMER_LIST = 'customer_list',
  CUSTOMER_DETAIL = 'customerdetail',
  PRODUCT_LIST = 'product_list',
  PRODUCT_PRICING_TIER = 'product_pricing',
  CATEGORY_LIST = 'product_categories',
  PRODUCT_ADJUSTMENT = 'product_adjustment',
  PROFILE_SETTINGS = 'profile_settings',

  // price tier
  PRICE_LIST = 'price_list',
  NEW_PRICE_TIER = 'new_price_tier',

  // reports - Finance
  REPORT_FINANCE_PROFIT_LOSS = 'reports_profitloss',
  REPORT_FINANCE_PAYMENT_COLLECTION = 'reports_paymentcollectionreport',
  REPORT_FINANCE_PAYOUTS = 'reports_paymentpayouts/payouts',
  REPORT_FINANCE_BALANCESHEET = 'reports_balancesheet',

  // reports - Sales
  REPORT_SALE_SALEITEM = 'reports_itemsalereport',
  REPORT_ITEM_SALE_DETAIL = 'report_itemsaledetailreport',
  REPORT_SALE_SALE_CUSTOMER = 'reports_customeritemsalereport',
  REPORT_SALE_TOBACCO_REPORT = 'reports_statereport',

  // reports - Purchase
  REPORT_PURCHASE_PURCHASEITEM = 'reports_itempurchasereport',
  REPORT_PURCHASE_VENDOR = 'reports_vendoritempurchasereport',

  // repots - Inventory
  REPORT_INVENTORY_SUMMARY = 'reports_stock',
  REPORT_INVENTORY_ITEM_DETAIL = 'reports_itemquick',
  REPORT_PRODUCT_RETAIL_BY_CATEGORY = 'productretailbycategory',
  REPORT_DAMAGE_ITEM_SUMMARY = 'damageItemSummary',
  // repots
  REPORT_OPEN_BALANCE = 'reports_openbalance',
  REPORT_TRANSACTION = 'reports_transactionreport',
  REPORT_VENDOR_TRANSACTION = 'report_vendortransactionreport',
  REPORT_CUSTOMER_AGING = 'reports_agingreport',
  REPORT_VENDOR_AGING = 'reports_vendoragingreport',
  REPORT_POS_Z = 'reports_z_shifts',
  REPORT_POS_TRANSACTION_TRAIL = 'reports_postransactiontrailreport',
  REPORT_POS_TRANSACTION_SUMMARY = 'reports_postransactionsummary'
}

// export const routesMappings = new Map([
//   [RoutesIds.AGING_REPORT, RoutesConstants.AGING_REPORT],
//   [RoutesIds.INVOICE_LIST, RoutesConstants.INVOICE_LIST],
// ]);


export interface HeaderMenu {
  title: string;
  page: string;
  root?: boolean;
}

export const HeaderMenusMapping = new Map<string, HeaderMenu>([


  [RoutesIds.NEW_BILL, { title: 'New Bill', root: true, page: RoutesConstants.NEW_BILL, }],
  [RoutesIds.NEW_BILLCREDIT, { title: 'New CreditBill', root: true, page: RoutesConstants.NEW_BILLCREDIT, }],
  [RoutesIds.NEW_BILLPAYMENT, { title: 'New BillPayment', root: true, page: RoutesConstants.NEW_BILLPAYMENT, }],
  [RoutesIds.NEW_CREDITMEMO, { title: 'New CreditMemo', root: true, page: RoutesConstants.NEW_CREDITMEMO, }],
  [RoutesIds.NEW_INVOICE, { title: 'NEW Invoice', root: true, page: RoutesConstants.NEW_INVOICE, }],
  [RoutesIds.NEW_PAYMENT, { title: 'Purchase Orders', root: true, page: RoutesConstants.NEW_PAYMENT, }],
  [RoutesIds.NEW_PO, { title: 'New PurchaseOrder', root: true, page: RoutesConstants.NEW_PO, }],
  [RoutesIds.NEW_SALEORDER, { title: 'New SaleOrder', root: true, page: RoutesConstants.NEW_SALEORDER, }],

  [RoutesIds.INVOICE_LIST, { title: 'Invoices', root: true, page: RoutesConstants.INVOICE_LIST, }],
  [RoutesIds.SALEORDER_LIST, { title: 'Sale Orders', root: true, page: RoutesConstants.SALEORDER_LIST, }],
  [RoutesIds.CREDITMEMO_LIST, { title: 'CreditMemo', root: true, page: RoutesConstants.CREDITMEMO_LIST, }],
  [RoutesIds.PAYMENT_LIST, { title: 'Payments', root: true, page: RoutesConstants.PAYMENT_LIST, }],
  [RoutesIds.BILLPAYMENT_LIST, { title: 'Bill Payments', root: true, page: RoutesConstants.BILLPAYMENT_LIST, }],
  [RoutesIds.PURCHASEORDER_LIST, { title: 'Purchase Orders', root: true, page: RoutesConstants.PO_LIST, }],
  [RoutesIds.BILL_LIST, { title: 'Bills', root: true, page: RoutesConstants.BILL_LIST, }],
  [RoutesIds.CREDITBILL_LIST, { title: 'Bills Credit', root: true, page: RoutesConstants.CREDITMEMO_LIST, }],

  [RoutesIds.ORDER_RECEIVED, { title: 'Order Received', root: true, page: RoutesConstants.ORDER_RECEIVED, }],

  [RoutesIds.CUSTOMER_LIST, { title: 'Customers', root: true, page: RoutesConstants.CUSTOMER_LIST, }],
  [RoutesIds.CUSTOMER_DETAIL, { title: 'Customer Details', root: true, page: RoutesConstants.CUSTOMER_DETAIL, }],

  [RoutesIds.PRODUCT_LIST, { title: 'Inventory', root: true, page: RoutesConstants.PRODUCT_LIST, }],
  [RoutesIds.PRODUCT_ADJUSTMENT, { title: 'Inv Adjustments', root: true, page: RoutesConstants.PRODUCT_ADJUSTMENT, }],
  // [RoutesIds.PRODUCT_PRICING_TIER, { title: 'Pricing Tiers', root: true, page: RoutesConstants.PRODUCT_PRICING_TIER, }],
  [RoutesIds.CATEGORY_LIST, { title: 'Categories', root: true, page: RoutesConstants.CATEGORY_LIST, }],

  [RoutesIds.PRICE_LIST, { title: 'Price List', root: true, page: RoutesConstants.PRICE_LIST, }],
  [RoutesIds.NEW_PRICE_TIER, { title: 'New Price Tier', root: true, page: RoutesConstants.NEW_PRICE_TIER, }],

  [RoutesIds.PROFILE_SETTINGS, { title: 'Setttings', root: true, page: RoutesConstants.PROFILE_SETTINGS, }],


  // reports - Finance
  [RoutesIds.REPORT_FINANCE_BALANCESHEET, { title: 'Balance Sheet', root: true, page: RoutesConstants.REPORT_FINANCE_BALANCESHEET, }],
  [RoutesIds.REPORT_FINANCE_PAYMENT_COLLECTION, { title: 'Payment Report', root: true, page: RoutesConstants.REPORT_FINANCE_PAYMENT_COLLECTION, }],
  [RoutesIds.REPORT_FINANCE_PAYOUTS, { title: 'Payouts', root: true, page: RoutesConstants.REPORT_FINANCE_PAYOUTS, }],
  [RoutesIds.REPORT_FINANCE_PROFIT_LOSS, { title: 'Profit & Loss', root: true, page: RoutesConstants.REPORT_FINANCE_PROFIT_LOSS, }],

  // reports - Sales
  [RoutesIds.REPORT_SALE_SALEITEM, { title: 'Sale by Item', root: true, page: RoutesConstants.REPORT_SALE_SALEITEM, }],
  [RoutesIds.REPORT_SALE_SALE_CUSTOMER, { title: 'Sale by Customer', root: true, page: RoutesConstants.REPORT_SALE_SALE_CUSTOMER, }],
  [RoutesIds.REPORT_SALE_TOBACCO_REPORT, { title: 'Tobacco Sales', root: true, page: RoutesConstants.REPORT_SALE_TOBACCO_REPORT, }],

  // reports - Purchase
  [RoutesIds.REPORT_PURCHASE_PURCHASEITEM, { title: 'Purchase by Item', root: true, page: RoutesConstants.REPORT_PURCHASE_PURCHASEITEM, }],
  [RoutesIds.REPORT_PURCHASE_VENDOR, { title: 'Purcahse by Vendor', root: true, page: RoutesConstants.REPORT_PURCHASE_VENDOR, }],

  // reports - Inventory
  [RoutesIds.REPORT_INVENTORY_ITEM_DETAIL, { title: 'Stock Report', root: true, page: RoutesConstants.REPORT_INVENTORY_ITEM_DETAIL, }],
  [RoutesIds.REPORT_INVENTORY_SUMMARY, { title: 'Inventory Details Report', root: true, page: RoutesConstants.REPORT_INVENTORY_SUMMARY, }],
  [RoutesIds.REPORT_PRODUCT_RETAIL_BY_CATEGORY, { title: 'Product Retail by Category', root: true, page: RoutesConstants.REPORT_PRODUCT_RETAIL_BY_CATEGORY, }],

  //reports
  [RoutesIds.REPORT_CUSTOMER_AGING, { title: 'Customer Aging', root: true, page: RoutesConstants.REPORT_CUSTOMER_AGING, }],
  [RoutesIds.REPORT_VENDOR_AGING, { title: 'Vendor Aging', root: true, page: RoutesConstants.REPORT_VENDOR_AGING, }],
  [RoutesIds.REPORT_TRANSACTION, { title: 'Customer Transactions', root: true, page: RoutesConstants.REPORT_TRANSACTION, }],
  [RoutesIds.REPORT_VENDOR_TRANSACTION, { title: 'Vendor Transactions', root: true, page: RoutesConstants.REPORT_VENDOR_TRANSACTION, }],
  [RoutesIds.REPORT_OPEN_BALANCE, { title: 'Open Balance Report', root: true, page: RoutesConstants.REPORT_OPEN_BALANCE, }],
  [RoutesIds.REPORT_POS_Z, { title: 'Z-Shifts Report', root: true, page: RoutesConstants.REPORT_POS_Z, }],
  [RoutesIds.REPORT_POS_TRANSACTION_TRAIL, { title: 'POS Trail', root: true, page: RoutesConstants.REPORT_POS_TRANSACTION_TRAIL, }],
  [RoutesIds.REPORT_POS_TRANSACTION_SUMMARY, { title: 'POS Transactions', root: true, page: RoutesConstants.REPORT_POS_TRANSACTION_SUMMARY, }],
]);


export function getMenuByRouteId(routeId: any): HeaderMenu {
  return HeaderMenusMapping.has(routeId) ? HeaderMenusMapping.get(routeId) : undefined;
}


