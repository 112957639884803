import { IAgingSummary } from './../../../../../models/reports/aging-summary.model';
import { Component, Input, OnInit } from '@angular/core';
import { OrderTypes } from '@otrack-lib/enums';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';

@Component({
  selector: 'app-invoice-subheader-template1',
  templateUrl: './invoice-subheader.component.html',
  styleUrls: ['./invoice-subheader.component.scss']
})
export class InvoiceSubHeaderComponent implements OnInit {
  @Input() invoice: IInvoice;
  @Input() customerAgingSummary: IAgingSummary;
  orderType = OrderTypes;

  constructor() {}

  ngOnInit(): void {
  }
}
