import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSettings from './settings.reducer';

export const selectSettingState = createFeatureSelector<fromSettings.State>(
  fromSettings.settingsFeatureKey
);

export const getDeliveryContainerSettings = createSelector(
  selectSettingState,
  (state: fromSettings.State) => {
    return state &&  state.deliveryContainer && !state.deliveryContainer.loading
              ? state.deliveryContainer.data
              : [];
  }
);

export const isDeliveryContainerLoaded = createSelector(
  selectSettingState,
  (state: fromSettings.State) => {
    return state && state.deliveryContainer && state.deliveryContainer.data && state.deliveryContainer.success;
  }
);

