<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> {{ this.reportFilter ? getDateRangeInString() : '' }} </h5>
    <h5> Payment Collection Summary </h5>
  </div>

  <div>
    <table class="summary-table">
      <tr class="summary-table-header">
      <td >Payment Type</td>
      <td >Count</td>
      <td class="thirdcell">Amount</td>

      </tr>
      <tr>
        <td >Cash</td>
        <td >{{ totalCashCount }}</td>
        <td class="thirdcell">{{ totalCashAmount  | currency }}</td>
      </tr>
      <tr>
        <td >Cheque</td>
        <td >{{ totalChequeCount }}</td>
        <td class="thirdcell">{{ totalChequeAmount  | currency }}</td>
      </tr>
      <tr>
        <td >Other</td>
        <td >{{ totalOtherCount }}</td>
        <td class="thirdcell">{{ totalOtherAmount | currency}}</td>
      </tr>
      <tr class="summary-table-footer">
        <td >Total</td>
        <td > {{ totalCount }}</td>
        <td class="thirdcell">{{ paymentAmountTotal  | currency}}</td>
      </tr>
    </table>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th class="column-small-cell" style="text-align: left;padding-left: 5px;">DATE</th>
        <th style="text-align: left; padding-left: 5px;">CUSTOMER</th>
        <th style="text-align: left; padding-left: 5px;">PAYMENT#</th>
        <th style="text-align: left; padding-left: 5px;">METHOD</th>
        <th style="text-align: right; padding-right: 5px;">AMOUNT</th>
        <th style="text-align: right; padding-right: 5px;">BALANCE</th>
        <th style="text-align: left; padding-left: 5px;">CHEQUE#</th>
        <th style="text-align: left; padding-left: 5px;">CREATE BY</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let report of transactions">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.paymentDate | date:'dd MMM, yyyy'}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.customerName}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.paymentNumber}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ getPaymentMethod(report.paymentType) }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.paymentAmount | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.unusedAmount | currency }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.chequeNo}} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.paymentBy}} </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: right;padding-right: 5px;"> {{ paymentAmountTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ paymentBalanceTotal | currency }} </td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
      </tr>
    </tfoot>

  </table>
</div>
