<div class="btn-wrapper">
    <ng-container *ngFor="let button of params.actions">
        <ng-container *ngIf="isButtonVisible(button, params) && button.id !== 'select'">
            <button class="action-button" mat-mat-flat-button
                [disabled]="isButtonDisabled(button, params)"
                (click)="onClick(button.id)">
                <mat-icon matSuffix>{{button.matSuffix}}</mat-icon>
                <div class="action-button-text">{{ button.label }}</div>
              </button>
        </ng-container>
        <ng-container *ngIf="isButtonVisible(button, params) && button.id === 'select'">
          <button class="basic-button-narrow"
          (click)="onClick(button.id)">
         {{ button.label }}
        </button>
        </ng-container>
    </ng-container>
</div>
