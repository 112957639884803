import { IAccounts, IAccountsCategory } from '../../../../models/expense/expense.model';
import { createAction, props } from '@ngrx/store';

export const loadAccounts = createAction(
  '[Data] Load Chart of Accounts'
);

export const loadAccountsSuccess = createAction(
  '[Data] Load Chart of Accounts Success',
  props<{ data: IAccounts[] }>()
);

export const loadAccountsFailure = createAction(
  '[Data] Load Chart of Accounts Failure',
  props<{ error: any }>()
);

export const loadAccountCategories = createAction(
  '[Data] Load Account Categories'
);

export const loadAccountCategoriesSuccess = createAction(
  '[Data] Load Account Categories Success',
  props<{ data: IAccountsCategory[] }>()
);

export const loadAccountCategoriesFailure = createAction(
  '[Data] Load Account Categories Failure',
  props<{ error: any }>()
);

export const clearAccounts = createAction(
  '[Data] Clear Data'
);
