<div class="billto-section">
  <div class="billto-section-item" >
      <div class="headerdiv-customer">
          <!-- <div class="sub-customerinfo"><b>Bill To</b></div>
          <div class="customername">Anis Noor Ali</div>
          <div class="sub-customerinfo">12691 BISSONET ST.</div>
          <div class="sub-customerinfo">Houston TX</div>
          <div class="sub-customerinfo">77099 </div> -->
          <div class="bold-text">BILL TO</div>
          <div><b>{{ invoice?.customer.customerName }}</b></div>
          <div>{{ this.invoice?.customer?.address1 }}</div>
          <div>{{ this.invoice?.customer?.city }} {{ this.invoice?.customer?.state }}</div>
          <div>{{ this.invoice?.customer?.postalCode }} {{ this.invoice?.customer?.country }}</div>
          <div *ngIf="invoice?.contactPhone">{{ invoice.contactPhone }}</div>
      </div>
  </div>

  <div *ngIf="customerAgingSummary" class="againsummary-section-item">
    <table class="agingSummaryTable">
      <tr  *ngIf="invoice?.customer?.openBalance && invoice?.orderType !== orderType.SalesOrder" >
        <td colspan="4">Account Receivable Aging Summary</td>
     </tr>
        <tr>
            <td><b>0 to 30</b> </td>
            <td><b> 31 to 60</b></td>
            <td> <b> 61 to 90</b></td>
            <td><b> 90+</b></td>
        </tr>
        <tr>
          <td> {{customerAgingSummary.oneToThirty ? (customerAgingSummary.oneToThirty  | currency) : '-'}} </td>
          <td> {{customerAgingSummary.thirtyOneToSixty ? (customerAgingSummary.thirtyOneToSixty  | currency) : '-'}} </td>
          <td> {{customerAgingSummary.sixtyOneToNinety ? (customerAgingSummary.sixtyOneToNinety  | currency) : '-'}} </td>
          <td> {{customerAgingSummary.moreThanNinety ? (customerAgingSummary.moreThanNinety  | currency) : '-'}} </td>
        </tr>
    </table>
</div>

  <div class="invoice-summary-section-item">
      <table class="invoiceinfo-table">
          <tr>
              <td class="right pr-10">Invoice By</td>
              <td> <b>{{ invoice?.invoiceBy | uppercase }}</b></td>
          </tr>
          <tr>
              <td class="right pr-10">Invoice Total</td>
              <td><b>{{ invoice?.totalAmount + invoice?.totalTax | currency }}</b></td>
          </tr>
          <tr  *ngIf="invoice?.customer?.openBalance && invoice?.orderType !== orderType.SalesOrder" >
              <td class="right pr-10">Open Balance</td>
              <td><b>{{ invoice?.customer?.openBalance | currency}}</b></td>
          </tr>
      </table>
  </div>
</div>
