import { Injectable, Injector } from '@angular/core';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { QueryParamsModel } from '@otrack-lib/models/query-params.model';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { IVendorTransaction } from '@otrack-lib/models/vendor/vendor-transaction.model';
import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { IVendorSummary } from '@otrack-lib/models/vendor/vendor-summary.model';



@Injectable({
  providedIn: 'root'
})
export class VendorService extends BaseApiService {
  // TODO:
  readonly API_URLS = {
    vendors: `${this.baseApiUrl}/v1/vendors`,
    odataVendorList: `${this.baseApiUrl}/v1/odata/vendors/VendorList`,
    addUpdateVendor: `${this.baseApiUrl}/v1/vendors`,
    vendorTransactionList: `${this.baseApiUrl}/v1/odata/vendors/VendorTransactionList`
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  // TODO:
  static getVendorModel(res: any): IVendor {
    return {
      vendorId: res.vendorId,
      firstName: res.firstName,
      lastName: res.lastName,
      address1: res.address1,
      address2: res.address2,
      city: res.city,
      companyName: res.companyName,
      country: res.country,
      vendorName: res.vendorName,
      isActive: res.isActive,
      isQbVendor: res.isQBCustomer,
      isQBSynced: res.isQbSynced,
      isTaxable: res.isTaxable,
      notes: res.notes,
      openBalance: +res.openBalance,
      postalCode: res.postalCode,
      state: res.state,
      faxNo: res.faxNo,
      email: res.email,
      phoneNo: res.phoneNo,
      taxPercent: res.taxPercent,
      taxId: res.taxId,
    };
  }

  static geTransactionModel(res: any): IVendorTransaction {
    return {
      amount: res.amount,
      date: res.date,
      id: res.id,
      billBalance: res.billBalance,
      isSynced: res.isSynced,
      transaction: res.transaction,
      referenceNumber: res.referenceNumber,
      transactionNumber: res.transactionNumber,
      transactionType: res.transactionType
    };
  }

  static getVendorSummaryModel(res: any): IVendorSummary {
    return {
      totalBillsAmount: res.totalBillsAmount,
      totalOpenBills: res.totalOpenBills
    };
  }

  getVendorList(): Observable<IVendor[]> {
    return this.httpAuthGet(`${this.API_URLS.vendors}`).pipe(
      map(res => {
        const vendor: IVendor[] = [];
        for (const item of res) {
          vendor.push(VendorService.getVendorModel(item));
        }
        return vendor;
      })
    );
  }

  getVendorListByFilter(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = queryParams.pageNumber * queryParams.pageSize;
    dataParams['top'] = queryParams.pageSize;

    const searchData = queryParams.getODataSearchFilterString();
    if (searchData.length > 0) {
      dataParams['vfilter'] = searchData;
    }

    const orderData = queryParams.getODataOrderByFilterString();
    if (orderData) {
      dataParams['orderby'] = orderData;
    }

    return this.httpAuthGet(
      `${this.API_URLS.odataVendorList}?${this.generateODataQueryString(dataParams)}`
    ).pipe(
      map(res => {
        const output: IVendor[] = [];
        const total = +res['@odata.count'];

        for (const item of res['value']) {
          output.push(VendorService.getVendorModel(item));
        }
        return new QueryResultsModel(output, total);
      })
    );
  }

  getVendorListById(vendorId: number): Observable<IVendor> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['vfilter'] = `vendorId eq ${vendorId}`;
    return this.httpAuthGet(
      `${this.API_URLS.odataVendorList}?${this.generateODataQueryString(
        dataParams
      )}`
    ).pipe(
      map(res => {
        const output: IVendor[] = [];
        const total = +res['@odata.count'];
        if (total >= 1) {
          return VendorService.getVendorModel(res['value'][0]);
        } else {
          return null;
        }
      })
    );
  }

  getVendorSummary(vendorId: number): Observable<IVendorSummary> {
    // return this.httpAuthGet('./assets/data/vendor.summary.json')
    return this.httpAuthGet(`${this.API_URLS.vendors}/${vendorId}/summary`)
      .pipe(
        map(res => {
          if (res) {
            return VendorService.getVendorSummaryModel(res.data);
          } else {
            return null;
          }
        })
      );
  }

  // TODO:
  updateVendor(data: IVendor): Observable<IVendor> {
    const dataToSend = {
      vendorName: data.vendorName,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      address1: data.address1,
      phoneNo: data.phoneNo,
      faxNo: data.faxNo,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      notes: data.notes,
      isActive: data.isActive,
      isQBVendor: data.isQbVendor,
      isTaxable: data.isTaxable,
      openBalance: data.openBalance
    };

    return this.httpAuthPut(`${this.API_URLS.addUpdateVendor}/${data.vendorId}`, dataToSend).pipe(
      map(res => {
        if (res) {
          const vendor: IVendor = VendorService.getVendorModel(res);
          return vendor;
        }
        throw new ErrorModel(null, 'Person not found');
      })
    );
  }

  // TODO:
  addVendor(data: IVendor): Observable<IVendor> {
    const dataToSend = {
      vendorName: data.vendorName,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      address1: data.address1,
      phoneNo: data.phoneNo,
      faxNo: data.faxNo,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      notes: data.notes,
      isActive: data.isActive,
      isQBVendor: data.isQbVendor,
      isTaxable: data.isTaxable,
      openBalance: data.openBalance,
    };

    return this.httpAuthPost(`${this.API_URLS.addUpdateVendor}`, dataToSend).pipe(
      map(res => {
        if (res) {
          const vendor: IVendor = VendorService.getVendorModel(res);
          return vendor;
        }
        throw new ErrorModel(null, 'Vendor not found');
      })
    );
  }


  getVendorDetail(vendorId: number): Observable<IVendor> {
    //  return this._http.get<ICustomerDetail>('./assets/dummy/customerdetail.json');
    const customerDetailUrl = `${this.API_URLS.vendors}/${vendorId}`;
    return this.httpAuthGet(customerDetailUrl).pipe(
      map(res => {
        return res ? VendorService.getVendorModel(res) : null;
      })
    );
  }


  getVendorTransactionList(vendorId: number, queryParams: QueryParamsModel): Observable<any> {
    const url = `${this.API_URLS.vendorTransactionList}(vendorId=${vendorId})`;

    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = queryParams.pageNumber * queryParams.pageSize;
    dataParams['top'] = queryParams.pageSize;

    const searchData = queryParams.getODataSearchFilterString();
    if (searchData.length > 0) {
      dataParams['vfilter'] = searchData;
    }

    const orderData = queryParams.getODataOrderByFilterString();
    if (orderData) {
      dataParams['orderby'] = orderData;
    }

    return this.httpAuthGet(`${url}?${this.generateODataQueryString(dataParams)}`).pipe(
      map(res => {
        const output: IVendorTransaction[] = [];
        const total = +res['@odata.count'];

        for (const item of res['value']) {
          output.push(VendorService.geTransactionModel(item));
        }
        return new QueryResultsModel(output, total);
      })
    );
  }
}
