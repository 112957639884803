<div class="invoice-summary">
  <div>
    <table>
      <thead>
      </thead>
      <tbody>
          <tr>
              <td class="summary-total-label"> Total Items: </td>
              <td class="summary-total-value">  {{ totalItems  }} </td>
          </tr>
          <tr>
              <td class="summary-total-label"> Total Ext. Retail: </td>
              <td class="summary-total-value"> {{ totalExtRetail | currency  }} </td>
          </tr>
      </tbody>
  </table>
  </div>
  <div>
  <table>
      <thead>
      </thead>
      <tbody>
          <tr *ngIf="invoice?.discountType && invoice?.discountType !== DiscountType.None">
            <td class="summary-total-label"> Discount  </td>
            <td class="summary-total-value"> {{ (invoice?.discountType === DiscountType.Percentage ? (invoice?.totalAmount * (invoice?.discountValue / 100)) : invoice?.discountValue) * -1 | currency : currencyCode : 'symbol-narrow'  }} </td>
          </tr>
          <tr>
              <td class="summary-total-label"> Total </td>
              <td class="summary-total-value">  {{ invoice?.totalAmount | currency  }} </td>
          </tr>
          <tr>
              <td class="summary-total-label"> Sales Tax </td>
              <td class="summary-total-value"> {{ invoice?.totalTax | currency  }} </td>
          </tr>
          <tr>
              <td class="summary-total-label"> Net Total </td>
              <td class="summary-total-value"> {{ invoice?.totalTax + invoice?.totalAmount | currency  }} </td>
          </tr>
      </tbody>
  </table>
  </div>
</div>
<div class="flex-invoicesubheader">
  <table>
      <tbody>
          <tr *ngIf="invoice?.memo">
            <td>
              <div class="disclaimer-heading">
                MEMO:
              </div>
              <p class="memo-text">
                {{ invoice?.memo }}
              </p>
            </td>
          </tr>
          <tr>
            <div class="disclaimer-heading">
              DISCLAIMER:
            </div>
            <p *ngIf="!invoice?.company?.disclaimer" class="disclaimer-text">
              ALL RETURN CHECKS ARE SUBJECT TO A $50.00 RETURN FEE.
              BY ACCEPTING THIS INVOICE CUSTOMER AGREE TO PAY {{ invoice?.company?.name | uppercase }} FOR THIS INVOICE IN FULL ACCORDING TO THEIR TERMS.
              A SERVICE CHARGE OF 18% APR PER MONTH WILL BE ADDED TO ALL PAST DUE INVOICES CUSTOMER WILL BE RESPONSIBLE FOR ALL LEGAL AND COLLECTION FEES.
              CUSTOMER IS RESPONSIBLE FOR ALL APPLICABLE SALES TAX.
              ALL RETURNS OR EXCHANGES MUST BE MADE WITHIN 30 DAYS WITH A RECEIPT.
            </p>
            <div *ngIf="invoice?.company?.disclaimer" >
                <div [innerHTML]="invoice?.company?.disclaimer"></div>
            </div>

          </tr>
      </tbody>
  </table>
</div>
