import { HttpHandler,  HttpInterceptor,  HttpRequest,  HttpResponse, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../helper-services/loader.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private NUM_OF_MS_UNTIL_SPINNER_LOADS = 250;

  constructor(private loader: LoaderService, private loadingBarService: LoadingBarService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let delayExpired = false;
    /* Set a timeout on the spinner, reason being if the request is too fast we do not
    want to flicker the spinner */
    this.loadingBarService.start();
    const _timeout = setTimeout(() => {
      delayExpired = true;
      this.totalRequests++;
      // Show spinner after delay
      this.loader.onRequestStarted();
      this.loadingBarService.increment(this.totalRequests);
    }, this.NUM_OF_MS_UNTIL_SPINNER_LOADS);

    return next.handle(request)
      .pipe(
        catchError((err) => {
          clearTimeout(_timeout);
          throw err;
        }),
        finalize<HttpResponse<any>>(() => {
          clearTimeout(_timeout);
          this.loadingBarService.complete();
          if (delayExpired) {
            // Hide spinner
            this.decreaseRequests();
          }
        }),
      );
  }

  private decreaseRequests(isCanadaPostRequest: boolean = false) {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loader.onRequestFinished();
    }
  }
}
