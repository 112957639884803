import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { CompanyPermission } from '@otrack-lib/models/company/company.model';

@Injectable({	providedIn: 'root', })
export class PermissionService {

  constructor(private userService: UserService) {}

  allowed(permission: CompanyPermission): boolean {
    return this.userService.user && this.userService.user.company.permissions.indexOf(permission) !== -1;
  }
}
