import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@otrack-lib/core/services/auth.service';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { PosConfirmationDialogComponent } from '../pos-confirmation-dialog/pos-confirmation-dialog.component';

@Component({
  selector: 'pos-entry',
  templateUrl: './pos-entry.component.html',
  styleUrls: ['./pos-entry.component.scss']
})
export class PosEntryComponent implements OnInit {
  dateTime$: Observable<Date>;
  form: FormGroup;
  loading = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly posService: PointOfSaleService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    // get posShifts from resolver
    if (this.route.snapshot.data.shiftDetails && this.route.snapshot.data.shiftDetails.shiftId) {
      this.router.navigate(['/pos/home']);
    }

    this.loading = false;

    this.dateTime$ = timer(0, 1000).pipe(map(() => new Date()));
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      startingCash: [null, Validators.min(0)]
    });
  }

  onSubmit() {
    this.loading = true;
    const controls = this.form.controls;
    const startingCash = controls.startingCash.value;
    this.posService.startShift(startingCash).subscribe(
      res => {
        if (res) {
          this.router.navigate(['/pos/home']);
        }

        this.loading = false;
      },
      err => {
        this.loading = false;
      });
  }

  onLogoutClicked() {
    const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
      data: {
        title: 'Confirm Logout',
        message: 'Are you sure you want to logout?'
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.confirm) {
        this.logout();
      }
    });
  }
  async logout() {
    await this.authService.logout();
  }

  get companyName() {
    const company = this.userService.getUserCompany();
    return company.name ? company.name : this.username;
  }

  get username() {
    const user = this.userService.user;
    return user.displayName ? user.displayName : user.username;
  }
}
