<div class="flex-container invoice print-payment" >
  <div class="adjustment-container">
    <table style="margin-bottom: 10px;">
      <tr class="">
        <td>
          <div>
            <div class="row">
              <div class="col-lg-4">
                Date
              </div>
              <div class="col-lg-4">
                {{ adjustment?.adjustmentDate | date:'yyyy/MM/dd' }}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                Reason
              </div>
              <div class="col-lg-4">
                {{ adjustment?.reason  }}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                Adjustment Type
              </div>
              <div class="col-lg-4">
                {{ adjustment?.adjustmentType === adjustmentType.QuantityAdjustment ? 'Quantity' :  'Value'  }}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                Adjusted By
              </div>
              <div class="col-lg-4">
                {{ adjustment?.adjustedBy | uppercase  }}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                Refernce #
              </div>
              <div class="col-lg-4">
                {{ adjustment?.refNum  }}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div style="font-size: 1.1rem;  font-weight: 400;  padding: 20px 50px 20px 50px;">

    <table>
      <thead>
        <tr class="invoice-header">
          <th style="text-align: left; padding-left: 10px;background: #c1c1c1;">Item Details</th>
          <th style="text-align: right; padding-right: 10px;background: #c1c1c1;">Adjusted Value</th>
        </tr>
      </thead>
      <tbody>
        <tr class="invoice-items" *ngFor="let item of adjustment?.lineItems">
          <td style="text-align: left; padding: 10px 10px 10px 10px;"> {{ item.productName }} <br> {{ item.description }}</td>
          <td *ngIf="adjustment?.adjustmentType === adjustmentType.QuantityAdjustment" style="text-align: right; padding: 10px 10px 10px 10px;"> {{ item.adjustedValue }} </td>
          <td *ngIf="adjustment?.adjustmentType !== adjustmentType.QuantityAdjustment" style="text-align: right; padding: 10px 10px 10px 10px;"> {{ item.adjustedValue | currency}} </td>
        </tr>
      </tbody>
    </table>
    <table>
      <tr *ngIf="adjustment?.memo">
        <td>
          <div class="disclaimer-heading">
            MEMO:
          </div>
          <p class="memo-text">
            {{ adjustment?.memo }}
          </p>
        </td>
      </tr>
    </table>
  </div>

</div>
