<div class="kt-portlet" [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : false}">
  <div class="kt-portlet__head kt-portlet__head__custom modal-header gradient-modal-header">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title" style="color:white !important; font-size: 2.0rem; font-weight: 600;">End Shift</h3>
    </div>
  </div>
  <form class="kt-form" [formGroup]="form">
    <mat-dialog-content class="mat-dialog-style">
      <div class="kt-portlet__body modal-body p-0">

        <div class="form-group kt-form__group row p-0 mr-0 ml-0">
          <div *ngIf="!closed" class="col-lg-8 kt-margin-bottom-20-mobile amount-input-container">
            <div class="form-group row" style="margin-bottom: 0px;">
              <label  class="col-5 col-form-label">Enter Closing Cash in Hand</label>
              <div class="col-7">
                <input class="form-control form-item rounded-input" type="number" placeholder="0.00" formControlName="closingCash" name="closingCash" />
              </div>
             </div>
          </div>

          <div *ngIf="posShiftSummary" class="col-lg-12 kt-margin-bottom-20-mobile amount-input-container">
            <pos-z-report [posShiftSummary]="posShiftSummary" [closed]="closed" [closingCash$]="closingCash$"></pos-z-report>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
      <div class="kt-form__actions kt-form__actions--solid modal-footer">
        <div class="row text-right">
          <div class="col-lg-12">
            <button type="button" mat-raised-button class="rounded-button cancel-button" (click)="onClose()" cdkFocusInitial matTooltip="Cancel changes" [disabled]="loading"> {{ closed ? 'Close' : 'Cancel' }} </button>
            <button *ngIf="!closed" type="button" mat-raised-button class="rounded-button save-button" [class.spinner]="loading" (click)="onSubmit()" [disabled]="form.invalid || loading"> End Shift & Print </button>
            <button *ngIf="closed" type="button" mat-raised-button class="rounded-button save-button" (click)="onPrint()"> Print </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
