import { AngularFireAuth } from '@angular/fire/auth';
import { getMenuByRouteId, HeaderMenu } from './../common/routes-consts';
// Angular
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum POSKeyboardScreen  {
  Screen1 = 'screen_1',
  Screen2 = 'screen_2'
}

@Injectable({ providedIn: 'root' })
export class UserPreferenceService {

  private posKeyboardScreen$ = new BehaviorSubject<POSKeyboardScreen>(POSKeyboardScreen.Screen1);
  private userId: string;

  constructor(private firestore: AngularFirestore,  private afAuth: AngularFireAuth) {
    //  this.afAuth.authState.subscribe(user => {
    //     if  ( user) {
    //       this.userId = user.uid;
    //     }
    //  });
  }

  fetchUserPreference(): Observable<any> {
    return this.firestore.collection(`userPOSUIPreferences`).doc(this.userId).valueChanges();
  }

  fetchUserPOSKeyboardPreferences(inUserId: string): Observable<POSKeyboardScreen> {
    this.userId = inUserId;
    return this.fetchUserPreference().pipe(map((data: any) => {
      this.posKeyboardScreen$.next(data ? data.posKeyboardScreen : POSKeyboardScreen.Screen1);
      return data ? data.posKeyboardScreen : POSKeyboardScreen.Screen1;
    }));
  }

  getUserPreferedKeyboardScreen(): Observable<POSKeyboardScreen> {
    return this.posKeyboardScreen$.asObservable();
  }

  setUserPreferedKeyboardScreen(posKeyBoardScreen: POSKeyboardScreen):  Promise<any> {
    return this.firestore.collection('userPOSUIPreferences').doc(this.userId).set({posKeyboardScreen: posKeyBoardScreen});
  }

}
