import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@admin-app/shared/shared.module';

import { ModalEmailViewComponent } from './modal-email-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { TemplateModule } from '@otrack-lib/common/templates/template.module';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    TemplateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ModalEmailViewComponent
  ],
  exports: [
    ModalEmailViewComponent
  ]
})
export class ModalEmailViewModule { }
