import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { IItemQuickSummaryRpt } from '@otrack-lib/models/reports/item-quick-summary.model';
import * as moment from 'moment';

@Component({
  selector: 'app-print-item-detail-report',
  templateUrl: './print-item-detail-report.component.html',
  styleUrls: ['./print-item-detail-report.component.scss']
})
export class PrintItemDetailReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  items: IItemQuickSummaryRpt[];
  totalQuantity = 0;
  totalCost = 0;
  totalAverageCost = 0;
  user: IAuthUser;

  constructor(
    userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchItemOrderSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchItemOrderSummaryReport() {
    this.reportFilter.toDate = moment(this.reportFilter.toDate).format(moment.HTML5_FMT.DATE);
    this.reportService.getItemQuickSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.items = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.totalQuantity = this.items.map(t => t.quantity).reduce((acc, value) => acc + value, 0);

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateInString(): string {

    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(toDate)
        .format('YYYY-MMM-DD')
    );
  }

}
