import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { PrintService } from "@otrack-lib/core/helper-services/print.service";
import { ReportService } from "@otrack-lib/core/services/report.service";
import { UserService } from "@otrack-lib/core/services/user.service";
import { IAuthUser } from "@otrack-lib/models/company/auth-user.model";
import { ReportFiltersModel } from "@otrack-lib/models/report-filters.model";
import { ZReportModel } from '@otrack-lib/models/reports/z-report.model';
import * as moment from "moment";

@Component({
  selector: 'print-z-report-list',
  templateUrl: './print-z-report-list.component.html',
  styleUrls: ['./print-z-report-list.component.scss']
})
export class PrintZReportListComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  items: ZReportModel[];
  user: IAuthUser;

  constructor(
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem("report_print"));
    if (this.reportFilter) {
      this.fetchItemOrderSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchItemOrderSummaryReport() {
    this.reportService.getZReports(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.items = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem("report_print");
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
