export enum ChargeStatusType {
  None = 'none',
  Pending = 'pending',
  Successed = 'successed',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed'
}

export enum DeliveryType {
  None = 'none',
  PickUp = 'pickup',
  Delivery = 'deliver'
}

export enum DeliveryStatus {
  PENDING = 'pending',
  ON_THE_WAY = 'on_the_way',
  DELIVERED = 'delivered',
  ASSIGNED = 'assigned'
}
