<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> As of {{ this.reportFilter ? getDateInString() : '' }} </h5>
    <h5> Inventory Summary </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left; padding-left: 5px;">CATEGORY</th>
        <th style="text-align: left;padding-left: 5px;">ITEM</th>
        <th style="text-align: left; padding-left: 5px;">DESCRIPTION</th>
        <th style="text-align: left; padding-left: 5px;">BARCODE</th>
        <th style="text-align: right; padding-right: 5px;">QUANTITY</th>
        <th style="text-align: right; padding-right: 5px;">AVG. COST</th>
        <th style="text-align: right; padding-right: 5px;">TOTAL VALUE</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items"
          *ngFor="let item of items">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.categoryName }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.productName }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.description }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;">
          <app-barcode-generator *ngIf="item.barcode && item.barcode.length > 7"
                                 [barcodeOptions]="barcodeOptions"
                                 [value]="item.barcode"></app-barcode-generator>
        </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.quantity }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.averageCost | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.value | currency }} </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: left;padding-left: 5px;"></td>
        <td style="text-align: right;padding-right: 5px;">{{ totalQuantity | number : '1.2-2' }}</td>
        <td style="text-align: right;padding-right: 5px;"></td>
        <td style="text-align: right;padding-right: 5px;"> {{ ( totalAverageCost ) | currency }} </td>
      </tr>
    </tfoot>

  </table>
</div>