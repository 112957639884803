<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> {{ this.reportFilter ? getToDateOnlyString() : '' }} </h5>
    <h5> Vendor Aging Summary </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left; padding-left: 5px;">VENDOR</th>
        <th style="text-align: right; padding-right: 5px;">CURRENT</th>
        <th style="text-align: right; padding-right: 5px;">1 to 30</th>
        <th style="text-align: right; padding-right: 5px;">31 to 60</th>
        <th style="text-align: right; padding-right: 5px;">61 to 90</th>
        <th style="text-align: right; padding-right: 5px;">> 90</th>
        <th style="text-align: right; padding-right: 5px;">TOTAL</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let report of transactions">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{report.vendorName}} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.current | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.oneToThirty | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.thirtyOneToSixty | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.sixtyOneToNinety  | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.moreThanNinety  | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{report.total  | currency }} </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="invoice-footer">
        <td style="text-align: left;padding-left: 5px;">TOTAL</td>
        <td style="text-align: right;padding-right: 5px;"> {{ currentTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ oneToThirtyTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ thirtyOneToSixtyTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ sixtyOneToNinetyTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ moreThanNinetyTotal | currency }} </td>
        <td style="text-align: right;padding-right: 5px;"> {{ totalTotal | currency }} </td>
      </tr>
    </tfoot>

  </table>
</div>
