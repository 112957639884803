import { Inject, Injectable, InjectionToken } from '@angular/core';
import { IConfiguration } from '@otrack-lib/models/configuration.model';

export const ConfigurationConfigService = new InjectionToken<IConfiguration>('Configuration');

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {

    constructor(@Inject(ConfigurationConfigService) private configuration: IConfiguration) { }

    get config(): IConfiguration {
        return this.configuration;
    }
}
