import { FormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { PointOfSaleTrailService } from '@otrack-lib/core/services/point-of-sale-trail.service';
import { PaymentMethodTypes } from '@otrack-lib/enums';
import { BusinessType } from '@otrack-lib/enums/business-type.enum';
import { ICompany } from '@otrack-lib/models/company/company.model';
import { ICustomerLastPrice } from '@otrack-lib/models/customer/customer-last-price.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import {
  IPosLineItem,
  IPosOrder,
  IPosOrderItem,
  IPosOrderPayment,
  IPosPrice,
  PosPriceType,
  IPosTrailItem
} from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { IProduct } from '@otrack-lib/models/product/product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IInternalPayment } from '../pos-payment/pos-payment.component';

@Injectable()
export class PointOfSaleHelperService {

  public readonly QTY_MIN = 1;
  public readonly QTY_MAX = 9999;

  constructor(
    private readonly trailService: PointOfSaleTrailService,
    private readonly fb: FormBuilder
  ) { }

  public createPosLineItemFromProduct(
    product: IProduct,
    customer: ICustomer,
    userCompany: ICompany,
    customerLastPrices: ICustomerLastPrice[],
    currentPrice?: number,
    shiftId?: number,
    currentOrderItems?: IPosLineItem[]
  ): Observable<IPosLineItem> {
    const priceListAndPrice = this.createPriceListAndPrice(product, customer, userCompany, customerLastPrices);
    const newItem: IPosLineItem = {
      sortOrder: 0,
      productId: product.productId,
      productName: product.name,
      productDescription: product.description,
      price: (currentPrice && currentPrice > 0) ? currentPrice : priceListAndPrice.price,
      prices: priceListAndPrice.priceList,
      isTaxable: product.isTaxable,
      taxPercent: (product.isTaxable && customer.isTaxable) ? (customer.taxPercent / 100) : 0,
      quantity: 1,
      id: this.getNewId(currentOrderItems)
    };

    return this.addItemTrail(shiftId, newItem, product, customer, currentOrderItems);
  }

  public createLineItemFromProductAndOrderItem(
    product: IProduct,
    customer: ICustomer,
    userCompany: ICompany,
    customerLastPrices: ICustomerLastPrice[],
    item: IPosOrderItem,
    currentOrderItems?: IPosLineItem[]
  ): IPosLineItem {
    const priceListAndPrice = this.createPriceListAndPrice(product, customer, userCompany, customerLastPrices);
    const newItem: IPosLineItem = {
      sortOrder: item.sortOrder,
      lineNo: item.lineNo,
      productId: product.productId,
      productName: product.name,
      productDescription: product.description,
      price: item.price,
      prices: priceListAndPrice.priceList,
      isTaxable: product.isTaxable,
      taxPercent: (product.isTaxable && customer.isTaxable) ? (customer.taxPercent / 100) : 0,
      quantity: item.quantity
    };
    newItem.id = this.getNewId(currentOrderItems);

    newItem.formGroup = this.fb.group({
      qty: [item.quantity, [Validators.required]]
    });

    if (currentOrderItems) {
      currentOrderItems.push(newItem);
    }

    return newItem;
  }

  public createLineItemFromProductAndPendingTransactionItem(
    product: IProduct,
    customer: ICustomer,
    userCompany: ICompany,
    customerLastPrices: ICustomerLastPrice[],
    item: IPosTrailItem,
    currentOrderItems?: IPosLineItem[]
  ): IPosLineItem {
    const priceListAndPrice = this.createPriceListAndPrice(product, customer, userCompany, customerLastPrices);
    const newItem: IPosLineItem = {
      lineNo: item.lineNo,
      productId: product.productId,
      productName: product.name,
      productDescription: product.description,
      price: item.price,
      prices: priceListAndPrice.priceList,
      isTaxable: (product.isTaxable),
      taxPercent: (product.isTaxable && customer.isTaxable) ? (customer.taxPercent / 100) : 0,
      quantity: item.quantity
    };
    newItem.id = this.getNewId(currentOrderItems);

    newItem.formGroup = this.fb.group({
      qty: [item.quantity, [Validators.required]]
    });

    if (currentOrderItems) {
      currentOrderItems.push(newItem);
    }

    return newItem;
  }

  private createPriceListAndPrice(
    product: IProduct,
    customer: ICustomer,
    userCompany: ICompany,
    customerLastPrices: ICustomerLastPrice[]
  ): { priceList: IPosPrice[], price: number } {
    const priceList: IPosPrice[] = [];
    const lastPrice = customerLastPrices.find(p => p.productId === product.productId);

    let price = product.basePrice ? product.basePrice : product.price;

    // add base or sale price
    priceList.push({
      name: 'Sale Price',
      type: PosPriceType.BASE_PRICE,
      price: product.basePrice ? product.basePrice : product.price
    });


    // add price from pricing tier
    // check if customer has pricing tier id
    // check if product has pricing tier
    if (customer.priceTierId && product.pricingTiers && product.pricingTiers.length > 0) {
      const _price = product.pricingTiers.find(pt => pt.tierId === customer.priceTierId);
      if (_price) {
        priceList.push({
          name: `Tier Price: ${customer.priceTierName}`,
          type: PosPriceType.TIER_PRICE,
          price: _price.price
        });
        price = _price.price;
      }
    }

    // add last price
    if (lastPrice) {
      priceList.push({
        name: 'Last Price',
        type: PosPriceType.LAST_PRICE,
        price: lastPrice.lastPrice
      });
      price = lastPrice.lastPrice;
    }

    // add promotion price in case of business type is B2C (maybe we don't need to check business Type??)
    if (userCompany && userCompany.businessType === BusinessType.B2C && product.promotionPrice) {
      priceList.push({
        name: 'Promotion Price',
        type: PosPriceType.PROMO_PRICE,
        price: product.promotionPrice
      });
      price = product.promotionPrice;
    }

    return { priceList, price };
  }

  public createOrderPaymentPayload(order: IPosOrder, shiftId: number, payment: IInternalPayment, orderDate: string): IPosOrderPayment {
    const paymentLines = [];
    let memo = '';

    memo += this.updateMemo(`$${order.netTotal.toFixed(2)} NET TOTAL`);
    if (payment.cashAmount > 0) {
      paymentLines.push({ paymentMethod: PaymentMethodTypes.Cash, amount: payment.cashAmount });
    }

    if (payment.cashAmount > 0) {
      memo += this.updateMemo(`$${payment.cashAmount + Math.abs(payment.dueOrChangeAmount)} AMOUNT PAID BY CASH`);
    }

    if (payment.creditCardAmount > 0) {
      // FIX this UI doesn't support multiple credit card companies
      paymentLines.push({ paymentMethod: PaymentMethodTypes.Visa, amount: payment.creditCardAmount });
      memo += this.updateMemo(`$${payment.creditCardAmount} AMOUNT PAID BY CREDIT_CARD`);
    }

    payment.checkPayments.forEach(p => {
      if (p.amount > 0) {
        paymentLines.push({ paymentMethod: PaymentMethodTypes.Check, amount: p.amount, description: p.description });
        memo += this.updateMemo(`$${p.amount} AMOUNT PAID BY CHECK # ${p.description}`);
      }
    });

    if (payment.accountAmount > 0) {
      memo += this.updateMemo(`$${payment.accountAmount} CHARGE ACCOUNT`);
    }

    const orderLines = [];
    for (let idx = 0; idx < order.lines.length; idx++) {
      const line = order.lines[idx];
      orderLines.push({
        productId: line.productId,
        quantity: line.quantity,
        price: line.price
      });
    }

    memo += this.updateMemo(`$${payment.dueOrChangeAmount} ${payment.dueOrChangeAmount < 0 ? 'CHANGE DUE' : 'AMOUNT DUE'}`);

    return {
      shiftId,
      order: {
        isTaxable: order.isTaxable,
        customerId: order.customerId,
        lines: orderLines,
        memo
      },
      payment: {
        lines: paymentLines
      },
      orderDate
    };
  }

  public createHoldPayload(order: IPosOrder, shiftId: number): IPosOrderPayment {
    const orderLines = [];
    for (let idx = 0; idx < order.lines.length; idx++) {
      const line = order.lines[idx];
      orderLines.push({
        productId: line.productId,
        quantity: line.quantity,
        price: line.price
      });
    }

    return {
      shiftId,
      order: {
        isTaxable: order.isTaxable,
        customerId: order.customerId,
        lines: orderLines
      }
    };
  }

  public getNewId(currentOrderItems: any[]): number {
    return currentOrderItems.length > 0 ? currentOrderItems.length : 0;
  }

  public normalizeQuantity(value: number): number {
    if (!value || value < this.QTY_MIN) {
      return this.QTY_MIN;
    }

    if (value > this.QTY_MAX) {
      return this.QTY_MAX;
    }

    return value;
  }

  public recalculateItems(customer: ICustomer, currentOrderItems?: IPosLineItem[]): void {
    currentOrderItems.forEach(item => {
      item.taxPercent = (item.isTaxable && customer.isTaxable) ? (customer.taxPercent / 100) : 0;
    });
  }

  private updateMemo(text: string): string {
    return text + '\n';
  }

  // ======================== TRAIL METHODS ===============================

  public addItemTrail(shiftId: number, item: IPosLineItem, product: IProduct, customer: ICustomer, currentOrderItems?: IPosLineItem[]): Observable<IPosLineItem> {
    const data = {
      productId: item.productId,
      customerId: customer.customerId,
      quantity: item.quantity,
      price: item.price,
      taxable: product.isTaxable
    };
    return this.trailService.addItemTrail(shiftId, data).pipe(
      map(res => {
        if (res && currentOrderItems) {
          currentOrderItems.push(item);

          item.lineNo = res.lineNo;
          item.formGroup = this.fb.group({
            qty: [item.quantity, [Validators.required]]
          });
          return item;
        }
      })
    );
  }

  public changeItemQuantityTrail(shiftId: number, item: IPosLineItem, customer: ICustomer, qty: number): Observable<IPosLineItem> {
    const data = {
      lineNo: item.lineNo,
      productId: item.productId,
      customerId: customer.customerId,
      quantity: this.normalizeQuantity(qty),
      price: item.price,
      taxable: item.isTaxable
    };
    return this.trailService.changeItemQtyTrail(shiftId, data).pipe(
      map(res => {
        if (res) {
          item.quantity = res.quantity;
          if (item.formGroup) {
            item.formGroup.controls.qty.patchValue(item.quantity, false);
          }
          return item;
        }
      })
    );
  }

  public changeItemPriceTrail(shiftId: number, item: IPosLineItem, customer: ICustomer, newPrice: number): Observable<IPosLineItem> {
    const data = {
      lineNo: item.lineNo,
      productId: item.productId,
      customerId: customer.customerId,
      quantity: item.quantity,
      price: newPrice,
      taxable: item.isTaxable
    };
    return this.trailService.changeItemPriceTrail(shiftId, data).pipe(
      map(res => {
        if (res) {
          item.price = res.price;
          return item;
        }
      })
    );
  }

  public deleteItemTrail(shiftId: number, item: IPosLineItem, customer: ICustomer): Observable<IPosLineItem> {
    const data = {
      lineNo: item.lineNo,
      productId: item.productId,
      customerId: customer.customerId,
      quantity: item.quantity,
      price: item.price,
      taxable: item.isTaxable
    };
    return this.trailService.deleteItemTrail(shiftId, data).pipe(
      map(res => {
        if (res) {
          return item;
        }
      })
    );
  }

  public changeCustomerTrail(shiftId: number, customerId: number): Observable<boolean> {
    return this.trailService.changeCustomerTrail(shiftId, { customerId });
  }

  public voidSales(shiftId: number): Observable<boolean> {
    return this.trailService.voidSaleTrail(shiftId);
  }
}
