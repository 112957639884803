<div class="div-balance">
  <ng-container *ngIf="invoiceInfo.invoiceType !== salesOrderType">
    <span class="balance">Balance Due</span>
    <span class="balance-amt">{{ invoiceInfo.amountDue }}</span>
  </ng-container>

  <ng-container *ngIf="invoiceInfo.invoiceType === salesOrderType" >
    <span class="balance">Status</span>
  <span class="balance-amt">{{ orderStatus }}</span>
  </ng-container>
</div>

<kt-timeline2 [data]="data"></kt-timeline2>