import { IStockSummaryRpt } from '@otrack-lib/models/reports/stock-summary.model';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ReportService} from '@otrack-lib/core/services/report.service';
import {PrintService} from '@otrack-lib/core/helper-services/print.service';
import {ReportFiltersModel} from '@otrack-lib/models/report-filters.model';
import {UserService} from '@otrack-lib/core/services/user.service';
import {IAuthUser} from '@otrack-lib/models/company/auth-user.model';
import * as moment from 'moment';

@Component({
  selector: 'app-print-stock-summary-report',
  templateUrl: './print-stock-summary-report.component.html',
  styleUrls: ['./print-stock-summary-report.component.scss']
})
export class PrintStockSummaryReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  items: IStockSummaryRpt[];
  totalQuantity = 0;
  totalCost = 0;
  totalAverageCost = 0;
  user: IAuthUser;
  barcodeOptions = {
    'width': 2,
    'height': 22,
    'fontSize': 14,
    'elementType': 'svg'
  };

  constructor(
    userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchItemOrderSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchItemOrderSummaryReport() {
    this.reportService.getStockSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.items = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {    
    this.totalQuantity = this.items.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
    this.totalAverageCost = this.items.map(t => t.value).reduce((acc, value) => acc + value, 0);

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateInString(): string {

    const toDate = new Date(this.reportFilter.toDate);

    return (
       moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
