import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
import { IAddress } from '../order/delivery-info.model';
import { IInvoice } from '../order/invoice.model';
import { ITransaction } from '../order/transaction.model';
export interface ICustomer {
  customerId: number;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  customerName?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country?: string;
  phoneNo?: number;
  email?: string;
  faxNo?: number;
  taxPercent?: number;
  taxId?: number;
  isTaxable?: boolean;
  openBalance?: number;
  quickBookId?: string;
  isQBCustomer?: boolean;
  isQBSynced?: boolean;
  isActive?: boolean;
  notes?: string;
  priceTierId?: number;
  priceTierName?: string;
  saleOrderStatus?: SalesOrderStatus;
  shippingAddress?: IAddress;
  billingAddress?: IAddress;
  activeOrderId?: number;
  salesTaxId?: number;
  salesTaxIdExpiryDate?: string;
  tobaccoPermitId?: number;
  tobaccoPermitIdExpiryDate?: string;
  deliveryCertificateNumber?: number;
  deliveryCertificateNumberExpiryDate?: string;
  tabcId?: number;
  tabcIdExpiryDate?: string;
  onlineAccess?: CustomerOnlineAccessType;
}

export interface ICustomerDetail extends ICustomer {
  salesOrders?: IInvoice[];
  transactions?: ITransaction[];
}

export enum CustomerOnlineAccessType {
  INVITED = 'invited',
  YES = 'yes',
  NO = 'no'
}

