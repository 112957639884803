import { IPricingTier } from '../../models/product/pricing.tier.model';
import { Injectable } from '@angular/core';
import { BaseApiService } from './baseapi.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { IUser, IUserList } from '@otrack-lib/models/user-management/user.model';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { IRole } from '@otrack-lib/models/user-management/role.model';
import { IPermission, IApplicationPermission } from '@otrack-lib/models/user-management/permission.model';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends BaseApiService {
  public user: IUser;
  pricingTier$ = new BehaviorSubject<IPricingTier[]>([]);

  readonly API_URLS = {
    users: `${this.baseApiUrl}/v1/users`,
    roles: `${this.baseApiUrl}/v1/roles`,
    permissions: `${this.baseApiUrl}/v1/permissions`,
  };

  static getUserListModel(res: any): IUserList {
    return {
      id: res['id'],
      email: res['email'],
      firstName: res['firstName'],
      lastName: res['lastName'],
      fullName: res['fullName'],
      role: res['role'],
      roleId: res['roleId'],
      isPrimary: res['isPrimary']
    };
  }

  static getUserModel(res: any): IUser {
    return {
      id: res['id'],
      email: res['email'],
      firstName: res['firstName'],
      lastName: res['lastName'],
      roleId: res['roleId']
    };
  }

  static getRoleListModel(res: any): IRole {
    return {
      id: res['id'],
      name: res['name'],
      description: res['description'],
      isCoreRole: res['isCoreRole'],
      permissions: res['permissions']
    };
  }

  static getApplicationPermissionsModel(res: any): IApplicationPermission {
    const applicationPermission: IApplicationPermission = {
      application: res['application'],
      permissions: []
    };

    for (const item of res['permissions']) {
      applicationPermission.permissions.push(<IPermission>{
        id: +item['id'],
        name: item['name'],
        description: item['description'],
        shortCode: item['shortCode'],
        parentId: +item['parentId'],
      });
    }
    return applicationPermission;
  }

  getUserList(): Observable<QueryResultsModel> {
    return this.httpAuthGet(`${this.API_URLS.users}`).pipe(
      map(res => {
        const output: IUserList[] = [];
        for (const item of res.data) {
          output.push(UserManagementService.getUserListModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }


  getAllEmployees(): Observable<IUserList[]> {
    return this.httpAuthGet(`${this.API_URLS.users}`).pipe(
      map(res => {
        const output: IUserList[] = [];
        for (const item of res.data) {
          output.push(UserManagementService.getUserListModel(item));
        }
        return output;
      })
    );
  }


  updateUser(user: IUser) {
    return this.httpAuthPut(`${this.API_URLS.users}/${user.id}`, user);
  }

  addUser(user: IUser): Observable<number> {
    return this.httpAuthPost(`${this.API_URLS.users}`, user).pipe(
      map(res => <number>res)
    );
  }

  updateRole(role: IRole) {
    return this.httpAuthPut(`${this.API_URLS.roles}/${role.id}`, role);
  }

  addRole(role: IRole): Observable<number> {
    return this.httpAuthPost(`${this.API_URLS.roles}`, role).pipe(
      map(res => <number>res)
    );
  }

  deleteRole(roleId: number) {
    return this.httpAuthDelete(`${this.API_URLS.roles}/${roleId}`);
  }

  getRoles(): Observable<IRole[]> {
    return this.httpAuthGet(`${this.API_URLS.roles}`).pipe(
      map(res => {
        const output: IRole[] = [];
        for (const item of res.data) {
          output.push(UserManagementService.getRoleListModel(item));
        }
        return output;
      })
    );
  }

  getAllPermissions(): Observable<IApplicationPermission[]> {
    return this.httpAuthGet(`${this.API_URLS.permissions}`).pipe(
      map(res => {
        const output: IApplicationPermission[] = [];
        for (const item of res.data) {
          output.push(UserManagementService.getApplicationPermissionsModel(item));
        }
        return output;
      })
    );
  }

  setUserPassword(userId: number, password: string): Observable<boolean> {
    const dataToSend = {
      password : password
    };

    return this.httpAuthPut(`${this.API_URLS.users}/${userId}/password`, dataToSend)
      .pipe(map(res => {
        return true;
      }));
  }


}
