import { ColumnPreferenceService } from './../../services/column-preference.service';
import { MenuDBService } from './../../services/menu-db.service';
// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '@admin-app/common/layout-core';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '@admin-app/shared/config/layout.config';
import { MenuConfig } from '@admin-app/shared/config/menu.config';
import { PageConfig } from '@admin-app/shared/config/page.config';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
  // Public variables
  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  desktopHeaderDisplay: boolean;
  fitTop: boolean;
  fluid: boolean;
  menuConfig: any;

  // Private properties
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  // private currentUserPermissions$: Observable<Permission[]>;



  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param menuConfigService: MenuConfifService
   * @param pageConfigService: PageConfigService
   * @param htmlClassService: HtmlClassService
   * @param store
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private menuDBService: MenuDBService,
    private columnPreferenceService: ColumnPreferenceService,
    private pageConfigService: PageConfigService,
    private afAuth: AngularFireAuth,
    private htmlClassService: HtmlClassService) {
    this.loadRolesWithPermissions();

    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfig = new MenuConfig().configs;
    this.menuConfigService.loadConfigs(this.menuConfig);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
      // reset body class based on global and page level layout config, refer to html-class.service.ts
      document.body.className = '';
      this.htmlClassService.setConfig(layoutConfig);
    });
    this.unsubscribe.push(subscr);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');
    this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
    this.fitTop = objectPath.get(config, 'content.fit-top');
    this.fluid = objectPath.get(config, 'content.width') === 'fluid';

    // let the layout type change
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout');
      });
    });
    this.unsubscribe.push(subscr);

    this.afAuth.authState.subscribe(user => {
        if  ( user) {
          const favoriteHeaderSubsc = this.menuDBService.fetchUserHeaderPreferences(user.uid).subscribe(headerMenu => {
            if (headerMenu && headerMenu.length > 0) {
              //   this.menuConfig.header.items\
              this.menuConfigService.loadConfigs({ ...this.menuConfig, header: { ...this.menuConfig.header, items: [...this.menuConfig.header.items, ...headerMenu] } });
            }
          });
          this.unsubscribe.push(favoriteHeaderSubsc);

          const columnPreferenceSubsc = this.columnPreferenceService.fetchUserColumnsPreferences(user.uid).subscribe();
          this.unsubscribe.push(columnPreferenceSubsc);
        }
     });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  /**
   * NGX Permissions, init roles
   */
  loadRolesWithPermissions() { }
}
