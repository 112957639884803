export enum ActiveFilterTypes {
  All = -1,
  Active = 0,
  InActive = 1
}

export enum QBFilterTypes {
  All = -1,
  QB = 0,
  NonQB = 1
}

export enum TaxableFilterTypes {
  All = -1,
  Taxable = 0,
  NonTaxable = 1
}
