export enum PaymentMethodTypes {
  All = -1,
  Other = 0,
  Cash = 1,
  Check = 2,
  MoneyOrder = 3,
  AmericanExpress = 4,
  MasterCard = 5,
  Visa= 6,
  Discover = 7,
  DebitCard = 8,
  GiftCard = 9 ,
  ECheck = 10
}

export enum CompanyPaymentAllowedMethod {
  CreditCard = 'credit_card',
  CashOnDelivery = 'cod'
}

