import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { ICompany, CompanyPermission, InvoiceTemplateType } from '@otrack-lib/models/company/company.model';
import { ErrorModel } from '@otrack-lib/models/error.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseApiService {
  companyInfo: ICompany = undefined;

  readonly API_URLS = {
    // profileDetail: `${this.baseApiUrl}/api/companyinfo`,
    companyDetail: `${this.baseApiUrl}/v1/company`,
    updateProfileInfo: `${this.baseApiUrl}/v1/companyinfo/edit`
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  static getCompanyModel(res: any): ICompany {
    return {
      companyId: +res['companyId'],
      ownername: res['ownername'],
      address: res['address'],
      city: res['city'],
      name: res['name'],
      country: res['country'],
      postalCode: res['postalCode'],
      state: res['state'],
      phoneNo: res['phoneNo'],
      email: res['replyToEmail'],
      isDemoCompany: res['isDemo'],
      isSetupCompleted: res['isSetupCompleted'],
      disclaimer: res['disclaimer'],
      sequences: {},
      logoUrl: res['logoUrl'],
      businessType: res['businessType'],
      deliveryMethods: res['deliveryMethods'],
      paymentMethods: res['paymentMethods'],
      invoiceTemplate: res['invoiceTemplate'] ? res['invoiceTemplate'] : InvoiceTemplateType.DefaultTemplate,
      paymentAccountId: res['paymentAccountId'],
      taxPercent: res['taxPercent'],
      usePickupSlots: res['usePickupSlots'],
      baseDomain: res['baseDomain'],
      pricingPolicy: res['pricingPolicy'],
      taxPermitId: res['taxPermitId'],
      tobaccoPermitNo: res['tobaccoPermitId'],
      permissions: res.permissions ? res.permissions.map((p: string) => CompanyPermission[p]) : [],
      highlightedTags: res.highlightedTags ? res.highlightedTags : []
    };
  }

  getCompanyDetail(): Observable<ICompany> {
    if (this.companyInfo === undefined) {
      return this.httpAuthGet(`${this.API_URLS.companyDetail}`).pipe(
        map(res => {
          if (res) {
            this.companyInfo = CompanyService.getCompanyModel(res);
            return this.companyInfo;
          }
          throw new ErrorModel(null, 'Person not found');
        })
      );
    }
    return of(this.companyInfo);
  }

  updateProfileInfo(companyData: ICompany, isSetupCompleted: boolean = true): Observable<ICompany> {
    const dataToSend = {
      companyId: companyData.companyId,
      name: companyData.name,
      ownername: companyData.ownername,
      address: companyData.address,
      phoneNo: companyData.phoneNo,
      city: companyData.city,
      state: companyData.state,
      postalCode: companyData.postalCode,
      replyToEmail: companyData.email,
      country: companyData.country,
      isSetupCompleted: isSetupCompleted,
      sequences: companyData.sequences,
    };
    return this.httpAuthPut(`${this.API_URLS.companyDetail}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return CompanyService.getCompanyModel(res);
        }
        throw new ErrorModel(null, 'Person not found');
      })
    );
  }

}
