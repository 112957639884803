import { LayoutUtilsService, MessageType } from '@admin-app/services/layout-utils.service';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@otrack-lib/core/facade/data.service';
import { ICompany } from '@otrack-lib/models/company/company.model';
import { ICustomerLastPrice } from '@otrack-lib/models/customer/customer-last-price.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { IPosLineItem, IPosOrderItem, IPosTrailItem } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { IProduct } from '@otrack-lib/models/product/product.model';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { PointOfSaleHelperService } from './../services/point-of-sale-helper.service';

@Component({
  selector: 'pos-product-search',
  templateUrl: './pos-product-search.component.html',
  styleUrls: ['./pos-product-search.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class PosProductSearchComponent implements OnInit, OnDestroy {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('barcodeinput', { static: false }) barcodeSearchInput: ElementRef;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() shiftId: number;
  @Input() currentOrderItems: IPosLineItem[];
  @Input() productList: IProduct[];
  @Input() userCompany: ICompany;
  @Input() customer$: BehaviorSubject<ICustomer>;
  @Input() customerLastPrices$: BehaviorSubject<ICustomerLastPrice[]>;
  @Input() autoFocus$: Observable<any>;
  @Input() restoreOrderProducts$: Observable<IPosOrderItem[]>;
  @Input() pendingTransactions$: Observable<IPosTrailItem[]>;
  @Output() itemUpdated: EventEmitter<IPosLineItem> = new EventEmitter();

  form: FormGroup;
  showBarcodeSearchField = true;
  private addOrUpdateItem$ = new BehaviorSubject<IProduct>(null);
  private subscriptions = new Subscription();
  private destroy$ = new Subject();

  constructor(
    private readonly dataService: DataService,
    private readonly helperService: PointOfSaleHelperService,
    private readonly fb: FormBuilder,
    private readonly layoutService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.form = this.createForm();
    if (this.restoreOrderProducts$) {
      const restoreOrderSub = this.subscribeToRestoreHoldOrder();
      this.subscriptions.add(restoreOrderSub);
    }

    if (this.pendingTransactions$) {
      const pendingTransactionsSub = this.subscribeToPendingTransactions();
      this.subscriptions.add(pendingTransactionsSub);
    }

    if (this.autoFocus$) {
      const focusSub = this.autoFocus$.subscribe(focus => {
        if (focus && focus.barcode) {
          setTimeout(() => {
            if (this.barcodeSearchInput) {
              this.barcodeSearchInput.nativeElement.focus();
            }
          });
        } else if (focus && focus.search) {
          setTimeout(() => {
            if (this.searchInput) {
              this.searchInput.nativeElement.focus();
            }
          });
        }
      });
      this.subscriptions.add(focusSub);
    }

    const addItemSub = this.subscribeToAddOrUpdateItem();
    this.subscriptions.add(addItemSub);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

    if (this.restoreOrderProducts$) {
      this.restoreOrderProducts$ = null;
    }

    if (this.pendingTransactions$) {
      this.pendingTransactions$ = null;
    }

    if (this.autoFocus$) {
      this.autoFocus$ = null;
    }
  }

  createForm(): FormGroup {
    return this.fb.group({
      productSearch: [],
      barcodeSearch: []
    });
  }

  searchProduct = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(debounceTime(500), filter(() => this.instance ? !this.instance.isPopupOpen() : false));
    const inputFocus$ = this.focus$.pipe(debounceTime(500));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        if (term.length < 2) {
          return [];
        } else {
          const result = this.productList.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            v.barcode === term).slice(0, this.productList.length);
          return result;
        }
      })

    );
  }

  formatter = (x: IProduct) => x.name;

  onItemSelected(event: { item: IProduct }) {
    this.addOrUpdateItem(event.item);

    const productSearch = this.form.controls.productSearch;
    if (productSearch) {
      setTimeout(() => {
        productSearch.patchValue('');
      }, 100);
    }
  }

  addOrUpdateItem(item: IProduct) {
    this.addOrUpdateItem$.next(item);
  }

  getNewId(): number {
    return this.currentOrderItems.length > 0 ? this.currentOrderItems.length : 0;
  }

  onEnterKeyPressed(event: any) {
    if (event.keyCode === 13 && event.target.value) {
      this.dataService.getProductUsingBarCode(event.target.value).subscribe(
        (item) => {
          if (item) {
            this.addOrUpdateItem(item);

          } else {
            this.layoutService.showNotification(
              `Product not found for bacode: ${event.target.value}`,
              MessageType.Error,
              10000
            );
          }
          this.updateFocus();
        },
        (error) => {
          this.layoutService.showNotification(
            `Product not found for bacode: ${event.target.value}`,
            MessageType.Error,
            10000
          );
          this.updateFocus();
        }
      );

      event.target.value = '';
    }
  }

  onScannerClicked() {
    this.showBarcodeSearchField = !this.showBarcodeSearchField;
    this.updateFocus();
  }

  updateFocus() {
    if (this.showBarcodeSearchField) {
      setTimeout(() => {
        this.barcodeSearchInput.nativeElement.focus();
      });
    } else {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      });
    }
  }

  onSearchProductBlur($event: any) {

  }

  subscribeToAddOrUpdateItem(): Subscription {
    return combineLatest([this.addOrUpdateItem$, this.customer$, this.customerLastPrices$])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(([product, customer, lastPrices]) => {
          if (product && customer) {
            // check if item already exists on last line, then increment quantity,
            // otherwise add a new line
            const lastIndex = this.currentOrderItems.length - 1;
            const existingItem = this.currentOrderItems[lastIndex];

            if (existingItem && existingItem.productId === product.productId) {
              // existingItem.quantity++;
              // return of(existingItem);
              return this.helperService.changeItemQuantityTrail(this.shiftId, existingItem, this.customer$.value, existingItem.quantity + 1);

            } else {
              return this.mapLineItemFromProduct(product, customer, lastPrices).pipe(catchError(error => EMPTY));
            }
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(
        item => {
          if (item) {
            this.itemUpdated.emit(item);
          }
          // clean the state of last product added
          // specially when product added using barcode text input
          this.addOrUpdateItem$.next(null);
        },
        error => {
          // fire and forget no need to show error
          this.addOrUpdateItem$.next(null);
        });
  }

  mapLineItemFromProduct(product: IProduct, customer: ICustomer, lastPrices: ICustomerLastPrice[]): Observable<IPosLineItem> {
    return this.helperService.createPosLineItemFromProduct(
      product,
      customer,
      this.userCompany,
      lastPrices,
      null,
      this.shiftId,
      this.currentOrderItems
    );
  }

  private subscribeToRestoreHoldOrder(): Subscription {
    return combineLatest([this.restoreOrderProducts$, this.customer$, this.customerLastPrices$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([order, customer, lastPrices]) => {
        if (order && order.length > 0 && customer) {
          order.forEach(item => {
            const product = this.productList.find(v => v.productId === item.productId);
            if (product) {
              const posItem = this.mapLineItemFromProductAndOrderItem(product, item, customer, lastPrices);
              this.itemUpdated.emit(posItem);
            }
          });
        }
      });
  }

  /**
   * Used when creating line items from Hold Order item and product.
   */
  private mapLineItemFromProductAndOrderItem(product: IProduct, item: IPosOrderItem, customer: ICustomer, lastPrices: ICustomerLastPrice[]): IPosLineItem {
    return this.helperService.createLineItemFromProductAndOrderItem(
      product,
      customer,
      this.userCompany,
      lastPrices,
      item,
      this.currentOrderItems
    );
  }

  private subscribeToPendingTransactions(): Subscription {
    return combineLatest([this.pendingTransactions$, this.customer$, this.customerLastPrices$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([pendingTransactions, customer, lastPrices]) => {
        if (pendingTransactions && pendingTransactions.length > 0 && customer && lastPrices) {
          pendingTransactions.forEach(item => {
            const product = this.productList.find(v => v.productId === item.productId);
            if (product) {
              const posItem = this.mapLineItemFromProductAndPendingTransactions(product, item, customer, lastPrices);
              this.itemUpdated.emit(posItem);
            }
          });
        }
      });
  }

  /**
   * Used when creating line items from Pending Transactions item and product.
   */
  private mapLineItemFromProductAndPendingTransactions(product: IProduct, item: IPosTrailItem, customer: ICustomer, lastPrices: ICustomerLastPrice[]): IPosLineItem {
    return this.helperService.createLineItemFromProductAndPendingTransactionItem(
      product,
      customer,
      this.userCompany,
      lastPrices,
      item,
      this.currentOrderItems
    );
  }
}
