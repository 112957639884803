import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { IPosLineItem, PosPriceType } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { Subject, Subscription } from 'rxjs';
import { PointOfSaleHelperService } from './../services/point-of-sale-helper.service';
import { PosPriceSelectorComponent } from './pos-price-selector/pos-price-selector.component';

@Component({
  selector: 'pos-order-table',
  templateUrl: './pos-order-table.component.html',
  styleUrls: ['./pos-order-table.component.scss']
})
export class PosOrderTableComponent implements OnInit, OnDestroy {

  @Input() currentOrderItems: IPosLineItem[] = [];
  @Input() customer: ICustomer;
  @Input() shiftId: number;
  @Output() itemRemoved = new EventEmitter();
  @Output() itemUpdated: EventEmitter<IPosLineItem> = new EventEmitter();

  private destroy$ = new Subject();
  /* private quantityChanged$ = new BehaviorSubject<{ item: IPosLineItem, newQty: number }>(null); */
  private subscription = new Subscription();

  constructor(
    private readonly bottomSheet: MatBottomSheet,
    private readonly helperService: PointOfSaleHelperService,
    private readonly fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.subscription.unsubscribe();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.currentOrderItems, event.previousIndex, event.currentIndex);
  }

  onItemRemove(item: IPosLineItem) {
    const removeSub = this.helperService.deleteItemTrail(this.shiftId, item, this.customer).subscribe(updatedItem => {
      this.itemRemoved.emit(item.id);
    });
    this.subscription.add(removeSub);
  }

  onItemUpdate($event) {
    this.itemUpdated.emit($event);
  }

  onQuantityBlur($event: any) {
    $event.target.value = this.helperService.normalizeQuantity(+$event.target.value);
  }

  onPriceSelect(item: IPosLineItem) {
    const bottomSheetRef = this.bottomSheet.open(PosPriceSelectorComponent, {
      data: {
        item
      }
    });

    bottomSheetRef.afterDismissed().subscribe(res => {
      if (res && res.updatedPrice) {
        const priceSub = this.helperService.changeItemPriceTrail(this.shiftId, item, this.customer, res.updatedPrice).subscribe(updatedItem => {
          // item.price = res.updatedPrice;
          this.itemUpdated.emit(updatedItem);
        });
        this.subscription.add(priceSub);
      }
    });

  }

  isPriceLessThanBasePrice(item: IPosLineItem): boolean {
    const basePrice = item.prices.find(p => p.type === PosPriceType.BASE_PRICE);
    return basePrice && item.price < basePrice.price;
  }
}
