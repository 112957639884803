import { IDeliveryContainer } from '@otrack-lib/models/settings/delivery-settings.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsActions from './settings.actions';
import { IStateBase } from '../data.reducer';
export const settingsFeatureKey = 'otrack-company-settings';

export interface ContainerSettigsDataState extends IStateBase {
  data: IDeliveryContainer[];
}


export interface State {
  deliveryContainer: ContainerSettigsDataState;
  // accountsCategoryList: AccountsCategoryDataState; add more in future
}

export const initialState: State = {
  deliveryContainer: { data: null, loading: false, success: false, error: null },
};

const settingsReducer = createReducer(
  initialState,
  on(SettingsActions.clearSettings, () => ( initialState )),
  on(SettingsActions.loadDeliveryContainerSettings, (state) => ( {...state,
    deliveryContainer: {...state.deliveryContainer,
      loading: true,
      success: false,
      error: null } }
      )),

  on(SettingsActions.loadDeliveryContainerSettingsFailure, (state, {error}) => ( {...state,
    deliveryContainer: {...state.deliveryContainer,
      loading: false,
      success: false,
      error: error } }
      )),

  on(SettingsActions.loadDeliveryContainerSettingsSuccess, (state,  {data }) => ( {...state,
    deliveryContainer: {...state.deliveryContainer,
    data: data,
    loading: false,
    success: true,
    error: null } }
    )),

  on(SettingsActions.deleteDeliveryContainer, (state) => ( {...state,
      deliveryContainer: {...state.deliveryContainer,
       // loading: true,
        error: null } }
     // }}
        )),

  on(SettingsActions.deleteDeliveryContainerFailure, (state, {error}) => ( {...state,
          deliveryContainer: {...state.deliveryContainer,
           // loading: false,
            error: error } }
            )),

);

export function reducer(state: State | undefined, action: Action) {
  return settingsReducer(state, action);
}
