import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'pos-confirmation-dialog',
  templateUrl: './pos-confirmation-dialog.component.html',
  styleUrls: ['./pos-confirmation-dialog.component.scss']
})
export class PosConfirmationDialogComponent implements OnInit {

  message = '';
  title = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly dialogRef: MatDialogRef<PosConfirmationDialogComponent>,
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
  }

  onYesClicked() {
    this.dialogRef.close({ confirm: true });
  }

  onNoClicked() {
    this.dialogRef.close({ confirm: false });
  }

}
