<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom" style="background: #a4cef3">
		<div class="kt-portlet__head-progress">
			<!-- here can place a progress bar-->
			<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
		</div>
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
        <div class="col-lg-1">
            <i class="flaticon-exclamation" style="color:#fb7434;font-size: 2rem;"></i>
        </div>
				<div class="col-lg-11" style="font-size: 1.2rem;">
					{{viewLoading ? data.waitDesciption : data.description}}
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<button mat-button  color="danger" (click)="onNoClick()">Cancel</button>&nbsp;
        <!-- <button mat-button color="primary" class="confirm-button" ></button> -->
        <button  (click)="onYesClick()" cdkFocusInitial [disabled]="viewLoading" type="button" class="btn btn-brand">
          <i class="la la-check"></i>
          <span class="kt-hidden-mobile">{{data.confirmButton}}</span>
        </button>
			</div>
		</div>
	</div>
</div>
