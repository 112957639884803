import { Component, OnInit, Input } from '@angular/core';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
@Component({
  selector: 'm-move-status-widget',
  templateUrl: './move-status-widget.component.html',
  styleUrls: ['./move-status-widget.component.scss']
})
export class MoveStatusWidgetComponent implements OnInit {
  @Input() fromStatus: SalesOrderStatus;
  @Input() toStatus: SalesOrderStatus;

  fromStatusDetail: any;
  toStatusDetail: any;
  constructor() {}

  ngOnInit() {}

  getDetail(saleOrderStatus: SalesOrderStatus) {
    switch (saleOrderStatus) {
      case SalesOrderStatus.Received:
        return { detail: 'Received Order', icon: 'move_to_inbox' };
      case SalesOrderStatus.Freezed:
        return { detail: 'Freeze Order', icon: 'ac_unit' };
      case SalesOrderStatus.Ready:
        return { detail: 'Ready Order', icon: 'local_shipping' };
      case SalesOrderStatus.ConvertedToInvoice:
        return { detail: 'Convert To Invoice', icon: 'send' };
      default:
        return { detail: 'UnKnown', icon: 'report_off' };
    }
  }
}
