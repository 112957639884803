import { LayoutUtilsService, MessageType } from '@admin-app/services/layout-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@otrack-lib/core/facade/data.service';
import { AuthService } from '@otrack-lib/core/services/auth.service';
import { CustomerService } from '@otrack-lib/core/services/customer.service';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { ProductService } from '@otrack-lib/core/services/product.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { ICustomerLastPrice } from '@otrack-lib/models/customer/customer-last-price.model';
import { ICustomerSummary } from '@otrack-lib/models/customer/customer-summary.model';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { IPosLineItem, IPosOrder, IPosOrderItem, IPosTrailItem } from '@otrack-lib/models/point-of-sale/point-of-sale.models';
import { IProduct } from '@otrack-lib/models/product/product.model';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject, Subscription, timer } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { POSKeyboardScreen, UserPreferenceService } from './../../../services/user-preference.service';
import { PosConfirmationDialogComponent } from './../pos-confirmation-dialog/pos-confirmation-dialog.component';
import { PosEndShiftComponent } from './../pos-end-shift/pos-end-shift.component';
import { PosPaymentComponent } from './../pos-payment/pos-payment.component';
import { PointOfSaleHelperService } from './../services/point-of-sale-helper.service';


export enum LoadCustomerType {
  CHANGE_CUSTOMER,
  RESTORE_HOLD_ORDER,
  PENDING_TRANSACTIONS,
}
export interface LoadCustomerWithType {
  type: LoadCustomerType;
  customerId: number;
  additionalData?: any;
}

@Component({
  selector: 'pos-home',
  templateUrl: './pos-home.component.html',
  styleUrls: ['./pos-home.component.scss']
})
export class PosHomeComponent implements OnInit, OnDestroy {
  loading = false;
  dateTime$: Observable<Date>;


  productList$ = this.dataService.getProductList();
  currentOrderItems: IPosLineItem[] = [];
  shiftId: number;
  resetCustomerSelection$ = new BehaviorSubject(false);
  restoreOrderProducts$ = new BehaviorSubject<IPosOrderItem[]>([]);
  pendingTransactions$ = new BehaviorSubject<IPosTrailItem[]>([]);
  autoFocus$ = new BehaviorSubject(null);
  posProducts$: Observable<IProduct[]>;

  itemAdded$: BehaviorSubject<IPosLineItem> = new BehaviorSubject(null);
  quickProductSelected$: BehaviorSubject<IProduct> = new BehaviorSubject(null);

  userSelectedKeyboardScreen$: Observable<POSKeyboardScreen>;
  POSKeyboardScreenEnum = POSKeyboardScreen;
  selectedScreen: POSKeyboardScreen;

  itemCount = 0;
  total = 0;
  totalTax = 0;
  netTotal = 0;

  showPrintInvoiceView = false;
  showRestoreHoldView = false;
  lastOrderMemo = '';
  amountFromKeypad = 0;

  public customerId$ = new BehaviorSubject<number>(null); // used to restore hold orders
  public customer$ = new BehaviorSubject<ICustomer>(null);
  public customerSummary$ = new BehaviorSubject<ICustomerSummary>(null);
  public customerLastPrices$ = new BehaviorSubject<ICustomerLastPrice[]>([]);
  public loadCustomer$ = new Subject<LoadCustomerWithType>();

  private destroy$ = new Subject();
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dataService: DataService,
    private readonly customerService: CustomerService,
    private readonly productService: ProductService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly posService: PointOfSaleService,
    private readonly helperService: PointOfSaleHelperService,
    private readonly layoutService: LayoutUtilsService,
    private readonly userPreferenceService: UserPreferenceService,
  ) {
  }

  ngOnInit() {
    // update date and time
    this.dateTime$ = timer(0, 1000).pipe(map(() => new Date()));
    this.userSelectedKeyboardScreen$ = this.userPreferenceService.getUserPreferedKeyboardScreen().pipe(tap(screen => {
      this.selectedScreen = screen;
      this.posProducts$ = this.productService.getPOSProducts();
    }));

    const customerLoadSub = this.loadCustomer$.pipe(
      takeUntil(this.destroy$),
      switchMap(data => this.loadCustomerDetail(data))
    ).subscribe(res => {
      if (res) {
        this.handleLoadCustomerDetail(res.data, res.customer, res.summary, res.lastPrices);
      } else {
        this.loading = false;
      }
    });
    this.subscriptions.add(customerLoadSub);

    // there must be a shift id exists to continue use the system, otherwise send back to pos-entry
    if (this.route.snapshot.data.shiftDetails && this.route.snapshot.data.shiftDetails.shiftId) {
      this.shiftId = this.route.snapshot.data.shiftDetails.shiftId;

      const pendingTransactions = this.route.snapshot.data.shiftDetails.pendingTransactions;
      if (pendingTransactions && pendingTransactions.length > 0) {
        const customerId = pendingTransactions[0].customerId;
        // this.loadPendingTransactions(customerId, pendingTransactions);
        this.loadCustomer$.next({
          type: LoadCustomerType.PENDING_TRANSACTIONS,
          customerId,
          additionalData: { pendingTransactions }
        });
      }
    } else {
      this.router.navigate(['/pos/entry']);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.subscriptions.unsubscribe();
  }

  loadCustomerDetail(data: LoadCustomerWithType): Observable<any> {
    return combineLatest([
      this.dataService.getCustomer(data.customerId),
      this.customerService.getCustomerSummary(data.customerId),
      this.customerService.getCustomerLastProductPrices(data.customerId)
    ]).pipe(
      map(([customer, summary, lastPrices]) => {
        return { data, customer, summary, lastPrices };
      })
    );
  }

  handleLoadCustomerDetail(
    data: LoadCustomerWithType,
    customer: ICustomer,
    summary: ICustomerSummary,
    lastPrices: { productId: number, lastPrice: number }[]
  ) {
    this.customerSummary$.next(summary);
    this.customerLastPrices$.next(lastPrices);
    this.customer$.next(customer);

    if (data.type === LoadCustomerType.PENDING_TRANSACTIONS) {
      this.customerId$.next(data.customerId);
      this.pendingTransactions$.next(data.additionalData.pendingTransactions);
      this.pendingTransactions$.next(null);

    } else if (data.type === LoadCustomerType.CHANGE_CUSTOMER) {
      this.autoFocus$.next({ barcode: true });

      // in the case of just customer information replced, but the order items still exists,
      // we'll need to recalculate taxes based on new customer
      // this.helperService.recalculateItems(customer, this.currentOrderItems);

    } else if (data.type === LoadCustomerType.RESTORE_HOLD_ORDER) {
      // update products in to table
      if (data.additionalData && data.additionalData.orderItems.length > 0) {
        this.customerId$.next(data.customerId);
        this.restoreOrderProducts$.next(data.additionalData.orderItems);
        this.onBackPressFromOtherView();
      }

    }

    this.loading = false;
  }

  onCustomerChange(customerId: any) {
    if (this.currentOrderItems.length > 0) {
      const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
        data: {
          title: 'Change Customer',
          message: 'Would you also like change customer, and void current transaction?'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res && res.confirm) {
          if (customerId) {
            this.loading = true;
            this.helperService.voidSales(this.shiftId)
              .pipe(
                switchMap(success => success ? this.helperService.changeCustomerTrail(this.shiftId, customerId) : EMPTY)
              )
              .subscribe(success => {
                if (success) {
                  this.clearLastSalesData(false);
                  this.loadCustomer$.next({ type: LoadCustomerType.CHANGE_CUSTOMER, customerId });
                } else {
                  this.loading = false;
                }
              });
          }
        } else {
          // set the last value, since the drop down selector changes the name,
          // even when dialog button NO is selected,
          // we need to revert it with the old value
          this.customerId$.next(this.customerId$.getValue());
        }
      });
    } else {
      if (customerId) {
        this.loadCustomer$.next({ type: LoadCustomerType.CHANGE_CUSTOMER, customerId });
      }
    }
  }

  onQuantityUpdatedFromKeypad(qty: number) {
    if (this.currentOrderItems.length > 0) {
      const lastItem = this.currentOrderItems[this.currentOrderItems.length - 1];
      let newQty = 0;
      if (qty > 0) {
        newQty = lastItem.quantity + 1;
      } else {
        newQty = lastItem.quantity - 1;
      }

      lastItem.formGroup.controls.qty.patchValue(newQty);
    }
  }

  onAmountUpdatedFromKeypad(amount: number) {
    this.amountFromKeypad = amount;
  }

  onQuickProductSelected(product: IProduct) {
    if (this.amountFromKeypad > 0) {
      const company = this.userService.getUserCompany();
      this.helperService.createPosLineItemFromProduct(
        product,
        this.customer$.value,
        company,
        this.customerLastPrices$.value,
        this.amountFromKeypad,
        this.shiftId,
        this.currentOrderItems
      ).subscribe(
        lineItem => {
          if (lineItem) {
            this.onItemUpdated(lineItem);
            this.quickProductSelected$.next(product);
          }

          this.amountFromKeypad = 0;
        }
      );
    } else {
      this.layoutService.showNotification(
        'Please enter amount on keypad first.',
        MessageType.Success,
        10000
      );
    }
  }

  onItemUpdated($event: any) {
    this.updateTotals();
  }

  onItemRemoved(itemId: any) {
    const index = this.currentOrderItems.findIndex(data => data.id === itemId);
    this.currentOrderItems.splice(index, 1);
    this.updateTotals();
  }

  changeScreen() {
    console.log(this.selectedScreen);
    if (this.selectedScreen === POSKeyboardScreen.Screen1) {
      this.userPreferenceService.setUserPreferedKeyboardScreen(POSKeyboardScreen.Screen2);
    } else {
      this.userPreferenceService.setUserPreferedKeyboardScreen(POSKeyboardScreen.Screen1);
    }
  }

  updateTotals() {
    this.itemCount = 0;
    this.total = 0;
    this.totalTax = 0;
    this.netTotal = 0;
    this.currentOrderItems.forEach(item => {
      this.itemCount += item.quantity;
      this.total += +(item.quantity * item.price).toFixed(2);
      this.totalTax += +(item.price * item.quantity * item.taxPercent).toFixed(2);
    });
    this.netTotal = this.total + this.totalTax;
  }

  onPayClicked() {
    if (this.shiftId) {
      const order: IPosOrder = {
        customerId: this.customer$.value.customerId,
        lines: this.currentOrderItems,
        itemCount: this.itemCount,
        total: this.total,
        totalTax: this.totalTax,
        netTotal: this.netTotal,
        isTaxable: this.customer$.value.isTaxable
      };

      const dialogRef = this.dialog.open(PosPaymentComponent, {
        data: {
          order,
          shiftId: this.shiftId
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }

        if (res.lastOrderMemo) {
          this.lastOrderMemo = res.lastOrderMemo;
        }

        this.clearLastSalesData(true);
      });
    }
  }

  onEndShiftClicked() {

    if (this.shiftId) {
      const dialogRef = this.dialog.open(PosEndShiftComponent, {
        data: {
          shiftId: this.shiftId
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }

        this.router.navigate(['/pos/entry']);
      });
    }
  }

  onLogoutClicked() {
    const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
      data: {
        title: 'Confirm Logout',
        message: 'Are you sure you want to logout?'
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.confirm) {
        this.logout();
      }
    });
  }

  onVoidSalesClicked() {
    const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
      data: {
        title: 'Confirm Void Sale',
        message: 'Are you sure you want to void the current sale?'
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.confirm) {
        const voidSalesSub = this.helperService.voidSales(this.shiftId).subscribe(success => {
          if (success) {
            this.clearLastSalesData(true);
          }
        });
        this.subscriptions.add(voidSalesSub);
      }
    });
  }

  clearLastSalesData(resetCustomer: boolean) {
    this.currentOrderItems = [];
    this.itemCount = 0;
    this.total = 0;
    this.totalTax = 0;
    this.netTotal = 0;
    this.customer$.next(null);
    this.customerSummary$.next(null);
    this.customerLastPrices$.next(null);
    this.resetCustomerSelection$.next(resetCustomer);
    this.restoreOrderProducts$.next(null);
    this.pendingTransactions$.next(null);
  }

  onHoldClicked() {
    if (this.shiftId) {
      const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
        data: {
          title: 'Confirm Hold Sale',
          message: 'Are you sure you want to hold the current sale?'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res && res.confirm) {
          this.loading = true;

          const order: IPosOrder = {
            customerId: this.customer$.value.customerId,
            lines: this.currentOrderItems,
            itemCount: this.itemCount,
            total: this.total,
            totalTax: this.totalTax,
            netTotal: this.netTotal,
            isTaxable: this.customer$.value.isTaxable
          };

          const dataToSend = this.helperService.createHoldPayload(order, this.shiftId);
          const subs = this.posService.holdOrder(dataToSend).subscribe(
            res => {
              if (res) {
                this.clearLastSalesData(true);
                this.layoutService.showNotification(
                  'Sale has been placed on hold successfully!',
                  MessageType.Success,
                  20000
                );
              }
              this.loading = false;
            },
            error => {
              this.layoutService.showNotification(
                'Sorry, we had an unexpected problem processing your request (sale on hold). Please try again or contact support.',
                MessageType.Error,
                20000
              );
              this.loading = false;
            }
          );
          this.subscriptions.add(subs);
        }
      });
    }
  }

  onRestoreHoldClicked() {
    if (this.shiftId) {
      this.showPrintInvoiceView = false;
      this.showRestoreHoldView = true;
    }
  }

  onRestoreClicked($event: any) {
    if (!$event.isEmpty()) {
      const orderId = +$event.selected[0].salesOrderId;
      const customerId = +$event.selected[0].customerId;
      const message = this.currentOrderItems.length > 0 ?
        `Would you like to void current sale before restoring Order # ${orderId}?` :
        'Are you sure you want to restore hold sale?';

      const dialogRef = this.dialog.open(PosConfirmationDialogComponent, {
        data: {
          title: `Confirm Restore Sale ${orderId}`,
          message
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res && res.confirm) {
          this.loading = true;
          let restoreObservable;
          if (this.currentOrderItems.length > 0) {
            restoreObservable = this.helperService.voidSales(this.shiftId)
              .pipe(
                switchMap(success => success ? this.posService.restoreOrder(orderId, customerId) : EMPTY)
              );
          } else {
            restoreObservable = this.posService.restoreOrder(orderId, customerId);
          }

          const restoreSub = restoreObservable
            .subscribe(
              orderItems => {
                this.handleRestoreSale(orderItems, orderId);
                this.loading = false;
              },
              error => {
                this.layoutService.showNotification(
                  `Sorry, we had an unexpected problem processing your request (restore hold sale # ${orderId}). Please try again or contact support.`,
                  MessageType.Error,
                  20000
                );
                this.loading = false;
              }
            );
          this.subscriptions.add(restoreSub);
        }
      });
    }
  }

  handleRestoreSale(orderItems: any, orderId: number) {
    if (orderItems) {
      if (this.currentOrderItems.length > 0) {
        this.clearLastSalesData(true);
      }

      if (orderItems.length > 0) {
        const customerId = orderItems[0].customerId;
        // this.loadPendingTransactions(customerId, pendingTransactions);
        this.loadCustomer$.next({
          type: LoadCustomerType.RESTORE_HOLD_ORDER,
          customerId,
          additionalData: { orderItems: orderItems }
        });
      }

      this.layoutService.showNotification(
        `Successfully restore hold sale # ${orderId}.`,
        MessageType.Error,
        20000
      );
    } else {
      this.layoutService.showNotification(
        `Sorry, we had an unexpected problem processing your request (restore hold sale # ${orderId}). Please try again or contact support.`,
        MessageType.Error,
        20000
      );
    }
  }

  onPrintInvoiceClicked() {
    if (this.shiftId) {
      this.showRestoreHoldView = false;
      this.showPrintInvoiceView = true;
    }
  }

  onBackPressFromOtherView() {
    this.showPrintInvoiceView = false;
    this.showRestoreHoldView = false;
  }

  async logout() {
    await this.authService.logout();
  }

  get companyName() {
    const company = this.userService.getUserCompany();
    return company.name ? company.name : this.username;
  }

  get username() {
    const user = this.userService.user;
    return user.displayName ? user.displayName : user.username;
  }
}
