import { AccessControlService } from '@otrack-lib/core/services/access-control.service';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { PermissionService } from '@otrack-lib/core/services/permission.service';

@Injectable()
export class MenuAsideService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(
    private permissionService: PermissionService,
    private accessControlService: AccessControlService,
    private menuConfigService: MenuConfigService) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
    const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
    const filteredMenuItems: any[] = this.accessControlService.applyPermissions(menuItems);
    this.menuList$.next(this.applyPermissions(filteredMenuItems));
  }

  applyPermissions(menuItems: any[]): any[] {
    return menuItems.filter(i => {
      const permissions = <any[]>i.viewPermissions;
      return permissions == null || permissions.some(p => this.permissionService.allowed(p));
    });
  }
}
