
import { Component, Input, OnInit } from '@angular/core';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ModeOfAdjustment } from '@otrack-lib/enums/inventory-type.enum';
import { IProductAdjustment } from '@otrack-lib/models/product/product.model';

@Component({
  selector: 'app-adjustment-template',
  templateUrl: './adjustment-template.component.html',
  styleUrls: ['./adjustment-template.component.scss']
})
export class AdjustmentTemplateComponent implements OnInit {
  @Input() public adjustment: IProductAdjustment;
  adjustmentType = ModeOfAdjustment;
  constructor(private printService: PrintService) { }

  ngOnInit(): void {
    if (this.printService.isPrinting && this.printService.documentData) {
      this.adjustment = this.printService.documentData[0];
      this.printService.onDataReady();
    }
  }
}
