import { ProductService } from './../../../../../../libs/core/services/product.service';

import { DataService } from '@otrack-lib/core/facade/data.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class DataResolver implements Resolve<boolean> {
  constructor(private dataService: DataService, private productService: ProductService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.dataService.preloadData();
    this.productService.loadPricingTiers().subscribe(); // load pricing tiers
    return this.dataService.isDataLoaded().pipe(filter(data => !data ), take(1));
  }
}
