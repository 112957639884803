export class QueryResultsModel {
  // fields
  items: any[];
  extended: any;
  additionalFields: any; // used for data like table footer (total fields)
  totalCount: number;
  errorMessage?: string;

  constructor(_items: any[] = [], _totalcount: number = -1, _errorMessage: string = '', additionalFields = null, inExtended = null) {
    this.items = _items;
    this.totalCount = _totalcount === -1 ? _items.length : _totalcount;
    this.additionalFields = additionalFields;
    this.extended = inExtended;
  }
}
