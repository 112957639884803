import { ModalInvoiceViewComponent } from '@admin-app/common/modals';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatSort } from '@angular/material';
import { PointOfSaleService } from '@otrack-lib/core/services/point-of-sale.service';
import { OrderTypes } from '@otrack-lib/enums';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
import { PosTransactionDataSource } from '../data-sources/pos-transcation.datasource';

@Component({
  selector: 'pos-invoices-table',
  templateUrl: './pos-invoices-table.component.html',
  styleUrls: ['./pos-invoices-table.component.scss']
})
export class PosInvoicesTableComponent implements OnInit {
  @Input() shiftId: number;
  @Output() backClicked = new EventEmitter();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // tslint:disable-next-line: max-line-length
  displayedColumns = ['date', 'transactionNumber', 'customerName', 'amount'];
  dataSource: PosTransactionDataSource;
  orderTypeEnum = OrderTypes;
  SalesOrderStatus = SalesOrderStatus;

  constructor(
    private readonly posService: PointOfSaleService,
    private readonly dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.dataSource = new PosTransactionDataSource(this.posService);
    this.loadOrdersList();

    this.sort.sortChange.subscribe(val => {
      this.dataSource.sortClientSide(val.active, val.direction);
    });
  }

  loadOrdersList() {
    if (this.shiftId) {
      this.dataSource.loadOrdersByShiftId(this.shiftId);
    }
  }


  openTransaction(transactionId: number, orderType: OrderTypes = null, refreshtable: boolean = false) {
    const dialogRef = this.dialog.open(ModalInvoiceViewComponent, {
      data: { orderId: transactionId, orderType: orderType, showEdit: false },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (refreshtable || res.refresh) {
          this.loadOrdersList();
        }
        return;
      }
    });
  }

}
