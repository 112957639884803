<div class="flex-container invoice print-invoice">

  <div class="report-print-header-container">
    <h3> {{ user.company.name }} </h3>
    <h5> {{ this.reportFilter ? getDateRangeInString() : '' }} </h5>
    <h5> POS Shifts Report </h5>
  </div>

  <table>
    <thead>
      <tr class="invoice-header">
        <th style="text-align: left;padding-left: 5px;">SHIFT #</th>
        <th style="text-align: left; padding-left: 5px;">SHIFT START</th>
        <th style="text-align: right; padding-right: 5px;">SHIFT END</th>
        <th style="text-align: right; padding-right: 5px;">STARTED BY</th>
        <th style="text-align: right; padding-right: 5px;">CLOSED BY</th>
        <th style="text-align: right; padding-right: 5px;">STARTING CASH</th>
        <th style="text-align: right; padding-right: 5px;">CLOSING CASH</th>
      </tr>
    </thead>

    <tbody>
      <tr class="invoice-items" *ngFor="let item of items">
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.sequenceNumber }} </td>
        <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ item.shiftStartDate | date:'dd MMM, yyyy, h:mm a' }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.shiftEndDate | date:'dd MMM, yyyy, h:mm a'  }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.startedByUserName }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.closedByUserName }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.startingCash | currency }} </td>
        <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{ item.closingCash | currency }} </td>
      </tr>
    </tbody>

  </table>
</div>
