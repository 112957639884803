import { UserManagementService } from '@otrack-lib/core/services/user-management.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { VendorService } from '@otrack-lib/core/services/vendor.service';
import { ProductService } from '@otrack-lib/core/services/product.service';
import { CustomerService } from '@otrack-lib/core/services/customer.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as DataActions from './data.actions';
import { DriverService } from '@otrack-lib/core/services/driver.service';


@Injectable()
export class DataEffects {

  loadCustomerDatas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataActions.loadCustomers),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.customerService.getCustomerList().pipe(
          map(data => DataActions.loadCustomerSuccess({ data })),
          catchError(error => of(DataActions.loadCustomerFailure({ error }))))
      )
    );
  });


  loadVendorDatas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataActions.loadVendors),
      concatMap(() =>
        this.vendorService.getVendorList().pipe(
          map(data => DataActions.loadVenderSuccess({ data })),
          catchError(error => of(DataActions.loadVenderFailure({ error }))))
      )
    );
  });


  loadProductDatas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataActions.loadProducts),
      concatMap(() =>
        this.productService.getAllProducts().pipe(
          map(data => DataActions.loadProductSuccess({ data })),
          catchError(error => of(DataActions.loadProductFailure({ error }))))
      )
    );
  });


  loadDrivesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataActions.loadDrivers),
      concatMap(() =>
        this.driverService.getDriversList().pipe(
          map(data => DataActions.loadDriversSuccess({ data })),
          catchError(error => of(DataActions.loadDriversFailure({ error }))))
      )
    );
  });

  loadEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataActions.loadEmployees),
      concatMap(() =>
        this.userManagmentService.getAllEmployees().pipe(
          map(data => DataActions.loadEmployeesSuccess({ data })),
          catchError(error => of(DataActions.loadEmployeesFailure({ error }))))
      )
    );
  });

  loadUserPermissionsDatas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataActions.loadUserPermissions),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.userService.getUserPermissions().pipe(
          map(data => DataActions.loadUserPermissionsSuccess({ data })),
          catchError(error => of(DataActions.loadUserPermissionsFailure({ error }))))
      )
    );
  });

  constructor(private actions$: Actions,
              private vendorService: VendorService,
              private userService: UserService,
              private customerService: CustomerService,
              private driverService: DriverService,
              private userManagmentService: UserManagementService,
              private productService: ProductService) {}

}
