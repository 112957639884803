export enum AppplicationPermissions {

  // Reports
  SalesAndCustomer = 'sales_customer',
  PurchasesAndVendor = 'purchases_vendor',
  Miscellaneous = 'miscellaneous',
  Reports = 'reports',
  PaymentReceivedReport = 'payment_received',
  ProfitAndLossReport = 'rp_profit_loss',
  PaymentReport = 'rp_payment',
  BalanceSheetReport = 'rp_balance_sheet',

  SaleByItemReport = 'rp_sales_by_item',
  SalesByCustomerReport = 'rp_sales_by_customer',
  TobaccoReport = 'rp_tobacco',
  PurchaseByItemReport = 'rp_purchase_by_item',
  PurchaseByVendorReport = 'rp_purchase_by_vendor',

  InventoryDetailReport = 'rp_inventory_detail',
  InventorySummaryReport = 'rp_inventory_summary',
  CustomerOpenBalanceReport = 'rp_customer_open_balance',

  CustomerTransactionsReport = 'rp_customer_transactions',
  VendorTransactionsReport = 'rp_vendor_transactions',
  CustomerAging = 'rp_customer_aging',
  VendorAgingReport = 'rp_vendor_aging',
  PosShiftsReport = 'rp_pos_shifts',

  // Other modules
  Delivery_Read = 'delivery',
  Delivery_Write = 'delivery.w',

  ChartOfAccounts_Write = 'chart_of_accounts.w',
  ChartOfAccounts_Read = 'chart_of_accounts',

  PaymentReceived_Write = 'payment_received.w',
  PaymentReceived_Read = 'payment_received',

  Product_Read = 'product',
  Product_Write = 'product.w',

  PriceTier_Write = 'price_tier.w',
  PriceTier_Read = 'price_tier',

  Adjustment_Read = 'adjustment',
  Adjustment_Write = 'adjustment.w',

  Vendor_Read = 'vendor',
  Vendor_Write = 'vendor.w',

  Purchase_Read = 'purchase',
  Purchase_Write = 'purchase.w',

  Expense_Read = 'expense',
  Expense_Write = 'expense.w',

  Pos_Read = 'pos',
  Pos_Write = 'pos.w',

  UserManagement_Read = 'user_management',
  UserManagement_Write = 'user_management.w',
  UserPublicInvitation = "user_public_invitation",

  Invoice_Read = 'invoice',
  Invoice_Edit = 'invoice.u'

}
