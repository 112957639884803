import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '@otrack-lib/core/helper-services/configuration.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
import { OrderTypes } from '@otrack-lib/enums';
import { BusinessType } from '@otrack-lib/enums/business-type.enum';
import { DeliveryType } from '@otrack-lib/enums/deliverytypes.enum';
import { SalesOrderType } from '@otrack-lib/enums/sales-order-type.enum';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { IAgingSummary } from '@otrack-lib/models/reports/aging-summary.model';
import { Subject } from 'rxjs';
import { UtilsService } from './../../../../core/helper-services/utils.service';
import { ReportService } from './../../../../core/services/report.service';
import { ICategorizedOrderList } from './../../../../models/order/order.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'otrack-printorder',
  templateUrl: './printorder-template1.component.html',
  styleUrls: ['./printorder-template1.component.scss', './table.scss']
})
export class PrintOrderTemplate1Component implements OnInit, AfterViewInit {
  // TODO: not in use, may be remove it ?
  public displayedColumns: string[] = ['qty', 'item', 'unitprice', 'cost'];
  // TODO: not in use, may be remove it ?
  public displayedColumns2: string[] = ['key', 'value'];

  public invoice: IInvoice;
  public cateogorizedOrderList: ICategorizedOrderList[];
  public orderType = OrderTypes;
  public isCustomerWithSRP = false;
  public totalQuantity = 0;
  public totalPrice = 0;
  public retailTotal = 0;
  public isBarCodeShow = false;
  public barcodeOptions = {};
  public isOTOCustomer = false;
  public businessType = BusinessType;
  public totalItems = 0;
  public totalExtRetail = 0;
  public customerAgingSummary$: Subject<IAgingSummary> = new Subject<IAgingSummary>();
  public customerId$: Subject<number> = new Subject<number>();

  reportFilter: ReportFiltersModel;

  constructor(
    private route: ActivatedRoute,
    protected invoiceService: InvoiceService,
    protected config: ConfigurationService,
    private ref: ChangeDetectorRef,
    private printService: PrintService,
    private reportService: ReportService,
    private elementRef: ElementRef,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.params['data']) {
      this.loadInvoice(this.route.snapshot.params['data'].split(','));
    }

    this.customerId$.subscribe(res => {
      if (res) {
        const reportFilter = new ReportFiltersModel(1, 10000, '');
        reportFilter.orderType = OrderTypes.All;
        const dataRange = this.utilsService.getDateRange('last-365-days');
        reportFilter.toDate = this.utilsService.getStringDate(new Date());
        reportFilter.customerFilterList = [res];

        this.reportService.getAgingSummaryReport(reportFilter)
          .subscribe(data => {
            if (data && data.items && data.items.length > 0) {
              this.customerAgingSummary$.next(data.items[0] as IAgingSummary);
            }

            this.onInvoiceReady();
          });
      }
    });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  loadInvoice(data: string[]) {
    this.isBarCodeShow = data[2] ? JSON.parse(data[2]) : false;
    const orderId: number = +data[1];

    if (data[0] === '1') {
      this.invoiceService.getOrder(orderId).subscribe(res => {
        if (res) {
          this.invoice = res;
          this.cateogorizedOrderList = InvoiceService.TransformOrderListIntoCategoryGrouping(res.items);
          this.totalItems = this.cateogorizedOrderList.reduce((a, b) => a + b.totalItems, 0);
          this.totalExtRetail = this.cateogorizedOrderList.reduce((a, b) => a + b.totalSRP, 0);
          this.customerId$.next(res.customerId);
        }
      });
    } else if (data[0] === '4') {
      this.invoiceService.getSalesOrder(orderId).subscribe(res => {
        this.invoice = res;
        this.invoice.orderType = OrderTypes.SalesOrder;
        this.onInvoiceReady();
      });
    }
  }

  onInvoiceReady() {
    this.barcodeOptions = {
      width: 1,
      height: 22,
      fontSize: 10,
      elementType: 'img'
    };

    this.isCustomerWithSRP = this.invoice.totalSuggestedRetailPrice > 0;

    // add SRP columns
    if (this.isCustomerWithSRP) {
      this.displayedColumns.splice(2, 0, 'suggestedRetailPrice');
      this.displayedColumns.splice(3, 0, 'retail');
    }

    // add barcode column
    if (this.isBarCodeShow) {
      this.displayedColumns.splice(1, 0, 'barcode');
    }

    this.calculatTotals();
    this.updateOTOCustomer();

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
    }, 100);
  }

  calculatTotals() {
    if (this.invoice) {
      this.invoice.items.forEach(c => {
        if (c.quantity > 0) {
          this.totalQuantity += c.quantity;
        }

        this.totalPrice += c.price * c.quantity;
      });

      this.retailTotal = this.invoice.totalSuggestedRetailPrice;
    }
  }

  updateOTOCustomer() {
    this.isOTOCustomer = this.invoice.isQBCustomer ? true : false;
  }

  get SalesOrderType() {
    return SalesOrderType;
  }

  get DeliveryType() {
    return DeliveryType;
  }
}
