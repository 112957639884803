import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn : 'root'})
export class LoaderService {

  private _loading = new BehaviorSubject(false);
  private _cpLoading = new BehaviorSubject(false);

  get loading(): boolean {
      return this._loading.getValue();
  }

  onRequestStarted(): void {
    this._loading.next(true);
  }

  onRequestFinished(): void {
    this._loading.next(false);
  }

  // onRequestStarted( isCanadaPostRequest: boolean = false ): void {
  //   isCanadaPostRequest ? this._cpLoading.next(true)  : this._loading.next(true);
  // }

  // onRequestFinished(isCanadaPostRequest: boolean = false ): void {
  //   isCanadaPostRequest ? this._cpLoading.next(false) : this._loading.next(false);
  // }
}
