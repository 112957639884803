import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '@otrack-lib/core/services/report.service';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { ITransactionList } from '@otrack-lib/models/order/transaction.model';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@otrack-lib/core/services/user.service';
import { IAuthUser } from '@otrack-lib/models/company/auth-user.model';
import * as moment from 'moment';

@Component({
  selector: 'app-printtransactionreport',
  templateUrl: './print-transaction-report.component.html',
  styleUrls: ['./print-transaction-report.component.scss']
})
export class PrintTransactionReportComponent implements OnInit, AfterViewInit {

  reportFilter: ReportFiltersModel;
  transactions: ITransactionList[];
  totalTax = 0;
  subTotalAmount = 0;
  totalAmount = 0;
  totalInvoiceBalance = 0;
  user: IAuthUser;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
    public printService: PrintService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.reportFilter = JSON.parse(localStorage.getItem('report_print'));
    if (this.reportFilter) {
      this.fetchTransactionSummaryReport();
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  fetchTransactionSummaryReport() {
    this.reportService.getTransactionSummaryReport(this.reportFilter).subscribe(
      (res) => {
        if (res) {
          this.transactions = res.items;
          this.onReportReady();
        }
      });
  }

  onReportReady() {
    // this.totalAmount = this.transactions.map(t => t.amount).reduce((acc, value) => acc + value, 0);
    // this.totalInvoiceBalance = this.transactions.map(t => t.invoiceBalance).reduce((acc, value) => acc + value, 0);

    this.transactions.forEach(item => {
      this.totalAmount += item.amount;
      this.totalInvoiceBalance += item.invoiceBalance;
      this.subTotalAmount += item.subTotal;
      this.totalTax += item.totalTax;
    });

    this.ref.detach();
    setTimeout(() => {
      this.ref.detectChanges();
      this.printService.onDataReady();
      localStorage.removeItem('report_print');
    }, 100);
  }

  getDateRangeInString(): string {
    const fromDate = new Date(this.reportFilter.fromDate);
    const toDate = new Date(this.reportFilter.toDate);

    return (
      moment(fromDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD') +
      '  -  ' +
      moment(toDate)
        .add(1, 'day')
        .format('YYYY-MMM-DD')
    );
  }

}
