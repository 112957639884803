import { AccessControlService } from '@otrack-lib/core/services/access-control.service';
// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private accessControlService: AccessControlService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const routePermission = route && route.data && route.data.permission ? route.data.permission : null;
    if ( routePermission == null ) {
        return of(true);
    }

    return this.accessControlService.isUserPermitted(routePermission);
  }
}
