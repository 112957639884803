
import { Injectable, Injector } from '@angular/core';
// Services
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseapi.service';
import { HttpUtilsService } from '../_base/crud';
import { IBillPaymentDetail, IUnpaidBill, IVendorPrePaymentDetail, IBillPayment } from '@otrack-lib/models/payment/bill-payment.model';
import { IPaymentHistoryInfo } from '@otrack-lib/models/payment/payment.model';
import { PaymentMethodTypes } from '@otrack-lib/enums/paymentMethod.enums';
import { ErrorModel } from '@otrack-lib/models/error.model';

@Injectable({
  providedIn: 'root'
})
export class BillPaymentService extends BaseApiService {
  readonly API_URLS = {
    paymentDetail: `${this.baseApiUrl}/v1/billpayments`,
    getUnpaidBills: `${this.baseApiUrl}/v1/bills/unpaid`,
    paymentHistoryInfo: `${this.baseApiUrl}/v1/billpayments/historyinfo`,// {{customerId}}
    creditApply: `${this.baseApiUrl}/v1/billpayments/creditreference`
  };

  constructor(private _injector: Injector, private httpUtils: HttpUtilsService) {
    super(_injector);
  }

  static getBillPaymentDetails(res: any): IBillPaymentDetail[] {
    const output: IBillPaymentDetail[] = [];

    res.forEach((item: { [x: string]: any; }) => {
      output.push({
        billId: +item['billId'],
        appliedAmount: item['appliedAmount'],
        billNumber: item['referenceNumber'] ? item['referenceNumber'] : item['billNumber'],
        isPaid: item['isPaid'],
        orderDate: item['orderDate'],
        paymentApplyDate: item['paymentApplyDate'],
        paymentAppliedBy: item['paymnetAppliedBy'],
        totalAmount: item['totalAmount'],
        amountDue: item['amountDue']
      });
    });
    return output;
  }

  static getUnPaidBillsModel(res: any): IUnpaidBill[] {
    const output: IUnpaidBill[] = [];
    res.forEach((item: { [x: string]: any; }) => {
      output.push({
        billId: +item['billId'],
        billDate: item['billDate'],
        billNo: item['billNo'],
        billBy: item['billBy'],
        totalAmount: item['totalAmount'],
        amountDue: item['amountDue'],
        referenceNumber: item['referenceNumber'],
        appliedAmount: 0,
        select: false
      });
    });

    return output;
  }

  static getPaymentHistoryModel(res: any): IPaymentHistoryInfo[] {
    const output: IPaymentHistoryInfo[] = [];
    if (res) {
      res.forEach((item: { [x: string]: any; }) => {
        output.push({
          eventDate: item['eventDate'],
          detail: item['eventDetail'],
          user: item['user'],
          paymentMethod: item['paymentMethod']
        });
      });
    }
    return output;
  }

  static getVendorPrePaymentDetail(res: { [x: string]: any; }): IVendorPrePaymentDetail {
    const output: IVendorPrePaymentDetail = {};
    if (res) {
      (output.address1 = res['address1']),
        (output.openBalance = res['openBalance']),
        (output.vendorId = res['vendorId']),
        (output.vendorName = res['vendorName'] ? res['vendorName'] : res['companyName']),
        (output.availableCreditAmount = res['availableCreditAmount']),
        (output.lastPaymentDate = res['lastPaymentDate']),
        (output.address1 = res['address1']),
        (output.address2 = res['address2']),
        (output.unpaidBills = BillPaymentService.getUnPaidBillsModel(res['unpaidBills']));
    }
    return output;
  }

  static getPaymentModel(res: any): IBillPayment {
    return {
      paymentId: +res['paymentId'],
      companyName: res['companyName'],
      unusedAmount: res['unusedAmount'],
      vendorId: res['vendorId'],
      vendorName: res['vendorName'],
      paidBills: BillPaymentService.getBillPaymentDetails(res['paidBills']),
      paymentBy: res['paymentBy'],
      paymentDate: res['paymentDate'],
      paymentMethod: res['paymentMethod'],
      chequeNo: res['paymentMethod'] === "Check" ? res['description'] : null,
      paymentMethodText: res['paymentMethod'] === "Check" ? `Check # ${res['description']}` : res['paymentMethod'],
      paymentNumber: res['paymentNumber'],
      paymentAmount: res['paymentAmount'],
      totalAmount: res['totalAmount'],
      accountId: res['accountId']
    };
  }

  static getPaidBillDetail(res: IBillPaymentDetail[]): any {
    const output: any[] = [];
    res.forEach(element => {
      output.push({ billId: element.billId, appliedAmount: element.appliedAmount });
    });
    return output;
  }

  getPaymentDetail(paymentId: number): Observable<IBillPayment> {
    return this.httpAuthGet(`${this.API_URLS.paymentDetail}/${paymentId}`).pipe(
      map(res => {
        const output: IBillPayment = BillPaymentService.getPaymentModel(res);
        return output;
      })
    );
  }

  getpaymentHistoryInfo(vendorId: number): Observable<IPaymentHistoryInfo[]> {
    return this.httpAuthGet(`${this.API_URLS.paymentHistoryInfo}/${vendorId}`).pipe(
      map(res => {
        const output: IPaymentHistoryInfo[] = BillPaymentService.getPaymentHistoryModel(res);
        return output;
      })
    );
  }

  getUnpaidBills(vendorId: number): Observable<IVendorPrePaymentDetail> {
    return this.httpAuthGet(`${this.API_URLS.getUnpaidBills}/${vendorId}`).pipe(
      map(res => {
        const output: IVendorPrePaymentDetail = BillPaymentService.getVendorPrePaymentDetail(res);
        return output;
      })
    );
  }


  editBillPayment(payment: IBillPayment): Observable<any> {
    const dataToSend = {
      paymentId: payment.paymentId,
      accountId: payment.accountId,
      paymentNumber: payment.paymentNumber,
      vendorId: payment.vendorId,
      description: payment.chequeNo,
      memo: payment.memo,
      paymentAmount: +payment.paymentAmount,
      paymentDate: payment.paymentDate,
      paymentMethod: PaymentMethodTypes[payment.paymentMethod],
      paidBills: BillPaymentService.getPaidBillDetail(payment.paidBills)
    };

    return this.httpAuthPost(`${this.API_URLS.paymentDetail}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res ? res.paymentId : 0;
        }
        throw new ErrorModel(null, 'Bill Payment not found');
      })
    );
  }

  deleteBillPayment(paymentId: number): Observable<boolean> {
    const deletePaymentUrl = `${this.API_URLS.paymentDetail}/${paymentId}`;
    return this.httpAuthDelete(`${deletePaymentUrl}`);
  }

  postPayment(payment: IBillPayment): Observable<any> {
    const dataToSend = {
      vendorId: payment.vendorId,
      accountId: payment.accountId,
      description: payment.chequeNo,
      memo: payment.memo,
      paymentAmount: +payment.paymentAmount,
      paymentDate: payment.paymentDate,
      paymentMethod: PaymentMethodTypes[payment.paymentMethod],
      paidBills: BillPaymentService.getPaidBillDetail(payment.paidBills)
    };

    return this.httpAuthPost(`${this.API_URLS.paymentDetail}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res ? res.paymentId : 0;
        }
        throw new ErrorModel(null, 'Vendor not found');
        // return true;
      })
    );
  }

  applyCreditPayment(payment: IBillPayment): Observable<any> {
    const dataToSend = {
      vendorId: payment.vendorId,
      creditAmount: payment.paymentAmount,
      paidBills: BillPaymentService.getPaidBillDetail(payment.paidBills)
    };

    return this.httpAuthPost(`${this.API_URLS.creditApply}`, dataToSend).pipe(
      map(res => {
        if (res) {
          return res;
        }
        throw new ErrorModel(null, 'Vendor not found');
      })
    );
  }
}
