import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from '../helper-services/notification.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  __userLogOutError$ = new BehaviorSubject<boolean>(false);
  __errorMessage$ = new BehaviorSubject<string>('');
  IsUserLogOut: Observable<boolean> = this.__userLogOutError$.asObservable();
  IsErrorMessage: Observable<string> = this.__errorMessage$.asObservable();

  constructor(private _injector: Injector) {
    // super(_injector);
  }

  setUserLoggedOut() {
    this.__userLogOutError$.next(true);
  }

  setErrorMessag(message: string) {
    this.__errorMessage$.next(message);
  }

  setError(error: Error) {
    const nSer = this._injector.get(NotificationService);
    const msg = this.getErrorMessage(error);
    nSer.showError(msg);

    this.__errorMessage$.next(msg);
  }

  getClientErrorMessage(error: Error | ErrorModel): string {
    return this.getErrorMessage(error);
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ?
      error.message :
      'No Internet Connection';
  }

  private getErrorMessage(error: Error | ErrorModel): string {
    var msg = error.message ?
      error.message :
      "Sorry, we had an unexpected problem processing your request. Please try again or contact support.";

    if (error.message && error.message.includes('is closed')) {
      msg = 'The current shift is closed maybe from another computer, please try refreshing the page to start a new shift.';
    }

    return msg;
  }

}
