import { environment } from './../../../environments/environment';
// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '@admin-app/common/layout-core';
// Object-Path
import * as objectPath from 'object-path';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'kt-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  fluid: boolean;
  versionNo: string;
  /**
   * Component constructor
   *
   * @param layoutConfigService: LayouConfigService
   */
  constructor(private layoutConfigService: LayoutConfigService) {
    this.versionNo = environment.build;
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();

    // footer width fluid
    this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
  }
}
