  <h3 matSubheader>Select Price</h3>
  <mat-selection-list #prices [multiple]="false" [(ngModel)]="selectedOptions">

    <mat-list-option checkboxPosition="before" [value]="newPrice">
      <div class="form-group row">
        <label class="col-6 col-form-label price-name">{{newPrice.name}}</label>
        <input type="number" placeholder="0.00" class="col-6 form-control price-input" [ngModel]="newPrice.price" (ngModelChange)="onPriceChange($event)">
      </div>
    </mat-list-option>

    <mat-list-option *ngFor="let price of priceList" checkboxPosition="before" [value]="price">
      <div class="form-group row">
        <label class="col-6 col-form-label price-name">{{price.name}}</label>
        <label class="col-6 col-form-label price-text">{{ price.price | currency }}</label>
      </div>
    </mat-list-option>

  </mat-selection-list>

  <div class="modal-footer">
    <div class="row text-right">
      <div class="col-lg-12">
        <button type="button" mat-raised-button class="rounded-button cancel-button" (click)="onClose()"> Cancel
        </button>
        <button type="button" mat-raised-button class="rounded-button save-button" (click)="onSubmit()"> Save </button>
      </div>
    </div>
  </div>