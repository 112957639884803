<div class="main-box">
  <div class="icon-box"><i  class="material-icons icon-size">{{ getDetail(fromStatus).icon }} </i>
    <span class="icon-name">{{ getDetail(fromStatus).detail }} </span>
  </div>
  <div class="icon-box">
      <i  class="material-icons icon-size icon-arror" > forward </i></div>
  <div class="icon-box">
      <i  class="material-icons icon-size">{{ getDetail(toStatus).icon }}</i>
    <span class="icon-name">{{getDetail(toStatus).detail}}</span>
  </div>
</div>