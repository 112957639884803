import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { IProduct } from '@otrack-lib/models/product/product.model';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ProductService } from '@otrack-lib/core/services/product.service';
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pos-quick-products',
  templateUrl: './pos-quick-products.component.html',
  styleUrls: ['./pos-quick-products.component.scss']
})
export class PosQuickProductsComponent implements OnInit, OnDestroy {
  @Input() customer: ICustomer;
  @Input() products: IProduct[];
  @Output() productSelected = new EventEmitter<IProduct>();
  private destroy$: Subject<boolean>;
  private subscriptions: Subscription[] = [];

  constructor(private readonly productService: ProductService) { }

  ngOnInit() {
    this.destroy$ = new Subject();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$ = null;
  }

  loadQuickProducts(): Subscription {
    return this.productService.getPOSProducts().pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.products = res;
      }
    });
  }

  onProductSelected(product: IProduct) {
    this.productSelected.emit(product);
  }

}
