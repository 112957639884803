import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigurationService } from '../helper-services/configuration.service';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../helper-services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private configurationService: ConfigurationService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.configurationService.config.env.toUpperCase() !== 'LOCAL') {
      const cssToken = this.authService.csstCookie;
      if (!cssToken) {// mean user is signOut
        await this.authService.logoutClient(true);
        return false;
      } else if (!this.authService.isEqualLocalAndCookieCsst) {
        const isSessionReloaded = await this.authService.reloadSession(cssToken);
        if (!isSessionReloaded) {
          await this.authService.logoutClient(true);
          return false;
        }
      }
    }

    const user = await this.authService.isAuthenticated();
    if (!user) {
      const sessionCheck = await this.authService.doGoogleCloudCheckStatusCall();
      if (!sessionCheck) {
        await this.authService.logoutClient(true);
        return false;
      }
    }
    return true;
  }
}
