import { DiscountType } from './../../../enums/discount-type.enum';
// Angular
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';
import { OrderTypes } from '@otrack-lib/enums';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { SalesOrderType } from '@otrack-lib/enums/sales-order-type.enum';
import { DeliveryType } from '@otrack-lib/enums/deliverytypes.enum';
import { ConfigurationService } from '@otrack-lib/core/helper-services/configuration.service';
import { BusinessType } from '@otrack-lib/enums/business-type.enum';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceTemplateComponent implements OnInit {
  // Private properties
  @Input() invoice: IInvoice;
  public displayedColumns: string[] = ['qty', 'item', 'unitprice', 'cost'];
  public isCustomerWithSRP = false;
  @Input() isBarCodeShow = false;
  @Input() currencyCode;
  public totalQuantity = 0;
  public totalPrice = 0;
  public retailTotal = 0;
  public barcodeOptions = {};
  public isOTOCustomer = false;
  public businessType = BusinessType;

  constructor(private printService: PrintService, protected config: ConfigurationService) { }

  ngOnInit() {
    if (this.printService.isPrinting && this.printService.documentData) {
      this.invoice = this.printService.documentData[0];
      this.isBarCodeShow = this.printService.documentData[1];
      this.printService.onDataReady();
    }

    this.isCustomerWithSRP = this.invoice.totalSuggestedRetailPrice > 0;

    // add SRP columns
    if (this.isCustomerWithSRP) {
      this.displayedColumns.splice(2, 0, 'suggestedRetailPrice');
      this.displayedColumns.splice(3, 0, 'retail');
    }

    // add barcode column
    if (this.isBarCodeShow) {
      this.displayedColumns.splice(1, 0, 'barcode');
    }

    this.barcodeOptions = {
      width: 1,
      height: 22,
      fontSize: 10,
      elementType: 'img'
    };

    this.calculatTotals();
    this.updateOTOCustomer();
  }

  calculatTotals() {
    let totalRetail = 0;
    if (this.invoice) {
      this.invoice.items.forEach(c => {
        if (c.quantity > 0) {
          this.totalQuantity += c.quantity;
        }
        totalRetail += c.suggestedRetailPrice * c.unitPerCase * c.quantity;
        this.totalPrice += c.price * c.quantity;
      });

      this.retailTotal = totalRetail; // this.invoice.totalSuggestedRetailPrice;
    }
  }

  toggleBarcode() {
    this.isBarCodeShow = !this.isBarCodeShow;
  }

  get orderType() {
    return OrderTypes;
  }

  get SalesOrderType() {
    return SalesOrderType;
  }

  get DeliveryType() {
    return DeliveryType;
  }

  get DiscountType() {
    return DiscountType;
  }

  updateOTOCustomer() {
    this.isOTOCustomer = this.invoice.isQBCustomer ? true : (this.invoice.customer && this.invoice.customer.isQBCustomer) ? this.invoice.customer.isQBCustomer : false;
  }
}
